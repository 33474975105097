import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Button, Modal } from '@carbon/react';
import Select from 'react-select';
import { useESG } from '../../action/ESGData/esgdata';
import { useHistory } from 'react-router-dom';
import { esgData } from '../../content/esgDataMapping';
import LoadingOverlay from '../Loader/Loader';
import { auth } from '../../action/fiirebase_config/firebase_config';
import videoIntro from '../../assets/video-intro.mp4';
import { ArrowRight, Help } from '@carbon/react/icons';
export const PopUp = ({ showModal, setShowModal, reportRef }) => {
  const options = [
    { value: 'A', label: 'A - AGRICOLTURA, SILVICOLTURA E PESCA' },
    { value: 'B', label: 'B - ESTRAZIONE DI MINERALI DA CAVE E MINIERE' },
    { value: 'C', label: 'C - ATTIVITÀ MANIFATTURIERE' },
    {
      value: 'D',
      label:
        'D - FORNITURA DI ENERGIA ELETTRICA, GAS, VAPORE E ARIA CONDIZIONATA',
    },
    {
      value: 'E',
      label:
        'E - FORNITURA DI ACQUA; RETI FOGNARIE, ATTIVITÀ DI GESTIONE DEI RIFIUTI E RISANAMENTO',
    },
    { value: 'F', label: 'F - COSTRUZIONI' },
    {
      value: 'G',
      label:
        "G - COMMERCIO ALL'INGROSSO E AL DETTAGLIO; RIPARAZIONE DI AUTOVEICOLI E MOTOCICLI",
    },
    { value: 'H', label: 'H - TRASPORTO E MAGAZZINAGGIO' },
    {
      value: 'I',
      label: 'I - ATTIVITÀ DEI SERVIZI DI ALLOGGIO E DI RISTORAZIONE',
    },
    { value: 'J', label: 'J - SERVIZI DI INFORMAZIONE E COMUNICAZIONE' },
    { value: 'K', label: 'K - ATTIVITÀ FINANZIARIE E ASSICURATIVE' },
    { value: 'L', label: 'L - ATTIVITÀ IMMOBILIARI' },
    {
      value: 'M',
      label: 'M - ATTIVITÀ PROFESSIONALI, SCIENTIFICHE E TECNICHE',
    },
    {
      value: 'N',
      label:
        'N - NOLEGGIO, AGENZIE DI VIAGGIO, SERVIZI DI SUPPORTO ALLE IMPRESE',
    },
    {
      value: 'O',
      label:
        'O - AMMINISTRAZIONE PUBBLICA E DIFESA; ASSICURAZIONE SOCIALE OBBLIGATORIA',
    },
    { value: 'P', label: 'P - ISTRUZIONE' },
    { value: 'Q', label: 'Q - SANITÀ E ASSISTENZA SOCIALE' },
    {
      value: 'R',
      label:
        'R - ATTIVITÀ ARTISTICHE, SPORTIVE, DI INTRATTENIMENTO E DIVERTIMENTO',
    },
    { value: 'S', label: 'S - ALTRE ATTIVITÀ DI SERVIZI' },
    {
      value: 'T',
      label:
        'T - ATTIVITÀ DI FAMIGLIE E CONVIVENZE COME DATORI DI LAVORO PER PERSONALE DOMESTICO; PRODUZIONE DI BENI E SERVIZI INDIFFERENZIATI PER USO PROPRIO DA PARTE DI FAMIGLIE E CONVIVENZE',
    },
    { value: 'U', label: 'U - ORGANIZZAZIONI ED ORGANISMI EXTRATERRITORIALI' },
  ];
  const { create_new_report, reportID } = useESG();
  const modalRef = useRef();
  const [sector, setSector] = useState('');
  const [viewSector, setViewSector] = useState(false);
  const [viewIntro, setViewIntro] = useState(true);
  const [isModalError, setIsModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const history = useHistory();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);
  const handleNoIntro = () => {
    setIsLoading(true);

    create_new_report()
      .then(value => {
        if (value != 'KO' && value != undefined) {
          setIsLoading(false);
          if (Object.keys(reportRef).length != 0) {
            reportRef.set({
              payment_status: 'shipped',
              userID: auth.currentUser.uid,
            });
          }

          history.push({
            pathname: '/esgstd',
            state: { visible_name: value['visible_name'] },
          });
        } else {
          setIsLoading(false);
          setIsModalError(true);
        }
      })
      .catch(error => {
        setIsLoading(false);
        setIsModalError(true);
      });
  };
  const returnHome = () => {
    auth.signOut();
  };
  return (
    <>
      {isLoading && (
        <LoadingOverlay main_text={'Stiamo creando il tuo report...'} />
      )}
      {showModal ? (
        <div className="background" ref={modalRef}>
          <animated.div style={animation}>
            <div className="modal-wrapper" showModal={showModal}>
              <div src={''} alt="camera" />
              {viewIntro ? (
                <div className="modal-content">
                  <h1>Benvenuto</h1>
                  <p>
                    Stiamo preparando il tuo nuovo report di sostenibilità, nel
                    frattempo segui con attenzione il video. Contiene le
                    istruzioni per utilizzare al meglio la piattaforma. <br />
                    Ti ricordiamo che questo tutorial è accessibile in qualunque
                    momento cliccando l'icona <Help />.
                  </p>
                  <video autoPlay controls style={{ height: '60vh' }}>
                    {' '}
                    <source src={videoIntro} type="video/mp4" />
                  </video>
                  <div className="hori_center">
                    {/*<Button
                      onClick={() => {
                        setViewSector(prev => true);
                        setViewIntro(prev => false);
                      }}>
                      Sì (Consigliato)
                    </Button> */}

                    <Button
                      renderIcon={ArrowRight}
                      onClick={handleNoIntro}
                      kind="secondary">
                      Prosegui
                    </Button>
                  </div>
                </div>
              ) : null}

              {viewSector ? (
                <div className="modal-content">
                  <p>Seleziona l'ambito operativo della tua azienda</p>
                  <Select
                    className="option_select_sector"
                    options={options}
                    onChange={target => {
                      setSector(prev => target.value);
                    }}
                  />
                  <div className="hori_center">
                    <Button
                      disabled={sector == '' ? true : false}
                      onClick={() => setShowModal(prev => false)}>
                      Avanti
                    </Button>
                  </div>
                </div>
              ) : null}
              <img
                aria-label="Close modal"
                onClick={() => setShowModal(prev => !prev)}
              />
            </div>
          </animated.div>
        </div>
      ) : null}
      <Modal
        open={isModalError}
        modalHeading="Si è verificato un errore"
        modalLabel="Errore piattaforma"
        primaryButtonText="Ritorna alla Home Page"
        onRequestSubmit={returnHome}>
        <p
          style={{
            marginBottom: '1rem',
          }}>
          Il server non risponde.
        </p>
      </Modal>
      ;
    </>
  );
};
