import Benefit from '../../../../assets/html_template/professional/A4/12-sociale-gri402-1-benefit.html';
import Benefit169 from '../../../../assets/html_template/professional/169/12-sociale-gri402-1-benefit.html';
import BenefitPopA4 from '../../../../assets/html_template/pop/A4/12-sociale-gri402-1-benefit.html';
import BenefitPop169 from '../../../../assets/html_template/pop/169/12-sociale-gri402-1-benefit.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const BenefitEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? Benefit : Benefit169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString = type == 'A4' ? BenefitPopA4 : BenefitPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? Benefit : Benefit169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [descrizioneBenefit, setDescrizioneBenefit] = useState('');
  const [benefitTable, setBenefitTable] = useState('');

  const [benefitData, SetBenefitData] = useState(Benefit);
  const [benefitDataA4, setbenefitDataA4] = useState(Benefit);
  const [benefitData169, setbenefitData169] = useState(Benefit169);
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetBenefitData(BenefitPopA4);
    setbenefitDataA4(BenefitPopA4);
    setbenefitData169(BenefitPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetBenefitData(Benefit);
    setbenefitDataA4(Benefit);
    setbenefitData169(Benefit169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetBenefitData(Benefit);
    setbenefitDataA4(Benefit);
    setbenefitData169(Benefit169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetBenefitData(BenefitPopA4);
      setbenefitDataA4(BenefitPopA4);
      setbenefitData169(BenefitPop169);
      force_update(type == 'A4' ? BenefitPopA4 : BenefitPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetBenefitData(Benefit);
      setbenefitDataA4(Benefit);
      setbenefitData169(Benefit169);
      force_update(type == 'A4' ? Benefit : Benefit169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetBenefitData(Benefit);
      setbenefitDataA4(Benefit);
      setbenefitData169(Benefit169);
      force_update(type == 'A4' ? Benefit : Benefit169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('402-1')
    ) {
      setIsVisible(false);
      delPageData('402-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(benefitData);
    } else {
      if (
        esgData['402-1'] != undefined &&
        esgData['402-1']['descrizione_benefit'] != undefined &&
        esgData['402-1']['descrizione_benefit'] != descrizioneBenefit
      ) {
        hasUpdate = true;
      }
      if (
        esgData['402-1'] != undefined &&
        esgData['402-1']['tabella_benefit'] != undefined &&
        !compareLists(esgData['402-1']['tabella_benefit'], benefitTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = [
      'descrizione',
      'tipologia',
      'operai',
      'impiegati',
      'dirigenti',
    ];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = benefitData169;
        to_init = '169';
      } else {
        templateHTMLString = benefitDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['402-1'] != undefined &&
        esgData['402-1']['descrizione_benefit'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'descrizione_benefit'
        );
        fotoLeaderElement.innerHTML = esgData['402-1']['descrizione_benefit'];
        setDescrizioneBenefit(esgData['402-1']['descrizione_benefit']);
      }
      //&&!compareLists(tabellacriticita,esgData["402-1"]["rendicontazione_criticita"])

      if (
        esgData['402-1'] != undefined &&
        esgData['402-1']['tabella_benefit'] != undefined
      ) {
        const table = parsedHTML.getElementById('tabella_benefit');
        if (esgData['402-1']['tabella_benefit'].length > 0) {
          const valori_visualizzati = esgData['402-1'][
            'tabella_benefit'
          ].filter(
            item =>
              item.impiegati !== 0 || item.operai !== 0 || item.dirigenti !== 0
          );

          popolaTabella(table, valori_visualizzati, ordered_keys);
          setBenefitTable(cloneDeep(esgData['402-1']['tabella_benefit']));
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('402-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Benefit per i Dipendenti',
          initA4: BenefitPopA4,
          init169: BenefitPop169,
          keys_to_check: ['descrizione_benefit', 'tabella_benefit'],
          gri: '402-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('402-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Benefit per i Dipendenti',
          initA4: Benefit,
          init169: Benefit169,
          keys_to_check: ['descrizione_benefit', 'tabella_benefit'],
          gri: '402-1',
        });
      }
    }
  };
  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const ordered_keys = [
        'descrizione',
        'tipologia',
        'operai',
        'impiegati',
        'dirigenti',
      ];
      const templateHTMLString =
        templateData == '' ? benefitData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['402-1'] != undefined &&
          esgData['402-1']['descrizione_benefit'] != undefined &&
          esgData['402-1']['descrizione_benefit'] != descrizioneBenefit
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_benefit'
          );
          fotoLeaderElement.innerHTML = esgData['402-1']['descrizione_benefit'];
          setDescrizioneBenefit(esgData['402-1']['descrizione_benefit']);
        }
        //&&!compareLists(tabellacriticita,esgData["402-1"]["rendicontazione_criticita"])

        if (
          esgData['402-1'] != undefined &&
          esgData['402-1']['tabella_benefit'] != undefined &&
          !compareLists(esgData['402-1']['tabella_benefit'], benefitTable)
        ) {
          const table = parsedHTML.getElementById('tabella_benefit');
          if (esgData['402-1']['tabella_benefit'].length > 0) {
            const valori_visualizzati = esgData['402-1'][
              'tabella_benefit'
            ].filter(
              item =>
                item.impiegati !== 0 ||
                item.operai !== 0 ||
                item.dirigenti !== 0
            );
            popolaTabella(table, valori_visualizzati, ordered_keys);
            setBenefitTable(cloneDeep(esgData['402-1']['tabella_benefit']));
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['402-1'] != undefined &&
          esgData['402-1']['descrizione_benefit'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_benefit'
          );
          fotoLeaderElement.innerHTML = esgData['402-1']['descrizione_benefit'];
          setDescrizioneBenefit(esgData['402-1']['descrizione_benefit']);
        }
        //&&!compareLists(tabellacriticita,esgData["402-1"]["rendicontazione_criticita"])

        if (
          esgData['402-1'] != undefined &&
          esgData['402-1']['tabella_benefit'] != undefined
        ) {
          const table = parsedHTML.getElementById('tabella_benefit');
          if (esgData['402-1']['tabella_benefit'].length > 0) {
            const valori_visualizzati = esgData['402-1'][
              'tabella_benefit'
            ].filter(
              item =>
                item.impiegati !== 0 ||
                item.operai !== 0 ||
                item.dirigenti !== 0
            );
            popolaTabella(table, valori_visualizzati, ordered_keys);
            setBenefitTable(cloneDeep(esgData['402-1']['tabella_benefit']));
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetBenefitData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default BenefitEngine;
