import Outro from '../../../../assets/html_template/professional/A4/14-end.html';
import Outro169 from '../../../../assets/html_template/professional/169/14-end.html';
import OutroPopA4 from '../../../../assets/html_template/pop/A4/14-end.html';
import OutroPop169 from '../../../../assets/html_template/pop/169/14-end.html';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';

const OutroEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? Outro : Outro169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString = type == 'A4' ? OutroPopA4 : OutroPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? Outro : Outro169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [logo_data, setLogoData] = useState('');
  const [OutroData, setOutroData] = useState(Outro);
  const [OutroDataA4, setOutroDataA4] = useState(Outro);
  const [OutroData169, setOutroData169] = useState(Outro169);
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    setOutroData(OutroPopA4);
    setOutroDataA4(OutroPopA4);
    setOutroData169(OutroPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    setOutroData(Outro);
    setOutroDataA4(Outro);
    setOutroData169(Outro169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    setOutroData(Outro);
    setOutroDataA4(Outro);
    setOutroData169(Outro169);
  }
  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();
  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setOutroData(OutroPopA4);
      setOutroDataA4(OutroPopA4);
      setOutroData169(OutroPop169);
      force_update(type == 'A4' ? OutroPopA4 : OutroPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setOutroData(Outro);
      setOutroDataA4(Outro);
      setOutroData169(Outro169);
      force_update(type == 'A4' ? Outro : Outro);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);

      setOutroData(Outro);
      setOutroDataA4(Outro);
      setOutroData169(Outro169);
      force_update(type == 'A4' ? Outro : Outro);
    }
    var changed = '';
    if (
      esgData['102-1'] != undefined &&
      esgData['102-1']['logoData'] != undefined &&
      esgData['102-1']['logoData'] != logo_data
    ) {
      changed = changed + ' gestione criticità,';
      hasUpdate = true;
    }

    if (hasUpdate) {
      if (esgData != undefined) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);
  const force_update = templateData => {
    if (esgData != undefined) {
      renderHTMLtoiFrame(templateData);
    }
  };

  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = OutroData169;
        to_init = '169';
      } else {
        templateHTMLString = OutroDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['102-1'] != undefined &&
        esgData['102-1']['logoData']['img_data'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById('logo_data');
        fotoLeaderElement.src = esgData['102-1']['logoData']['img_data'];
        setLogoData(esgData['102-1']['logoData']['img_data']);
      }

      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('outro', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Copertina chiusura',
          initA4: OutroPopA4,
          init169: OutroPop169,
          keys_to_check: ['logo_data'],
          gri: 'outro',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('outro', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Copertina chiusura',
          initA4: Outro,
          init169: Outro169,
          keys_to_check: ['logo_data'],
          gri: 'outro',
        });
      }
    }
  };
  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString = templateData == '' ? OutroData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['logoData'] != undefined &&
          esgData['102-1']['logoData']['img_data'] != logo_data
        ) {
          const fotoLeaderElement = parsedHTML.getElementById('logo_data');
          fotoLeaderElement.src = esgData['102-1']['logoData']['img_data'];
          setLogoData(esgData['102-1']['logoData']['img_data']);
        }
      } else {
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['logoData'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById('logo_data');
          fotoLeaderElement.src = esgData['102-1']['logoData']['img_data'];
          setLogoData(esgData['102-1']['logoData']['img_data']);
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      setOutroData(parsedHTML.documentElement.outerHTML);
      updateDataUrl(blobURL);
      setIframeURL(blobURL);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      <iframe title="Report" src={iframeURL} style={imageRes} />
    </div>
  );
};

export default OutroEngine;
