import React, { useState, useEffect } from 'react';
import { TextInput, DataTable, Stack, TextArea } from '@carbon/react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG102_21 = () => {
  const currentYear = new Date().getFullYear();
  const [esg102_21, setEsg102_21] = useState();
  const [tableData, setTableData] = useState([
    { year: currentYear - 3, totalA: 0, totalB: 0, rapport: 0 },
    { year: currentYear - 2, totalA: 0, totalB: 0, rapport: 0 },
    { year: currentYear - 1, totalA: 0, totalB: 0, rapport: 0 },
  ]);

  const [tableDataAumenti, setTableDataAumenti] = useState([
    { year: currentYear - 1, aumentoA: 0, aumentoB: 0, rapportAumento: 0 },
    { year: currentYear, aumentoA: 0, aumentoB: 0, rapportAumento: 0 },
  ]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const calculateRatio = (totalA, avgB) => {
    if (totalA !== 0 && avgB !== 0) {
      return (totalA / avgB).toFixed(2);
    } else {
      return 0;
    }
  };
  useEffect(() => {
    if (esgData['102-21'] != undefined) {
      setEsg102_21(esgData['102-21']);
      if (esgData['102-21']['tabella_rapporto_retributivo'] != undefined)
        setTableData(esgData['102-21']['tabella_rapporto_retributivo']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-21', esg102_21);
  }, [esg102_21]);

  const legend = (
    <p>
      A= Persona che riceve la massima retribuzione
      <br />
      B = tutti i dipendenti dell’organizzazione esclusa la suddetta persona
      <br />
      La retribuzione annuale totale comprende lo stipendio, i bonus, i premi in
      azioni, i premi in opzioni, i compensi dei piani di incentivazione non
      azionari, la variazione del valore della pensione e i guadagni da compensi
      differiti non qualificati erogati nel corso di un anno. Quando calcola il
      rapporto, l’organizzazione dovrebbe, a seconda delle sue norme riguardanti
      la retribuzione e la disponibilità di dati, considerare tutti i seguenti
      aspetti:
      <br />
      • Stipendio base, che è la somma dei compensi in denaro garantiti, a breve
      termine e non variabili.
      <br />
      • Il compenso totale in contanti, pari alla soma dello stipendio base e di
      indennità in contanti, bonus, commissioni, condivisioni di profitti in
      contanti e altre forme di pagamenti in contanti variabili.
      <br />• Il compenso diretto, pari alla somma del compenso totale in
      contanti e del valore equo totale di tutti gli incentivi a lungo termine
      annuali (per es., premi in opzioni, unità o azioni vincolate, unità o
      azioni legate alla performance, azioni fantasma, diritti sull’aumento di
      valore di azioni e premi in contanti a lungo termine).
    </p>
  );
  const retributiveColumnDefs = [
    { headerName: 'Anno', field: 'year', headerTooltip: 'Inserire Anno' },
    {
      headerName: 'Retribuzione totale annuale di A',
      field: 'totalA',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      headerTooltip: 'A= Persona che riceve la massima retribuzione',
    },
    {
      headerName: 'Retribuzione totale annuale di B',
      field: 'totalB',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      headerTooltip:
        'B = tutti i dipendenti dell’organizzazione esclusa la suddetta persona',
    },
    {
      headerName: 'Rapporto A/B',
      field: 'rapport',
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      headerTooltip:
        'Colonna calcolata automaticamente come rapporto fra A e B',
    },
  ];
  const onRetributiveCellValueChanged = event => {
    const updatedRowData = tableData.map(row => {
      if (row.year === event.data.year) {
        event.data.rapport = calculateRatio(
          event.data.totalA,
          event.data.totalB
        );
        return { ...row, [event.colDef.field]: event.newValue };
      }
      return row;
    });
    setTableData(updatedRowData);
    setEsg102_21({
      ...esg102_21,
      ['tabella_rapporto_retributivo']: updatedRowData,
    });
  };
  const onRetributiveCellValueChangedAumenti = event => {
    const updatedRowData = tableDataAumenti.map(row => {
      if (row.year === event.data.year) {
        event.data.rapportAumento = calculateRatio(
          event.data.aumentoA,
          event.data.aumentoB
        );
        return { ...row, [event.colDef.field]: event.newValue };
      }
      return row;
    });
    setTableDataAumenti(updatedRowData);
    setEsg102_21({
      ...esg102_21,
      ['tabella_rapporto_retributivo']: updatedRowData,
    });
  };

  const retributiveColumnDefsAumenti = [
    { headerName: 'Anno', field: 'year' },
    {
      headerName: 'Aumento Retribuzione % di A',
      field: 'aumentoA',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: 'Aumento Retribuzione % di B',
      field: 'aumentoB',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: 'Rapporto A/B',
      field: 'rapportAumento',
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
  ];
  const handleChanges = e => {
    setEsg102_21({ ...esg102_21, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Rapporto di Retribuzione Totale Annua</h1>
      {legend}
      <TextArea
        maxCount={500}
        enableCounter={true}
        id="descrizione_rapporto_retribuzione"
        value={
          esg102_21 &&
          esg102_21['descrizione_rapporto_retribuzione'] != undefined
            ? esg102_21['descrizione_rapporto_retribuzione']
            : ''
        }
        labelText="Descrivere il rapporto di retribuzione totale"
        placeholder="La retribuzione annuale totale comprende lo stipendio, i bonus, i premi in azioni, i premi in opzioni, i compensi dei piani di incentivazione non azionari, la variazione del valore della pensione e i guadagni da compensi differiti non qualificati erogati nel corso di un anno."
        onChange={handleChanges}
      />
      <p>Completare la tabella secondo la legenda</p>
      <div className="ag-theme-alpine" style={{ height: '200px' }}>
        <AgGridReact
          rowData={tableData}
          columnDefs={retributiveColumnDefs}
          onCellValueChanged={onRetributiveCellValueChanged}
        />
      </div>
      {/* <h2>Aumenti Retributivi % Rispetto all'anno precedente</h2>
      <div className="ag-theme-alpine" style={{ height: '200px'}}>
        <AgGridReact
          rowData={tableDataAumenti}
          columnDefs={retributiveColumnDefsAumenti}
          onCellValueChanged={onRetributiveCellValueChangedAumenti}
        />
      </div>*/}
      <footer style={{ fontSize: 10, marginRight: 0 }}>
        GRI 102-21 / ESRS S1{' '}
      </footer>
    </Stack>
  );
};

export default ESG102_21;
