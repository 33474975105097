import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  TextInput,
  NumberInput,
  Button,
  Stack,
  Tooltip,
  TextArea,
} from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';

function ESG303_1() {
  const { esgData, pushESGData, refreshESGData } = useESG();
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [esg303_1, setEsg303_1] = useState();

  const columnDefs = [
    {
      headerName: 'Anno',
      field: 'year',
      headerTooltip:
        "Inserisci l'anno di riferimento (deve essere successivo al 2019)",
      editable: params => params.data.year > 2019,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Consumo Idrico',
      headerTooltip: 'Inserisci il consumo idrico',
      field: 'consumption',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Scarico Idrico',
      headerTooltip: 'Inserisci lo scarico idrico',
      field: 'discharge',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Unità di Misura',
      field: 'unit',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];

  function addRow() {
    if (rowData.length < 5) {
      const newRow = { year: 2022, consumption: 0, discharge: 0, unit: 'mc' };
      setRowData([...rowData, newRow]);
      setEsg303_1({
        ...esg303_1,
        ['consumo_idrico_tabella']: [...rowData, newRow],
      });
    }
  }
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg303_1({ ...esg303_1, ['consumo_idrico_tabella']: ex_rowData });
  };
  useEffect(() => {
    if (esgData['303-1'] != undefined) {
      setEsg303_1(esgData['303-1']);
      if (esgData['303-1']['consumo_idrico_tabella'] != undefined) {
        setRowData(esgData['303-1']['consumo_idrico_tabella']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('303-1', esg303_1);
  }, [esg303_1]);

  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg303_1({ ...esg303_1, ['consumo_idrico_tabella']: updatedRowData });
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const handleChanges = e => {
    setEsg303_1({ ...esg303_1, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Rendicontazione Consumo Idrico</h1>
      <p>
        Un'organizzazione può incidere sulla qualità e la disponibilità
        dell'acqua attraverso la propria catena del valore. Se l'organizzazione
        che rendiconta ha identificato impatti significativi sulle risorse
        idriche nella catena del valore, la quale comprende attività condotte
        dalla stessa e da interlocutori a monte e valle della stessa, deve
        descrivere in dettaglio tali impatti.
      </p>

      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="descrizione_risorse_idriche"
        value={
          esg303_1 && esg303_1['descrizione_risorse_idriche'] != undefined
            ? esg303_1['descrizione_risorse_idriche']
            : ''
        }
        labelText="Descrivere i principali impatti sulle risorse idriche"
        onChange={handleChanges}
      />
      <p>Completare la tabella inserendo le informazioni richieste.</p>

      <div
        className="ag-theme-alpine"
        style={{
          width: '100%',
          height: '200px',
        }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowSelection={'multiple'}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          onCellValueChanged={onChangeRowValue}
        />
        <Button onClick={addRow} iconDescription="Aggiungi riga" kind="ghost">
          Aggiungi riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga.  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 303-1 </footer>
    </Stack>
  );
}

function NumberInputRenderer(props) {
  return (
    <NumberInput
      {...props}
      labelText=""
      min={0}
      invalidText="Inserisci un numero maggiore di 0"
    />
  );
}

function TextInputRenderer(props) {
  return <TextInput {...props} labelText="" />;
}

export default ESG303_1;
