import AttivitaMinorile from '../../../../assets/html_template/professional/A4/12-sociale-gri408-1-attivitaminorile.html';
import AttivitaMinorile169 from '../../../../assets/html_template/professional/169/12-sociale-gri408-1-attivitaminorile.html';
import AttivitaMinorilePopA4 from '../../../../assets/html_template/pop/A4/12-sociale-gri408-1-attivitaminorile.html';
import AttivitaMinorilePop169 from '../../../../assets/html_template/pop/169/12-sociale-gri408-1-attivitaminorile.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';

import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const AttivitaMinorileEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString =
        type == 'A4' ? AttivitaMinorile : AttivitaMinorile169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? AttivitaMinorilePopA4 : AttivitaMinorilePop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString =
        type == 'A4' ? AttivitaMinorile : AttivitaMinorile169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [rischiMinorili, setrischiMinorili] = useState('');
  const [provvedimentiMinorili, setProvvedimentiMinorili] = useState('');

  const [attivitaMinorileData, SetAttivitaMinorileData] = useState(
    AttivitaMinorile
  );
  const [attivitaMinorileDataA4, setattivitaMinorileDataA4] = useState(
    AttivitaMinorile
  );
  const [attivitaMinorileData169, setattivitaMinorileData169] = useState(
    AttivitaMinorile169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetAttivitaMinorileData(AttivitaMinorilePopA4);
    setattivitaMinorileDataA4(AttivitaMinorilePopA4);
    setattivitaMinorileData169(AttivitaMinorilePop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetAttivitaMinorileData(AttivitaMinorile);
    setattivitaMinorileDataA4(AttivitaMinorile);
    setattivitaMinorileData169(AttivitaMinorile169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetAttivitaMinorileData(AttivitaMinorile);
    setattivitaMinorileDataA4(AttivitaMinorile);
    setattivitaMinorileData169(AttivitaMinorile169);
  }
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAttivitaMinorileData(AttivitaMinorilePopA4);
      setattivitaMinorileDataA4(AttivitaMinorilePopA4);
      setattivitaMinorileData169(AttivitaMinorilePop169);
      force_update(
        type == 'A4' ? AttivitaMinorilePopA4 : AttivitaMinorilePop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAttivitaMinorileData(AttivitaMinorile);
      setattivitaMinorileDataA4(AttivitaMinorile);
      setattivitaMinorileData169(AttivitaMinorile169);
      force_update(type == 'A4' ? AttivitaMinorile : AttivitaMinorile169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAttivitaMinorileData(AttivitaMinorile);
      setattivitaMinorileDataA4(AttivitaMinorile);
      setattivitaMinorileData169(AttivitaMinorile169);
      force_update(type == 'A4' ? AttivitaMinorile : AttivitaMinorile169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('408-1')
    ) {
      setIsVisible(false);
      delPageData('408-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(attivitaMinorileData);
    } else {
      if (
        esgData['408-1'] != undefined &&
        esgData['408-1']['esposizione_att_fornitori'] != undefined &&
        esgData['408-1']['esposizione_att_fornitori'] != rischiMinorili
      ) {
        hasUpdate = true;
      }

      if (
        esgData['408-1'] != undefined &&
        esgData['408-1']['misure_nolavmin'] != undefined &&
        esgData['408-1']['misure_nolavmin'] != provvedimentiMinorili
      ) {
        hasUpdate = true;
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }
      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = attivitaMinorileData169;
        to_init = '169';
      } else {
        templateHTMLString = attivitaMinorileDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['408-1'] != undefined &&
        esgData['408-1']['esposizione_att_fornitori'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'esposizione_att_fornitori'
        );
        fotoLeaderElement.innerHTML =
          esgData['408-1']['esposizione_att_fornitori'];
        setrischiMinorili(esgData['408-1']['esposizione_att_fornitori']);
      }
      //&&!compareLists(tabellacriticita,esgData["408-1"]["rendicontazione_criticita"])
      if (
        esgData['408-1'] != undefined &&
        esgData['408-1']['misure_nolavmin'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById('misure_nolavmin');
        fotoLeaderElement.innerHTML = esgData['408-1']['misure_nolavmin'];
        setProvvedimentiMinorili(esgData['408-1']['misure_nolavmin']);
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('408-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Lavoro Minorile e Forzato',
          initA4: AttivitaMinorilePopA4,
          init169: AttivitaMinorilePop169,
          keys_to_check: ['esposizione_att_fornitori', 'misure_nolavmin'],
          gri: '408-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('408-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Lavoro Minorile e Forzato',
          initA4: AttivitaMinorile,
          init169: AttivitaMinorile169,
          keys_to_check: ['esposizione_att_fornitori', 'misure_nolavmin'],
          gri: '408-1',
        });
      }
    }
  };

  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? attivitaMinorileData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['408-1'] != undefined &&
          esgData['408-1']['esposizione_att_fornitori'] != undefined &&
          esgData['408-1']['esposizione_att_fornitori'] != rischiMinorili
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'esposizione_att_fornitori'
          );
          fotoLeaderElement.innerHTML =
            esgData['408-1']['esposizione_att_fornitori'];
          setrischiMinorili(esgData['408-1']['esposizione_att_fornitori']);
        }
        //&&!compareLists(tabellacriticita,esgData["408-1"]["rendicontazione_criticita"])
        if (
          esgData['408-1'] != undefined &&
          esgData['408-1']['misure_nolavmin'] != undefined &&
          esgData['408-1']['misure_nolavmin'] != provvedimentiMinorili
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'misure_nolavmin'
          );
          fotoLeaderElement.innerHTML = esgData['408-1']['misure_nolavmin'];
          setProvvedimentiMinorili(esgData['408-1']['misure_nolavmin']);
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['408-1'] != undefined &&
          esgData['408-1']['esposizione_att_fornitori'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'esposizione_att_fornitori'
          );
          fotoLeaderElement.innerHTML =
            esgData['408-1']['esposizione_att_fornitori'];
          setrischiMinorili(esgData['408-1']['esposizione_att_fornitori']);
        }
        //&&!compareLists(tabellacriticita,esgData["408-1"]["rendicontazione_criticita"])
        if (
          esgData['408-1'] != undefined &&
          esgData['408-1']['misure_nolavmin'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'misure_nolavmin'
          );
          fotoLeaderElement.innerHTML = esgData['408-1']['misure_nolavmin'];
          setProvvedimentiMinorili(esgData['408-1']['misure_nolavmin']);
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetAttivitaMinorileData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AttivitaMinorileEngine;
