import { getFunctions, httpsCallable } from "firebase/functions";
import { app,auth,storage } from "../fiirebase_config/firebase_config";
import {
    addDoc,
    collection,
    getFirestore,
    onSnapshot,
  } from "firebase/firestore";
const db=storage
export const getCheckoutUrl = async ( priceId,pec,sdi) => {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("User is not authenticated");
    const checkoutSessionRef = collection(
      db,
      "customers",
      userId,
      "checkout_sessions"
    );
  
      const internal_key=auth.currentUser.uid+"_"+Date.now().toString()
    const docRef = await db
  .collection('customers')
  .doc(userId)
  .collection("checkout_sessions")
  .add({
    mode: "payment",
    price: priceId, // One-time price created in Stripe
    success_url: `${window.location.origin}/new/{CHECKOUT_SESSION_ID}`,
    cancel_url: window.location.origin,
    custom_fields: [
      {
        key: 'pec',
        label: {
          type: 'custom',
          custom: 'PEC',
        },
        type: 'text',
      },
    ],
    metadata:{
      internal_key:internal_key,
      pec:pec,
      sdi:sdi
    },
    // Add tax information
    tax_id_collection: {
      enabled: true,
    },
    
    phone_number_collection: {
      enabled: true,
    },
    automatic_tax: {
      enabled: true,
    },
  });

  
    return new Promise((resolve, reject) => {
        
      const unsubscribe = onSnapshot(docRef, (snap) => {
        console.log(snap.data())
        const { error, url } = snap.data();
        if (error) {
          unsubscribe();
          reject(new Error(`An error occurred: ${error.message}`));
        }
        if (url) {
          console.log("Stripe Checkout URL:", url);
          unsubscribe();
          resolve([url,docRef.id,snap.data().sessionId]);
        }
      });
    });
  };
  
  export const getPortalUrl = async () => {

    const user = auth.currentUser;
  
    let dataWithUrl;
    try {
      const functions = getFunctions(app, "us-central1");
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );
      const { data } = await functionRef({
        customerId: user?.uid,
        returnUrl: window.location.origin,
      });
  
      // Add a type to the data
      dataWithUrl = data;
      console.log("Reroute to Stripe portal: ", dataWithUrl.url);
    } catch (error) {
      console.error(error);
    }
  
    return new Promise((resolve, reject) => {
      if (dataWithUrl.url) {
        resolve(dataWithUrl.url);
      } else {
        reject(new Error("No url returned"));
      }
    });
  };