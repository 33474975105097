import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Information } from '@carbon/icons-react';
import { Stack, Tooltip, Button, TextArea } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG302_4 = () => {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [esg302_4, setEsg302_4] = useState();
  const addRow = () => {
    if (rowData.length < 5) {
      const newRow = {
        misuraDiRiduzione: '',
        tipoDiEnergia: '',
        percentualeRiduzione: 0,
        note: '',
      };
      setRowData([...rowData, newRow]);
      setEsg302_4({
        ...esg302_4,
        ['riduzione_energia_tabella']: [...rowData, newRow],
      });
    }
  };
  const columnDefs = [
    {
      headerName: 'Misura di Riduzione',
      headerTooltip:
        'Inserisci la misura di riduzione (ad es: pannelli solari, tele riscaldamento, ...)',

      field: 'misuraDiRiduzione',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Tipo Energia',
      headerTooltip:
        'Inserisci la tipologia di energia influenzata (es. termica, elettrica, ecc.)',

      field: 'tipoDiEnergia',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Percentuale Riduzione',
      headerTooltip:
        'Inserisci la percentuale di riduzione (anche prevista se la misura non è ancora stata adottata)',

      field: 'percentualeRiduzione',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Eventuali Note Operative',
      field: 'note',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];

  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg302_4({ ...esg302_4, ['riduzione_energia_tabella']: ex_rowData });
  };
  useEffect(() => {
    if (esgData['302-4'] != undefined) {
      setEsg302_4(esgData['302-4']);
      if (esgData['302-4']['riduzione_energia_tabella'] != undefined) {
        setRowData(esgData['302-4']['riduzione_energia_tabella']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('302-4', esg302_4);
  }, [esg302_4]);

  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg302_4({
        ...esg302_4,
        ['riduzione_energia_tabella']: updatedRowData,
      });
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const handleChanges = e => {
    setEsg302_4({ ...esg302_4, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Misure di Riduzione</h1>

      <p>
        L’organizzazione che rendiconta può prioritizzare la divulgazione di
        iniziative di riduzione che sono state attuate nel periodo di
        rendicontazione e che possono contribuire in misura notevole alle
        riduzioni stesse. L’organizzazione può descrivere iniziative di
        riduzione e i loro traguardi quando rendiconta come gestisce questo
        tema.
        <br />
        Ad esempio, potrebbe essere descritta l'installazione di pannelli
        fotovoltaici che porterebbero ad una presunta riduzione di Energia
        Elettrica del 30%. Utilizzare il campo "Eventuali Note Operative" per
        aggiungere ulteriori dettagli sull'iniziativa.
      </p>

      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="descrizione_riduzione_energia"
        value={
          esg302_4 && esg302_4['descrizione_riduzione_energia'] != undefined
            ? esg302_4['descrizione_riduzione_energia']
            : ''
        }
        labelText="Descrivere le principali iniziative per la riduzione del consumo energetico"
        onChange={handleChanges}
      />
      <p>Completare la tabella inserendo le informazioni richieste.</p>

      <div
        className="ag-theme-alpine"
        style={{
          width: '100%',
        }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          suppressHorizontalScroll={true}
          onCellValueChanged={onChangeRowValue}
        />
        <Button kind="ghost" onClick={addRow}>
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga. E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 302-4 </footer>
    </Stack>
  );
};

export default ESG302_4;
