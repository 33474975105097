import React, { useEffect, useState } from 'react';
import {
  Industry,
  Tree,
  MagicWand,
  Notification,
  UserAvatar,
} from '@carbon/react/icons';
import { Button, Checkbox, Modal, Stack } from '@carbon/react';
import { getCheckoutUrl } from '../../action/StripePayment/StripePayment';
import { useHistory } from 'react-router-dom';
import { auth, storage } from '../../action/fiirebase_config/firebase_config';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import LoadingOverlay from '../../components/Loader/Loader';

import { analytics } from '../../action/fiirebase_config/firebase_config';
import { logEvent } from 'firebase/analytics';
import { useUser } from '../../action/UserData/UserData';
const BuyItem = () => {
  const history = useHistory();
  const [clientSecret, SetClientSecret] = useState('');
  const [loading, setOnLoading] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [privacyRead, setPrivacyRead] = useState(false);
  const { state,fetchUserData } = useUser();

  const startBuy = async () => {
    logEvent(analytics, 'ON ROUTE TO STRIPE');
    setOnLoading(true);
    //const priceId = 'price_1Ovy9YBxTmpYlrFmljTbemnP';
    const priceId='price_1OwOueBxTmpYlrFmMcos6jv1';
    if (state['pec']==undefined || state['sdi']==undefined)
    {
      console.log("NoData");
      

    }
    const checkout_url = await getCheckoutUrl(
      priceId,
      state['pec'],
      state['sdi']
    );
    //history.push(checkout_url)

    const reportRef = storage
      .collection('payments-internal')
      .doc(checkout_url[2]);
    SetClientSecret(checkout_url[2]);
    await reportRef.set({
      payment_status: 'pending',
      userID: auth.currentUser.uid,
    });
    window.location.href = checkout_url[0];
  };
  const createNewTemplate = () => {
    history.push('/new', { paymentInfo: clientSecret });
  };
  const handlePrivacy = e => {
    console.log('Hello');
    setPrivacyRead(!privacyRead);
  };
  function PrivacyLabel() {
    console.log('Here');
    return (
      <>
        <p>
          Dichiaro di aver preso visione dell'
          <a
            href="/VASTUMesg_informativaprivacy.pdf"
            target="_blank"
            rel="noopener noreferrer">
            Informativa sulla Privacy
          </a>
        </p>
      </>
    );
  }

  return (
    <>
      {loading && (
        <LoadingOverlay
          main_text={
            'Stiamo preparando il pagamento. Attendi per essere re-indirizzato sulla piattaforma pagamento.'
          }
        />
      )}

      <div className="card">
        <div className="imgBx">
          <img
            src="https://newsroom.hdec.kr/FileContents/EditorImg/20210217/%EB%B3%B8%EB%AC%B81(1).jpg"
            alt="Report ESG Facile"
          />
        </div>
        <div className="details">
          <div className="content">
            <h2>
              Report di Sostenibilità <br />
              <span>verso un futuro sostenibile</span>
            </h2>

            <p>
              Inserisci i dati e produci il tuo report ESG su misura. <br />
              Tutto qui?
              <br />
              Sì, siamo lieti di presentarti il report ESG a portata di tutti.
            </p>
            <p className="product-colors">
              Dedicato a:
              <br />
              <span style={{ marginRight: '70px' }}>
                <Industry />
                <small> Piccole e Medie Imprese </small>
              </span>
              <span style={{ marginRight: '70px' }}>
                <Tree />
                <small>Green Lovers</small>
              </span>
              <span>
                <MagicWand />
                <small>Early Adopters</small>
              </span>
            </p>
            <div style={{ width: '100%', height: '80px' }}>
              <h5 style={{ textDecoration: 'line-through' }}>€ 999</h5>
              <h3>€ 499</h3>
            </div>
            <div style={{ width: '100%' }}>
              <Checkbox
                id="checkboxPrivacy"
                onClick={handlePrivacy}
                checked={privacyRead}
                labelText={<PrivacyLabel />}
              />
              <Button disabled={!privacyRead} onClick={() => startBuy()}>
                Acquista ora
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={modalError}
        modalLabel={'Brutte Notizie'}
        modalHeading={'Il pagamento non è andato a buon fine.'}
        onRequestClose={() => setModalError(false)}
        passiveModal
        primaryButtonText={'Continua'}
        secondaryButtonText="Annulla">
        <p>Ti preghiamo di riprovare.</p>
      </Modal>

      <Modal
        open={modalSuccess}
        modalLabel={'Congratulazioni'}
        modalHeading={
          'Sei ad un passo dalla creazione del tuo report di sostenibilità.'
        }
        preventCloseOnClickOutside={true}
        onSubmit={() => createNewTemplate()}
        primaryButtonText={'Procedi'}>
        <p>Clicca sul pulsante "Prosegui"</p>
      </Modal>
    </>
  );
};

export default BuyItem;
