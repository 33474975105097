import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { auth, storage } from '../fiirebase_config/firebase_config';

const UserDataContext = createContext();
const initialState = {};

const userDataReducer = (state, action) => {
  console.log(action);
  switch (action.action) {
    case 'UPDATE_DATA':
      if (
        action.payload != undefined &&
        Object.keys(action.payload).length !== 0
      ) {
        return action.payload;
      } else {
        return state;
      }
    case 'CLEAR_DATA':
      return {};
    case 'LOAD_DATA':
      if (action.payload != undefined) {
        return action.payload;
      }
  }
};

export const UserDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userDataReducer, initialState);

  const updateUserData = newData => {
    dispatch({ action: 'UPDATE_DATA', payload: newData });
  };

  const clearData = () => {
    dispatch({ action: 'CLEAR_DATA' });
  };
  const loadUserData = payload => {
    dispatch({ action: 'LOAD_DATA', payload: payload });
  };

  const fetchUserData = async uid => {
    try {
      console.log('fetching user data');
      const userRef = storage.collection('users_details').doc(uid);
      const snapshot = await userRef.get();

      console.log(snapshot);
      if (snapshot.exists) {
        loadUserData(snapshot.data());
      }
      else{
        console.log('Error fetching user data:');
      }
    } catch (error) {
      throw error('Error fetching user data:', error);
    }
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      console.log('Stato cambiato');
      if (user) {
        fetchUserData(auth.currentUser.uid);
      } else {
        clearData(); // Se l'utente non è loggato, cancella i dati
      }
    });

    return () => unsubscribe();
  }, []);
  return (
    <UserDataContext.Provider value={{ state, updateUserData, fetchUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserDataContext);
};
