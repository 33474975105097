import React, { useEffect, useState } from 'react';
import { Accordion, Grid, Column, AccordionItem, Button } from '@carbon/react';
import { ArrowDown, ArrowRight } from '@carbon/icons-react';
import { useESG } from '../../action/ESGData/esgdata';
import LoadingOverlay from '../../components/Loader';
import { useHistory } from 'react-router-dom';

const datatest = [
  {
    nome: 'reportSostAziendaA',
    datacreazione: '2023-06-20',
    id: 'ptqoilsib082pm1u',
    descrizionereport:
      "Report per evidenziare l'impegno dell'azienda A in ambito sostenibilità",
  },
  {
    nome: 'reportSostAziendaB',
    id: 's3qm4t8i4iq6540r',
    datacreazione: '2023-08-27',
    descrizionereport:
      "Report per evidenziare l'impegno dell'azienda B in ambito sostenibilità",
  },
  {
    nome: 'reportSostAziendaC',
    id: '8xto0j78m5xqj0y7',
    datacreazione: '2023-06-29',
    descrizionereport:
      "Report per evidenziare l'impegno dell'azienda C in ambito sostenibilità",
  },
];

const History = () => {
  const history = useHistory();
  const context_obj = useESG();
  const [reportData, SetReportData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [completeSize, setCompleteSize] = useState(0);
  useEffect(() => {
    setIsLoading(true);
    context_obj.return_docs_uid().then(value => {
      if (value == 'KO') {
        setIsLoading(false);
      } else {
        SetReportData(value);
      }
    });
  }, []);

  useEffect(() => {
    accordionGenerator();
    setIsLoading(false);
  }, [reportData]);

  const accordionGenerator = () => {
    var slim_report_data = [];
    reportData.forEach(val => {
      if (val.report_status == 'completed') {
        slim_report_data.push(val);
      }
    });

    const returnToMainMenu = () => {
      history.push('/main_user');
    };

    const handleChange = (id, jsondata) => {
      setIsLoading(true);
      context_obj.setReportIDOnCallback(id, jsondata);
      history.push('esgstd');
    };
    return (
      <>
        {slim_report_data.length > 0 ? (
          <Accordion>
            {slim_report_data.map((n, index) => (
              <AccordionItem title={n.visible_name}>
                <Grid>
                  <Column className="header" lg={7} md={7} sm={7}>
                    Creazione
                  </Column>
                  <Column lg={7} md={7} sm={7}>
                    Completamento
                  </Column>
                  <Column lg={2} md={2} sm={2}>
                    Visualizza
                  </Column>
                  <Column lg={7} md={7} sm={7}>
                    {n.report_creation_date}
                  </Column>

                  <Column lg={7} md={7} sm={7}>
                    {n.report_completion_date}
                  </Column>
                  <Column lg={2} md={2} sm={2}>
                    <Button
                      kind="ghost"
                      renderIcon={ArrowRight}
                      onClick={() => handleChange(n.id, n)}
                    />
                  </Column>
                </Grid>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p>Non hai ancora completato nessuna pratica</p>
            <Button onClick={() => returnToMainMenu()} kind="ghost">
              Torna al menu principale
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <Grid>
        <Column lg={10} md={10} sm={10} className="landing-page__r3">
          <h1>Storico Pratiche</h1>
          <p>In questa sezione sono presenti le pratiche completate</p>
        </Column>
        <Column lg={6} md={6} sm={6} className="landing-page__r3">
          <img
            width="40%"
            src={`${process.env.PUBLIC_URL}/history.svg`}
            alt="Storico Pratiche"
          />
        </Column>
      </Grid>
      <div className="OnWorkingContainer">{accordionGenerator()}</div>
      {isLoading && (
        <LoadingOverlay main_text={'Caricamento pratiche in corso'} />
      )}
    </div>
  );
};

export default History;
