import {
  Button,
  Column,
  Row,
  Grid,
  Stack,
  Select,
  SelectItem,
} from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { Document, Outline, Page, Thumbnail } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { useESG } from '../../../action/ESGData/esgdata';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Edit } from '@carbon/react/icons';
import './_template_card.scss';

const TemplateCard = ({ setModalValue }) => {
  const { esgData, pushESGData } = useESG();

  const handleChangesSelectCoverVariant = e => {
    var template_value = esgData['template'];
    template_value['variante_cover'] = e.target.value;
    pushESGData('template', template_value);
  };
  useEffect(() => {});
  const professionalTemplateCoverVariant = [
    { value: 'environment', text: 'Environment' },
    { value: 'light', text: 'Light' },
    { value: 'natural', text: 'Natural' },
  ];
  return (
    <div>
      <p style={{ float: 'left' }}>
        Template Selezionato:{' '}
        {esgData['template'] && esgData['template']['name']
          ? esgData['template']['name']
          : ''}{' '}
      </p>
      <Button
        style={{ marginLeft: '10px' }}
        size="sm"
        onClick={() => setModalValue(true)}
        hasIconOnly
        renderIcon={Edit}
        kind="tertiary"
        disabled={esgData.report_status != 'working' ?? true}
      />

      <Select
        id="cover_background"
        defaultValue={
          esgData['template'] != undefined &&
          esgData['template']['variante_cover'] != undefined
            ? esgData['template']['variante_cover']
            : ''
        }
        size="xs"
        onChange={handleChangesSelectCoverVariant}
        labelText="Scegli lo sfondo per il Template">
        <SelectItem value="" text="" />

        {professionalTemplateCoverVariant.map(item => (
          <SelectItem value={item.value} text={item.text} />
        ))}
      </Select>
      <></>
    </div>
  );
};

export default TemplateCard;
