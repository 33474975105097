import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Add, Subtract } from '@carbon/icons-react';
import Multiselect from 'multiselect-react-dropdown';
import {
  Column,
  Modal,
  Button,
  TextInput,
  Stack,
  RadioButtonGroup,
  RadioButton,
  FormGroup,
  Select,
  MultiSelect,
  Grid,
  TextArea,
  SelectItem,
  FilterableMultiSelect,
} from '@carbon/react';
import IndirizzoComponent from '../Indirizzo/IndirizzoComponent';
import { useESG } from '../../../action/ESGData/esgdata';

import CustomColorExtractor from '../../CustomColorExtractor';
const items_form_giu = [
  { value: 'Società semplice - S.S.', text: 'Società semplice - S.S.' },
  {
    value: 'Società in nome collettivo - S.N.C.',
    text: 'Società in nome collettivo - S.N.C.',
  },
  {
    value: 'Società in accomandita semplice - S.A.S.',
    text: 'Società in accomandita semplice - S.A.S.',
  },
  {
    value: 'Società a responsabilità limitata - S.R.L.',
    text: 'Società a responsabilità limitata - S.R.L.',
  },
  {
    value:
      'Società a responsabilità limitata con un unico socio - S.R.L. Unipersonale',
    text:
      'Società a responsabilità limitata con un unico socio - S.R.L. Unipersonale',
  },
  {
    value: 'Società semplificata a responsabilità limitata - S.S.R.L.',
    text: 'Società semplificata a responsabilità limitata - S.S.R.L.',
  },
  { value: 'Società per azioni - S.P.A.', text: 'Società per azioni - S.P.A.' },
  {
    value: 'Società per azioni - S.P.A. Unipersonale',
    text: 'Società per azioni - S.P.A. Unipersonale',
  },
  {
    value: 'Società in accomandita per azioni - S.A.P.A.',
    text: 'Società in accomandita per azioni - S.A.P.A.',
  },
];
const items_nation = [
  { value: 'UNITED_STATES', label: 'Stati Uniti' },
  { value: 'CHINA', label: 'Cina' },
  { value: 'JAPAN', label: 'Giappone' },
  { value: 'GERMANY', label: 'Germania' },
  { value: 'INDIA', label: 'India' },
  { value: 'UNITED_KINGDOM', label: 'Regno Unito' },
  { value: 'FRANCE', label: 'Francia' },
  { value: 'BRAZIL', label: 'Brasile' },
  { value: 'ITALY', label: 'Italia' },
  { value: 'CANADA', label: 'Canada' },
  { value: 'SOUTH_KOREA', label: 'Corea del Sud' },
  { value: 'RUSSIA', label: 'Russia' },
  { value: 'AUSTRALIA', label: 'Australia' },
  { value: 'SPAIN', label: 'Spagna' },
  { value: 'MEXICO', label: 'Messico' },
  { value: 'INDONESIA', label: 'Indonesia' },
  { value: 'TURKEY', label: 'Turchia' },
  { value: 'SAUDI_ARABIA', label: 'Arabia Saudita' },
  { value: 'SWITZERLAND', label: 'Svizzera' },
  { value: 'ARGENTINA', label: 'Argentina' },
  { value: 'TAIWAN', label: 'Taiwan' },
  { value: 'NETHERLANDS', label: 'Paesi Bassi' },
  { value: 'SWEDEN', label: 'Svezia' },
  { value: 'POLAND', label: 'Polonia' },
  { value: 'BELGIUM', label: 'Belgio' },
  { value: 'NORWAY', label: 'Norvegia' },
  { value: 'AUSTRIA', label: 'Austria' },
  { value: 'UNITED_ARAB_EMIRATES', label: 'Emirati Arabi Uniti' },
  { value: 'NIGERIA', label: 'Nigeria' },
  { value: 'IRAN', label: 'Iran' },
  { value: 'ISRAEL', label: 'Israele' },
  { value: 'IRELAND', label: 'Irlanda' },
  { value: 'SINGAPORE', label: 'Singapore' },
  { value: 'MALAYSIA', label: 'Malaysia' },
  { value: 'SOUTH_AFRICA', label: 'Sudafrica' },
  { value: 'PHILIPPINES', label: 'Filippine' },
  { value: 'COLOMBIA', label: 'Colombia' },
  { value: 'CHILE', label: 'Cile' },
  { value: 'THAILAND', label: 'Thailandia' },
  { value: 'PAKISTAN', label: 'Pakistan' },
  { value: 'EGYPT', label: 'Egitto' },
  { value: 'BANGLADESH', label: 'Bangladesh' },
  { value: 'VIETNAM', label: 'Vietnam' },
  { value: 'UKRAINE', label: 'Ucraina' },
  { value: 'ROMAoNIA', label: 'Romania' },
  { value: 'PERU', label: 'Perù' },
  { value: 'NEW_ZEALAND', label: 'Nuova Zelanda' },
  { value: 'GREECE', label: 'Grecia' },
  { value: 'CZECH_REPUBLIC', label: 'Repubblica Ceca' },
  { value: 'VENEZUELA', label: 'Venezuela' },
];

function ESG102_1(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { esgData, pushESGData, refreshESGData } = useESG();

  const openModal = idx => {
    SetCurrentAddrId(idx);
    setIsModalOpen(true);
  };
  const [add_obj, SetAddObj] = useState({});

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [add_str, SetAddStr] = useState({
    0: 'Nessun indirizzo inserito..',
    1: 'Nessun indirizzo inserito..',
    2: 'Nessun indirizzo inserito..',
    3: 'Nessun indirizzo inserito..',
    4: 'Nessun indirizzo inserito..',
    5: 'Nessun indirizzo inserito..',
  });

  const [esg102_1, setEsg102_1] = useState({});
  const [isModified, setIsModified] = useState(0);
  const [idSelect, setIdSelect] = useState();
  const anagrafica_ids = [
    '0',
    'ragione_azienda',
    'anni_attivita',
    'codice_ateco',
    'forma_giuridica',
    'num_dipendenti',
    'nazioni_operanti',
  ];
  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-1'] != undefined) {
      setEsg102_1(esgData['102-1']);

      if (esgData['102-1'][0] != undefined) {
      }

      if (esgData['102-1']['forma_giuridica'] != undefined) {
        retrieveSelectedItems(esgData['102-1']['forma_giuridica']);
      }
    } else if (esgData['anagrafica'] != undefined) {
      setEsg102_1(esgData['anagrafica']);
    } else {
    }

    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-1', esg102_1);
  }, [esg102_1]);

  const setAddress = addressData => {
    setEsg102_1({ ...esg102_1, [0]: addressData });
    SetAddObj({ ...add_obj, [current_addr_id]: addressData });
  };

  const [current_addr_id, SetCurrentAddrId] = useState(0);
  const setAddressFun = () => {
    var address_string = '';
    var current_addr_id = 0;
    if (current_addr_id in add_obj) {
      var via = '';
      var ncivico = '';
      var citta = '';
      var prov = '';
      var reg = '';
      var cap = '';
      var com = '';
      var naz = '';
      if (add_obj[current_addr_id].via !== undefined) {
        via = 'Via ' + add_obj[current_addr_id].via;
      }
      if (add_obj[current_addr_id].numeroCivico !== undefined) {
        ncivico = ' N. ' + add_obj[current_addr_id].numeroCivico;
      }
      if (add_obj[current_addr_id].citta !== undefined) {
        com = ', ' + add_obj[current_addr_id].citta;
      }
      if (add_obj[current_addr_id].cap !== undefined) {
        cap = ' ' + add_obj[current_addr_id].cap;
      }
      if (add_obj[current_addr_id].provincia !== undefined) {
        prov = ', (' + add_obj[current_addr_id].provincia;
      }
      if (add_obj[current_addr_id].regione !== undefined) {
        reg = '), ' + add_obj[current_addr_id].regione;
      }
      if (add_obj[current_addr_id].nazione !== undefined) {
        naz = ' ,' + add_obj[current_addr_id].nazione;
      }
      address_string = via + ncivico + citta + cap + prov + reg + naz;
    } else {
      address_string = 'Nessun indirizzo inserito..';
    }

    SetAddStr({ ...add_obj, [current_addr_id]: address_string });
    closeModal();
  };

  const handleChangesSelect = e => {
    if ('selectedItems' in e) {
      setEsg102_1({ ...esg102_1, nazioni_operanti: e });
    } else {
      setEsg102_1({ ...esg102_1, ['forma_giuridica']: e.target.value });
    }
  };

  const handleChanges = e => {
    setEsg102_1({ ...esg102_1, [e.target.id]: e.target.value });
    retrieveSelectedItems(e.target.value);
  };
  const setImages = data => {
    delete data['selected_img'];
    setEsg102_1({ ...esg102_1, ['logoData']: data });
  };
  const retrieveSelectedItems = txt_to_check => {
    for (var i = 0; i < items_form_giu.length; i++) {
      if (items_form_giu[i].text == txt_to_check) {
        setIdSelect(i);
        break;
      }
      return i;
    }
  };
  return (
    <Stack gap={8}>
      <h1>Dettagli Organizzativi</h1>
      <div>
        <p>
          Completare i campi in questa sezione inserendo le informazioni
          anagrafiche
        </p>
        <small>
          N.B. vengono riportati i dati inseriti nella checklist preparatoria
          (se compilata)
        </small>
      </div>

      <Stack gap={7}>
        <div class="cds--grid">
          <div class="cds--row">
            <div class="cds--col">
              <TextInput
                id="ragione_azienda"
                value={
                  esg102_1['ragione_azienda'] != undefined
                    ? esg102_1['ragione_azienda']
                    : ''
                }
                disabled={esgData.report_status != 'working' ?? true}
                onChange={handleChanges}
                labelText="Ragione Sociale Azienda"
              />
              <TextInput
                id="anni_attivita"
                value={
                  esg102_1['anni_attivita'] != undefined
                    ? esg102_1['anni_attivita']
                    : ''
                }
                onChange={handleChanges}
                type="number"
                labelText="Anni Attività"
                disabled={esgData.report_status != 'working' ?? true}
              />
            </div>
            <div class="cds--col">
              <TextInput
                id="codice_ateco"
                value={
                  esg102_1['codice_ateco'] != undefined
                    ? esg102_1['codice_ateco']
                    : ''
                }
                onChange={handleChanges}
                disabled={esgData.report_status != 'working' ?? true}
                labelText="Codice Ateco"
              />
              <TextInput
                type="number"
                onChange={handleChanges}
                value={
                  esg102_1['num_dipendenti'] != undefined
                    ? esg102_1['num_dipendenti']
                    : ''
                }
                id="num_dipendenti"
                min={1}
                max={500}
                disabled={esgData.report_status != 'working' ?? true}
                labelText="Numero Dipendenti"
              />
            </div>
            <div class="cds--col">
              <Select
                id="forma_giuridica"
                defaultValue={
                  (esgData['102-1'] && esgData['102-1']['forma_giuridica']) ||
                  ''
                }
                onChange={handleChangesSelect}
                titleText="Forma Giuridica"
                disabled={esgData.report_status != 'working' ?? true}
                labelText="Scegli la forma sociale">
                <SelectItem value="" text="" />

                {items_form_giu.map(item => (
                  <SelectItem value={item.value} text={item.text} />
                ))}
              </Select>
            </div>
          </div>

          <div class="cds--row">
            <div class="cds--col">
              <p>Sede Legale</p>
              <TextInput
                id={'0'}
                value={esg102_1['0'] != undefined ? esg102_1['0'] : ''}
                disabled={esgData.report_status != 'working' ?? true}
                placeholder="Nessun indirizzo inserito..."
                onChange={handleChanges}
              />{' '}
              {/*<Button kind="ghost" onClick={() => openModal(0)}>
                Aggiungi Indirizzo
              </Button>*/}
            </div>
            {/*   <div class="cds--col"> <p>Altre sedi</p>
      <TextInput type="number" value={0} id="Numero sedi" labelText="Numero sedi (max 5)"   onChange={callSedi}/>
      {sedi_obj}</div>
  */}
          </div>
          <div class="cds--row">
            <div class="cds--col">
              <FilterableMultiSelect
                ariaLabel="Seleziona la nazione"
                id="nazioni_operative"
                titleText="Nazioni Operative"
                items={items_nation}
                itemToString={items_nation =>
                  items_nation ? items_nation.label : ''
                }
                onChange={handleChangesSelect}
                disabled={esgData.report_status != 'working' ?? true}
                initialSelectedItems={
                  (esgData['102-1'] &&
                    esgData['102-1']['nazioni_operanti'] &&
                    esgData['102-1']['nazioni_operanti']['selectedItems']) ||
                  []
                }
              />
              {/*               <Select
                placeholder="Seleziona Nazione/i"
                options={items_nation}
                aria-label="Seleziona la nazione"
                onChange={handleChangesSelect}
                defaultValue={esg102_1["nazioni_operanti"]!=undefined??esg102_1["nazioni_operanti"]}
                isSearchable={true}
                isMulti={true}
              /> */}
              {/* <p>Nazioni Operative</p>
              <Multiselect
                placeholder="Seleziona Nazione/i"
                options={items_nation} // Options to display in the dropdown
                selectedValues={
                  esg102_1['nazioni_operanti'] != undefined
                    ? esg102_1['nazioni_operanti']
                    : {}
                } // Preselected value to persist in dropdown
                onSelect={handleChangesSelect} // Function will trigger on select event
                onRemove={handleChangesSelect} // Function will trigger on remove event
                displayValue="label" // Property name to display in the dropdown options
              />*/}
            </div>
          </div>
          <Modal
            open={isModalOpen}
            modalHeading="Inserimento Indirizzo"
            modalLabel="Inserisci le informazioni nel form sottostante"
            onRequestClose={closeModal}
            primaryButtonText="Conferma"
            secondaryButtonText="Chiudi"
            onRequestSubmit={setAddressFun}>
            <IndirizzoComponent setAddess={setAddress} />
          </Modal>
        </div>

        <TextArea
          maxCount={1000}
          enableCounter={true}
          id="storia_azienda"
          value={
            esg102_1['storia_azienda'] != undefined
              ? esg102_1['storia_azienda']
              : ''
          }
          placeholder="Descrivere con testo libero la storia dell'azienda"
          onChange={handleChanges}
          labelText="Storia Azienda"
        />
        <CustomColorExtractor
          selectedItem={{ ...esg102_1.logoData, reportId: esgData.id }}
          setImage={setImages}
        />
      </Stack>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 102-1</footer>
    </Stack>
  );
}

export default ESG102_1;
