import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { TextInput, TextArea, Stack } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG102_15 = props => {
  const [esg102_15, setEsg102_15] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-15'] != undefined) {
      setEsg102_15(esgData['102-15']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-15', esg102_15);
  }, [esg102_15]);
  const handleChanges = e => {
    setEsg102_15({ ...esg102_15, [e.target.id]: e.target.value });
  };

  return (
    <Stack gap={8}>
      <h1>GRI 102-15 </h1>
      <h2>Conflitti d'interesse</h2>
      <p>Completare i seguenti campi con i dati richiesti.</p>

      <TextArea
        placeholder="Descrivere i processi riguardanti il massimo organo di governo per far sì che i conflitti
      d’interesse siano prevenuti e mitigati;"
        maxCount={1000}
        enableCounter={true}
        labelText="Processi per la gestione dei conflitti d'interesse"
        id="procedura_conflittiinteresse"
        value={
          esg102_15['procedura_conflittiinteresse'] != undefined
            ? esg102_15['procedura_conflittiinteresse']
            : ''
        }
        onChange={handleChanges}
      />

      <TextArea
        placeholder="rendicontare se i conflitti d’interesse vengono comunicati agli stakeholder, compresi
      almeno quelli riguardanti:
      i. appartenenza a diversi consigli di amministrazione;
      ii.titolarità di azioni nella stessa organizzazione di cui sono azionisti fornitorie altri
      stakeholder;
      iii.esistenza di azionisti con poteri di controllo;
      iv.parti correlate e i relativi rapporti, transazioni e saldi scoperti.
      "
        maxCount={500}
        enableCounter={true}
        labelText="Comunicazione conflitti di interesse agli stakeholder"
        id="comunicazione_conflittiinteresse"
        value={
          esg102_15['comunicazione_conflittiinteresse'] != undefined
            ? esg102_15['comunicazione_conflittiinteresse']
            : ''
        }
        onChange={handleChanges}
      />
    </Stack>
  );
};

const LargeTextCellRenderer = props => {
  return <div className="ag-large-text-cell">{props.value}</div>;
};

const LargeTextCellEditor = props => {
  const handleValueChange = event => {
    props.stopEditing();
  };

  return (
    <TextArea
      id={props.id}
      labelText={props.column.colDef.headerName}
      onChange={handleValueChange}
      onBlur={props.stopEditing}
      value={props.value}
      rows={4}
    />
  );
};

export default ESG102_15;
