import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Select, Stack, Tooltip } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { useESG } from '../../../action/ESGData/esgdata';
import { sortingFns } from '@tanstack/react-table';
const label = [
  'Stati Uniti',
  'Cina',
  'Giappone',
  'Germania',
  'India',
  'Regno Unito',
  'Francia',
  'Brasile',
  'Italia',
  'Canada',
  'Corea del Sud',
  'Russia',
  'Australia',
  'Spagna',
  'Messico',
  'Indonesia',
  'Turchia',
  'Arabia Saudita',
  'Svizzera',
  'Argentina',
  'Taiwan',
  'Paesi Bassi',
  'Svezia',
  'Polonia',
  'Belgio',
  'Norvegia',
  'Austria',
  'Emirati Arabi Uniti',
  'Nigeria',
  'Iran',
  'Israele',
  'Irlanda',
  'Singapore',
  'Malaysia',
  'Sudafrica',
  'Filippine',
  'Colombia',
  'Cile',
  'Thailandia',
  'Pakistan',
  'Egitto',
  'Bangladesh',
  'Vietnam',
  'Ucraina',
  'Romania',
  'Perù',
  'Nuova Zelanda',
  'Grecia',
  'Repubblica Ceca',
];
const ESFTableDipendenti = () => {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]); // Una riga di default vuota
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [isEditing, setIsEditing] = useState(true);
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Nazionalità dipendente',
      headerTooltip:
        'Inserire la nazionalità del dipendente (es. Italiana, Americana,...)',
      field: 'nationality',
      cellEditor: 'agTextEditor',
      editable: true,
    },
    {
      headerName: 'N. Donne',
      headerTooltip: 'Inserire il numero di dipendenti donne',
      field: 'female',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
    },
    {
      headerName: 'N. Uomini',
      headerTooltip: 'Inserire il numero di dipendenti uomini',
      field: 'maschi',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
    },
    {
      headerName: 'N. Altro',
      headerTooltip: 'Inserire il numero di dipendenti con altro sesso',
      field: 'other',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
    },

    {
      headerName: 'Indeterminato',
      headerTooltip:
        'Inserire il numero di dipendenti con contratto a tempo indeterminato',
      field: 'indeterminate',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
    },
    {
      headerName: 'Determinato',
      headerTooltip:
        'Inserire il numero di dipendenti con contratto a tempo determinato',
      field: 'determined',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
    },

    {
      headerName: 'Full time',
      headerTooltip: 'Inserire il numero di dipendenti con contratto fulltime',
      field: 'fullTime',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
    },
    {
      headerName: 'Part-time',
      headerTooltip: 'Inserire il numero di dipendenti con contratto part-time',
      field: 'partTime',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
    },
  ]);

  useEffect(() => {
    if (esgData['102-7'] != undefined) {
      setRowData(esgData['102-7']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    console.log('Aggiorno i dati');
    pushESGData('102-7', rowData);
  }, [rowData]);

  const onChangeRowValue = e => {
    console.log(e);
    const ex_rowData = cloneDeep(rowData);
    ex_rowData[e.rowIndex] = e.data;
    console.log('SETTO ROW DATA');
    setRowData(ex_rowData);
  };

  const addNewRow = () => {
    if (isEditing && rowData.length < 5) {
      const newRow = {};
      setRowData([...rowData, newRow]);
    }
  };
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };

  return (
    <Stack>
      <h2>Lavoratori Dipendenti</h2>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValue}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
        />
        {/*a <Button
        onClick={addTotalRow}
        kind="ghost"
        variant="contained"

        
      >
        {isEditing ? 'Calcola Totali' : 'Modifica'}
      </Button>
      */}
        <Button
          onClick={addNewRow}
          kind="ghost"
          variant="contained"
          disabled={!isEditing}>
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga. E' possibile inserire fino ad un massimo di 5 righe.">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
    </Stack>
  );
};

export default ESFTableDipendenti;
