import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Information } from '@carbon/icons-react';
import { Stack, Button, Tooltip, TextArea } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG406_1 = () => {
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const gridApiRef = useRef(null);
  const [esg406_1, setEsg406_1] = useState();
  const columnDefs = [
    {
      headerName: 'Episodi di discriminazione',
      headerTooltip:
        'Inserisci il numero totale di episodi di discriminazione registrati o segnalati',
      field: 'numepisodi',
      editable: true,
      flex: 1,
      cellEditor: 'agLargeTextEditor',
    },
    {
      headerName: 'Descrizione Episodio',
      headerTooltip:
        'Fornisci una descrizione di ciascun episodio di discriminazione, inclusi i dettagli su quando è accaduto, chi è stato coinvolto e le circostanze circostanti',
      field: 'episodio',
      editable: true,
      flex: 1,
      cellEditor: 'agLargeTextEditor',
    },
    {
      headerName: 'Misure correttive adottate',
      headerTooltip:
        'Descrivi le azioni o le misure correttive prese in risposta agli episodi di discriminazione, incluse le politiche adottate, le formazioni fornite e le sanzioni applicate',
      field: 'measures',
      editable: true,
      flex: 1,
      cellEditor: 'agLargeTextEditor',
    },
  ];
  useEffect(() => {
    if (esgData['406-1'] != undefined) {
      setEsg406_1(esgData['406-1']);
      if (esgData['406-1']['tabella_episodi']) {
        setRowData(esgData['406-1']['tabella_episodi']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('406-1', esg406_1);
  }, [esg406_1]);
  const defaultColDef = {
    editable: true,
    flex: 1,
  };

  const addRow = () => {
    if (rowData.length < 5) {
      const newRowData = [
        ...rowData,
        { numepisodi: 0, measures: '', episodio: '' },
      ];

      setRowData(newRowData);
      setEsg406_1({ ...esg406_1, tabella_episodi: newRowData });
    }
  };

  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg406_1({ ...esg406_1, tabella_episodi: ex_rowData });
  };
  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg406_1({ ...esg406_1, tabella_episodi: updatedRowData });
    }
  };
  const handleChanges = e => {
    setEsg406_1({ ...esg406_1, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Misure di Non Discriminazione</h1>

      <p>
        Nel contesto di questa informativa, per ’episodio’ si intende un’azione
        legale o un reclamo inoltrato presso l’organizzazione che rendiconta o
        le autorità competenti attraverso una procedura formale, oppure un caso
        di non conformità identificato dall’organizzazione attraverso procedure
        definite.
      </p>

      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="misure_non_discriminazione"
        value={
          esg406_1 != undefined &&
          esg406_1['misure_non_discriminazione'] != undefined
            ? esg406_1['misure_non_discriminazione']
            : ''
        }
        placeholder="Rendicontare le misure anti discriminazione che l'azienda mette in atto e descrivere eventuali episodi"
        onChange={handleChanges}
        labelText="Rendicontazione Misure e Episodi"
      />
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          domLayout="autoHeight"
          onCellValueChanged={onChangeRowValue}
        />
      </div>
      <div>
        <Button onClick={addRow} kind="ghost">
          Aggiungi Riga
        </Button>
        <Button onClick={onDeleteRow} kind="ghost">
          {' '}
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga. E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 406-1</footer>
    </Stack>
  );
};

export default ESG406_1;
