import { Column, Button } from '@carbon/react';
const IconChoice = props => {
  return (
    <Column sm={4} md={8} lg={4} className="info-card__body">
      {props.icon()}
      <Button size={"sm"} onClick={props.onClick} kind="secondary">
        {props.text_icon}
      </Button>
    </Column>
  );
};

export default IconChoice;
