import cover from '../../../../assets/html_template/professional/A4/01-cover.html';
import cover169 from '../../../../assets/html_template/professional/169/01-cover.html';
import coverPopA4 from '../../../../assets/html_template/pop/A4/01-cover.html';
import coverPop169 from '../../../../assets/html_template/pop/169/01-cover.html';
import lightProfessional from '../../../../assets/html_template/professional/A4/img/light.png';
import naturalProfessional from '../../../../assets/html_template/professional/A4/img/natural.png';
import environmentProfessional from '../../../../assets/html_template/professional/A4/img/environment.png';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { bucket } from '../../../../action/fiirebase_config/firebase_config';
const CoverEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    transformOrigin: '0 0',
    height: '1130px',
    transform: 'scale(0.25)',
  };
  const resizeDataBig = {
    width: '800px',
    transformOrigin: '0 0',
    height: '1130px',
    transform: 'scale(0.56)',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
  };

  const backgroundUrl = {
    professional: {
      light: 'gs://stillab-esg.appspot.com/light.png',
      environment: 'gs://stillab-esg.appspot.com/environment.png',
      natural: 'gs://stillab-esg.appspot.com/natural.png',
    },
    pop: {
      light: 'gs://stillab-esg.appspot.com/light.png',
      environment: 'gs://stillab-esg.appspot.com/environment.png',
      natural: 'gs://stillab-esg.appspot.com/natural.png',
    },
  };

  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();
  const [template, setTemplate] = useState(false);
  const updateImageRes = useCallback(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);

  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? cover : cover169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString = type == 'A4' ? coverPopA4 : coverPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? cover : cover169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);

  useEffect(() => {
    updateImageRes();
  }, [updateImageRes]);

  const cover_image_values = [
    { value: 'environment', text: 'environment' },
    { value: 'light', text: 'light' },
    { value: 'natural', text: 'natural' },
  ];
  const [coverVariant, setCoverVariant] = useState('');
  const [logoData, setLogoData] = useState({});
  const [coverData, setCoverData] = useState(cover);
  const [coverDataA4, setcoverDataA4] = useState(cover);
  const [coverData169, setcoverData169] = useState(cover169);
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    setCoverData(coverPopA4);
    setcoverDataA4(coverPopA4);
    setcoverData169(coverPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    setCoverData(cover);
    setcoverDataA4(cover);
    setcoverData169(cover169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    setCoverData(cover);
    setcoverDataA4(cover);
    setcoverData169(cover169);
  }
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');
  const { esgData } = useESG();

  const updateDataUrl = async (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = coverData169;
        to_init = '169';
      } else {
        templateHTMLString = coverDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['template'] != undefined &&
        esgData['template']['variante_cover'] != undefined
      ) {
        const yourIdElement = parsedHTML.getElementById('background_cover');

        //codice per caricare la foto in base64
        if (yourIdElement) {
          console.log(esgData['template']['name_sample']);
          if (
            esgData['template']['name_sample'] == 'professional' &&
            esgData.template.variante_cover != '' &&
            esgData.template.variante_cover != undefined
          ) {
            const imageRef = bucket.ref(
              esgData['template']['name_sample'] +
                '/' +
                type +
                '/' +
                [esgData.template.variante_cover] +
                '.png'
            );
            const url = await imageRef.getDownloadURL();

            yourIdElement.style.backgroundImage = `url(${url})`;
          } else {
            yourIdElement.classList.contains('light') ??
              yourIdElement.classList.remove('light');
            yourIdElement.classList.contains('environment') ??
              yourIdElement.classList.remove('environment');
            yourIdElement.classList.contains('natural') ??
              yourIdElement.classList.remove('natural');
            yourIdElement.classList.add([esgData.template.variante_cover]);
          }

          setCoverVariant(esgData['template']['variante_cover']);
        }
      }
      if (
        esgData['102-1'] != undefined &&
        esgData['102-1']['logoData'] != undefined &&
        esgData['102-1']['logoData']['img_data'] != undefined
      ) {
        const yourIdElement = parsedHTML.getElementById('logo_data');

        //codice per caricare la foto in base64
        yourIdElement.src = esgData['102-1']['logoData']['img_data'];
        setLogoData(esgData['102-1']['logoData']['img_data']);
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const yourIdElement = parsedHTML.getElementById('anno_riferimento');

        //codice per caricare la foto in base64
        yourIdElement.innerHTML = esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);
      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('cover', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Copertina cover',
          initA4: coverPopA4,
          init169: coverPop169,
          keys_to_check: ['anno_riferimento', 'logo_data'],
          gri: 'cover',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('cover', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Copertina cover',
          initA4: cover,
          init169: cover169,
          keys_to_check: ['anno_riferimento', 'logo_data'],
          gri: 'cover',
        });
      }
    }
  };

  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };

  const renderHTMLtoiFrame = async (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString = templateData == '' ? coverData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['template'] != undefined &&
          esgData['template']['variante_cover'] != coverVariant
        ) {
          const yourIdElement = parsedHTML.getElementById('background_cover');
          //codice per caricare la foto in base64
          /*
              if (yourIdElement) {
                 yourIdElement.classList = ['cover '+esgData["template"]["variante_cover"]];
               }*/
          if (yourIdElement) {
            console.log(esgData['template']['name_sample']);
            if (
              esgData['template']['name_sample'] == 'professional' &&
              esgData.template.variante_cover != '' &&
              esgData.template.variante_cover != undefined
            ) {
              const imageRef = bucket.ref(
                esgData['template']['name_sample'] +
                  '/' +
                  type +
                  '/' +
                  [esgData.template.variante_cover] +
                  '.png'
              );
              const url = await imageRef.getDownloadURL();

              yourIdElement.style.backgroundImage = `url(${url})`;
            } else if (
              esgData.template.variante_cover != '' &&
              esgData.template.variante_cover != undefined
            ) {
              yourIdElement.classList.contains('light') ??
                yourIdElement.classList.remove('light');
              yourIdElement.classList.contains('environment') ??
                yourIdElement.classList.remove('environment');
              yourIdElement.classList.contains('natural') ??
                yourIdElement.classList.remove('natural');
              yourIdElement.classList.add([esgData.template.variante_cover]);
            }
            setCoverVariant(esgData['template']['variante_cover']);
          }
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['logoData'] != undefined &&
          esgData['102-1']['logoData']['img_data'] != logoData
        ) {
          const yourIdElement = parsedHTML.getElementById('logo_data');
          //codice per caricare la foto in base64

          yourIdElement.src = esgData['102-1']['logoData']['img_data'];
          setLogoData(esgData['102-1']['logoData']['img_data']);
        }
        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const yourIdElement = parsedHTML.getElementById('anno_riferimento');
          //codice per caricare la foto in base64
          yourIdElement.innerHTML = esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['template'] != undefined &&
          esgData['template']['variante_cover'] != undefined &&
          esgData['template']['variante_cover'] != ''
        ) {
          const yourIdElement = parsedHTML.getElementById('background_cover');
          //codice per caricare la foto in base64
          console.log(esgData['template']);
          if (yourIdElement) {
            console.log(esgData['template']['name_sample']);
            console.log(esgData.template.variante_cover);
            if (
              esgData['template']['name_sample'] == 'professional' &&
              esgData.template.variante_cover != '' &&
              esgData.template.variante_cover != undefined
            ) {
              const imageRef = bucket.ref(
                esgData['template']['name_sample'] +
                  '/' +
                  type +
                  '/' +
                  [esgData.template.variante_cover] +
                  '.png'
              );
              const url = await imageRef.getDownloadURL();

              yourIdElement.style.backgroundImage = `url(${url})`;
            } else {
              yourIdElement.classList.contains('light') ??
                yourIdElement.classList.remove('light');
              yourIdElement.classList.contains('environment') ??
                yourIdElement.classList.remove('environment');
              yourIdElement.classList.contains('natural') ??
                yourIdElement.classList.remove('natural');
              yourIdElement.classList.add([esgData.template.variante_cover]);
            }
            setCoverVariant(esgData['template']['variante_cover']);
          }
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['logoData']['img_data'] != undefined
        ) {
          const yourIdElement = parsedHTML.getElementById('logo_data');
          //codice per caricare la foto in base64
          yourIdElement.src = esgData['102-1']['logoData']['img_data'];
          setLogoData(esgData['102-1']['logoData']['img_data']);
        }
        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const yourIdElement = parsedHTML.getElementById('anno_riferimento');
          //codice per caricare la foto in base64
          yourIdElement.innerHTML = esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      setCoverData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setCoverData(coverPopA4);
      setcoverDataA4(coverPopA4);
      setcoverData169(coverPop169);
      force_update(type == 'A4' ? coverPopA4 : coverPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setCoverData(cover);
      setcoverDataA4(cover);
      setcoverData169(cover169);
      force_update(type == 'A4' ? cover : cover169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setCoverData(cover);
      setcoverDataA4(cover);
      setcoverData169(cover169);
      force_update(type == 'A4' ? cover : cover169);
    }
    if (
      esgData['template'] != undefined &&
      esgData['template']['variante_cover'] != coverVariant
    ) {
      hasUpdate = true;
    }
    if (
      esgData['102-1'] != undefined &&
      esgData['102-1']['logoData'] != undefined &&
      esgData['102-1']['logoData']['img_data'] != undefined &&
      esgData['102-1']['logoData']['img_data'] != logoData
    ) {
      hasUpdate = true;
    }
    if (
      esgData['102-3'] != undefined &&
      esgData['102-3']['periodo_rendicontazione'] != undefined &&
      esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
    ) {
      hasUpdate = true;
    }
    if (hasUpdate) {
      renderHTMLtoiFrame();
    }
  }, [esgData]);

  //prova img

  //{renderTemplate('cover', { name: 'John' })}
  return (
    <iframe
      title="Report"
      style={imageRes}
      src={iframeURL}
      sandbox="allow-same-origin allow-scripts"

      // Altezza arbitraria per visualizzazione
      // style={{  border: "1px solid #000" }}
    />
  );
};

export default CoverEngine;
