import SaluteSicurezza from '../../../../assets/html_template/professional/A4/11-ambiente-gri102-SaluteSicurezza.html';
import SaluteSicurezza169 from '../../../../assets/html_template/professional/169/11-ambiente-gri102-SaluteSicurezza.html';
import SaluteSicurezzaPopA4 from '../../../../assets/html_template/pop/A4/11-ambiente-gri102-SaluteSicurezza.html';
import SaluteSicurezzaPop169 from '../../../../assets/html_template/pop/169/11-ambiente-gri102-SaluteSicurezza.html';
import React, { useEffect, useCallback, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { Button, Column, Grid } from '@carbon/react';
import { ViewOff } from '@carbon/react/icons';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const SaluteSicurezzaEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? SaluteSicurezza : SaluteSicurezza169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? SaluteSicurezzaPopA4 : SaluteSicurezzaPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? SaluteSicurezza : SaluteSicurezza169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [dichiarazioneSalute, setDichiarazioneSalute] = useState('');
  const [saluteTable, setSaluteTable] = useState([]);

  const [saluteData, SetSaluteData] = useState(SaluteSicurezza);
  const [saluteDataA4, setsaluteDataA4] = useState(SaluteSicurezza);
  const [saluteData169, setsaluteData169] = useState(SaluteSicurezza169);
  var actualyear_3 = (new Date().getFullYear() - 3).toString();
  var actualyear_2 = (new Date().getFullYear() - 2).toString();
  var actualyear_1 = (new Date().getFullYear() - 1).toString();
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetSaluteData(SaluteSicurezzaPopA4);
    setsaluteDataA4(SaluteSicurezzaPopA4);
    setsaluteData169(SaluteSicurezzaPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetSaluteData(SaluteSicurezza);
    setsaluteDataA4(SaluteSicurezza);
    setsaluteData169(SaluteSicurezza169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetSaluteData(SaluteSicurezza);
    setsaluteDataA4(SaluteSicurezza);
    setsaluteData169(SaluteSicurezza169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetSaluteData(SaluteSicurezzaPopA4);
      setsaluteDataA4(SaluteSicurezzaPopA4);
      setsaluteData169(SaluteSicurezzaPop169);
      force_update(type == 'A4' ? SaluteSicurezzaPopA4 : SaluteSicurezzaPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetSaluteData(SaluteSicurezza);
      setsaluteDataA4(SaluteSicurezza);
      setsaluteData169(SaluteSicurezza169);
      force_update(type == 'A4' ? SaluteSicurezza : SaluteSicurezza169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetSaluteData(SaluteSicurezza);
      setsaluteDataA4(SaluteSicurezza);
      setsaluteData169(SaluteSicurezza169);
      force_update(type == 'A4' ? SaluteSicurezza : SaluteSicurezza169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('403-1')
    ) {
      setIsVisible(false);
      delPageData('403-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(saluteData);
    } else {
      if (
        esgData['403-1'] != undefined &&
        esgData['403-1']['dichiarazione_salute'] != undefined &&
        esgData['403-1']['dichiarazione_salute'] != dichiarazioneSalute
      ) {
        hasUpdate = true;
      }
      if (
        esgData['403-1'] != undefined &&
        esgData['403-1']['tabella_salute'] != undefined &&
        !compareLists(esgData['403-1']['tabella_salute'], saluteTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);
  const force_update = templateData => {
    if (esgData != undefined) {
      renderHTMLtoiFrame(templateData);
    }
  };
  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    try {
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = [
      'Descrizione',
      [actualyear_1],
      [actualyear_2],
      [actualyear_3],
    ];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = saluteData169;
        to_init = '169';
      } else {
        templateHTMLString = saluteDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['403-1'] != undefined &&
        esgData['403-1']['dichiarazione_salute'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'dichiarazione_salute'
        );
        fotoLeaderElement.innerHTML = esgData['403-1']['dichiarazione_salute'];
        setDichiarazioneSalute(esgData['403-1']['dichiarazione_salute']);
      }
      if (
        esgData['403-1'] != undefined &&
        esgData['403-1']['tabella_salute'] != undefined
      ) {
        const table = parsedHTML.getElementById('tabella_salute');
        const grafico = parsedHTML.getElementById('create_chart');
        if (esgData['403-1']['tabella_salute'].length > 0) {
          var filteredData = esgData['403-1']['tabella_salute'].filter(
            item =>
              item.actualyear !== 0 ||
              item.actualyear_1 !== 0 ||
              item.actualyear_2 !== 0
          );

          const listaModificata = filteredData.map(elemento => ({
            [actualyear_1]: elemento.actualyear,
            [actualyear_2]: elemento.actualyear_1,
            [actualyear_3]: elemento.actualyear_2,
            Descrizione: elemento.description,
          }));
          popolaTabella(table, listaModificata, ordered_keys);
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
          //TOSTART
          var data = ${JSON.stringify(filteredData, null, 2)};
          //TOEND;

        ` + grafico.textContent;
        }
        setSaluteTable(cloneDeep(esgData['403-1']['tabella_salute']));
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('403-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Salute e Sicurezza sul Lavoro',
          initA4: SaluteSicurezzaPopA4,
          init169: SaluteSicurezzaPop169,
          keys_to_check: [
            'create_chart',
            'tabella_salute',
            'dichiarazione_salute',
          ],
          gri: '403-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('403-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Salute e Sicurezza sul Lavoro',
          initA4: SaluteSicurezza,
          init169: SaluteSicurezza169,
          keys_to_check: [
            'create_chart',
            'tabella_salute',
            'dichiarazione_salute',
          ],
          gri: '403-1',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString = templateData != '' ? templateData : saluteData;
      const parser = new DOMParser();
      const ordered_keys = [
        'Descrizione',
        [actualyear_1],
        [actualyear_2],
        [actualyear_3],
      ];
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['403-1'] != undefined &&
          esgData['403-1']['dichiarazione_salute'] != undefined &&
          esgData['403-1']['dichiarazione_salute'] != dichiarazioneSalute
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'dichiarazione_salute'
          );
          fotoLeaderElement.innerHTML =
            esgData['403-1']['dichiarazione_salute'];
          setDichiarazioneSalute(esgData['403-1']['dichiarazione_salute']);
        }
        //&&!compareLists(tabellacriticita,esgData["403-1"]["rendicontazione_criticita"])

        if (
          esgData['403-1'] != undefined &&
          esgData['403-1']['tabella_salute'] != undefined &&
          !compareLists(esgData['403-1']['tabella_salute'], saluteTable)
        ) {
          const table = parsedHTML.getElementById('tabella_salute');
          const grafico = parsedHTML.getElementById('create_chart');
          if (esgData['403-1']['tabella_salute'].length > 0) {
            var filteredData = esgData['403-1']['tabella_salute'].filter(
              item =>
                item.actualyear !== 0 ||
                item.actualyear_1 !== 0 ||
                item.actualyear_2 !== 0
            );
            const listaModificata = filteredData.map(elemento => ({
              [actualyear_1]: elemento.actualyear,
              [actualyear_2]: elemento.actualyear_1,
              [actualyear_3]: elemento.actualyear_2,
              Descrizione: elemento.description,
            }));
            popolaTabella(table, listaModificata, ordered_keys);
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
                //TOSTART
                var data = ${JSON.stringify(filteredData, null, 2)};
                //TOEND;

              ` + grafico.textContent;
          }
          setSaluteTable(cloneDeep(esgData['403-1']['tabella_salute']));
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['403-1'] != undefined &&
          esgData['403-1']['dichiarazione_salute'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'dichiarazione_salute'
          );
          fotoLeaderElement.innerHTML =
            esgData['403-1']['dichiarazione_salute'];
          setDichiarazioneSalute(esgData['403-1']['dichiarazione_salute']);
        }
        if (
          esgData['403-1'] != undefined &&
          esgData['403-1']['tabella_salute'] != undefined
        ) {
          const table = parsedHTML.getElementById('tabella_salute');
          const grafico = parsedHTML.getElementById('create_chart');
          if (esgData['403-1']['tabella_salute'].length > 0) {
            var filteredData = esgData['403-1']['tabella_salute'].filter(
              item =>
                item.actualyear !== 0 ||
                item.actualyear_1 !== 0 ||
                item.actualyear_2 !== 0
            );
            const listaModificata = filteredData.map(elemento => ({
              [actualyear_1]: elemento.actualyear,
              [actualyear_2]: elemento.actualyear_1,
              [actualyear_3]: elemento.actualyear_2,
              Descrizione: elemento.description,
            }));
            popolaTabella(table, listaModificata, ordered_keys);
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
              //TOSTART
              var data = ${JSON.stringify(filteredData, null, 2)};
              //TOEND;

            ` + grafico.textContent;
          }
          setSaluteTable(cloneDeep(esgData['403-1']['tabella_salute']));
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetSaluteData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SaluteSicurezzaEngine;
