import React, { useEffect, useState, useRef } from 'react';
import { Tooltip, Button, Stack, TextArea } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { useESG } from '../../../action/ESGData/esgdata';
const ESG301_2 = () => {
  const gridApiRefMatRiciclati = useRef(null);
  // Definisci uno stato per le righe della tabella
  const [rowDataMatRiciclati, setRowDataMatRiciclati] = useState([]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [esg301_2_3, setEsg301_2_3] = useState();
  // Definisci le colonne della tabella
  const columnsDefMatRiciclati = [
    {
      headerName: 'Nome Materiale',
      field: 'materialName',
      headerTooltip:
        'Inserisci il nome del materiale (es. carta, plastica, vetro, metallo, ecc.)',
      editable: true,
      cellEditor: 'agTextCellEditor',
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Quantità Materiale in peso o volume',
      headerTooltip: 'Inserisci la quantità del materiale in peso o volume ',

      field: 'materialQuantity',
      editable: true,
      cellEditor: 'agTextNumberCellEditor',
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: '% Riciclato',
      headerTooltip:
        'Inserisci la percentuale di materiale che è riciclato rispetto al totale (es. 25% se un quarto del materiale è riciclato, 50% se metà del materiale è riciclato, ecc.)',

      field: 'recyclingPercentage',
      editable: true,
      cellEditor: 'agTextNumberCellEditor',
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];
  //materiali recuperati data

  const gridApiRefMatRecuperati = useRef(null);
  const [rowDataMatRecuperati, setRowDataMatRecuperati] = useState([]);

  const onAddRowClickMatRecuperati = () => {
    if (rowDataMatRecuperati.length < 5) {
      const newRow = {
        CategoriaProdotto: '',
        PercentualeProdottiRecuperati: 0,
        MaterialiConfezionamento: '',
      };
      setRowDataMatRecuperati([...rowDataMatRecuperati, newRow]);
      setEsg301_2_3({
        ...esg301_2_3,
        ['materiali_recuperati_tabella']: [...rowDataMatRecuperati, newRow],
      });
    }
  };
  const columnDefsMatRecuperati = [
    {
      headerName: 'Categoria Prodotto',
      headerTooltip: 'Inserisci la categoria del prodotto',
      field: 'CategoriaProdotto',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: '% Prodotti Recuperati',
      headerTooltip:
        'Inserisci la percentuale di prodotti recuperati che compongono la categoria prodotto',

      field: 'PercentualeProdottiRecuperati',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Materiali Confezionamento',
      headerTooltip:
        'Inserisci i principali materiali di confezionamento utilizzati per la categoria di prodotto',

      field: 'MaterialiConfezionamento',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];
  const onChangeRowValueMatRecuperati = e => {
    const ex_rowData = rowDataMatRecuperati;
    ex_rowData[e.rowIndex] = e.data;
    setRowDataMatRecuperati(ex_rowData);
    setEsg301_2_3({
      ...esg301_2_3,
      ['materiali_recuperati_tabella']: ex_rowData,
    });
  };

  const onDeleteRowMatRecuperati = () => {
    const selectedRows = gridApiRefMatRecuperati.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowDataMatRecuperati.filter(
        row => !selectedRows.includes(row)
      );
      setRowDataMatRecuperati(updatedRowData);
      setEsg301_2_3({
        ...esg301_2_3,
        ['materiali_recuperati_tabella']: updatedRowData,
      });
    }
  };

  const onGridReadyMatRecuperati = params => {
    gridApiRefMatRecuperati.current = params.api;
  };
  // Aggiungi una nuova riga alla tabella
  const addRowMatRiciclati = () => {
    if (rowDataMatRecuperati.length < 5) {
      const newRow = {
        materialName: '',
        materialQuantity: 0,
        recyclingPercentage: 0,
      };
      setRowDataMatRiciclati([...rowDataMatRiciclati, newRow]);
      setEsg301_2_3({
        ...esg301_2_3,
        ['materiali_riciclati_tabella']: [...rowDataMatRiciclati, newRow],
      });
    }
  };
  const onChangeRowValueMatRiciclati = e => {
    const ex_rowData = rowDataMatRiciclati;
    ex_rowData[e.rowIndex] = e.data;
    setRowDataMatRiciclati(ex_rowData);
    setEsg301_2_3({
      ...esg301_2_3,
      ['materiali_riciclati_tabella']: ex_rowData,
    });
  };
  useEffect(() => {
    if (esgData['301-2-3'] != undefined) {
      setEsg301_2_3(esgData['301-2-3']);
      if (esgData['301-2-3']['materiali_riciclati_tabella']) {
        setRowDataMatRiciclati(
          esgData['301-2-3']['materiali_riciclati_tabella']
        );
      }
      if (esgData['301-2-3']['materiali_recuperati_tabella']) {
        setRowDataMatRecuperati(
          esgData['301-2-3']['materiali_recuperati_tabella']
        );
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('301-2-3', esg301_2_3);
  }, [esg301_2_3]);

  const onDeleteRowMatRiciclati = () => {
    const selectedRows = gridApiRefMatRiciclati.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowDataMatRiciclati.filter(
        row => !selectedRows.includes(row)
      );
      setRowDataMatRiciclati(updatedRowData);
      setEsg301_2_3({
        ...esg301_2_3,
        ['materiali_riciclati_tabella']: updatedRowData,
      });
    }
  };

  const onGridReadyMatRiciclati = params => {
    gridApiRefMatRiciclati.current = params.api;
  };
  const handleChanges = e => {
    setEsg301_2_3({ ...esg301_2_3, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Riciclo e Recupero Materiali</h1>
      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="descrizione_riciclo_materiali"
        value={
          esg301_2_3 && esg301_2_3['descrizione_riciclo_materiali'] != undefined
            ? esg301_2_3['descrizione_riciclo_materiali']
            : ''
        }
        labelText="Descrivere i principali processi di riciclo e di recupero dei materiali lavorati."
        onChange={handleChanges}
      />
      <h2>% Riciclo materiali lavorati</h2>
      <p>
        Consuntivare di seguito le procedure e le quantità dei materiali
        riciclati
      </p>

      <div className="ag-theme-alpine" style={{ height: 200, width: '100%' }}>
        <AgGridReact
          rowData={rowDataMatRiciclati}
          columnDefs={columnsDefMatRiciclati}
          rowSelection={'multiple'}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          onGridReady={onGridReadyMatRiciclati}
          onCellValueChanged={onChangeRowValueMatRiciclati}
        />
        <Button kind="ghost" onClick={addRowMatRiciclati}>
          Aggiungi Riga
        </Button>
        <Button
          kind="ghost"
          onClick={onDeleteRowMatRiciclati}
          className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga.  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <h2>Materiali Recuperati</h2>
      <p>
        Rendicontare i prodotti recuperati e i relativi materiali di
        confezionamento per ciascuna categoria di prodotto
      </p>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowDataMatRecuperati}
          columnDefs={columnDefsMatRecuperati}
          onGridReady={onGridReadyMatRecuperati}
          domLayout="autoHeight"
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValueMatRecuperati}
        />
        <Button kind="ghost" onClick={onAddRowClickMatRecuperati}>
          Aggiungi Riga
        </Button>

        <Button
          kind="ghost"
          onClick={onDeleteRowMatRecuperati}
          className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga.  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>
        GRI 301-2 e GRI 301-3{' '}
      </footer>
    </Stack>
  );
};

export default ESG301_2;
