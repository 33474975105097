import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Information } from '@carbon/icons-react';
import { Stack, Tooltip, Button, TextArea } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG306_1 = () => {
  const gridApiRef = useRef(null);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [rowData, setRowData] = useState([]);
  const [esg306_1, setEsg306_1] = useState();
  const columnDefs = [
    {
      headerName: 'Categoria',
      field: 'categoria',
      headerTooltip:
        'Inserisci la categoria del rifiuto (es. plastica, vetro, carta, organico, metalli, ecc.)',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Quantità',
      field: 'quantita',
      headerTooltip: 'Inserisci la quantità del rifiuto prodotto',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'U.M.',
      headerTooltip:
        "Inserisci l'unità di misura della quantità di rifiuti (es. kg, tonnellate, metri cubi, ecc.)",
      field: 'um',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: '% Conferita a Recupero (R)',
      headerTooltip:
        'Inserisci la percentuale del rifiuto che viene conferita a recupero (R) rispetto alla quantità totale del rifiuto',
      field: 'nonConferitiInDiscarica',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: '% Conferiti a Smaltimento (D)',
      headerTooltip:
        'Inserisci la percentuale del rifiuto che viene conferita a smaltimento (D) rispetto alla quantità totale del rifiuto',
      field: 'conferitiInDiscarica',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];

  const addRow = () => {
    if (rowData.length < 5) {
      const newRow = {
        categoria: '',
        quantita: 0,
        um: 'kg',
        nonConferitiInDiscarica: 0,
        conferitiInDiscarica: 0,
      };
      setRowData([...rowData, newRow]);
      setEsg306_1({ ...esg306_1, ['rifiuti_tabella']: [...rowData, newRow] });
    }
  };
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg306_1({ ...esg306_1, ['rifiuti_tabella']: ex_rowData });
  };
  useEffect(() => {
    if (esgData['306-1'] != undefined) {
      setEsg306_1(esgData['306-1']);
      if (esgData['306-1']['rifiuti_tabella'] != undefined) {
        setRowData(esgData['306-1']['rifiuti_tabella']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('306-1', esg306_1);
  }, [esg306_1]);
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg306_1({ ...esg306_1, ['rifiuti_tabella']: updatedRowData });
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const handleChanges = e => {
    setEsg306_1({ ...esg306_1, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Gestione dei rifiuti</h1>

      <p>
        La quantità, il tipo e la qualità dei rifiuti generati da
        un’organizzazione sono una conseguenza delle attività necessarie per la
        realizzazione dei suoi prodotti e servizi (per es., estrazione,
        lavorazione, approvvigionamento di materiali, progettazione di prodotti
        o servizi, produzione, distribuzione) e il loro successivo consumo. Una
        valutazione di come i materiali si muovono entrando nell’organizzazione,
        attraversandola e uscendone possono aiutare a comprendere come, nella
        catena del valore dell’organizzazione, questi materiali alla fine
        diventano rifiuti. Ciò fornisce una panoramica olistica della
        generazione di rifiuti e delle sue cause, che a sua volta può consentire
        all’organizzazione di identificare più facilmente opportunità di
        prevenzione dei rifiuti e di adozione di misurazioni della circolarità.
      </p>
      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="descrizione_rifiuti"
        value={
          esg306_1 && esg306_1['descrizione_rifiuti'] != undefined
            ? esg306_1['descrizione_rifiuti']
            : ''
        }
        labelText="Descrivere le principali categorie di rifiuti prodotti dall'organizzazione e come essi vengono smaltiti"
        onChange={handleChanges}
      />
      <p>Completare la tabella inserendo le informazioni richieste.</p>

      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowSelection={'multiple'}
          domLayout="autoHeight"
          onCellValueChanged={onChangeRowValue}
        />
        <Button onClick={addRow} iconDescription="Aggiungi riga" kind="ghost">
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga.  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 306-1 </footer>
    </Stack>
  );
};

export default ESG306_1;
