import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Add, Subtract } from '@carbon/icons-react';
import {
  Column,
  Modal,
  Button,
  TextInput,
  Stack,
  RadioButtonGroup,
  RadioButton,
  FormGroup,
  Dropdown,
  FilterableMultiSelect,
  Grid,
  TextArea,
  DatePicker,
} from '@carbon/react';
import { ArrowDown } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
import UploadImage from '../../UploadImage';
const opzioni_settore = [
  { label: 'Settore Pubblico', value: 'settore_pubblico' },
  { label: 'Settore Privato', value: 'settore_privato' },
  { label: 'Settore di Formazione', value: 'settore_formazione' },
  { label: 'Settore Finanziario', value: 'settore_finanziario' },
  { label: 'Agricoltura', value: 'agricoltura' },
  { label: 'Manifattura', value: 'manifattura' },
  { label: "Tecnologia dell'informazione", value: 'tecnologia_informazione' },
  { label: 'Sanità', value: 'sanita' },
  { label: 'Ingegneria e Costruzione', value: 'ingegneria_costruzione' },
  { label: 'Trasporti e Logistica', value: 'trasporti_logistica' },
  { label: 'Energia', value: 'energia' },
  { label: 'Ristorazione', value: 'ristorazione' },
  { label: 'Media e Intrattenimento', value: 'media_intrattenimento' },
  { label: 'Altro', value: 'altro' },
];
function ESG102_10(props) {
  const [esg102_10, setEsg102_10] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-10'] != undefined) {
      setEsg102_10(esgData['102-10']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-10', esg102_10);
  }, [esg102_10]);
  //si utilizza useEffect per caricare i dati in ingresso se ci sono

  const handleChanges = e => {
    setEsg102_10({ ...esg102_10, [e.target.id]: e.target.value });
  };
  const setImgData = data => {
    setEsg102_10({ ...esg102_10, ['organigramma']: data });
  };
  const setImgFile = data => {
    setEsg102_10({ ...esg102_10, ['fileOrganigramma']: data });
  };
  return (
    <Stack gap={8}>
      <h1>GRI 102-10</h1>
      <h2>Nomina e selezione del massimo organo di governo</h2>
      <p>
        Completare i campi in questa sezione inserendo le informazioni richieste
      </p>

      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="nomina_selezione"
        value={
          esg102_10['nomina_selezione'] != undefined
            ? esg102_10['nomina_selezione']
            : ''
        }
        placeholder="Descrivere con testo libero  le procedure e i criteri di nomina e selezione del massimo organo di governo e dei suoi comitati;
"
        onChange={handleChanges}
        labelText="Procedure di Nomina del Massimo Organo di Governo e dei suoi Comitati"
      />
      <UploadImage
        setImgData={setImgData}
        setImgFile={setImgFile}
        selectedItem={esg102_10['organigramma']}
        reportId={esgData.id}
        tipo={'Organigramma'}
        filename={'organigramma'}
      />
    </Stack>
  );
}

export default ESG102_10;
