import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Information } from '@carbon/icons-react';
import { TextInput, Button, Stack, Tooltip } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

function ESGTableComitati(setStateFun, esg102_9) {
  const gridApiRef = useRef(null);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [rowData, setRowData] = useState([
    { id: 1, nomeComitato: '', influenzaDecisionali: '' },
  ]);
  useEffect(() => {
    if (esgData['102-9'] != undefined) {
      if (esgData['102-9']['definizione_comitati'] != undefined) {
        setRowData(esgData['102-9']['definizione_comitati']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);

  useEffect(() => {
    setStateFun['setStateFun']('definizione_comitati', rowData);
  }, [rowData]);
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
  };

  const columnDefs = [
    {
      headerName: 'Nome Comitato',
      field: 'nomeComitato',
      editable: true,
      resizable: true,
      cellEditor: 'agTextCellEditor',
    },
    {
      headerName: 'Influenza sui Processi Decisionali',
      field: 'influenzaDecisionali',
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      editable: true,
      resizable: true,
      cellEditorParams: {
        maxLength: 250,
        rows: 10,
        cols: 50,
      },
    },
  ];
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const frameworkComponents = {
    agLargeTextCellEditor: LargeTextCellEditor,
  };

  const gridOptions = {
    defaultColDef: {
      resizable: true,
      editable: true,
    },
  };

  const handleAddRow = () => {
    const newRow = {
      id: rowData.length + 1,
      nomeComitato: '',
      influenzaDecisionali: '',
    };
    setRowData([...rowData, newRow]);
  };

  return (
    <Stack gap={8}>
      <p>
        {' '}
        Elencare i comitati del suddetto organo responsabili dei processi
        decisionali e del controllo della gestione degli impatti
        dell’organizzazione sull’economia, sull’ambiente e sulle persone;
      </p>
      <div className="ag-theme-alpine" style={{ height: 200, width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValue}
        />
        <Button kind="ghost" onClick={handleAddRow} className="add-row-button">
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
    </Stack>
  );
}

function LargeTextCellEditor(props) {
  const { value, onValueChange } = props;

  const handleTextInputChange = e => {
    onValueChange(e.target.value);
  };

  return <TextInput value={value} onChange={handleTextInputChange} />;
}

export default ESGTableComitati;
