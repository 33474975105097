import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Stack, TextArea } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG405_2 = () => {
  const [actualYear, setActualYear] = useState(new Date().getFullYear());
  const [esg405_2, setEsg405_2] = useState({});
  const { esgData, pushESGData, refreshESGData } = useESG();

  const init_rowoperai = [
    {
      anno: actualYear - 3,
      uomini: 0,
      donne: 0,
    },
    {
      anno: actualYear - 2,
      uomini: 0,
      donne: 0,
    },
    {
      anno: actualYear - 1,
      uomini: 0,
      donne: 0,
    },
  ];
  const init_rowimpiegati = [
    {
      anno: actualYear - 3,
      uomini: 0,
      donne: 0,
    },
    {
      anno: actualYear - 2,
      uomini: 0,
      donne: 0,
    },
    {
      anno: actualYear - 1,
      uomini: 0,
      donne: 0,
    },
  ];
  const init_rowmanagers = [
    {
      anno: actualYear - 3,
      uomini: 0,
      donne: 0,
    },
    {
      anno: actualYear - 2,
      uomini: 0,
      donne: 0,
    },
    {
      anno: actualYear - 1,
      uomini: 0,
      donne: 0,
    },
  ];
  const columnDefsWhite = [
    {
      headerName: 'Anno',
      field: 'anno',
      cellEditor: 'agTextEditor',
      editable: true,
      flex: 1,
    },
    {
      headerName: 'Uomini',
      field: 'uomini',
      cellEditor: 'agTextEditor',
      editable: true,
      flex: 1,
    },
    {
      headerName: 'Donne',
      field: 'donne',
      cellEditor: 'agTextEditor',
      editable: true,
      flex: 1,
    },
  ];
  // Dati delle tabelle
  const [blueCollarsData, setBlueCollarsData] = useState(init_rowoperai);

  const [whiteCollarsData, setWhiteCollarsData] = useState(init_rowimpiegati);

  const [managersData, setManagersData] = useState(init_rowmanagers);
  useEffect(() => {
    // Calcola l'anno attuale
    const currentYear = new Date().getFullYear();
    setActualYear(currentYear);
    if (esgData['405-2'] != undefined) {
      setEsg405_2(esgData['405-2']);

      if (esgData['405-2']['managers'] != undefined) {
        setManagersData(esgData['405-2']['managers']);
      }

      if (esgData['405-2']['bluecollars'] != undefined) {
        setBlueCollarsData(esgData['405-2']['bluecollars']);
      }

      if (esgData['405-2']['whitecollars'] != undefined) {
        setWhiteCollarsData(esgData['405-2']['whitecollars']);
      }
    }
  }, []);
  useEffect(() => {
    pushESGData('405-2', esg405_2);
  }, [esg405_2]);
  const columnDefsBlue = [
    {
      headerName: 'Anno',
      field: 'anno',
      cellEditor: 'agTextEditor',
      editable: true,
      flex: 1,
    },
    {
      headerName: 'Uomini',
      field: 'uomini',
      cellEditor: 'agTextEditor',
      editable: true,
      flex: 1,
    },
    {
      headerName: 'Donne',
      field: 'donne',
      cellEditor: 'agTextEditor',
      editable: true,
      flex: 1,
    },
  ];
  const onChangeRowValueBlue = e => {
    const ex_rowData = blueCollarsData;
    ex_rowData[e.rowIndex] = e.data;
    setBlueCollarsData(ex_rowData);
    setEsg405_2({ ...esg405_2, ['bluecollars']: ex_rowData });
  };

  const onChangeRowValueManagers = e => {
    const ex_rowData = managersData;
    ex_rowData[e.rowIndex] = e.data;
    setManagersData(ex_rowData);
    setEsg405_2({ ...esg405_2, ['managers']: ex_rowData });
  };

  const onChangeRowValueWhite = e => {
    const ex_rowData = whiteCollarsData;
    ex_rowData[e.rowIndex] = e.data;
    setWhiteCollarsData(ex_rowData);
    setEsg405_2({ ...esg405_2, ['whitecollars']: ex_rowData });
  };
  const handleChanges = e => {
    setEsg405_2({ ...esg405_2, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>
        Rapporto tra Salario di base e retribuzione delle donne rispetto agli
        uomini
      </h1>
      <p>
        Un’organizzazione può adoperarsi attivamente per riesaminare le proprie
        attività e decisioni al fine di promuovere la diversità, eliminare il
        pregiudizio di genere e sostenere le pari opportunità. Questi principi
        si applicano allo stesso modo alle procedure di assunzione, alle
        opportunità di avanzamento professionale e alle politiche retributive.
        Anche la parità di salario è un fattore importante per la fidelizzazione
        di dipendenti qualificati.
      </p>
      <TextArea
        id="misure_paropp"
        maxCount={1000}
        enableCounter={true}
        value={
          esg405_2['misure_paropp'] != undefined
            ? esg405_2['misure_paropp']
            : ''
        }
        placeholder="Rendicontare le misure messe in atto dall'organizzazione per favorire la diversità e le pari opportunità"
        onChange={handleChanges}
        labelText="Misure per le Pari Opportunità"
      />
      {/* Tabella Blue Collars */}
      <div>
        <h3>Salari Medi Blue Collars/Operaio</h3>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={blueCollarsData}
            domLayout="autoHeight"
            columnDefs={columnDefsBlue}
            onCellValueChanged={onChangeRowValueBlue}
          />
        </div>
      </div>

      {/* Tabella White Collars */}
      <div>
        <h3> Salari Medi White Collars/Impiegati</h3>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={whiteCollarsData}
            domLayout="autoHeight"
            columnDefs={columnDefsWhite}
            onCellValueChanged={onChangeRowValueWhite}
          />
        </div>
      </div>

      {/* Tabella Middle Managers/Dirigenti */}
      <div>
        <h3> Salari Medi Middle Managers/Dirigenti</h3>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={managersData}
            domLayout="autoHeight"
            columnDefs={[
              {
                headerName: 'Anno',
                field: 'anno',
                cellEditor: 'agTextEditor',
                editable: true,
                flex: 1,
              },
              {
                headerName: 'Uomini',
                field: 'uomini',
                cellEditor: 'agTextEditor',
                editable: true,
                flex: 1,
              },
              {
                headerName: 'Donne',
                field: 'donne',
                cellEditor: 'agTextEditor',
                editable: true,
                flex: 1,
              },
            ]}
            onCellValueChanged={onChangeRowValueManagers}
          />
        </div>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>405-2</footer>
    </Stack>
  );
};

export default ESG405_2;
