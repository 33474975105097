import React, { useState, useEffect, useRef } from 'react';
import { useESG } from '../../../action/ESGData/esgdata';
import { Stack, Tooltip, TextArea, Button } from '@carbon/react';
import { AgGridReact } from 'ag-grid-react';
import { Information } from '@carbon/icons-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function ESG102_29(props) {
  const gridApiRef = useRef(null);
  const options = [
    'Clienti',
    'Fornitori',
    'Soci',
    'Dipendenti',
    'Azionisti',
    'Enti di controllo',
    'Territorio e Comunità',
    'Istituti di credito',
    'Università e Istituti di ricerca',
    'Generazioni future',
    'Media',
    'Altro',
  ];
  const [rowData, setRowData] = useState([]);
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setEsg102_29({ ...esg102_29, ['tabella_stakeholder']: ex_rowData });
    //setRowData(ex_rowData);
  };
  const gridOptions = {
    onCellValueChanged: onChangeRowValue,
  };
  const { esgData, pushESGData, refreshESGData } = useESG();
  const columnDefs = [
    /*  {
      headerName: 'Tipologia Stakeholder',
      field: 'tipologiastakeholder',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: options,
        valueListGap: 0,
      },
      editable: true,
    },*/
    {
      headerName: 'Tipologia Stakeholder',
      field: 'tipologiastakeholder',
      cellEditor: 'agTextEditor',
      editable: true,
      wrapText: true,
      resizable: true,
      headerTooltip: 'Esempi: clienti, fornitori, territorio...',
    },
    {
      headerName: 'Aspettative',
      field: 'aspettative',
      cellEditor: 'agTextEditor',
      editable: true,
      wrapText: true,
      resizable: true,
      headerTooltip: 'Rendicontare cosa gli stakeholder si aspettano',
    },
  ];
  const [esg102_29, setEsg102_29] = useState({});
  useEffect(() => {
    if (esgData['102-29'] != undefined) {
      setEsg102_29(esgData['102-29']);
      if (esgData['102-29']['tabella_stakeholder'] != undefined) {
        setRowData(esgData['102-29']['tabella_stakeholder']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-29', esg102_29);
  }, [esg102_29]);

  const addNewRow = () => {
    if (rowData.length < 5) {
      const newRow = {
        tipologiastakeholder: '',
        aspettative: '',
      };
      setRowData([...rowData, newRow]);
      setEsg102_29({
        ...esg102_29,
        ['tabella_stakeholder']: [...rowData, newRow],
      });
    }
  };
  const handleChanges = e => {
    //setScopo_stakeholder(e.target.value);
    setEsg102_29({ ...esg102_29, [e.target.id]: e.target.value });
  };
  const onGridReady = params => {
    params.api.sizeColumnsToFit();
    gridApiRef.current = params.api;
  };
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg102_29({ ...esg102_29, ['tabella_stakeholder']: updatedRowData });
    }
  };
  return (
    <Stack gap={8}>
      <h1>Approccio al coinvolgimento degli stakeholder </h1>
      <p>
        Selezionare i principali stakeholder a cui l'organizzazione si rivolge
      </p>
      <Stack gap={4}>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            rowData={rowData}
            onCellValueChanged={onChangeRowValue}
            domLayout="autoHeight"
            rowSelection={'multiple'}
            overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          />
          <Button onClick={addNewRow} kind="ghost" variant="contained">
            Aggiungi Riga
          </Button>
          <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
            Elimina Riga
          </Button>
          <Tooltip
            align="bottom"
            label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga. E' possibile aggiungere  E' possibile popolare fino a 5 righe">
            <Button kind="ghost" className="sb-tooltip-trigger" type="button">
              <Information />
            </Button>
          </Tooltip>
        </div>
      </Stack>
      <Stack gap={4}>
        <p>Rendicontare lo scopo del coinvolgimento degli stakeholder.</p>
        <TextArea
          maxCount={500}
          enableCounter={true}
          id="scopo_stakeholder"
          value={
            esg102_29['scopo_stakeholder'] != undefined
              ? esg102_29['scopo_stakeholder']
              : ''
          }
          placeholder="Descrivere con testo libero lo scopo del coinvolgimento degli stakeholder"
          onChange={handleChanges}
        />
      </Stack>
      <Stack gap={4}>
        <p>
          Rendicontare come l’organizzazione cerca di assicurare un
          coinvolgimento significativo con gli stakeholder.
        </p>
        <TextArea
          id="come_stakeholder"
          maxCount={700}
          enableCounter={true}
          value={
            esg102_29['come_stakeholder'] != undefined
              ? esg102_29['come_stakeholder']
              : ''
          }
          placeholder="Descrivere con testo libero come l’organizzazione assicurare il coinvolgimento  degli
stakeholder"
          onChange={handleChanges}
        />
      </Stack>
      <footer style={{ fontSize: 10, marginRight: 0 }}>
        GRI 102-29 /ESRS SBM{' '}
      </footer>
    </Stack>
  );
}

export default ESG102_29;
