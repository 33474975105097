import React, { useState, useEffect } from 'react';
import {
  Form,
  TextInput,
  Stack,
  Button,
  TreeNode,
  TreeView,
  Modal,
  Grid,
  Column,
  TextArea,
  ToastNotification,
} from '@carbon/react';
import {
  Folder,
  Document,
  Edit,
  Checkmark,
  SkipForward,
  SkipBack,
  Save,
  Home,
  TaskRemove,
} from '@carbon/react/icons';
import CustomForm from '../../components/Standard/Form';
import ESGTableConsumi from '../../components/ESGTable/ESGTableConsumi';
import CustomColorExtractor from '../../components/CustomColorExtractor';
import PDFCreator from '../../components/PDFCreator';
import { saveAs } from 'file-saver';
import JsonToReactConverter from '../../action/JsonToReactConverter/JsonToReactConverter';
import ESG_all from '../../components/ESGTable/ESG_all';
import renderComponent from '../../action/RenderComponent/RenderComponent';
import Anagrafica from '../../components/Checklist/Anagrafica';
import ESGResult from '../../components/ESGTable/ESGResult';
import ESFTableDipendenti from '../../components/ESGTable/ESGTableDipendenti';
import ESG102_11 from '../../components/ESGTable/102-11/ESG102_11';
import ESG102_19_20 from '../../components/ESGTable/102-19-20/ESG102_19_20';
import ESGTableComitati from '../../components/ESGTable/ESGTableComitati/ESGTableComitati';
import ESG102_9 from '../../components/ESGTable/102-9/ESG102_9';
import ESG102_8 from '../../components/ESGTable/102-8/ESG102_8';
import ESG102_29 from '../../components/ESGTable/102-29/ESG102_29';
import ESG102_16 from '../../components/ESGTable/102-16/ESG102_16';
import ESG102_17 from '../../components/ESGTable/102-17/ESG102_17';
import ESG102_18 from '../../components/ESGTable/102-18/ESG102_18';
import ESG102_21 from '../../components/ESGTable/102-21/ESG102_21';
import ESG102_27 from '../../components/ESGTable/102-27/ESG102_27';
import ESG102_28 from '../../components/ESGTable/102-28/ESG102_28';
import ESG201_1 from '../../components/ESGTable/201-1/ESG201_1';
import ESG201_2 from '../../components/ESGTable/201-2/ESG201_2';
import ESG203_1 from '../../components/ESGTable/203-1/ESG203_1';
import ESG203_2 from '../../components/ESGTable/203-2/ESG203_2';
import ESG204_1 from '../../components/ESGTable/204-1/ESG204_1';
import ESG205_2_3 from '../../components/ESGTable/205-2-3/ESG205_2_3';
import ESG301_1 from '../../components/ESGTable/301-1/ESG301_1';
import ESG301_2 from '../../components/ESGTable/301-2/ESG301_2';
import ESG301_3 from '../../components/ESGTable/301-3/ESG301_3';
import ESG302_1_3 from '../../components/ESGTable/302-1-3/ESG302_1_3';
import ESG302_4 from '../../components/ESGTable/302-4/ESG302_4';
import ESG303_1 from '../../components/ESGTable/303-1/ESG303_1';
import ESG305_1 from '../../components/ESGTable/305-1/ESG305_1';
import ESG306_1 from '../../components/ESGTable/306-1/ESG306_1';
import ESG308_1 from '../../components/ESGTable/308-1/ESG308_1';
import ESG401_1 from '../../components/ESGTable/401-1/ESG401_1';
import ESG413_1 from '../../components/ESGTable/413-1/ESG413_1';
import ESG406_1 from '../../components/ESGTable/406-1/ESG406_1';
import ESG405_2 from '../../components/ESGTable/405-2/ESG405_2';
import ESG405_1 from '../../components/ESGTable/405-1/ESG405_1';
import ESG402_1 from '../../components/ESGTable/401-2/ESG402_1';
import ESG401_3 from '../../components/ESGTable/401-3/ESG401_3';
import ESG403_1 from '../../components/ESGTable/403-1/ESG403_1';
import ESG404_1 from '../../components/ESGTable/404-1/ESG404_1';
import { useESG } from '../../action/ESGData/esgdata';
import TemplateChoice from '../../components/ESGTable/TemplateChoice/index.js';
import MultiRenderComponent from '../../components/ESGTable/MultiRendereComponent';
import ESG102_1 from '../../components/ESGTable/102-1';
import ESG102_3 from '../../components/ESGTable/102-3';
import ESG102_6 from '../../components/ESGTable/102-6/ESG102_6';
import ESG102_22 from '../../components/ESGTable/102-22';
import ESG102_10 from '../../components/ESGTable/102-10';
import ESG102_12 from '../../components/ESGTable/102-12';
import ESG102_13 from '../../components/ESGTable/102-13/ESG102_13';
import ESG102_14 from '../../components/ESGTable/102-14';
import ESG102_15 from '../../components/ESGTable/102-15';
import { MappingClEsg, evaluate_report_vs_empty } from '../esgDataMapping.js';
import ESG206_1 from '../../components/ESGTable/206-1/ESG206_1';
import ESG408_1 from '../../components/ESGTable/408-1/ESG408_1';
import ESG102_6a from '../../components/ESGTable/102-6a';
import ESG_Obiettivi from '../../components/ESGTable/obiettivi/ESG_Obiettivi.js';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/ESGTable/OverviewOutput/OverviewOutput.js';
import TemplateCreator from '../../components/ESGTable/TemplateCreator/TemplateCreator.js';

const fetchNodes = json__data => {
  const converter = new JsonToReactConverter(json__data);
  const nodes_2 = converter.convert();
  return nodes_2;
};

const fetchNavi = nodes => {
  var navi = [];
  nodes.forEach(node => {
    navi.push(node.id);
    node.children.forEach(node_ch => {
      navi.push(node_ch.id);
      node_ch.children.forEach(node_ch_min => {
        navi.push(node_ch_min.id);
      });
    });
  });
  return navi;
};

const ESGStd = props => {
  const json__data = MappingClEsg();
  const state = useLocation();
  const [nodes, setNodes] = useState({});
  const [forwardActive, setForwardActive] = useState(true);
  const [backwardActive, setBackwardActive] = useState(true);
  const [navi, setNavi] = useState([]);
  const [actNavi, setActNavi] = useState('ESG_all');
  const [component, setComponent] = useState(<div />);
  const [visibility_list, setVisibilityList] = useState([]);
  const [visibilitypopup, setvisibilitypopup] = useState(false);
  const {
    updateESGData,
    esgData,
    visibleName,
    updateVisibleName,
    create_esg_report,
    pushESGData,
  } = useESG();

  const [isModalHomeOpen, setIsModalHomeOpen] = useState(false);
  const [canModifyName, setCanModifyName] = useState(false);
  const [modalTMStatusOpen, setModalTMStatusOpen] = useState(false);
  const [notCorrectESG, setNotCorrectESG] = useState([]);
  const [canProduceReport, setCanProduceReport] = useState(false);
  const [templateChoiced, setTemplateChoiced] = useState(false);
  const [templateModal, settemplateModal] = useState(false);
  const [savedNewName, setSavedNewName] = useState(false);
  const [savedReport, setSavedReport] = useState(false);
  const handleModifyName = () => {
    if (canModifyName) {
      updateESGData();
      toast.success('Nome Report Aggiornato', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }

    setCanModifyName(!canModifyName);
  };
  const updateVName = e => {
    updateVisibleName(e.target.value);
  };
  const closeModalTMStatus = () => {
    setModalTMStatusOpen(false);
  };
  const okproceedTMStatus = () => {
    create_esg_report()
      .then(val => {
        //saveAs(val.data);
      })
      .catch(error => {});
  };
  const history = useHistory();
  var node4buttons = [];
  const openModalHome = () => {
    setIsModalHomeOpen(true);
  };

  const closeModalHome = () => {
    setIsModalHomeOpen(false);
  };

  const handleNavigation = () => {
    // Qui puoi aggiungere la logica per verificare le modifiche non salvate
    // e decidere se navigare alla home o rimanere sulla pagina corrente.
    history.push('/');
    // Per ora, chiudiamo semplicemente il modal.
    closeModalHome();
  };
  const griData = {
    'GRI102-1': {
      question: 'Ragione sociale',
      answer: 'Materassi Dream',
    },
    'GRI102-3': {
      question: 'Indirizzo Sede Principale',
      answer: 'Via Materassi, 123, Città dei Sogni',
    },
    'GRI102-4': {
      question: "Numero di paesi in cui opera l'organizzazione",
      answer: 5,
    },
    'GRI102-4-b': {
      question: 'Nomi paesi',
      answer: ['Italia', 'Francia', 'Spagna', 'Germania', 'Regno Unito'],
    },
    'GRI102-5': {
      question: 'Processo di Assurance',
      answer: 'Sì, sottoponiamo i nostri materassi a rigorosi test di qualità.',
    },
    'GRI102-6': {
      question:
        "Descrivere l'Attività, prodotti, servizi e la Catena di fornitura dell'organizzazione ove applicabile",
      answer:
        'Siamo un produttore leader di materassi di alta qualità, offrendo una vasta gamma di materassi a molle, in lattice e in memory foam.',
    },
    'GRI102-7': {
      question: 'Dipendenti',
      answer: [
        {
          job_title: 'Direttore Generale',
          number_of_employees: 1,
        },
        {
          job_title: 'Operai di produzione',
          number_of_employees: 50,
        },
        {
          job_title: 'Personale Amministrativo',
          number_of_employees: 10,
        },
      ],
    },
    'GRI102-8': {
      question: 'Lavoratori non dipendenti',
      answer: 'Nessun lavoratore non dipendente.',
    },
    'GRI102-22': {
      question: 'Dichiarazione sulla strategia di sviluppo sostenibile',
      answer:
        "La nostra azienda è impegnata a sviluppare materassi sostenibili e ridurre l'impatto ambientale.",
    },
    'GRI102-29': {
      question: 'Approccio al coinvolgimento degli stakeholder',
      answer: ['Clienti', 'Fornitori', 'Comunità locali'],
    },
    'GRI102-9': {
      question: 'Composizione Governo',
      answer: 'Il nostro Consiglio di Amministrazione è composto da 5 membri.',
    },
    'GRI102-10': {
      question: 'Nomina Governo',
      answer:
        'Il processo di nomina del Consiglio di Amministrazione segue le linee guida statutarie.',
    },
    'GRI102-11': {
      question: 'Presidente Governo',
      answer:
        "Il Presidente del Consiglio di Amministrazione è anche il CEO dell'azienda.",
    },
  };

  useEffect(() => {
    setNodes(fetchNodes(json__data));
    node4buttons = nodes;
    return_node('GRI_all', nodes);
    if (esgData != undefined && esgData['visibility'] != undefined) {
      setVisibilityList(esgData['visibility']);
    }
    if (esgData != undefined && esgData['template'] == undefined) {
      settemplateModal(true);
    }
  }, []);
  useEffect(() => {
    if (nodes && nodes.length > 0) {
      const navigatorData = fetchNavi(nodes); // Replace with your logic
      setNavi(navigatorData);
    }
  }, [nodes]);

  const handleUpdateReport = () => {
    updateESGData()
      .then(value => {
        if (value != 'KO' && value != undefined) {
          toast.success('Salvataggio avvenuto con successo', {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        } else {
          toast.error(
            'Il salvataggio non è stato eseguito, verifica la tua connessione. Se il problema dovesse persistere, contattare il servizio clienti.',
            {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            }
          );
        }
      })
      .catch(error => {
        toast.error(
          'Il salvataggio non è stato eseguito, verifica la tua connessione. Se il problema dovesse persistere, contattare il servizio clienti.',
          {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        );
      });
  };

  const exec_not = text => toast(text);
  const list_excluded = ['102-1'];

  const test_node = id => {};
  useEffect(() => {
    if (esgData != undefined) {
      if (Object.keys(esgData).includes('102-1')) {
        if (
          Object.keys(esgData['102-1']).includes('0') &&
          Object.keys(esgData['102-1']).includes('anni_attivita') &&
          Object.keys(esgData['102-1']).includes('codice_ateco') &&
          Object.keys(esgData['102-1']).includes('forma_giuridica') &&
          Object.keys(esgData['102-1']).includes('logoData') &&
          Object.keys(esgData['102-1']).includes('nazioni_operanti') &&
          Object.keys(esgData['102-1']).includes('num_dipendenti') &&
          Object.keys(esgData['102-1']).includes('ragione_azienda') &&
          Object.keys(esgData['102-1']).includes('storia_azienda')
        ) {
          setCanProduceReport(true);
        } else {
        }
      }
    }
    if ('template' in esgData) {
      setTemplateChoiced(true);
      settemplateModal(false);
    }
  }, [esgData]);

  const renderTree = ({ nodes, expanded, withIcons = false }) => {
    if (!nodes || !Array.isArray(nodes)) {
      return;
    }

    return nodes.map(({ children, renderIcon, isExpanded, ...nodeProps }) => (
      <TreeNode
        key={nodeProps.id}
        renderIcon={withIcons ? renderIcon : null}
        selected={actNavi}
        isExpanded={expanded ?? isExpanded}
        onSelect={() => return_node(nodeProps.id, nodes)}
        {...nodeProps}
        style={
          visibility_list.includes(nodeProps.id)
            ? { textDecoration: 'line-through' }
            : { textDecoration: '' }
        }>
        {renderTree({
          nodes: children,
          expanded,
          withIcons,
        })}
      </TreeNode>
    ));
  };
  const decrypt_data = node => {
    if (node.type == 'text') {
      return <TextInput />;
    } else if (node.type == 'textarea') {
      return <TextArea />;
    } else if (node.type == 'object') {
      if (node.id == '102-1') {
        return <ESG102_1 />;
      } else if (node.id == '102-3') {
        return <ESG102_3 />;
      } else if (node.id == '102-6') {
        return <ESG102_6 />;
      } else if (node.id == '102-6a') {
        return <ESG102_6a />;
      } else if (node.id == '102-8') {
        return <ESG102_8 />;
      } else if (node.id == '102-9') {
        return <ESG102_9 />;
      } else if (node.id == '102-10') {
        return <ESG102_10 />;
      } else if (node.id == '102-11') {
        return <ESG102_11 />;
      } else if (node.id == '102-12') {
        return <ESG102_12 />;
      } else if (node.id == '102-13') {
        return <ESG102_13 />;
      } else if (node.id == '102-14') {
        return <ESG102_14 />;
      } else if (node.id == '102-15') {
        return <ESG102_15 />;
      } else if (node.id == '102-16') {
        return <ESG102_16 />;
      } else if (node.id == '102-17') {
        return <ESG102_17 />;
      } else if (node.id == '102-18') {
        return <ESG102_18 />;
      } else if (node.id == '102-19-20') {
        return <ESG102_19_20 />;
      } else if (node.id == '102-21') {
        return <ESG102_21 />;
      } else if (node.id == '102-22') {
        return <ESG102_22 />;
      } else if (node.id == '102-27') {
        return <ESG102_27 />;
      } else if (node.id == '102-28') {
        return <ESG102_28 />;
      } else if (node.id == '102-29') {
        return <ESG102_29 />;
      } else if (node.id == '201-1') {
        return <ESG201_1 />;
      } else if (node.id == '201-2') {
        return <ESG201_2 />;
      } else if (node.id == '203-1') {
        return <ESG203_1 />;
      } else if (node.id == '203-2') {
        return <ESG203_2 />;
      } else if (node.id == '204-1') {
        return <ESG204_1 />;
      } else if (node.id == '205-2-3') {
        return <ESG205_2_3 />;
      } else if (node.id == '206-1') {
        return <ESG206_1 />;
      } else if (node.id == '301-1') {
        return <ESG301_1 />;
      } else if (node.id == '301-2') {
        return <ESG301_2 />;
      } else if (node.id == '301-3') {
        return <ESG301_3 />;
      } else if (node.id == '302-1-2-3') {
        return <ESG302_1_3 />;
      } else if (node.id == '302-4') {
        return <ESG302_4 />;
      } else if (node.id == '303-1') {
        return <ESG303_1 />;
      } else if (node.id == '305-1') {
        return <ESG305_1 />;
      } else if (node.id == '306-1') {
        return <ESG306_1 />;
      } else if (node.id == '308-1') {
        return <ESG308_1 />;
      } else if (node.id == '401-1') {
        return <ESG401_1 />;
      } else if (node.id == '402-1') {
        return <ESG402_1 />;
      } else if (node.id == '401-3') {
        return <ESG401_3 />;
      } else if (node.id == '403-1') {
        return <ESG403_1 />;
      } else if (node.id == '404-1') {
        return <ESG404_1 />;
      } else if (node.id == '405-1') {
        return <ESG405_1 />;
      } else if (node.id == '405-2') {
        return <ESG405_2 />;
      } else if (node.id == '406-1') {
        return <ESG406_1 />;
      } else if (node.id == '408-1') {
        return <ESG408_1 />;
      } else if (node.id == '413-1') {
        return <ESG413_1 />;
      } else if (node.id == 'obiettivi') {
        return <ESG_Obiettivi />;
      }
    }
  };
  const return_node = (id, nodes) => {
    var node = renderComponent(id, nodes);

    var created = <div />;

    node.forEach(nd => {
      if (nd.type == 'ESG_all') {
        created = <ESG_all />;
      } else if (nd.type == 'ppt_creator') {
        created = <ESGResult griData={griData} />;
      } else if (nd.type == 'object') {
        created = decrypt_data(nd);
      } else if (nd.type == 'templateChoice') {
        created = <TemplateChoice />;
      }
    });
    setComponent(created);

    setActNavi(id);
  };

  const moveNavi = param => {
    var current_item = actNavi;
    var idx_curr_item = navi.indexOf(current_item);

    if (param == 'f') {
      if (idx_curr_item == navi.length - 1) {
        setForwardActive(false);
        setBackwardActive(true);
      } else if (idx_curr_item != navi.length - 1 && idx_curr_item != 0) {
        setForwardActive(true);
        setBackwardActive(true);
        var search_id = navi[idx_curr_item + 1];
        var search_node = [];
        nodes.forEach(nd => {
          if (nd.id == search_id) {
            search_node.push(nd);
          } else {
            if ('children' in nd) {
              var found_id = false;
              nd['children'].forEach(child => {
                if (child.id == search_id) {
                  search_node.push(child);
                  found_id = true;
                }
                if (!found_id) {
                  if ('children' in child) {
                    child['children'].forEach(baby => {
                      if (baby.id == search_id) {
                        search_node.push(baby);
                      }
                    });
                  }
                }
              });
            }
          }
        });
        return_node(navi[idx_curr_item + 1], search_node);
      }
    } else if (param == 'b') {
      if (idx_curr_item == 0) {
        setForwardActive(true);
        setBackwardActive(false);
      } else {
        var search_id = navi[idx_curr_item - 1];
        var search_node = [];
        nodes.forEach(nd => {
          if (nd.id == search_id) {
            search_node.push(nd);
          } else {
            if ('children' in nd) {
              var found_id = false;
              nd['children'].forEach(child => {
                if (child.id == search_id) {
                  search_node.push(child);
                  found_id = true;
                }
                if (!found_id) {
                  if ('children' in child) {
                    child['children'].forEach(baby => {
                      if (baby.id == search_id) {
                        search_node.push(baby);
                      }
                    });
                  }
                }
              });
            }
          }
        });
        return_node(navi[idx_curr_item - 1], search_node);
        //setActNavi(navi[idx_curr_item-1])
        setForwardActive(true);
        setBackwardActive(true);
      }
    }
  };
  /*   const toast_notification=(kind,title,subtitle,closingfunction)=>{
    return
      <ToastNotification role="status" kind={kind}  timeout={3000} title={title} onClose={()=>{closingfunction}} subtitle={subtitle} />
  } */

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    if (templateChoiced) {
      setIsSidebarOpen(!isSidebarOpen);
    } else {
      settemplateModal(true);
    }
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };
  const hideGRI = () => {
    if (!visibility_list.includes(actNavi)) {
      setVisibilityList([...visibility_list, actNavi]);
    } else {
      let newArray = visibility_list.filter(item => item !== actNavi);
      setVisibilityList(newArray);
    }
    setvisibilitypopup(!visibilitypopup);
  };

  const showVisibilityPopUp = () => {
    setvisibilitypopup(!visibilitypopup);
  };
  useEffect(() => {
    pushESGData('visibility', visibility_list);
  }, [visibility_list]);
  return (
    <div className="stack_container_esgstd">
      <ToastContainer />
      <div className="data_header">
        <div className="div_button">
          <TextInput
            width="24%"
            className="text_input_name"
            value={visibleName}
            onChange={updateVName}
            disabled={!canModifyName}
          />

          <Button
            className="text_input_modify"
            kind="ghost"
            onClick={handleModifyName}
            hasIconOnly
            renderIcon={canModifyName ? Checkmark : Edit}
            iconDescription={
              canModifyName ? 'Salva Modifica' : 'Modifica nome report'
            }
            tooltipPosition="bottom"
          />
          <Button
            className="forward"
            disabled={!backwardActive}
            kind="ghost"
            hasIconOnly
            renderIcon={SkipBack}
            iconDescription={'Vai indietro'}
            tooltipPosition="bottom"
            onClick={() => moveNavi('b')}
          />
          <Button
            className="back"
            disabled={!forwardActive}
            kind="ghost"
            onClick={() => moveNavi('f')}
            hasIconOnly
            renderIcon={SkipForward}
            iconDescription={'Vai avanti'}
            tooltipPosition="bottom"
          />
          <Button
            hasIconOnly
            className="save"
            kind="ghost"
            onClick={handleUpdateReport}
            renderIcon={Save}
            iconDescription={'Salva Report'}
            tooltipPosition="bottom"
          />
          <Button
            kind="ghost"
            className="pptcreator"
            onClick={handleToggleSidebar}>
            {templateChoiced ? 'Anteprima Report' : 'Scegli un template'}
          </Button>
          {/*          <Button className="home" kind="ghost" onClick={openModalHome}>
            <Home />
          </Button> */}
        </div>
      </div>
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={handleCloseSidebar}
        setModalValue={settemplateModal}
        visibility={visibility_list}
      />

      <Grid className="grid_container">
        <Column lg={4} md={4} sm={4} className="treeview_viewer">
          <TreeView className="treeviewViz" label="Temi materiali">
            {renderTree({
              nodes,
              withIcons: true,
              setComponent,
            })}
          </TreeView>
        </Column>
        <Column lg={12} md={12} sm={12}>
          <Form>
            {' '}
            <div
              className={
                visibility_list.includes(actNavi) ? 'overlay-container' : ''
              }
            />{' '}
            {component}
          </Form>

          <Button
            size="sm"
            kind={!visibility_list.includes(actNavi) ? 'danger' : 'primary'}
            style={
              actNavi == 'GRI_all' ||
              actNavi == '102-1' ||
              actNavi == '102-6' ||
              actNavi == '102-3' ||
              actNavi == '102-22' ||
              actNavi == '102-29' ||
              actNavi == 'obiettivi'
                ? { visibility: 'hidden' }
                : { float: 'right', zIndex: 4 }
            }
            renderIcon={TaskRemove}
            onClick={() => showVisibilityPopUp()}>
            {!visibility_list.includes(actNavi)
              ? 'Rimuovi Informativa dal report'
              : 'Riattiva Informativa'}
          </Button>
        </Column>
      </Grid>
      {/* <CustomColorExtractor></CustomColorExtractor>
  <PDFCreator></PDFCreator>*/}
      <Modal
        open={isModalHomeOpen}
        onRequestClose={closeModalHome}
        modalHeading="Attenzione"
        modalLabel="Potrebbero esserci delle modifiche non salvate."
        shouldSubmitOnEnter={false}
        primaryButtonText="Vai alla home"
        secondaryButtonText="Annulla"
        onRequestSubmit={handleNavigation}>
        <p>Sei sicuro di voler procedere?</p>
      </Modal>
      <Modal
        open={visibilitypopup}
        modalLabel={
          !visibility_list.includes(actNavi)
            ? 'Disattivazione Componente'
            : 'Riattivazione Componente'
        }
        modalHeading={
          !visibility_list.includes(actNavi)
            ? "Se scegli di non compilare un'informativa, essa sarà rimossa dal report."
            : "Stai per riattivare l'informativa. Verra inclusa la relativa pagina nel report."
        }
        shouldSubmitOnEnter={false}
        onRequestClose={() => setvisibilitypopup()}
        danger={!visibility_list.includes(actNavi) ? true : false}
        primaryButtonText={
          !visibility_list.includes(actNavi) ? 'Disattiva' : 'Riattiva'
        }
        secondaryButtonText="Annulla"
        onRequestSubmit={hideGRI}>
        <p>Sei sicuro di voler procedere?</p>
        <p style={{ fontSize: 12 }}>
          {!visibility_list.includes(actNavi)
            ? "Per riattivarla utilizzare il pulsante 'Riattiva Informativa'"
            : "Per rimuovere l'informativa dal report utilizzare il pulsante 'Rimuovi Informativa dal Report'"}{' '}
        </p>
      </Modal>
      <Modal
        open={templateModal}
        modalHeading="Scelta Template"
        passiveModal
        preventCloseOnClickOutside={true}
        shouldSubmitOnEnter={false}
        size="lg"
        primaryButtonText="Vai alla home"
        onRequestSubmit={handleNavigation}>
        <TemplateChoice />
      </Modal>
    </div>
  );
};
export default ESGStd;
