import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Button, Column, Grid, Modal } from '@carbon/react';
import Select from 'react-select';
import { useESG } from '../../action/ESGData/esgdata';
import { useHistory } from 'react-router-dom';
import { esgData } from '../../content/esgDataMapping';
import LoadingOverlay from '../Loader/Loader';
import { auth } from '../../action/fiirebase_config/firebase_config';
import videoIntro from '../../assets/video-intro.mp4';
import {
  ArrowRight,
  Book,
  Help,
  Tablet,
  TabletLandscape,
} from '@carbon/react/icons';
import { useHTML } from '../../action/HTMLContext/HTMLContext';

import { InfoSection } from '../Info';
import IconChoice from '../IconChoice';

import axios from 'axios';
export const OutputVisualizer = ({ showModal, setShowModal, reportRef }) => {
  const { state } = useHTML();
  const [htmlInput, setHtmlInput] = useState();
  var payload = {};
  const visualizeOutput = async () => {
    if (state != undefined) {
      Object.keys(state).forEach(async element => {
        if (Object.keys(state[element]).includes('169')) {
          const pageA4 = await fetch(state[element]['A4']);
          const htmlStringA4 = await pageA4.text();
          const page169 = await fetch(state[element]['169']);
          const htmlString169 = await page169.text();
          payload[element] = { A4: htmlStringA4, '169': htmlString169 };
        }
      });
    }
  };

  function getPDF() {
    const jsonString = JSON.stringify(payload);

    const payloadSizeInBytes = new Blob([jsonString]).size;

    return axios.post(`http://127.0.0.1:8080/create-pdf`, payload, {
      responseType: 'blob',
      headers: {
        Accept: 'application/zip',
      },
    });
  }
  const savePDF = async () => {
    const response = await getPDF();

    const blob = new Blob([response.data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `your-file-name.zip`;
    link.click().catch(err => console.log(err));
  };
  useEffect(() => {
    visualizeOutput();
  }, []);

  return (
    <>
      <div className="landing-page__r3">
        <Grid>
          <Column lg={10} md={10} sm={10} className="landing-page__r3">
            <h1>Visualizzazione Output</h1>
            <p>
              Complimenti, sei arrivato alla fine. <br />
              Utilizza i pulsanti in basso per scaricare il report in formato A4
              o 16:9 (ovviamente prima rivedilo){' '}
            </p>
          </Column>
          <Column lg={6} md={6} sm={6} className="landing-page__r3">
            <img
              width="40%"
              src={`${process.env.PUBLIC_URL}/winner.svg`}
              alt="Pratiche Aperte"
            />
          </Column>
        </Grid>
        <InfoSection heading="Opzioni:">
          {/*<IconChoice
            text_icon="Nuovo Report"
            icon={() => <EarthSoutheastAsiaFilled size={64} />}
            onClick={navigationHandlerNew}
  />
          <IconChoice
            text_icon="Pratiche in corso"
            icon={() => <FolderShared size={64} />}
            onClick={navigationHandlerOnWorking}
          />
       */}
          <IconChoice
            text_icon="Download report"
            icon={() => <Book size={30} />}
            onClick={() => savePDF()}
          />
        </InfoSection>
      </div>
      <div id="visualizer" />
    </>
  );
};
