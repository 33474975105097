import React, { useState, useEffect, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Stack, Tooltip, Button, TextArea } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
const ESG102_17 = () => {
  const gridApiRef = useRef(null);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [esg102_17, setEsg102_17] = useState();
  const [rowData, setRowData] = useState([]);
  useEffect(() => {
    if (esgData['102-17'] != undefined) {
      setEsg102_17(esgData['102-17']);
      if (esgData['102-17']['misure_aggiornamentoesg'] != undefined)
        setRowData(esgData['102-17']['misure_aggiornamentoesg']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-17', esg102_17);
  }, [esg102_17]);

  const handleAddRow = () => {
    if (rowData.length < 5) {
      const newRow = {
        name: '',
        desc: '',
      };
      setRowData([...rowData, newRow]);
      setEsg102_17({
        ...esg102_17,
        ['misure_aggiornamentoesg']: [...rowData, newRow],
      });
    }
  };
  const columnDefs = [
    {
      headerName: 'Nome Procedura di Aggiornamento',
      field: 'name',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      headerTooltip:
        'Rendicontare eventuali procedure di aggiornamento (formazione periodica/incontri con specialisti/partecipazione a fiere...',
    },
    {
      headerName: 'Descrizione Procedura',
      field: 'desc',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      headerTooltip:
        'Rendicontare le attività e gli scopi delle procedure di aggiornamento',
    },
  ];
  const onChangeRowValue = e => {
    var ex_rowData = [...rowData];
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg102_17({ ...esg102_17, ['misure_aggiornamentoesg']: ex_rowData });
  };

  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg102_17({
        ...esg102_17,
        ['misure_aggiornamentoesg']: updatedRowData,
      });
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const handleChanges = e => {
    setEsg102_17({ ...esg102_17, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Conoscenze collettive della Dirigenza</h1>
      <p>
        Rendicontare le misure adottate per portare avanti le conoscenze
        collettive, le capacità e l’esperienza della Dirigenza riguardo allo
        sviluppo sostenibile.
      </p>
      <TextArea
        maxCount={500}
        enableCounter={true}
        id="descrizione_misure"
        value={
          esg102_17 != undefined && esg102_17['descrizione_misure'] != undefined
            ? esg102_17['descrizione_misure']
            : ''
        }
        labelText="Descrivere le misure di aggiornamento ESG"
        placeholder="Descrivere con testo le misure adottate per l'aggiornamento delle conoscenze in ambito ESG"
        onChange={handleChanges}
      />
      <label>
        Completare la tabella inserendo le informazioni richieste
        <br />
      </label>
      <div
        className="ag-theme-alpine"
        style={{ height: '200px', width: '800px' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          onCellValueChanged={onChangeRowValue}
          rowSelection={'multiple'}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
        />

        <Button onClick={handleAddRow} kind="ghost">
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga. E' possibile inserire  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 102-17</footer>
    </Stack>
  );
};

export default ESG102_17;
