import React, { useState, useEffect } from 'react';
import { RadioButtonGroup, Stack, TextArea } from '@carbon/react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useESG } from '../../../action/ESGData/esgdata';

function ESG403_1() {
  const years = [
    new Date().getFullYear() - 3,
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 1,
  ];
  const [esg403_1, setEsg403_1] = useState();
  const [tableData, setTableData] = useState([
    {
      description: 'Ore Lavorate',
      actualyear_2: 0,
      actualyear_1: 0,
      actualyear: 0,
    },
    {
      description: 'N° infortuni sul lavoro registrabili',
      actualyear_2: 0,
      actualyear_1: 0,
      actualyear: 0,
    },
    {
      description:
        'N° di infortuni con gravi conseguenze (ad esclusione dei decessi)',
      actualyear_2: 0,
      actualyear_1: 0,
      actualyear: 0,
    },
    {
      description: 'N° di decessi a seguito di infortuni sul lavoro',
      actualyear_2: 0,
      actualyear_1: 0,
      actualyear: 0,
    },
    {
      description: 'Numero di decessi derivanti da malattie professionali',
      actualyear_2: 0,
      actualyear_1: 0,
      actualyear: 0,
    },
    {
      description: 'Numero di casi di malattie professionali registrabili',
      actualyear_2: 0,
      actualyear_1: 0,
      actualyear: 0,
    },
  ]);
  const { esgData, pushESGData, refreshESGData } = useESG();

  const columnDefs = [
    {
      headerName: 'Descrizione',
      field: 'description',
      cellEditor: 'agLargeTextEditor',
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: years[0],
      field: 'actualyear_2',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: years[1],
      field: 'actualyear_1',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: years[2],
      field: 'actualyear',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];

  const onCellValueChanged = params => {
    const ex_rowData = tableData;
    ex_rowData[params.rowIndex] = params.data;
    setTableData(ex_rowData);
    setEsg403_1({ ...esg403_1, tabella_salute: ex_rowData });
  };
  useEffect(() => {
    if (esgData['403-1'] != undefined) {
      setEsg403_1(esgData['403-1']);
      if (esgData['403-1']['tabella_salute']) {
        setTableData(esgData['403-1']['tabella_salute']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('403-1', esg403_1);
  }, [esg403_1]);
  const handleChanges = e => {
    setEsg403_1({ ...esg403_1, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Salute e Sicurezza sul Lavoro</h1>
      <h2 />
      <p>
        Inserire le informazioni nella tabella sotto riportata, dichiarando come
        la società implementa un sistema di gestione per la salute e la
        sicurezza sul lavoro.
      </p>
      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="dichiarazione_salute"
        value={
          esgData['403-1'] != undefined &&
          esgData['403-1']['dichiarazione_salute'] != undefined
            ? esgData['403-1']['dichiarazione_salute']
            : ''
        }
        placeholder="Dichiarare come la società implementa un sistema di gestione per la salute e la sicurezza sul lavoro"
        onChange={handleChanges}
        labelText="Dichiarazione sistema Gestione Salute"
      />
      <div
        className="ag-theme-alpine"
        style={{
          width: '100%',
        }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={tableData}
          domLayout="autoHeight"
          onCellValueChanged={onCellValueChanged}
        />
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 403-1</footer>
    </Stack>
  );
}

export default ESG403_1;
