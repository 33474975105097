import ConsumoIdrico from '../../../../assets/html_template/professional/A4/11-ambiente-gri303-1-ConsumoIdrico.html';
import ConsumoIdrico169 from '../../../../assets/html_template/professional/169/11-ambiente-gri303-1-ConsumoIdrico.html';
import ConsumoIdricoPopA4 from '../../../../assets/html_template/pop/A4/11-ambiente-gri303-1-ConsumoIdrico.html';
import ConsumoIdricoPop169 from '../../../../assets/html_template/pop/169/11-ambiente-gri303-1-ConsumoIdrico.html';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
import { cloneDeep, sortBy } from 'lodash';

const ConsumoIdricoEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? ConsumoIdrico : ConsumoIdrico169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString = type == 'A4' ? ConsumoIdricoPopA4 : ConsumoIdrico169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? ConsumoIdrico : ConsumoIdrico169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [descrizioneConsumoIdrico, setDescrizioneConsumoIdrico] = useState('');
  const [consumoIdricoTable, setConsumoIdricoTable] = useState([]);

  const [consumoIdricoData, SetConsumoIdricoData] = useState(ConsumoIdrico);
  const [consumoIdricoDataA4, setconsumoIdricoDataA4] = useState(ConsumoIdrico);
  const [consumoIdricoData169, setconsumoIdricoData169] = useState(
    ConsumoIdrico169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetConsumoIdricoData(ConsumoIdricoPopA4);
    setconsumoIdricoDataA4(ConsumoIdricoPopA4);
    setconsumoIdricoData169(ConsumoIdricoPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetConsumoIdricoData(ConsumoIdrico);
    setconsumoIdricoDataA4(ConsumoIdrico);
    setconsumoIdricoData169(ConsumoIdrico169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetConsumoIdricoData(ConsumoIdrico);
    setconsumoIdricoDataA4(ConsumoIdrico);
    setconsumoIdricoData169(ConsumoIdrico169);
  }
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();
  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetConsumoIdricoData(ConsumoIdricoPopA4);
      setconsumoIdricoDataA4(ConsumoIdricoPopA4);
      setconsumoIdricoData169(ConsumoIdricoPop169);
      force_update(type == 'A4' ? ConsumoIdricoPopA4 : ConsumoIdricoPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetConsumoIdricoData(ConsumoIdrico);
      setconsumoIdricoDataA4(ConsumoIdrico);
      setconsumoIdricoData169(ConsumoIdrico169);
      force_update(type == 'A4' ? ConsumoIdrico : ConsumoIdrico169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetConsumoIdricoData(ConsumoIdrico);
      setconsumoIdricoDataA4(ConsumoIdrico);
      setconsumoIdricoData169(ConsumoIdrico169);
      force_update(type == 'A4' ? ConsumoIdrico : ConsumoIdrico169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('303-1')
    ) {
      setIsVisible(false);
      delPageData('303-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(consumoIdricoData);
    } else {
      if (
        esgData['303-1'] != undefined &&
        esgData['303-1']['descrizione_risorse_idriche'] != undefined &&
        esgData['303-1']['descrizione_risorse_idriche'] !=
          descrizioneConsumoIdrico
      ) {
        hasUpdate = true;
      }
      if (
        esgData['303-1'] != undefined &&
        esgData['303-1']['consumo_idrico_tabella'] != undefined &&
        !compareLists(
          esgData['303-1']['consumo_idrico_tabella'],
          consumoIdricoTable
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = ['Anno', 'Consumo', 'Scarico', 'Unità'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = consumoIdricoData169;
        to_init = '169';
      } else {
        templateHTMLString = consumoIdricoDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['303-1'] != undefined &&
        esgData['303-1']['descrizione_risorse_idriche'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'descrizione_impatti_idrici'
        );
        fotoLeaderElement.innerHTML =
          esgData['303-1']['descrizione_risorse_idriche'];
        setDescrizioneConsumoIdrico(
          esgData['303-1']['descrizione_risorse_idriche']
        );
      }
      //&&!compareLists(tabellacriticita,esgData["303-1"]["rendicontazione_criticita"])

      if (
        esgData['303-1'] != undefined &&
        esgData['303-1']['consumo_idrico_tabella'] != undefined
      ) {
        const table = parsedHTML.getElementById('consumo_idrico_tabella');
        const grafico = parsedHTML.getElementById('creaGrafico');
        if (esgData['303-1']['consumo_idrico_tabella'].length > 0) {
          const renamed_data = esgData['303-1']['consumo_idrico_tabella'].map(
            elemento => ({
              Unità: elemento.unit,
              Consumo: elemento.consumption,
              Scarico: elemento.discharge,
              Anno: elemento.year,
            })
          );
          popolaTabella(table, renamed_data, ordered_keys);
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
          //TOSTART
          var data = ${JSON.stringify(
            esgData['303-1']['consumo_idrico_tabella'],
            null,
            2
          )};
          //TOEND;

        ` + grafico.textContent;
        }
        setConsumoIdricoTable(
          cloneDeep(esgData['303-1']['consumo_idrico_tabella'])
        );
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('303-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Consumo idrico',
          initA4: ConsumoIdricoPopA4,
          init169: ConsumoIdricoPop169,
          keys_to_check: [
            'consumo_idrico_tabella',
            'descrizione_impatti_idrici',
          ],
          gri: '303-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('303-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Consumo idrico',
          initA4: ConsumoIdrico,
          init169: ConsumoIdrico169,
          keys_to_check: [
            'consumo_idrico_tabella',
            'descrizione_impatti_idrici',
          ],
          gri: '303-1',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      const ordered_keys = ['Anno', 'Consumo', 'Scarico', 'Unità'];
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? consumoIdricoData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['303-1'] != undefined &&
          esgData['303-1']['descrizione_risorse_idriche'] != undefined &&
          esgData['303-1']['descrizione_risorse_idriche'] !=
            descrizioneConsumoIdrico
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_impatti_idrici'
          );
          fotoLeaderElement.innerHTML =
            esgData['303-1']['descrizione_risorse_idriche'];
          setDescrizioneConsumoIdrico(
            esgData['303-1']['descrizione_risorse_idriche']
          );
        }
        //&&!compareLists(tabellacriticita,esgData["303-1"]["rendicontazione_criticita"])

        if (
          esgData['303-1'] != undefined &&
          esgData['303-1']['consumo_idrico_tabella'] != undefined &&
          !compareLists(
            esgData['303-1']['consumo_idrico_tabella'],
            consumoIdricoTable
          )
        ) {
          const table = parsedHTML.getElementById('consumo_idrico_tabella');
          const grafico = parsedHTML.getElementById('creaGrafico');
          if (esgData['303-1']['consumo_idrico_tabella'].length > 0) {
            const renamed_data = esgData['303-1']['consumo_idrico_tabella'].map(
              elemento => ({
                Unità: elemento.unit,
                Consumo: elemento.consumption,
                Scarico: elemento.discharge,
                Anno: elemento.year,
              })
            );
            popolaTabella(table, renamed_data, ordered_keys);
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
                //TOSTART
                var data = ${JSON.stringify(
                  esgData['303-1']['consumo_idrico_tabella'],
                  null,
                  2
                )};
                //TOEND;

              ` + grafico.textContent;
          }
          setConsumoIdricoTable(
            cloneDeep(esgData['303-1']['consumo_idrico_tabella'])
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['303-1'] != undefined &&
          esgData['303-1']['descrizione_risorse_idriche'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_impatti_idrici'
          );
          fotoLeaderElement.innerHTML =
            esgData['303-1']['descrizione_risorse_idriche'];
          setDescrizioneConsumoIdrico(
            esgData['303-1']['descrizione_risorse_idriche']
          );
        }
        //&&!compareLists(tabellacriticita,esgData["303-1"]["rendicontazione_criticita"])

        if (
          esgData['303-1'] != undefined &&
          esgData['303-1']['consumo_idrico_tabella'] != undefined
        ) {
          const table = parsedHTML.getElementById('consumo_idrico_tabella');
          const grafico = parsedHTML.getElementById('creaGrafico');
          if (esgData['303-1']['consumo_idrico_tabella'].length > 0) {
            const renamed_data = esgData['303-1']['consumo_idrico_tabella'].map(
              elemento => ({
                Unità: elemento.unit,
                Consumo: elemento.consumption,
                Scarico: elemento.discharge,
                Anno: elemento.year,
              })
            );
            popolaTabella(table, renamed_data, ordered_keys);
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
              //TOSTART
              var data = ${JSON.stringify(
                esgData['303-1']['consumo_idrico_tabella'],
                null,
                2
              )};
              //TOEND;

            ` + grafico.textContent;
          }
          setConsumoIdricoTable(
            cloneDeep(esgData['303-1']['consumo_idrico_tabella'])
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetConsumoIdricoData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ConsumoIdricoEngine;
