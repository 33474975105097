import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Stack, Button, Tooltip, TextArea } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ESG305_1 = () => {
  const gridApiRef = useRef(null);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [rowData, setRowData] = useState([]);

  const [esg305_1, setEsg305_1] = useState();

  const columnDefs = [
    {
      headerName: 'Anno',
      field: 'anno',
      headerTooltip: "Inserisci l'anno di riferimento dei dati",
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Scope1 (tCO2eq)',
      headerTooltip:
        'Inserisci le emissioni di Scope 1 (dirette) in tonnellate di CO2 equivalenti',
      field: 'scope1',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Scope2 (tCO2eq)',
      field: 'scope2',
      headerTooltip:
        'Inserisci le emissioni di Scope 2 (indirette) in tonnellate di CO2 equivalenti',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Scope3 (tCO2eq)',
      field: 'scope3',
      headerTooltip:
        'Inserisci le emissioni di Scope 3 (altre indirette) in tonnellate di CO2 equivalenti',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Totale',
      field: 'total',
      headerTooltip:
        'Il totale delle emissioni (Scope 1 + Scope 2 + Scope 3) calcolato automaticamente',
      cellEditor: 'agTextEditor',
      editable: false,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      valueGetter: params => {
        const scope1 = params.data.scope1 || 0;
        const scope2 = params.data.scope2 || 0;
        const scope3 = params.data.scope3 || 0;
        return scope1 + scope2 + scope3;
      },
      flex: 1,
    },
  ];

  const addRow = () => {
    if (rowData.length < 5) {
      const newRow = {
        anno: 2023,
        scope1: 0,
        scope2: 0,
        scope3: 0,
        total: 0,
      };

      setRowData([...rowData, newRow]);
      setEsg305_1({ ...esg305_1, ['emissioni_tabella']: [...rowData, newRow] });
    }
  };
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg305_1({ ...esg305_1, ['emissioni_tabella']: ex_rowData });
  };
  useEffect(() => {
    if (esgData['305-1'] != undefined) {
      setEsg305_1(esgData['305-1']);
      if (esgData['305-1']['emissioni_tabella'] != undefined) {
        setRowData(esgData['305-1']['emissioni_tabella']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('305-1', esg305_1);
  }, [esg305_1]);

  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg305_1({ ...esg305_1, ['emissioni_tabella']: updatedRowData });
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const handleChanges = e => {
    setEsg305_1({ ...esg305_1, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Emissioni</h1>

      <p>
        Riportare sia le emissioni dirette che le emissioni indirette da energia
        importata
      </p>
      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="descrizione_emissioni"
        value={
          esg305_1 && esg305_1['descrizione_emissioni'] != undefined
            ? esg305_1['descrizione_emissioni']
            : ''
        }
        labelText="Descrivere le principali emissioni legate alla catena e le misura intraprese per la loro riduzione"
        onChange={handleChanges}
      />
      <p>Completare la tabella inserendo le informazioni richieste.</p>
      <div
        className="ag-theme-alpine"
        style={{
          width: '100%',
        }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValue}
        />

        <Button onClick={addRow} iconDescription="Aggiungi riga" kind="ghost">
          Aggiungi riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga.  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      {/*<Editor onChange={handleTextNewChanges} editorStyle={{ border: "1px solid #C0C0C0" }}  placeholder='Rendicontare ulteriori informazioni sulla gestione delle emissioni, laddove possibile.'/>
       */}
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 305-1 </footer>
    </Stack>
  );
};

export default ESG305_1;
