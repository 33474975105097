import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Add, Subtract } from '@carbon/icons-react';
import {
  Column,
  Modal,
  Button,
  TextInput,
  Stack,
  RadioButtonGroup,
  RadioButton,
  FormGroup,
  Dropdown,
  FilterableMultiSelect,
  Grid,
  TextArea,
  DatePicker,
} from '@carbon/react';
import IndirizzoComponent from '../Indirizzo/IndirizzoComponent';
import { useESG } from '../../../action/ESGData/esgdata';

function ESG102_3(props) {
  const [esg102_3, setEsg102_3] = useState({});
  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-3'] != undefined) {
      setEsg102_3(esgData['102-3']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-3', esg102_3);
  }, [esg102_3]);
  const handleChanges = e => {
    setEsg102_3({ ...esg102_3, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Periodo di rendicontazione, frequenza e punto di contatto</h1>

      <p>
        Completare i campi in questa sezione inserendo le informazioni richieste
      </p>

      <Grid>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="periodo_rendicontazione"
            value={
              esg102_3['periodo_rendicontazione'] != undefined
                ? esg102_3['periodo_rendicontazione']
                : ''
            }
            type="number"
            disabled={esgData.report_status != 'working' ?? true}
            placeholder="Indicare l'anno al quale si riferisce il periodo di rendicontazione di sostenibilità"
            onChange={handleChanges}
            labelText="Periodo Rendicontazione"
          />

          <TextInput
            id="frequenza_fiscale"
            value={
              esg102_3['frequenza_fiscale'] != undefined
                ? esg102_3['frequenza_fiscale']
                : ''
            }
            disabled={esgData.report_status != 'working' ?? true}
            placeholder="Indicare la frequenza del rendiconto finanziario"
            onChange={handleChanges}
            labelText="Frequenza Rendicontazione Finanziaria (N. mesi)"
          />
        </Column>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="frequenza_rendicontazione"
            value={
              esg102_3['frequenza_rendicontazione'] != undefined
                ? esg102_3['frequenza_rendicontazione']
                : ''
            }
            disabled={esgData.report_status != 'working' ?? true}
            onChange={handleChanges}
            placeholder="Indicare la frequenza di rendicontazione di sostenibilità"
            type="number"
            labelText="Frequenza Rendicontazione (N. mesi)"
          />

          <TextInput
            type="email"
            id="contatto_email"
            value={
              esg102_3['contatto_email'] != undefined
                ? esg102_3['contatto_email']
                : ''
            }
            placeholder="Indicare l'email del referente della documentazione"
            onChange={handleChanges}
            labelText="Contatto per la rendicontazione"
          />
        </Column>
      </Grid>

      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="scopo_documento"
        value={
          esg102_3['scopo_documento'] != undefined
            ? esg102_3['scopo_documento']
            : ''
        }
        onChange={handleChanges}
        labelText="Scopo del documento"
        placeholder="Descrivi in modo chiaro e dettagliato l'obiettivo di questo  report di sostenibilità. Fornisci informazioni sullo scopo principale, gli argomenti trattati e gli obiettivi che si intendono raggiungere."
      />
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 102-3</footer>
    </Stack>
  );
}

export default ESG102_3;
