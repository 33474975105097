import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  Stack,
  Button,
  Tooltip,
  TextArea,
  Grid,
  Column,
  TextInput,
} from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
import UploadImage from '../../UploadImage';

const ESG413_1 = () => {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [esg403_1, setEsg403_1] = useState();
  const [forceModified, setForceModified] = useState(false);
  const columnDefs = [
    {
      headerName: 'Operazioni con il coinvolgimento della comunità locale',
      field: 'operation',
      headerTooltip:
        'Descrivi le operazioni o le attività che coinvolgono la comunità locale (es. progetti di sviluppo, iniziative sociali, collaborazioni con organizzazioni locali, ecc.)',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Portata (se misurabile)',
      headerTooltip:
        "Se possibile, descrivi la portata dell'operazione che coinvolge la comunità locale (es. numero di persone coinvolte, area geografica interessata, ecc.)",
      field: 'scope',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Impatto sulla comunità',
      headerTooltip:
        "Descrivi l'impatto delle operazioni svolte sulla comunità locale (es. benefici sociali, miglioramenti della qualità della vita, sviluppo economico locale, ecc.)",
      field: 'impact',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];
  useEffect(() => {
    if (esgData['413-1'] != undefined) {
      setEsg403_1(esgData['413-1']);
      if (esgData['413-1']['table_coinvolgimento'] != undefined) {
        setRowData(esgData['413-1']['table_coinvolgimento']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('413-1', esg403_1);
  }, [esg403_1]);

  const defaultRowData = {
    operation: '',
    scope: '',
    impact: '',
  };

  const onAddRow = () => {
    if (rowData.length < 5) {
      setRowData([...rowData, defaultRowData]);
      setEsg403_1({
        ...esg403_1,
        table_coinvolgimento: [...rowData, defaultRowData],
      });
    }
  };

  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg403_1({ ...esg403_1, table_coinvolgimento: updatedRowData });
    }
  };

  const gridOptions = {
    onRowClicked: event => {
      if (event.event.target.className.includes('delete-row')) {
        onDeleteRow();
      }
    },
  };
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg403_1({ ...esg403_1, table_coinvolgimento: ex_rowData });
    //setForceModified(!forceModified);
  };
  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const handleChanges = e => {
    setEsg403_1({ ...esg403_1, [e.target.id]: e.target.value });
  };

  const setImgData = data => {
    setEsg403_1({ ...esg403_1, ['foto_iniziative']: data });
  };
  const setImgFile = data => {
    setEsg403_1({ ...esg403_1, ['file_fotoIniziative']: data });
  };

  return (
    <Stack gap={8}>
      <h1>Coinvolgimento della Comunità Locale</h1>

      <p>
        Un elemento chiave nella gestione degli impatti sulle persone facenti
        parte di comunità locali consiste nella valutazione e pianificazione
        condotte per comprendere gli impatti effettivi e potenziali oltre che in
        un forte coinvolgimento con le comunità stesse per capirne le
        aspettative e le esigenze. Vi sono molti elementi che possono essere
        incorporati nel coinvolgimento con le comunità locali, nelle valutazioni
        degli impatti e nei programmi di sviluppo. Questa informativa cerca di
        identificare quali elementi sono stati applicati in modo uniforme a
        livello dell’intera organizzazione.
      </p>
      <Grid>
        <Column sm={6} md={6} lg={6}>
          <TextArea
            maxCount={1000}
            enableCounter={true}
            id="attori_sviluppo"
            value={
              esgData['413-1'] != undefined &&
              esgData['413-1']['attori_sviluppo'] != undefined
                ? esgData['413-1']['attori_sviluppo']
                : ''
            }
            placeholder="Raccontare l'importanza nel coinvolgere i diversi attori della comunità locale e come l'azienda raccoglie i bisogni e le esigenze per avviare delle attività e/o programmi di sviluppo"
            onChange={handleChanges}
            labelText="Coinvolgimento Attori Locali"
          />
        </Column>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="didascalia_interventi"
            value={
              esgData['413-1'] != undefined &&
              esgData['413-1']['didascalia_interventi'] != undefined
                ? esgData['413-1']['didascalia_interventi']
                : ''
            }
            placeholder="Inaugurazione Parco per Bambini, 12/02/2022"
            onChange={handleChanges}
            labelText="Inserisci una didascalia per l'immagine"
          />

          <UploadImage
            setImgData={setImgData}
            setImgFile={setImgFile}
            reportId={esgData.id}
            selectedItem={
              esgData['413-1'] != undefined &&
              esgData['413-1']['foto_iniziative'] != undefined
                ? esgData['413-1']['foto_iniziative']
                : {}
            }
            filename={'iniziative'}
            tipo={
              'rappresentante gli interventi a supporto della comunità locale '
            }
          />
        </Column>
      </Grid>

      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValue}
        />
        <Button kind="ghost" onClick={onAddRow}>
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga. E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>

      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 413/ ESRS S3</footer>
    </Stack>
  );
};

export default ESG413_1;
