import React, { useState } from 'react';
import {
  Grid,
  Column,
  TextInput,
  Select,
  TextArea,
  Button,
  Modal,
  Pagination,
} from '@carbon/react';

import { ArrowRight } from '@carbon/react/icons';
const GRI102Summary = ({ data, onToggleInclude }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [answer, setAnswer] = useState(data.answer);

  const renderAnswer = () => {
    if (typeof answer === 'string') {
      return answer;
    } else if (Array.isArray(answer)) {
      return answer.join(', ');
    } else if (answer && typeof answer === 'object') {
      return (
        <ul>
          {Object.keys(answer).map(jobTitle => (
            <li key={jobTitle}>
              {jobTitle}: {answer[jobTitle]}
            </li>
          ))}
        </ul>
      );
    }
    return '';
  };

  return (
    <div className="gri-102-summary">
      <input
        type="checkbox"
        checked={data.includeInPPT}
        onChange={() => onToggleInclude(data.id)}
      />
      <div className="gri-102-question">
        {data.question}
        <ArrowRight />
      </div>
      {isEditable ? (
        <TextInput value={answer} onChange={e => setAnswer(e.target.value)} />
      ) : (
        <div className="gri-102-answer">{renderAnswer()}</div>
      )}
      <Button onClick={() => setIsEditable(!isEditable)}>Modifica</Button>
    </div>
  );
};

// Il resto del codice rimane invariato

const ESGResult = ({ griData }) => {
  const [includedInPPT, setIncludedInPPT] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 7;

  const handleToggleInclude = id => {
    setIncludedInPPT(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const generateReport = () => {
    setIsPopupOpen(false);
  };

  const paginatedItems = Object.keys(griData).map(key => {
    var item = griData[key];
    item.id = key;
    return (
      <GRI102Summary
        key={item.id}
        data={{
          ...item,
          includeInPPT: includedInPPT[item.id] || false,
        }}
        onToggleInclude={handleToggleInclude}
      />
    );
  });

  const totalPages = Math.ceil(paginatedItems.length / itemsPerPage);

  const itemsToDisplay =
    paginatedItems.length > 0
      ? paginatedItems.slice(
          currentPage * itemsPerPage,
          (currentPage + 1) * itemsPerPage
        )
      : [];
  return (
    <Grid fullWidth className="ESGTableConsumi">
      <Column sm={16} md={16} lg={16}>
        {itemsToDisplay}
        <Pagination
          page={currentPage + 1}
          pageSize={itemsPerPage}
          totalItems={paginatedItems.length}
          pageSizes={[7]}
          total={totalPages}
          onChange={({ page }) => setCurrentPage(page - 1)}
        />
      </Column>
      <Button onClick={handleOpenPopup}>Genera Report</Button>
      <Modal open={isPopupOpen} onRequestClose={handleClosePopup}>
        <p>
          Sei ad un passo dalla creazione del tuo report. Sei sicuro di voler
          rivedere i dati inseriti per generare il report?
        </p>
        <Button onClick={handleClosePopup}>Annulla</Button>
        <Button onClick={generateReport}>Continua</Button>
      </Modal>
    </Grid>
  );
};

export default ESGResult;
