import EconomicData from '../../../../assets/html_template/professional/A4/11-ambiente-gri201-1-ValutazioneEconomica.html';
import EconomicData169 from '../../../../assets/html_template/professional/169/11-ambiente-gri201-1-ValutazioneEconomica.html';
import EconomicDataPopA4 from '../../../../assets/html_template/pop/A4/11-ambiente-gri201-1-ValutazioneEconomica.html';
import EconomicDataPop169 from '../../../../assets/html_template/pop/169/11-ambiente-gri201-1-ValutazioneEconomica.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
import { cloneDeep } from 'lodash';

const ValutazioneEconomicaEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? EconomicData : EconomicData169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? EconomicDataPopA4 : EconomicDataPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? EconomicData : EconomicData169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [dichiarazioneEconomica, setDichiarazioneEconomca] = useState('');
  const [economicaTable, setEconomicTable] = useState([]);
  const [indicatorTable, setIndicatorTable] = useState([]);
  const [economicValueData, SetEconomicValueData] = useState(EconomicData);
  const [economicValueDataA4, seteconomicValueDataA4] = useState(EconomicData);
  const [economicValueData169, seteconomicValueData169] = useState(
    EconomicData169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetEconomicValueData(EconomicDataPopA4);
    seteconomicValueDataA4(EconomicDataPopA4);
    seteconomicValueData169(EconomicDataPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetEconomicValueData(EconomicData);
    seteconomicValueDataA4(EconomicData);
    seteconomicValueData169(EconomicData169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetEconomicValueData(EconomicData);
    seteconomicValueDataA4(EconomicData);
    seteconomicValueData169(EconomicData169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;

    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetEconomicValueData(EconomicDataPopA4);
      seteconomicValueDataA4(EconomicDataPopA4);
      seteconomicValueData169(EconomicDataPop169);
      force_update(type == 'A4' ? EconomicDataPopA4 : EconomicDataPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetEconomicValueData(EconomicData);
      seteconomicValueDataA4(EconomicData);
      seteconomicValueData169(EconomicData169);
      force_update(type == 'A4' ? EconomicData : EconomicData169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetEconomicValueData(EconomicData);
      seteconomicValueDataA4(EconomicData);
      seteconomicValueData169(EconomicData169);
      force_update(type == 'A4' ? EconomicData : EconomicData169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('201-1')
    ) {
      setIsVisible(false);
      delPageData('201-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(economicValueData);
    } else {
      if (
        esgData['201-1'] != undefined &&
        esgData['201-1']['dichiarazione_economico'] != undefined &&
        esgData['201-1']['dichiarazione_economico'] != dichiarazioneEconomica
      ) {
        hasUpdate = true;
      }
      if (
        esgData['201-1'] != undefined &&
        esgData['201-1']['economicData'] != undefined &&
        !compareLists(esgData['201-1']['economicData'], economicaTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['201-1'] != undefined &&
        esgData['201-1']['economicIndicatorsData'] != undefined &&
        !compareLists(
          esgData['201-1']['economicIndicatorsData'],
          indicatorTable
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);
  const force_update = templateData => {
    if (esgData != undefined) {
      renderHTMLtoiFrame(templateData);
    }
  };
  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    try {
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = ['Indicatore', 'Valore'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = economicValueData169;
        to_init = '169';
      } else {
        templateHTMLString = economicValueDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['201-1'] != undefined &&
        esgData['201-1']['dichiarazione_economico'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'dichiarazione_economico'
        );
        fotoLeaderElement.innerHTML =
          esgData['201-1']['dichiarazione_economico'];
        setDichiarazioneEconomca(esgData['201-1']['dichiarazione_economico']);
      }
      //&&!compareLists(tabellacriticita,esgData["201-1"]["rendicontazione_criticita"])

      if (
        esgData['201-1'] != undefined &&
        esgData['201-1']['economicData'] != undefined
      ) {
        const table = parsedHTML.getElementById('economicData');
        if (esgData['201-1']['economicData'].length > 0) {
          var filteredData = esgData['201-1']['economicData'].filter(
            item => item.value !== 0
          );
          filteredData = filteredData.map(elemento => ({
            Indicatore: elemento.indicator,
            Valore: elemento.value,
          }));
          popolaTabella(table, filteredData, ordered_keys);
        }
        setEconomicTable(cloneDeep(esgData['201-1']['economicData']));
      }

      if (
        esgData['201-1'] != undefined &&
        esgData['201-1']['economicIndicatorsData'] != undefined
      ) {
        const table = parsedHTML.getElementById('economicIndicatorsData');
        if (esgData['201-1']['economicIndicatorsData'].length > 0) {
          var filteredData = esgData['201-1']['economicIndicatorsData'].filter(
            item => item.value !== 0
          );
          filteredData = filteredData.map(elemento => ({
            Indicatore: elemento.indicator,
            Valore: elemento.value,
          }));
          popolaTabella(table, filteredData, ordered_keys);
        }
        setIndicatorTable(
          cloneDeep(esgData['201-1']['economicIndicatorsData'])
        );
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('201-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Indicatori Economici',
          initA4: EconomicDataPopA4,
          init169: EconomicDataPop169,
          keys_to_check: [
            'economicIndicatorsData',
            'dichiarazione_economico',
            'economicData',
          ],
          gri: '201-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('201-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Indicatori Economici',
          initA4: EconomicData,
          init169: EconomicData169,
          keys_to_check: [
            'economicIndicatorsData',
            'dichiarazione_economico',
            'economicData',
          ],
          gri: '201-1',
        });
      }
    }
  };

  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? economicValueData : templateData;
      const parser = new DOMParser();
      const ordered_keys = ['Indicatore', 'Valore'];
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['201-1'] != undefined &&
          esgData['201-1']['dichiarazione_economico'] != undefined &&
          esgData['201-1']['dichiarazione_economico'] != dichiarazioneEconomica
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'dichiarazione_economico'
          );
          fotoLeaderElement.innerHTML =
            esgData['201-1']['dichiarazione_economico'];
          setDichiarazioneEconomca(esgData['201-1']['dichiarazione_economico']);
        }
        //&&!compareLists(tabellacriticita,esgData["201-1"]["rendicontazione_criticita"])

        if (
          esgData['201-1'] != undefined &&
          esgData['201-1']['economicData'] != undefined &&
          !compareLists(esgData['201-1']['economicData'], economicaTable)
        ) {
          const table = parsedHTML.getElementById('economicData');
          if (esgData['201-1']['economicData'].length > 0) {
            var filteredData = esgData['201-1']['economicData'].filter(
              item => item.value !== 0
            );
            filteredData = filteredData.map(elemento => ({
              Indicatore: elemento.indicator,
              Valore: elemento.value,
            }));
            popolaTabella(table, filteredData, ordered_keys);
          }
          setEconomicTable(cloneDeep(esgData['201-1']['economicData']));
        }

        if (
          esgData['201-1'] != undefined &&
          esgData['201-1']['economicIndicatorsData'] != undefined &&
          !compareLists(
            esgData['201-1']['economicIndicatorsData'],
            indicatorTable
          )
        ) {
          const table = parsedHTML.getElementById('economicIndicatorsData');
          if (esgData['201-1']['economicIndicatorsData'].length > 0) {
            var filteredData = esgData['201-1'][
              'economicIndicatorsData'
            ].filter(item => item.value !== 0);
            filteredData = filteredData.map(elemento => ({
              Indicatore: elemento.indicator,
              Valore: elemento.value,
            }));
            popolaTabella(table, filteredData, ordered_keys);
          }
          setIndicatorTable(
            cloneDeep(esgData['201-1']['economicIndicatorsData'])
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['201-1'] != undefined &&
          esgData['201-1']['dichiarazione_economico'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'dichiarazione_economico'
          );
          fotoLeaderElement.innerHTML =
            esgData['201-1']['dichiarazione_economico'];
          setDichiarazioneEconomca(esgData['201-1']['dichiarazione_economico']);
        }
        //&&!compareLists(tabellacriticita,esgData["201-1"]["rendicontazione_criticita"])

        if (
          esgData['201-1'] != undefined &&
          esgData['201-1']['economicData'] != undefined
        ) {
          const table = parsedHTML.getElementById('economicData');
          if (esgData['201-1']['economicData'].length > 0) {
            var filteredData = esgData['201-1']['economicData'].filter(
              item => item.value !== 0
            );
            filteredData = filteredData.map(elemento => ({
              Indicatore: elemento.indicator,
              Valore: elemento.value,
            }));
            popolaTabella(table, filteredData, ordered_keys);
          }
        }

        if (
          esgData['201-1'] != undefined &&
          esgData['201-1']['economicIndicatorsData'] != undefined
        ) {
          const table = parsedHTML.getElementById('economicIndicatorsData');
          if (esgData['201-1']['economicIndicatorsData'].length > 0) {
            var filteredData = esgData['201-1'][
              'economicIndicatorsData'
            ].filter(item => item.value !== 0);
            filteredData = filteredData.map(elemento => ({
              Indicatore: elemento.indicator,
              Valore: elemento.value,
            }));
            popolaTabella(table, filteredData, ordered_keys);
          }
          setIndicatorTable(
            cloneDeep(esgData['201-1']['economicIndicatorsData'])
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetEconomicValueData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ValutazioneEconomicaEngine;
