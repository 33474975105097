import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { TextInput, TextArea, Button, Tooltip } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { Stack } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG102_27 = () => {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [esg102_27, setEsg102_27] = useState();

  const { esgData, pushESGData, refreshESGData } = useESG();
  const columnDefs = [
    {
      headerName: 'Certification',
      field: 'certification',
      editable: true,
      headerTooltip: 'Inserire il nome della certificazione',
    },
    {
      headerName: 'N. Non-Conformità Senza pena pecuniaria',
      field: 'nonConformityWithoutFine',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      headerTooltip:
        'Numero delle non conformità che non hanno indotto pene pecuniarie',
    },
    {
      headerName: 'N. Non-Conformità Con pena pecuniaria',
      field: 'nonConformityWithFine',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      headerTooltip:
        'Numero delle non conformità che hanno indotto pene pecuniarie',
    },
    {
      headerName: 'Descrivere come sono state trattate le non conformità',
      field: 'description',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      headerTooltip:
        'Descrivere le procedure per la gestione delle non conformità',
    },
    {
      headerName: 'ID Certificazione',
      field: 'linkcredenziale',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      cellEditorParams: { maxLength: 30 },
      headerTooltip:
        "Inserire l'ID della certificazione. Il massimo numero di caratteri inseribili è 30",
    },
  ];

  const addNewRow = () => {
    if (rowData.length < 5) {
      const newRow = {
        certification: '',
        nonConformityWithoutFine: 0,
        nonConformityWithFine: 0,
        description: '',
        linkcredenziale: '',
      };

      setRowData([...rowData, newRow]);
      setEsg102_27({
        ...esg102_27,
        ['rendicontazione_certificazioni']: [...rowData, newRow],
      });
    }
  };

  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg102_27({
      ...esg102_27,
      ['rendicontazione_certificazioni']: ex_rowData,
    });
  };
  useEffect(() => {
    if (esgData['102-27'] != undefined) {
      setEsg102_27(esgData['102-27']);
      if (esgData['102-27']['rendicontazione_certificazioni']) {
        setRowData(esgData['102-27']['rendicontazione_certificazioni']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-27', esg102_27);
  }, [esg102_27]);

  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg102_27({
        ...esg102_27,
        ['rendicontazione_certificazioni']: updatedRowData,
      });
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const handleChanges = e => {
    setEsg102_27({ ...esg102_27, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Certificazioni</h1>
      <p>
        Questa informativa riguarda la non conformità, o la mancata
        ottemperanza, alle leggi e ai regolamenti che si applicano
        all'organizzazione. La non conformità a leggi e regolamenti può fornire
        indicazioni sulla capacità del management di garantire che
        l'organizzazione si conformi a determinati parametri di performance. Le
        leggi e i regolamenti possono essere emanati da vari organismi, tra cui
        governi locali, regionali e nazionali, autorità di regolamentazione e
        agenzie pubbliche.
      </p>

      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="descrizione_certificazioni"
        value={
          esg102_27 && esg102_27['descrizione_certificazioni'] != undefined
            ? esg102_27['descrizione_certificazioni']
            : ''
        }
        labelText="Descrivere le principali certificazioni ottenute durante il periodo di rendicontazione"
        onChange={handleChanges}
      />
      <p>
        Completare la tabella inserendo le relative certificazioni ottenute.
      </p>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          onCellValueChanged={onChangeRowValue}
        />
        <Button onClick={addNewRow} kind="ghost" variant="contained">
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>

      <footer style={{ fontSize: 10, marginRight: 0 }}>
        GRI 102-27 e GRI 102-28{' '}
      </footer>
    </Stack>
  );
};

const NumericCellEditor = props => {
  const onCellValueChanged = event => {
    const newValue = event.newValue;
    if (isNaN(newValue) || newValue <= 0) {
      // Display an error message or handle the invalid input as needed
      // You can also prevent saving the value here
      alert('Please enter a valid number greater than 0');
      event.api.stopEditing();
      return;
    }
  };

  return <TextInput {...props} onCellValueChanged={onCellValueChanged} />;
};

const TextAreaCellEditor = props => {
  return <TextArea {...props} />;
};

export default ESG102_27;
