import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { TextInput, TextArea, Stack, Tooltip, Button } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';
import { Information } from '@carbon/react/icons';

const ESG102_16 = () => {
  const gridApiRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const [esg102_16, setEsg102_16] = useState({});
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [rowData, setRowData] = useState([]);

  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg102_16({ ...esg102_16, rendicontazione_criticita: ex_rowData });
  };
  useEffect(() => {
    if (esgData['102-16'] != undefined) {
      setEsg102_16(esgData['102-16']);
      if (esgData['102-16']['rendicontazione_criticita'] != undefined) {
        setRowData(esgData['102-16']['rendicontazione_criticita']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-16', esg102_16);
  }, [esg102_16]);

  const columnDefs = [
    {
      headerName: 'Numero Criticità',
      field: 'nCriticità',

      cellEditor: 'agTextEditor',
      headerTooltip:
        "Numero delle criticità riscontrate durante l'anno di rendicontazione",
    },
    {
      headerName: 'Natura',
      field: 'natura',
      cellEditor: 'agTextEditor',
      wrapText: true,
      resizable: true,
      autoHeight: true,
      headerTooltip: 'Descrivere la natura delle criticità riscontrate',
    },
    {
      headerName: 'Impatti',
      field: 'impatti',
      cellEditor: 'agTextEditor',
      wrapText: true,
      resizable: true,
      autoHeight: true,
      headerTooltip:
        'Rendicontare eventuali impatti rispetto alle criticità riscontrate',
    },
  ];
  const handleAddRow = () => {
    if (rowData.length < 5) {
      const newRow = {
        impatti: '',
        nCriticità: 0,
        natura: '',
      };
      setRowData([...rowData, newRow]);
      setEsg102_16({
        ...esg102_16,
        ['rendicontazione_criticita']: [...rowData, newRow],
      });
    }
  };
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg102_16({
        ...esg102_16,
        ['rendicontazione_criticita']: updatedRowData,
      });
    }
  };
  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const defaultColDef = {
    flex: 1,
    resizable: true,
    editable: true,
  };
  const handleChanges = e => {
    setEsg102_16({ ...esg102_16, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Comunicazione degli Segnalazioni </h1>
      <p>Descrivere come vengono comunicate le segnalazioni al presidente</p>

      <TextArea
        placeholder="Descrivere se e come le criticità vengono comunicate al massimo organo di governo;"
        labelText="Come le criticità vengono comunicate al Massimo Organo di Governo"
        maxCount={1000}
        enableCounter={true}
        id="gestione_crit_maxorggov"
        value={
          esg102_16['gestione_crit_maxorggov'] != undefined
            ? esg102_16['gestione_crit_maxorggov']
            : ''
        }
        onChange={handleChanges}
      />
      <label>
        Rendicontare il numero totale e la natura delle criticità che son state
        comunicate al massimo organo di governo durante il periodo di
        rendicontazione. Le criticità includono le preoccupazioni per i reali e
        i potenziali impatti negativi, potenziali ed effettivi, sugli
        stakeholder raccolti attraverso procedure di reclamo e di altro tipo.
        Includono anche preoccupazioni individuate attraverso altre procedure
        riguardanti la condotta dell’organizzazione nelle sue operazioni e
        rapporti di business.
      </label>
      <TextArea
        placeholder="Descrivere le criticità riscontrate e gli impatti collegati con testo libero;"
        labelText="Descrizione a supporto"
        maxCount={500}
        enableCounter={true}
        id="descrizione_crit"
        value={
          esg102_16['descrizione_crit'] != undefined
            ? esg102_16['descrizione_crit']
            : ''
        }
        onChange={handleChanges}
      />
      <div
        className="ag-theme-alpine"
        style={{ height: '200px', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          frameworkComponents={{
            agLargeTextCellRenderer: LargeTextCellRenderer,
            agLargeTextCellEditor: LargeTextCellEditor,
          }}
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValue}
        />
        <Button onClick={handleAddRow} kind="ghost">
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga.  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>

      <footer style={{ fontSize: 10, marginRight: 0 }}>
        GRI 102-16 / ESRS G1-1
      </footer>
    </Stack>
  );
};

const LargeTextCellRenderer = props => {
  return <div className="ag-large-text-cell">{props.value}</div>;
};

const LargeTextCellEditor = props => {
  const handleValueChange = event => {
    props.stopEditing();
  };

  return (
    <TextArea
      id={props.id}
      labelText={props.column.colDef.headerName}
      onChange={handleValueChange}
      onBlur={props.stopEditing}
      value={props.value}
      rows={4}
    />
  );
};

export default ESG102_16;
