import React, { useState, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Stack, Select, SelectItem } from '@carbon/react';

const ESG405_1 = () => {
  const [selectedYear, setSelectedYear] = useState('actualyear');
  const [tableData, setTableData] = useState([]);

  const columnDefs = [
    { headerName: 'Tipologia', field: 'tipologia' },
    {
      headerName: 'Uomini',
      field: 'uomini',
      cellEditor: 'agTextEditor',
      editable: true,
    },
    {
      headerName: 'Donne',
      field: 'donne',
      cellEditor: 'agTextEditor',
      editable: true,
    },
  ];

  const handleYearChange = event => {
    setSelectedYear(event.target.value);
  };

  const handleCellValueChange = (rowIndex, colKey, value) => {
    const newData = [...tableData];
    newData[rowIndex][colKey] = value;
    setTableData(newData);
  };

  useEffect(() => {
    // Simula un caricamento dei dati in base all'anno selezionato
    const fetchDataForYear = year => {
      // Qui dovresti effettuare una richiesta per ottenere i dati in base all'anno
      // Nel mio esempio, lo imposto solo a scopo illustrativo
      const dataForYear = [
        {
          tipologia: '405-1a Executives',
          uomini: 0,
          donne: 0,
        },
        {
          tipologia: 'Middle managers/Dirigenti',
          uomini: 0,
          donne: 0,
        },
        {
          tipologia: 'White collars / Impiegati',
          uomini: 0,
          donne: 0,
        },
        {
          tipologia: 'Blue collars /Operaio',
          uomini: 0,
          donne: 0,
        },
      ];
      setTableData(dataForYear);
    };

    fetchDataForYear(selectedYear);
  }, [selectedYear]);

  return (
    <Stack gap={8}>
      <h1>GRI 405-1</h1>
      <h2>Diversità nel contesto aziendale</h2>
      <p>
        Questa informativa fornisce un criterio di misurazione quantitativa
        della diversità all’interno di un’organizzazione e può essere usata
        unitamente a metriche per settore o regione geografica. Il confronto fra
        la diversità fra i dipendenti in generale e la diversità fra i manager
        fornisce informazioni sulle pari opportunità. Le informazioni riportate
        nella presente informativa consentono anche di valutare quali aspetti
        sono particolarmente rilevanti in certi segmenti degli organi di
        governance o delle categorie di dipendenti.
      </p>
      <Select
        labelText="Seleziona l'anno"
        id="year-select"
        onChange={handleYearChange}
        defaultValue="actualyear"
        value={selectedYear}>
        <SelectItem value="actualyear-2" text={new Date().getFullYear() - 2} />
        <SelectItem value="actualyear-1" text={new Date().getFullYear() - 1} />
        <SelectItem value="actualyear" text={new Date().getFullYear()} />
      </Select>
      <div
        className="ag-theme-alpine"
        style={{ height: '300px', width: '600px' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={tableData}
          onCellValueChanged={event => {
            handleCellValueChange(
              event.rowIndex,
              event.column.colId,
              event.newValue
            );
          }}
        />
      </div>
    </Stack>
  );
};

export default ESG405_1;
