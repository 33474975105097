import { Button, Column, Row, Grid, Stack, Modal } from '@carbon/react';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Outline, Page, Thumbnail } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { useESG } from '../../../action/ESGData/esgdata';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {
  ArrowRight,
  ArrowLeft,
  TabletLandscape,
  Tablet,
  Download,
  Close,
  Maximize,
  Minimize,
} from '@carbon/react/icons';
import './_overview_output.scss';
import { Visibility } from '@tanstack/react-table';
import TemplateCard from '../TemplateCard/TemplateCard';
import TemplateCreator from '../TemplateCreator/TemplateCreator';
import CoverEngine from '../TemplateEngine/Cover/CoverEngine';
import MessaggioCeoEngine from '../TemplateEngine/MessaggioCeo/MessaggioCeoEngine';
import OrganigrammaEngine from '../TemplateEngine/Organigramma/OrganigrammaEngine';
import CompanyProfileEngine from '../TemplateEngine/CompanyProfile/CompanyProfileController';
import CriticitaEngine from '../TemplateEngine/CriticitaEngine/CriticitaEngine';
import AggiornamentoESGEngine from '../TemplateEngine/AggiornamentoESG/AggiornamentoESGController';
import RapportoRetributivoEngine from '../TemplateEngine/RapportoRetributivo/RapportoRetributivoController';
import CertificazioneEngine from '../TemplateEngine/CertificazioniEngine/CertificazioniController';
import StakeholderEngine from '../TemplateEngine/StakeholderEngine/StakeholderController';
import FornitoriLocaliEngine from '../TemplateEngine/FornitoriLocali/FornitoriLocaliController';
import AnticorruzioneEngine from '../TemplateEngine/Anticorruzione/AnticorruzioneController';
import AnticompetitivoEngine from '../TemplateEngine/AnticompetitivoController/AnticompetitivoEngine';
import MaterialiEngine from '../TemplateEngine/MaterialiEngine/MaterialiController';
import MaterialiRicRecEngine from '../TemplateEngine/MaterialiRiclatiRecuperatiController/MaterialiRiciclatiRecuperatiController';
import UtilizzoEnergiaEngine from '../TemplateEngine/UtilizzoEnegiaEngine/UtilizzoEnergiaController';
import ConsumoIdricoEngine from '../TemplateEngine/ConsumoIdricoController/ConsumoIdricoEngine';
import EmissioniEngine from '../TemplateEngine/EmissioniController/EmissioniEngine';
import RifiutiEngine from '../TemplateEngine/RifiutiEngine/RifiutiController';
import BenefitEngine from '../TemplateEngine/BenefitEngine/BenefitController';
import AttoriLocaliEngine from '../TemplateEngine/ComunitaLocaleController/ComunitaLocaleEngine';
import AttivitaMinorileEngine from '../TemplateEngine/AttivitMinoriliController/AttivitaMinoriliEngine';
import NonDiscriminazioneEngine from '../TemplateEngine/NonDiscriminazioneEngine/NonDiscriminazioneController';
import PariOpportunitaEngine from '../TemplateEngine/SalariUomoDonnaController/SalariUomoDonnaController';
import SaluteSicurezzaEngine from '../TemplateEngine/SaluteSicurezzaController/SaluteSicurezzaEngine';
import ValutazioneEconomicaEngine from '../TemplateEngine/ValutazioneEconomicaController/ValutazioneEconomicaEngine';
import ProdottiServiziEngine from '../TemplateEngine/ProdottieServiziController/ProdottieServiziEngine';
import RiduzioneEnergiaEngine from '../TemplateEngine/RiduzioneEnergiaController/RiduzioneEnergiaEngine';
import { useHTML } from '../../../action/HTMLContext/HTMLContext';
import { useHistory } from 'react-router-dom';
import OutroEngine from '../TemplateEngine/OutroController/OutroController';
import { evaluate_report_vs_empty } from '../../../content/esgDataMapping';
import axios from 'axios';
import mappaturaIndic from '../../../assets/mappatura_indice.json';
import LoadingOverlay from '../../Loader';
import {
  auth,
  createPdf,
  bucket,
} from '../../../action/fiirebase_config/firebase_config';
import CriticitaEnginev2 from '../TemplateEngine/CriticitaEngine/CriticitaEnginev2';
import { create_esg_report_firebase } from '../../../action/firebase_communication/firebaseManager';
import DettaglioLavoratoriEngine from '../TemplateEngine/Dipendenti&noDipendenti/Dipendenti_nodipendentiController';
import CongedoEngine from '../TemplateEngine/CongedoEngine/CongedoController';
import CrescitaProfessionaleEngine from '../TemplateEngine/CrescitaProfessionale/CrescitaProfessionale';
import ObiettiviEngine from '../TemplateEngine/ObiettiviController/ObiettiviEngine';
const Sidebar = ({ isOpen, onClose, children, setModalValue, visibility }) => {
  const history = useHistory();
  const { esgData } = useESG();
  const [isExpanded, setIsExpanded] = useState(false);
  const [type, setType] = useState('A4');
  const { state } = useHTML();
  const [modalTMStatusOpen, setModalTMStatusOpen] = useState(false);
  const [modalOblOpen, setModalOblOpen] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [notCorrectESG, setNotCorrectESG] = useState([]);
  const { generatePDF } = useHTML();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPhrase, SetLoadingPhrase] = useState('');
  const [themesStatus, SetThemeStatus] = useState({});
  const [themesOblStatus, SetThemeOblStatus] = useState([]);
  const [congratModal, setCongratModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  var payload = {};
  const handleToggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const handleVerifier = async () => {
    SetLoadingPhrase('Stiamo verificando le informazioni fornite');
    setIsLoading(true);
    const lista_obbligatori = [
      '102-1',
      '102-6',
      '102-3',
      '102-22',
      '102-29',
      'obiettivi',
    ];
    //controlla che per ogni oggetto presente in state tutti i campi siano pieni (hasUpdate pari a ture)
    const parserOrig = new DOMParser();
    const parserNew = new DOMParser();
    var ok_list = [];
    var ok_read_list = [];
    var mid_list = [];
    var mid_read_list = [];
    var not_list = [];
    var not_read_list = [];
    var obl_list = [];

    //var esg_data = evaluate_report_vs_empty(esgData);
    for (var elementkey of Object.keys(state)) {
      const element = state[elementkey];
      if (Object.keys(element).includes('keys_to_check')) {
        //controllo se initA4 è diverso da A4
        console.log(element);
        const parsedHTMLA4Orig = parserOrig.parseFromString(
          element['initA4'],
          'text/html'
        );
        let response = await fetch(element['A4']);
        const htmlStringA4 = await response.text();
        const parsedHTMLA4Mod = parserNew.parseFromString(
          htmlStringA4,
          'text/html'
        );

        for (var key of element['keys_to_check']) {
          console.log(key);
          if (
            parsedHTMLA4Orig.getElementById(key).outerHTML !=
            parsedHTMLA4Mod.getElementById(key).outerHTML
          ) {
            //se non è ne nella lista mid ne nella lista false
            if (
              !mid_list.includes(element['gri']) &&
              !not_list.includes(element['gri']) &&
              !ok_list.includes(element['gri'])
            ) {
              ok_list.push(element['gri']);
              ok_read_list.push(element['readable_name']);
            } else if (mid_list.includes(element['gri'])) {
            } else if (not_list.includes(element['gri'])) {
              //elimino dalla not
              not_list = not_list.filter(function(e) {
                return e !== element['gri'];
              });

              not_read_list = not_read_list.filter(function(e) {
                return e !== element['readable_name'];
              });
              mid_list.push(element['gri']);
              mid_read_list.push(element['readable_name']);
              if (lista_obbligatori.includes(element['gri'])) {
                obl_list.push(element['readable_name']);
              }
            }
          } else {
            //se sono uguali
            if (ok_list.includes(element['gri'])) {
              mid_list.push(element['gri']);
              mid_read_list.push(element['readable_name']);
              ok_list = ok_list.filter(function(e) {
                return e !== element['gri'];
              });
              if (lista_obbligatori.includes(element['gri'])) {
                obl_list.push(element['readable_name']);
              }

              ok_read_list = ok_read_list.filter(function(e) {
                return e !== element['readable_name'];
              });
            } else if (!not_list.includes(element['gri'])) {
              not_list.push(element['gri']);
              not_read_list.push(element['readable_name']);
              if (lista_obbligatori.includes(element['gri'])) {
                obl_list.push(element['readable_name']);
              }
            }
          }
        }
      }
    }
    setIsLoading(false);

    SetThemeStatus({
      ok: ok_list,
      ok_read: ok_read_list,
      not: not_list,
      not_read: not_read_list,
      mid_read: mid_read_list,
      mid: mid_list,
    });
    if (mid_list.length > 0 || not_list.length > 0) {
      SetThemeOblStatus(obl_list);
      if (obl_list.length == 0) {
        setModalTMStatusOpen(true);
      } else {
        setModalOblOpen(true);
      }
    } else {
      setsuccessModal(true);
    }
  };

  const create_report = async () => {
    console.log('CREO REPORT');
    setsuccessModal(false);
    SetLoadingPhrase(
      "Creazione report in corso. L'operazione potrebbe richiedere alcuni minuti."
    );
    setIsLoading(true);
    //ordino le pagine rispetto all'indice
    var index = 0;
    //cover
    if (
      Object.keys(state).includes('cover') &&
      themesStatus.ok.includes('cover')
    ) {
      const pageA4 = await fetch(state['cover']['A4']);
      const htmlStringA4 = await pageA4.text();
      const page169 = await fetch(state['cover']['169']);
      const htmlString169 = await page169.text();
      //aggiungo il numero pagina
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(htmlStringA4, 'text/html');
      if (parsedHTML.getElementById('page_number') != undefined) {
        parsedHTML.getElementById('page_number').innerHTML = index;
      }

      payload['cover'] = {
        idx: index,
        A4: parsedHTML.documentElement.outerHTML,
        '169': htmlString169,
      };
    }
    //order obj by key
    mappaturaIndic.sort(function(a, b) {
      return a.pageDefault - b.pageDefault;
    });
    console.log('CREO REPORT');
    console.log(mappaturaIndic);
    for (var element of mappaturaIndic) {
      console.log(element.gri);
      if (
        esgData['visibility_list'] != undefined &&
        esgData['visibility_list'].includes(element.gri)
      ) {
      } else if (
        state[element.gri] != undefined &&
        themesStatus.ok.includes(element.gri)
      ) {
        index = index + 1;
        const pageA4 = await fetch(state[element.gri]['A4']);
        const htmlStringA4 = await pageA4.text();

        const page169 = await fetch(state[element.gri]['169']);
        const htmlString169 = await page169.text();
        const parser = new DOMParser();
        // Analizza il testo HTML modificato
        const parsedHTML = parser.parseFromString(htmlStringA4, 'text/html');
        if (parsedHTML.getElementById('page_number') != undefined) {
          parsedHTML.getElementById('page_number').innerHTML = index;
        }

        const parser169 = new DOMParser();
        // Analizza il testo HTML modificato
        const parsedHTML169 = parser169.parseFromString(
          htmlString169,
          'text/html'
        );
        if (parsedHTML169.getElementById('page_number') != undefined) {
          parsedHTML169.getElementById('page_number').innerHTML = index;
        }
        //const blobA4 = new Blob([parsedHTML.documentElement.outerHTML], { type: 'text/html' });
        //const blob169 = new Blob([parsedHTML169.documentElement.outerHTML], { type: 'text/html' });
        payload[element.gri] = {
          idx: index,
          A4: parsedHTML.documentElement.outerHTML,
          '169': parsedHTML169.documentElement.outerHTML,
        };
      }
    }
    //outro
    if (
      Object.keys(state).includes('outro') &&
      themesStatus.ok.includes('outro')
    ) {
      index = index + 1;
      const pageA4 = await fetch(state['outro']['A4']);
      const htmlStringA4 = await pageA4.text();
      const page169 = await fetch(state['outro']['169']);
      const htmlString169 = await page169.text();
      payload['outro'] = { idx: index, A4: htmlStringA4, '169': htmlString169 };
    }
    console.log(payload);
    console.log('HEREEE');
    savePDF();
  };
  const last_ver = () => {
    setModalTMStatusOpen(false);
    setsuccessModal(true);
  };
  async function getPDF() {
    console.log(payload);

    const jsonString = JSON.stringify(payload);

    const payloadSizeInBytes = new Blob([jsonString]).size;

    const storageRef = bucket.ref();
    if (auth.currentUser.uid) {
      const fileRef = storageRef.child(
        auth.currentUser.uid + '/' + esgData['id'] + '.json'
      ); // Specifica il percorso e il nome del file
      await fileRef.putString(jsonString);

      //return axios.post('http://127.0.0.1:5001/stillab-esg/us-central1/createPdfCall',{data:{ bucketPath:auth.currentUser.uid+'/'+esgData["id"]+".json"}})

      return createPdf({
        bucketPath: auth.currentUser.uid + '/' + esgData['id'] + '.json',
      });
    }
  }
  const savePDF = async () => {
    try {
      const call = getPDF()
        .then(result => {
          setIsLoading(false);

          const zipData = result.data.file;
          //const zipData = result.data.result.file;
          // Converti i dati base64 in un array di byte
          /*
      const zipArray = Uint8Array.from(atob(zipData), c => c.charCodeAt(0));
      
      // Crea un Blob dai dati
      const blob = new Blob([zipArray], { type: 'application/zip' });
      
      // Crea un URL per il Blob
      const url = window.URL.createObjectURL(blob);
      
      // Crea un link e avvia il download
      */
          const imageRef = bucket.ref(zipData);
          imageRef.getDownloadURL().then(url => {
            const link = document.createElement('a');
            link.href = url;
            link.download = `ReportSostenibilita_${
              esgData['102-1']['ragione_azienda']
            }_${esgData['102-3']['periodo_rendicontazione']}.zip`;
            link.click();

            create_esg_report_firebase(esgData['id'], esgData)
              .then(res => {
                setCongratModal(true);
              })
              .catch(err => {
                setIsLoading(false);
                setErrorModal(true);
              });

            // imageRef.delete().then(()=>{})
          });
        })
        .catch(error => {
          setIsLoading(false);
          setErrorModal(true);
        });
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setErrorModal(true);
    }
  };

  const sidebarClassName = isOpen ? 'sidebar open' : 'sidebar';
  const enlargedClassName = isExpanded ? (isOpen ? 'enlarged' : '') : '';
  const handleChangeFormat = () => {
    if (type == 'A4') {
      setType('169');
    } else {
      setType('A4');
    }
  };

  //        <MessaggioCeoEngine  isOpen={isExpanded}/>
  // <CoverEngine isOpen={isExpanded}/>
  return (
    <div className={`${sidebarClassName} ${enlargedClassName}`}>
      {isLoading ? <LoadingOverlay main_text={loadingPhrase} /> : ''}
      <div>
        <Grid>
          <Column sm={4} md={4} lg={4} />
          <Column sm={3} md={3} lg={3}>
            <Button
              kind="ghost"
              hasIconOnly
              renderIcon={Download}
              disabled={
                esgData.download != undefined && esgData.download >= 5
                  ? true
                  : false
              }
              iconDescription={
                esgData.download != undefined && esgData.download >= 5
                  ? 'Hai raggiunto il limite di download.'
                  : 'Clicca qui per scaricare il report.'
              }
              tooltipPosition="bottom"
              onClick={handleVerifier}
              className="enlarge-button"
            />
          </Column>
          <Column sm={3} md={3} lg={3}>
            <Button
              kind="ghost"
              hasIconOnly
              renderIcon={isExpanded ? Minimize : Maximize}
              onClick={handleToggleSidebar}
              iconDescription={isExpanded ? 'Minimizza' : 'Massimizza'}
              tooltipPosition="bottom"
              className="enlarge-button"
            />
          </Column>

          <Column sm={3} md={3} lg={3}>
            <Button
              kind="ghost"
              hasIconOnly
              renderIcon={type == 'A4' ? Tablet : TabletLandscape}
              iconDescription={
                type == 'A4' ? 'Visualizza in 16:9' : 'Visualizza in A4'
              }
              tooltipPosition="bottom"
              onClick={handleChangeFormat}
              /*style={{ visibility: isExpanded ? 'hidden' : 'visible' }} <TemplateCreator isOpen={isExpanded}/> */
            />
          </Column>
          <Column sm={3} md={3} lg={3}>
            <Button
              kind="ghost"
              hasIconOnly
              renderIcon={Close}
              onClick={onClose}
              iconDescription={'Chiudi Anteprima'}
              tooltipPosition="bottom"
              className="close-button"
              /*style={{ visibility: isExpanded ? 'hidden' : 'visible' }} <TemplateCreator isOpen={isExpanded}/> */
            />
          </Column>
        </Grid>

        <div className="sidebar-content">
          <h3>Anteprima PDF</h3>
        </div>
        <TemplateCard setModalValue={setModalValue} />

        <Grid>
          <Column sm={16} md={16} lg={16} className="columnTemplate">
            <CoverEngine isOpen={isExpanded} type={type} />

            <MessaggioCeoEngine isOpen={isExpanded} type={type} />
            <ObiettiviEngine isOpen={isExpanded} type={type} />
            <CompanyProfileEngine isOpen={isExpanded} type={type} />
            <OrganigrammaEngine isOpen={isExpanded} type={type} />
            <DettaglioLavoratoriEngine isOpen={isExpanded} type={type} />
            <ProdottiServiziEngine isOpen={isExpanded} type={type} />

            <CriticitaEngine isOpen={isExpanded} type={type} />
            <AggiornamentoESGEngine isOpen={isExpanded} type={type} />
            <RapportoRetributivoEngine isOpen={isExpanded} type={type} />

            <CertificazioneEngine isOpen={isExpanded} type={type} />
            <AnticompetitivoEngine isOpen={isExpanded} type={type} />
            <StakeholderEngine isOpen={isExpanded} type={type} />

            <ValutazioneEconomicaEngine isOpen={isExpanded} type={type} />

            <FornitoriLocaliEngine isOpen={isExpanded} type={type} />
            <AnticorruzioneEngine isOpen={isExpanded} type={type} />
            <MaterialiEngine isOpen={isExpanded} type={type} />

            <MaterialiRicRecEngine isOpen={isExpanded} type={type} />
            <UtilizzoEnergiaEngine isOpen={isExpanded} type={type} />
            <RiduzioneEnergiaEngine isOpen={isExpanded} type={type} />
            <ConsumoIdricoEngine isOpen={isExpanded} type={type} />
            <EmissioniEngine isOpen={isExpanded} type={type} />
            <RifiutiEngine isOpen={isExpanded} type={type} />
            <CongedoEngine isOpen={isExpanded} type={type} />
            <BenefitEngine isOpen={isExpanded} type={type} />
            <SaluteSicurezzaEngine isOpen={isExpanded} type={type} />
            <CrescitaProfessionaleEngine isOpen={isExpanded} type={type} />

            <PariOpportunitaEngine isOpen={isExpanded} type={type} />

            <NonDiscriminazioneEngine isOpen={isExpanded} type={type} />
            <AttivitaMinorileEngine isOpen={isExpanded} type={type} />
            <AttoriLocaliEngine isOpen={isExpanded} type={type} />
            <OutroEngine isOpen={isExpanded} type={type} />
          </Column>
        </Grid>

        <Modal
          open={modalTMStatusOpen}
          onRequestClose={() => setModalTMStatusOpen(false)}
          primaryButtonText="Continua"
          secondaryButtonText="Rimani"
          size="lg"
          onRequestSubmit={last_ver}
          modalHeading="Sembra che alcuni temi materiali non siano stati compilati, sei sicuro di voler procedere?"
          modalLabel="Revisione Temi Materiali">
          <p
            style={{
              marginBottom: '1rem',
            }}>
            Sembra che alcune informazioni richieste non siano ancora state
            inserite.
            <br />
            Viene di seguito riportata la lista dei temi materiali suddivisi per
            stato di compilazione.
            <br />
            <Grid>
              <Column sm={4} md={4} lg={4}>
                <p>
                  Completati:
                  {themesStatus.ok &&
                    themesStatus.ok_read.map((tema, index) => (
                      <p key={index} style={{ fontSize: 9 }}>
                        - {tema}
                      </p>
                    ))}
                </p>
              </Column>
              <Column sm={4} md={4} lg={4}>
                <p>
                  Incompleti:
                  {themesStatus.mid &&
                    themesStatus.mid_read.map((tema, index) => (
                      <p key={index} style={{ fontSize: 9 }}>
                        - {tema}
                      </p>
                    ))}
                </p>
              </Column>
              <Column sm={4} md={4} lg={4}>
                <p>
                  Non Completati:
                  {themesStatus.not &&
                    themesStatus.not_read.map((tema, index) => (
                      <p key={index} style={{ fontSize: 9 }}>
                        - {tema}
                      </p>
                    ))}
                </p>
              </Column>
              <Column sm={4} md={4} lg={4}>
                <p>
                  Esclusi dal report:
                  {esgData.visibility &&
                    esgData.visibility.map((tema, index) => (
                      <p key={index} style={{ fontSize: 9 }}>
                        - {tema}
                      </p>
                    ))}
                </p>
              </Column>
            </Grid>
          </p>
        </Modal>
        <Modal
          open={modalOblOpen}
          onRequestClose={() => setModalOblOpen(false)}
          primaryButtonText="Rimani"
          size="lg"
          onRequestSubmit={() => setModalOblOpen(false)}
          modalHeading="Completa le informative elencate per produrre il report."
          modalLabel="Revisione Temi Materiali">
          <p
            style={{
              marginBottom: '1rem',
            }}>
            Alcune informative obbligatorie non sono state completate:
            <p>
              {themesOblStatus &&
                themesOblStatus.map((tema, index) => (
                  <p key={index}>- {tema}</p>
                ))}
            </p>
          </p>
        </Modal>
        <Modal
          open={successModal}
          onRequestClose={() => setsuccessModal(false)}
          primaryButtonText="Continua"
          secondaryButtonText="Rimani"
          onRequestSubmit={create_report}
          modalHeading="Produzione Report"
          modalLabel="Conferma Invio">
          <p
            style={{
              marginBottom: '1rem',
            }}>
            - Verrà prodotto un nuovo report con il template da te selezionato.
            <br />- Lo stato del report passerà da <em>
              In Lavorazione
            </em> a <em>Completato</em>.<br />- Alcuni campi come il{' '}
            <em>Periodo di Rendicontazione</em> diventeranno{' '}
            <em>Non Modificabili</em>
            <br />- Ti ricordiamo che puoi effettuare fino a 5 revisioni.
            Revisioni rimanenti:{' '}
            {esgData.download != undefined ? 5 - esgData.download : 5}
            <br />
            Sei sicuro di voler procedere?
          </p>
        </Modal>

        <Modal
          open={congratModal}
          onRequestClose={() => setCongratModal(false)}
          passiveModal
          modalHeading="Conferma Produzione"
          modalLabel="Congratulazioni!">
          <p>Il report è stato prodotto correttamente</p>
        </Modal>

        <Modal
          open={errorModal}
          onRequestClose={() => setErrorModal(false)}
          passiveModal
          modalHeading="Abbiamo avuto un imprevisto"
          modalLabel="Impossibile generare il report!">
          <p>
            Si è verificato un errore. <br /> Riprovare più tardi...
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default Sidebar;
