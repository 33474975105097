import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Tooltip, Stack, TextArea } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';

const DefaultData = [
  {
    activityType: 'Comunicazioni appese in bacheca',
    employeesInvolved: 0,
    percentOfTotal: 0,
  },
  {
    activityType: 'Formazione',
    employeesInvolved: 0,
    percentOfTotal: 0,
  },
  {
    activityType: 'Presente nel codice etico',
    employeesInvolved: 0,
    percentOfTotal: 0,
  },
];

const DefaultIncidents = [
  {
    year: 0,
    confirmedIncidents: 0,
    actionsTaken: '',
  },
];

const ESG205_2_3 = () => {
  const gridApiRefActivity = useRef(null);
  const gridApiRefIncident = useRef(null);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [activityData, setActivityData] = useState(DefaultData);
  const [incidentData, setIncidentData] = useState([]);
  const [esg205_2_3, setEsg205_2_3] = useState({
    activityData: activityData,
    incidentData: incidentData,
  });

  useEffect(() => {
    if (esgData['205-2-3'] != undefined) {
      if (esgData['205-2-3']['activityData'] != undefined) {
        setActivityData(esgData['205-2-3']['activityData']);
      }
      if (esgData['205-2-3']['incidentData'] != undefined) {
        setIncidentData(esgData['205-2-3']['incidentData']);
      }
      setEsg205_2_3(esgData['205-2-3']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('205-2-3', esg205_2_3);
  }, [esg205_2_3]);

  const addRow = table => {
    if (table === 'activity' && activityData.length < 5) {
      setActivityData([
        ...activityData,
        {
          activityType: '',
          employeesInvolved: 0,
          percentOfTotal: 0,
        },
      ]);
    } else if (table === 'incident' && incidentData.length < 5) {
      setIncidentData([
        ...incidentData,
        {
          year: 0,
          confirmedIncidents: 0,
          actionsTaken: '',
        },
      ]);
    }
  };

  const activityColumns = [
    {
      headerName: 'Tipo di attività',
      field: 'activityType',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'N° dipendenti coinvolti',
      field: 'employeesInvolved',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: '% sul totale dipendenti',
      field: 'percentOfTotal',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      valueGetter: function(params) {
        const totalNumDipendenti =
          esgData['102-1'] != undefined &&
          esgData['102-1']['num_dipendenti'] != undefined
            ? esgData['102-1']['num_dipendenti']
            : 0;
        const employeesInvolved = params.data.employeesInvolved;
        if (totalNumDipendenti != 0 && employeesInvolved) {
          const percentage = (employeesInvolved / totalNumDipendenti) * 100;
          return percentage.toFixed(2) + '%';
        } else {
          return null;
        }
      },
    },
  ];

  const incidentColumns = [
    {
      headerName: 'Anno',
      field: 'year',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'N° totale di incidenti confermati di corruzione',
      field: 'confirmedIncidents',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Azioni intraprese',
      field: 'actionsTaken',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];
  const onCellValueChangedActivity = e => {
    const ex_rowData = activityData;
    ex_rowData[e.rowIndex] = e.data;
    setActivityData(ex_rowData);
    setEsg205_2_3({ ...esg205_2_3, ['activityData']: ex_rowData });
  };
  const onCellValueChangedIncident = e => {
    const ex_rowData = incidentData;
    ex_rowData[e.rowIndex] = e.data;
    setIncidentData(ex_rowData);
    setEsg205_2_3({ ...esg205_2_3, ['incidentData']: ex_rowData });
  };

  const onGridReadyActivity = params => {
    gridApiRefActivity.current = params.api;
  };
  const onGridReadyIncident = params => {
    gridApiRefIncident.current = params.api;
  };
  const onDeleteRowActivity = () => {
    const selectedRows = gridApiRefActivity.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = activityData.filter(
        row => !selectedRows.includes(row)
      );
      setActivityData(updatedRowData);
      setEsg205_2_3({ ...esg205_2_3, ['activityData']: updatedRowData });
    }
  };

  const onDeleteRowIncident = () => {
    const selectedRows = gridApiRefIncident.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = incidentData.filter(
        row => !selectedRows.includes(row)
      );
      setIncidentData(updatedRowData);
      setEsg205_2_3({ ...esg205_2_3, ['incidentData']: updatedRowData });
    }
  };
  const handleChanges = e => {
    setEsg205_2_3({ ...esg205_2_3, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Politiche Anticorruzione</h1>

      <p>
        La seguente informativa riguarda la formazione per procedure
        anticorruzione:
        <br /> Rendicontare come il personale viene informato delle procedure
        AntiCorruzione.
        <br />
        Rendicontare inoltre, eventuali incidenti con le corrispondenti azioni
        intraprese
      </p>
      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="descrizione_procedure_anticorruzione"
        value={
          esg205_2_3 &&
          esg205_2_3['descrizione_procedure_anticorruzione'] != undefined
            ? esg205_2_3['descrizione_procedure_anticorruzione']
            : ''
        }
        labelText="Descrivere le procedure e le azioni per la gestione delle procedure anticorruzione"
        onChange={handleChanges}
      />
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          columnDefs={activityColumns}
          rowData={activityData}
          domLayout="autoHeight"
          onGridReady={onGridReadyActivity}
          rowSelection={'multiple'}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          onCellValueChanged={onCellValueChangedActivity}
        />
        <Button kind="ghost" onClick={() => addRow('activity')}>
          Aggiungi riga
        </Button>
        <Button
          kind="ghost"
          onClick={onDeleteRowActivity}
          className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga.  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>

      <p>Rendicontazione Incidenti: </p>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          columnDefs={incidentColumns}
          rowData={incidentData}
          domLayout="autoHeight"
          onGridReady={onGridReadyIncident}
          rowSelection={'multiple'}
          onCellValueChanged={onCellValueChangedIncident}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
        />
        <Button kind="ghost" onClick={() => addRow('incident')}>
          {' '}
          Aggiungi riga
        </Button>
        <Button
          kind="ghost"
          onClick={onDeleteRowIncident}
          className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga. E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>
        GRI 205-2 e GRI 205-3{' '}
      </footer>
    </Stack>
  );
};

export default ESG205_2_3;
