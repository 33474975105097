import React, { useState, useEffect, useRef } from 'react';
import { Content, Grid, Row, Tooltip, Stack } from '@carbon/react';
import { TextArea, TextInput, Button } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG204_1 = () => {
  const gridApiRef = useRef(null);
  const [esg204_1, setEsg204_1] = useState();
  const [rowData, setRowData] = useState([]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const columnDefs = [
    {
      headerName: 'Tipologia di fornitura locale',
      field: 'type',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      headerTooltip:
        'Inserisci il tipo di fornitura locale (ad esempio: energia elettrica, gas, acqua, etc.)',
    },
    {
      headerName: 'Portata annuale fornitura locale',
      field: 'annualScope',
      headerTooltip: 'Inserisci la portata annuale della fornitura locale',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Totale di fornitura annuale',
      field: 'totalAnnualSupply',
      cellEditor: 'agTextCellEditor',
      headerTooltip: 'Inserisci il totale della fornitura annuale',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: '%Locale',
      field: 'localPercentage',
      headerTooltip:
        'Percentuale della portata locale rispetto al totale (calcolato automaticamente)',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
      valueGetter: function(params) {
        const annualScope = params.data.annualScope;
        const totalAnnualSupply = params.data.totalAnnualSupply;
        if (annualScope && totalAnnualSupply) {
          const percentage = (annualScope / totalAnnualSupply) * 100;
          return percentage.toFixed(2) + '%';
        } else {
          return null;
        }
      },
    },
  ];

  const onAddRow = () => {
    if (rowData.length < 5) {
      const newRow = {
        type: '',
        annualScope: 0,
        totalAnnualSupply: 0,
        localPercentage: 0,
      };
      setRowData([...rowData, newRow]);
      setEsg204_1({
        ...esg204_1,
        ['tabella_valutazione_fornitori']: [...rowData, newRow],
      });
    }
  };
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg204_1({ ...esg204_1, ['tabella_valutazione_fornitori']: ex_rowData });
  };
  useEffect(() => {
    if (esgData['204-1'] != undefined) {
      setEsg204_1(esgData['204-1']);
      if (esgData['204-1']['tabella_valutazione_fornitori'] != undefined) {
        setRowData(esgData['204-1']['tabella_valutazione_fornitori']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('204-1', esg204_1);
  }, [esg204_1]);

  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg204_1({
        ...esg204_1,
        ['tabella_valutazione_fornitori']: updatedRowData,
      });
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const handleChanges = e => {
    setEsg204_1({ ...esg204_1, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Proporzione della spesa effettuata a favore di fornitori locali</h1>
      <p>
        Sostenendo fornitori locali, un’organizzazione può attrarre
        indirettamente investimenti aggiuntivi nell’economia locale.
        L’approvvigionamento locale può rappresentare una strategia che
        contribuisce ad assicurare l’approvvigionamento, sostenere un’economia
        locale stabile e mantenere saldi i rapporti con la comunità.
      </p>

      <TextArea
        maxCount={1500}
        enableCounter={true}
        id="impegno_fornitori_locali"
        value={
          esg204_1 && esg204_1['impegno_fornitori_locali'] != undefined
            ? esg204_1['impegno_fornitori_locali']
            : ''
        }
        labelText="Descrivere la strategia di selezione e valutazione dei fornitori."
        onChange={handleChanges}
      />
      <p>
        Completare la tabella dettagliando le forniture provenienti da fornitori
        locali
      </p>

      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          onGridReady={onGridReady}
          onCellValueChanged={onChangeRowValue}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
        />
        <Button kind="ghost" onClick={onAddRow}>
          Aggiungi riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga. E' possibile aggiungere  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 204-1 </footer>
    </Stack>
  );
};

export default ESG204_1;
