import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { Stack, TextInput, TextArea } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG302_1_3 = () => {
  const [currentYear] = useState(new Date().getFullYear());
  const [gridApi, setGridApi] = useState(null);
  const [esg302_1_3, setEsg302_1_3] = useState();
  const [rowData, setRowData] = useState([
    {
      Parametro: 'Consumo di Elettricità',
      AnnoRiferimento: currentYear - 1,
      UnitàMisura: 'kWh',
      valore: 0,
    },
    {
      Parametro: 'Consumo di Gas',
      AnnoRiferimento: currentYear - 1,
      UnitàMisura: 'mc',
      valore: 0,
    },
    {
      Parametro: 'Parametro specifico (denominatore)',
      AnnoRiferimento: currentYear - 1,
      UnitàMisura: 'udm denominatore',
      valore: 0,
    },
    {
      Parametro: 'Intensità energetica (Elettricità)',
      AnnoRiferimento: currentYear - 1,
      UnitàMisura: 'Kwh/pezzoprodotto',
      valore: 0,
    },
    {
      Parametro: 'Intensità energetica (Gas)',
      AnnoRiferimento: currentYear - 1,
      UnitàMisura: 'mc/pezzoprodotto',
      valore: 0,
    },
  ]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const columnDefs = [
    {
      headerName: 'Parametro',
      field: 'Parametro',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Anno di Riferimento',
      field: 'AnnoRiferimento',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Unità di misura',
      field: 'UnitàMisura',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Valore',
      field: 'valore',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      resizable: true,
      flex: 1,
    },
  ];

  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg302_1_3({ ...esg302_1_3, ['consumo_enegia_tabella']: ex_rowData });
  };
  useEffect(() => {
    if (esgData['302-1-3'] != undefined) {
      setEsg302_1_3(esgData['302-1-3']);
      if (esgData['302-1-3']['consumo_enegia_tabella'] != undefined) {
        setRowData(esgData['302-1-3']['consumo_enegia_tabella']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('302-1-3', esg302_1_3);
  }, [esg302_1_3]);
  const handleChanges = e => {
    setEsg302_1_3({ ...esg302_1_3, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Consumo di Energia</h1>
      <p>
        Per alcune organizzazioni, l’energia elettrica è la sola forma rilevante
        di energia che queste consumano. Per altre, possono essere importanti
        anche fonti di energia, come vapore o acqua, fornite da un impianto di
        riscaldamento locale o un impianto idrico di acqua raffreddata.
        L’energia può essere acquistata da fonti esterne all’organizzazione o
        prodotta da quest’ultima (generata autonomamente). I combustibili da
        fonti non rinnovabili possono essere quelli utilizzati per la
        combustione in caldaie, forni, impianti di riscaldamento, turbine,
        impianti di combustione di gas, inceneritori, generatori e veicoli di
        proprietà dell’organizzazione o da questa controllati. I combustibili da
        fonti non rinnovabili includono quelli acquistati dall’organizzazione.
        Possono includere inoltre combustibili generati dalle attività
        dell’organizzazione – come carbone estratto da miniere o gas ricavato da
        pozzi petroliferi e di gas. I combustibili da fonti rinnovabili possono
        includere biocombustibili, quando acquistati per l’uso diretto, e
        biomasse in fonti di proprietà dell’organizzazione o da questa
        controllate.
      </p>

      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="descrizione_utilizzo_energia"
        value={
          esg302_1_3 && esg302_1_3['descrizione_utilizzo_energia'] != undefined
            ? esg302_1_3['descrizione_utilizzo_energia']
            : ''
        }
        labelText="Descrivere i principali processi coinvolti nell'utilizzo di energia"
        onChange={handleChanges}
      />
      <p>Completare la tabella inserendo le informazioni richieste.</p>

      <div
        className="ag-theme-alpine"
        style={{
          width: '100%',
        }}>
        <AgGridReact
          onGridReady={params => setGridApi(params.api)}
          columnDefs={columnDefs}
          rowData={rowData}
          domLayout="autoHeight"
          suppressHorizontalScroll={true}
          onCellValueChanged={onChangeRowValue}
        />
      </div>

      <footer style={{ fontSize: 10, marginRight: 0 }}>
        GRI 302-1, GRI 302-2 e GRI 302-3{' '}
      </footer>
    </Stack>
  );
};

export default ESG302_1_3;
