import React, { useState, useEffect, useRef } from 'react';
import { useESG } from '../../../action/ESGData/esgdata';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  Button,
  TextArea,
  TextInput,
  Tooltip,
  TextInputNumber,
} from '@carbon/react';
import { Stack } from '@carbon/react';

import { Information } from '@carbon/react/icons';

const years = ['2023', '2022', '2021'];
const columnDefs = [
  {
    headerName: 'Indicatore',
    field: 'indicator',
    editable: false,
    autoHeight: true,
    wrapText: true,
  },
  {
    headerName: 'Valore (€)',
    field: 'value',
    cellEditor: 'agTextCellEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
  },
];

const columnDefsIndicator = [
  {
    headerName: 'Indicatore',
    field: 'indicator',
    editable: false,
    autoHeight: true,
    wrapText: true,
  },
  {
    headerName: 'Valore (%)',
    field: 'value',
    cellEditor: 'agTextCellEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
  },
];
const economicIndicators = [
  { indicator: '%ROE (Return on Equity)', value: 0 },
  { indicator: '%ROS (Return on Sale)', value: 0 },
  { indicator: '%ROI (Return on Investiment)', value: 0 },
];
const ESG2011 = [
  {
    indicator: 'Ricavi delle vendite',
    value: 0,
  },
  {
    indicator: 'Altri ricavi e proventi',
    value: 0,
  },
  {
    indicator: 'Valore economico generato',
    value: 0,
  },
  {
    indicator: 'salari e stipendi e altri costi',
    value: 0,
  },
  {
    indicator: 'Oneri sociali',
    value: 0,
  },
  {
    indicator: 'Servizi',
    value: 0,
  },
  {
    indicator: 'Materie prime, sussidiarie, di consumo e di merci',
    value: 0,
  },
  {
    indicator: 'Godimento di beni di terzi',
    value: 0,
  },
  {
    indicator: 'Ammortamenti e svalutazioni',
    value: 0,
  },
  {
    indicator: 'Oneri diversi di gestione',
    value: 0,
  },
  {
    indicator: 'Valore economico distribuito',
    value: 0,
  },
  {
    indicator: 'Valore economico trattenuto',
    value: 0,
  },
  {
    indicator: 'Utile prima delle imposte',
    value: 0,
  },
  {
    indicator: "Utile (perdita) dell'esercizio",
    value: 0,
  },
];
const ESG201_1 = () => {
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [esg201_1, setEsg201_1] = useState({});
  const [economicData, setEconomicData] = useState(ESG2011);
  const [economicIndicatorsData, setEconomicIndicatorsData] = useState(
    economicIndicators
  );

  useEffect(() => {
    if (esgData['201-1'] != undefined) {
      setEsg201_1(esgData['201-1']);
      if (esgData['201-1']['economicData'] != undefined) {
        setEconomicData(esgData['201-1']['economicData']);
      }

      if (esgData['201-1']['economicIndicatorsData'] != undefined) {
        setEconomicIndicatorsData(esgData['201-1']['economicIndicatorsData']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('201-1', esg201_1);
  }, [esg201_1]);

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };
  const onChangeRowValueData = e => {
    const ex_rowData = economicData;
    ex_rowData[e.rowIndex] = e.data;
    setEsg201_1({ ...esg201_1, ['economicData']: ex_rowData });
    //setRowData(ex_rowData);
  };
  const onChangeRowValueIndicators = e => {
    const ex_rowData = economicIndicatorsData;
    ex_rowData[e.rowIndex] = e.data;
    setEsg201_1({ ...esg201_1, ['economicIndicatorsData']: ex_rowData });
    //setRowData(ex_rowData);
  };
  const handleChanges = e => {
    //setScopo_stakeholder(e.target.value);
    setEsg201_1({ ...esg201_1, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Valore economico diretto generato e distribuito</h1>
      <p>
        Informazioni sulla creazione e distribuzione di valore economico
        forniscono un’indicazione base su come l’organizzazione ha creato
        ricchezza per gli stakeholder. Numerose componenti del valore economico
        generato e distribuito (EVG&D) forniscono anche un profilo economico
        dell’organizzazione, che può essere utile per normalizzare altre cifre
        relative alla performance.
      </p>
      <TextArea
        maxCount={700}
        enableCounter={true}
        id="dichiarazione_economico"
        value={
          esgData['201-1'] != undefined &&
          esgData['201-1']['dichiarazione_economico'] != undefined
            ? esgData['201-1']['dichiarazione_economico']
            : ''
        }
        placeholder="Descrivere il profilo economico dell'organizzazione."
        onChange={handleChanges}
        labelText="Descrizione Profilo Economico"
      />
      <div style={{ display: 'flex', marginBottom: '16px' }}>
        <div />
      </div>
      <Stack gap={1}>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={economicData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            domLayout="autoHeight"
            onCellValueChanged={onChangeRowValueData}
          />
        </div>
      </Stack>
      <h2>Indicatori Economici</h2>
      <div className="ag-theme-alpine" style={{ width: 400 }}>
        <AgGridReact
          rowData={economicIndicatorsData}
          columnDefs={columnDefsIndicator}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          onCellValueChanged={onChangeRowValueIndicators}
        />
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 201-1 </footer>
    </Stack>
  );
};

export default ESG201_1;
