import React, { useEffect } from 'react';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import firebase from 'firebase/compat/app';
import { Column, Grid, Stack } from '@carbon/react';
import { analytics } from '../../action/fiirebase_config/firebase_config';
import { logEvent } from 'firebase/analytics';
const AuthGoogle = () => {
  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('.firebase-auth-container', {
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
        },
      ],
      signInSuccessUrl: '/login',
    });
    logEvent(analytics, 'In SIGNON PAGE');
  }, []);
  return (
    <Grid>
      <Column sm={16} md={16} lg={16}>
        <Stack gap={7}>
          <img
            src={`${process.env.PUBLIC_URL}/headerlogo.png`}
            style={{ margin: '0 auto' }}
          />
          <p style={{ margin: 'auto', width: '70%', textAlign: 'center' }}>
            Crea il tuo account inserendo Email e Password. <br />
            Verrai re-indirizzato nella pagina di Login per accedere alla Tua
            Area personale dove poter acquistare il tuo Report di sostenibilità.
          </p>
          <div className="firebase-auth-container" />
        </Stack>
      </Column>
    </Grid>
  );
};
export default AuthGoogle;
