import React, { useState, useEffect } from 'react';

import { TextArea, Stack } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG102_12 = props => {
  const [esg102_12, setEsg102_12] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-12'] != undefined) {
      setEsg102_12(esgData['102-12']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-12', esg102_12);
  }, [esg102_12]);

  const handleChanges = e => {
    setEsg102_12({ ...esg102_12, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>GRI 102-12 </h1>
      <h2>
        Ruolo del massimo organo di governo nel controllo della gestione degli
        impatti
      </h2>
      <p>Completare i campi con le informazioni richieste.</p>

      <TextArea
        id="ruolo_aog_gestioneimpatti"
        maxCount={700}
        enableCounter={true}
        value={
          esg102_12['ruolo_aog_gestioneimpatti'] != undefined
            ? esg102_12['ruolo_aog_gestioneimpatti']
            : ''
        }
        placeholder="Descrivere con testo libero il ruolo del più alto organo di governo e dei dirigenti nello sviluppo,
      nell'approvazione e nell'aggiornamento delle dichiarazioni di scopo, valore o missione
      dell'organizzazione, delle strategie, delle politiche e degli obiettivi relativi allo sviluppo
      sostenibile;"
        onChange={handleChanges}
        labelText="Ruolo alto Organo di Governo nella Gestione degli Impatti"
      />

      {/*<TextArea id="ruolo_aog_duediligence" 
maxCount={300} enableCounter={true}
      value={esg102_12["ruolo_aog_duediligence"]!=undefined?esg102_12["ruolo_aog_duediligence"]:""} 
      placeholder="Descrivere con testo libero il ruolo del massimo organo di governo nel controllo della due diligence
      dell’organizzazione e di altri processi per identificare e gestire gli impatti
      dell’organizzazione sull’economia, sull’ambiente e sulle persone. 
      se e come il massimo organo di governo coinvolge gli stakeholder a sostegno di
      questi processi;
      come il massimo organo di governo considera i risultati di questi processi." onChange={handleChanges} labelText="Ruolo alto Organo di Governo nella due diligence dell’organizzazione e di altri processi per identificare e gestire gli impatti"></TextArea>

 */}
    </Stack>
  );
};

export default ESG102_12;
