import React, { useEffect, useState } from 'react';
import { ArrowRight } from '@carbon/icons-react';
import { useHistory } from 'react-router-dom';
import {
  StructuredListWrapper,
  Row,
  Grid,
  Column,
  StructuredListSkeleton,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListInput,
  StructuredListCell,
  Stack,
  Button,
} from '@carbon/react';
import { useESG } from '../../action/ESGData/esgdata';
import LoadingOverlay from '../../components/Loader';

const data = [
  {
    nome: 'reportSostAziendaA',
    datacreazione: '2023-06-20',
    id: 'ptqoilsib082pm1u',
    descrizionereport:
      "Report per evidenziare l'impegno dell'azienda A in ambito sostenibilità",
  },
  {
    nome: 'reportSostAziendaB',
    id: 's3qm4t8i4iq6540r',
    datacreazione: '2023-08-27',
    descrizionereport:
      "Report per evidenziare l'impegno dell'azienda B in ambito sostenibilità",
  },
  {
    nome: 'reportSostAziendaC',
    id: '8xto0j78m5xqj0y7',
    datacreazione: '2023-06-29',
    descrizionereport:
      "Report per evidenziare l'impegno dell'azienda C in ambito sostenibilità",
  },
];
const structuredListGenerator = (data, handleChange) => {
  var slim_report_data = [];

  data.forEach(val => {
    if (val.report_status == 'working') {
      slim_report_data.push(val);
    }
  });

  return slim_report_data.map(n => (
    <StructuredListRow key={`row-${n.id}`}>
      <StructuredListCell>{n.visible_name}</StructuredListCell>
      <StructuredListCell>{n.report_creation_date}</StructuredListCell>
      <StructuredListCell>{n.id}</StructuredListCell>
      <StructuredListCell>
        <Button
          kind="ghost"
          onClick={() => handleChange(n.id, n)}
          renderIcon={ArrowRight}
        />
      </StructuredListCell>
    </StructuredListRow>
  ));
};
const OnWorking = args => {
  const history = useHistory();
  const context_obj = useESG();
  const [reportData, SetReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (id, jsondata) => {
    context_obj.setReportIDOnCallback(id, jsondata);
    history.push('esgstd');
  };
  useEffect(() => {
    setIsLoading(true);
    context_obj.return_docs_uid().then(value => {
      if (value == 'KO') {
      } else {
        SetReportData(value);
        setIsLoading(false);
      }
    });
  }, []);
  return (
    <div>
      <Grid>
        <Column lg={10} md={10} sm={10} className="landing-page__r3">
          <h1>Pratiche Aperte</h1>
          <p>In questa sezione sono presenti le pratiche non ancora chiuse</p>
        </Column>
        <Column lg={6} md={6} sm={6} className="landing-page__r3">
          <img
            width="40%"
            src={`${process.env.PUBLIC_URL}/onworking.svg`}
            alt="Pratiche Aperte"
          />
        </Column>
      </Grid>

      <Stack gap={7}>
        <StructuredListWrapper className="OnWorkingContainer" {...args}>
          <StructuredListHead>
            <StructuredListRow head>
              <StructuredListCell head>Nome Report</StructuredListCell>
              <StructuredListCell head>Data Creazione</StructuredListCell>
              <StructuredListCell head>ID</StructuredListCell>
            </StructuredListRow>
          </StructuredListHead>
          <StructuredListBody>
            {structuredListGenerator(reportData, handleChange)}
          </StructuredListBody>
        </StructuredListWrapper>
      </Stack>
      {isLoading && (
        <LoadingOverlay main_text={'Caricamento pratiche in corso'} />
      )}
    </div>
  );
  OnWorking.args = {
    isCondensed: false,
    isFlush: false,
  };
  OnWorking.argTypes = {
    selection: {
      control: {
        disable: true,
      },
    },
    isCondensed: {
      control: {
        type: 'boolean',
      },
    },
    isFlush: {
      control: {
        type: 'boolean',
      },
    },
  };
};

export default OnWorking;
