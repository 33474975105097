import { useState, React, useEffect } from 'react';
import { Form, TextInput, Stack, Button, Modal } from '@carbon/react';
import { signInWithCredential, signOut } from 'firebase/auth';
import { FirebaseError } from '@firebase/util';
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router-dom';
import { useESG } from '../../action/ESGData/esgdata';
import { useUser } from '../../action/UserData/UserData';
import LoadingOverlay from '../../components/Loader/Loader';
import { analytics } from '../../action/fiirebase_config/firebase_config';
import { logEvent } from 'firebase/analytics';
const LoginPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalError, setIsModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const  { state,updateUserData } = useUser();
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    history.push('/main_user');
  };
  const [localUserData, setLocalUserData] = useState({});
  const set_local_user_data = e => {
    var old_obj = localUserData;

    old_obj[e.target.id] = e.target.value;
    setLocalUserData({ ...localUserData, [e.target.id]: e.target.value });
    updateUserData({ ...localUserData, [e.target.id]: e.target.value })
  };
  const history = useHistory();
  const context_obj = useESG();

  const handleChanges_modal = e => {
    set_local_user_data(e);
  };

  const [errorLogIn, setErrorLogIn] = useState('');
  const [pwdNotValid, setPwdNotValid] = useState(false);
  const TextInputProps = {
    className: 'input',
    id: 'email',
    placeholder: 'Email',
    pattern:
      "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/.",
  };
  const PasswordProps = {
    className: 'input',
    id: 'password',
    placeholder: 'Password',
    pattern: '(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{6,}',
    invalid: pwdNotValid,
    invalidText:
      'Password di almeno 6 caratteri, 1 carattere Maiuscolo, 1 minuscolo, 1 numero e almeno 1 carattere speciale',
  };
  const update_user_info = () => {
    context_obj.update_user_details(localUserData).then(value => {
      history.push('/main_user');
    });
  };
  const returnHome = () => {
    history.push('/');
  };

  const wait_user_data = value => {
    if (value == 'KO') {
      setIsModalError(true);
    } else {
      setLocalUserData(value);
      var no_det_ins = false;

      Object.keys(value).forEach(key => {
        if (
          value[key] == '' ||
          value[key] == undefined ||
          value[key] == 'ERROR'
        ) {
          no_det_ins = true;
        }
      });
      if (no_det_ins) {
        setIsModalOpen(true);
      } else {
        history.push('/main_user');
      }
    }
  };
  const handleSubmit = event => {
    event.preventDefault();
    setErrorLogIn('');
    setIsLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(
        event.target.email.value,
        event.target.password.value
      )
      .then(userCredential => {
        context_obj
          .return_user_details(userCredential.user.multiFactor.user.accessToken)
          .then(value => {
            setIsLoading(false);
            logEvent(analytics, 'OK LOGIN');
            wait_user_data(value);
          })
          .catch(error => {
            logEvent(analytics, 'FAILED LOGIN');
            setIsLoading(false);
            firebase
              .auth()
              .signOut()
              .then(() => {
                setIsModalError(true);
              });
          });
      })
      .catch(error => {
        setErrorLogIn(
          'Verifica Email e Password. Tentativo di accesso fallito.'
        );
        logEvent(analytics, 'FAILED LOGIN');
        setErrorCode(error.code);
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  const handleSubmit2 = async event => {
    event.preventDefault();
    setErrorLogIn('');
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(
          event.target.email.value,
          event.target.password.value
        );

      const userDetails = await context_obj.return_user_details();
      if (userDetails.success) {
        wait_user_data(userDetails.data);
      } else {
        // Gestisci l'errore in base allo stato restituito

        // Esempio: logout e mostrare un messaggio di errore
        await firebase.auth().signOut();
        setIsModalError(true);
      }
    } catch (error) {
      // Gestisci altri errori qui, se necessario
      console.error(error);
      if (error instanceof FirebaseError) {
        setErrorLogIn('Errore di accesso');
      } else if (!error instanceof TypeError) {
        await firebase.auth().signOut();
        setIsModalError(true);
      }
    }
  };
  const checkPassword = event => {};

  return (
    <Stack as="div" gap={8} className="container">
      <Form className="stack_container2" onSubmit={handleSubmit}>
        <Stack gap={8}>
          <Stack gap={3}>
            <img
              className="LogoLogin"
              src={`${process.env.PUBLIC_URL}/headerlogo.png`}
            />
            <h1>Accedi alla tua Area Personale</h1>
            <small>
              Non hai un account?{' '}
              <a
                onClick={() => {
                  history.push('/signon');
                }}>
                Clicca qui per registrarti
              </a>
            </small>
          </Stack>

          <TextInput {...TextInputProps} />

          <TextInput
            type="password"
            required
            className="input"
            id="password"
            placeholder="Password"
          />
          <Stack gap={3}>
            <p style={{ color: 'red', fontSize: '10px' }}>{errorLogIn}</p>
            <Button className="ButtonForm" type="submit">
              Login
            </Button>
            <a
              onClick={() => {
                history.push('/resetlink');
              }}>
              Password dimenticata?
            </a>
          </Stack>
        </Stack>
      </Form>
      <Modal
        open={isModalOpen}
        onRequestClose={closeModal}
        primaryButtonText="Conferma"
        secondaryButtonText="Salta"
        onRequestSubmit={update_user_info}
        onSecondarySubmit={closeModal}
        modalHeading="Inserisci le informazioni richieste per completare la registrazione."
        modalLabel="Informazioni Account">
        <p
          style={{
            marginBottom: '1rem',
          }}>
          Sembra che alcune informazioni richieste non siano ancora state
          inserite. Utilizza il form sottostante per inserirle.
          ALternativamente, puoi saltare questo passaggio.
        </p>
        <TextInput
          id="firstname"
          onChange={handleChanges_modal}
          labelText="Nome"
          value={localUserData['firstname']}
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="lastname"
          onChange={handleChanges_modal}
          labelText="Cognome"
          value={localUserData['lastname']}
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="company"
          onChange={handleChanges_modal}
          labelText="Azienda"
          value={localUserData['company']}
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="role"
          onChange={handleChanges_modal}
          labelText="Ruolo Aziendale"
          value={localUserData['role']}
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="pec"
          onChange={handleChanges_modal}
          labelText="PEC Aziendale"
          value={localUserData['pec']}
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="sdi"
          onChange={handleChanges_modal}
          labelText="Codice Destinatario (SDI"
          value={localUserData['sdi']}
          style={{
            marginBottom: '1rem',
          }}
        />
      </Modal>
      ;
      <Modal
        open={isModalError}
        modalHeading="Si è verificato un errore"
        modalLabel="Errore piattaforma"
        primaryButtonText="Ritorna alla Home Page"
        onRequestSubmit={returnHome}>
        <p
          style={{
            marginBottom: '1rem',
          }}>
          Il server non risponde.
        </p>
      </Modal>
      ;
      {isLoading && (
        <LoadingOverlay main_text={'Tentativo di accesso in corso'} />
      )}
    </Stack>
  );
};
export default LoginPage;
