import Anticorruzione from '../../../../assets/html_template/professional/A4/10-governance205-2-3-anticorruzione.html';
import Anticorruzione169 from '../../../../assets/html_template/professional/169/10-governance205-2-3-anticorruzione.html';
import AnticorruzionePopA4 from '../../../../assets/html_template/pop/A4/10-governance205-2-3-anticorruzione.html';
import AnticorruzionePop169 from '../../../../assets/html_template/pop/169/10-governance205-2-3-anticorruzione.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';

import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const AnticorruzioneEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? Anticorruzione : Anticorruzione169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? AnticorruzionePopA4 : AnticorruzionePop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? Anticorruzione : Anticorruzione169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [descrizioneProcedure, setDescrizioneProcedure] = useState('');

  const [anticorruzioneData, SetAnticorruzioneData] = useState(Anticorruzione);
  const [anticorruzioneDataA4, SetAnticorruzioneDataA4] = useState(
    Anticorruzione
  );
  const [anticorruzioneData169, SetAnticorruzioneData169] = useState(
    Anticorruzione169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetAnticorruzioneData(AnticorruzionePopA4);
    SetAnticorruzioneDataA4(AnticorruzionePopA4);
    SetAnticorruzioneData169(AnticorruzionePop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetAnticorruzioneData(Anticorruzione);
    SetAnticorruzioneDataA4(Anticorruzione);
    SetAnticorruzioneData169(Anticorruzione169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetAnticorruzioneData(Anticorruzione);
    SetAnticorruzioneDataA4(Anticorruzione);
    SetAnticorruzioneData169(Anticorruzione169);
  }

  const [formazioneTable, setFormazioneTable] = useState([]);
  const [incidentiTable, setIncidentiTable] = useState([]);
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;

    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAnticorruzioneData(AnticorruzionePopA4);
      SetAnticorruzioneDataA4(AnticorruzionePopA4);
      SetAnticorruzioneData169(AnticorruzionePop169);
      force_update(type == 'A4' ? AnticorruzionePopA4 : AnticorruzionePop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAnticorruzioneData(Anticorruzione);
      SetAnticorruzioneDataA4(Anticorruzione);
      SetAnticorruzioneData169(Anticorruzione169);
      force_update(type == 'A4' ? Anticorruzione : Anticorruzione169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAnticorruzioneData(Anticorruzione);
      SetAnticorruzioneDataA4(Anticorruzione);
      SetAnticorruzioneData169(Anticorruzione169);
      force_update(type == 'A4' ? Anticorruzione : Anticorruzione169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('205-2-3')
    ) {
      setIsVisible(false);
      delPageData('205-2-3');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(anticorruzioneData);
    } else {
      if (
        esgData['205-2-3'] != undefined &&
        esgData['205-2-3']['descrizione_procedure_anticorruzione'] !=
          undefined &&
        esgData['205-2-3']['descrizione_procedure_anticorruzione'] !=
          descrizioneProcedure
      ) {
        hasUpdate = true;
      }

      if (
        esgData['205-2-3'] != undefined &&
        esgData['205-2-3']['activityData'] != undefined &&
        !compareLists(esgData['205-2-3']['activityData'], formazioneTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['205-2-3'] != undefined &&
        esgData['205-2-3']['incidentData'] != undefined &&
        !compareLists(esgData['205-2-3']['incidentData'], incidentiTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys_act = [
      'Attività',
      'N. Dipendenti coinvolti',
      '% Coinvolgimento',
    ];
    const ordered_keys_inc = ['Anno', 'N. Incidenti', 'Azioni intraprese'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = anticorruzioneData169;
        to_init = '169';
      } else {
        templateHTMLString = anticorruzioneDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['205-2-3'] != undefined &&
        esgData['205-2-3']['descrizione_procedure_anticorruzione'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'procedure_anticorruzione'
        );
        fotoLeaderElement.innerHTML =
          esgData['205-2-3']['descrizione_procedure_anticorruzione'];
        setDescrizioneProcedure(
          esgData['205-2-3']['descrizione_procedure_anticorruzione']
        );
      }
      if (
        esgData['205-2-3'] != undefined &&
        esgData['205-2-3']['activityData'] != undefined
      ) {
        const table = parsedHTML.getElementById('formazione_anticorruzione');
        if (esgData['205-2-3']['activityData'].length > 0) {
          let filtered_data = esgData['205-2-3']['activityData'].map(
            elemento => ({
              Attività: elemento.activityType,
              'N. Dipendenti coinvolti': elemento.employeesInvolved,
              '% Coinvolgimento':
                (elemento.employeesInvolved /
                  esgData['102-1']['num_dipendenti']) *
                100,
            })
          );
          popolaTabella(table, filtered_data, ordered_keys_act);
          setFormazioneTable(cloneDeep(esgData['205-2-3']['activityData']));
        }
      }
      if (
        esgData['205-2-3'] != undefined &&
        esgData['205-2-3']['incidentData'] != undefined
      ) {
        const table = parsedHTML.getElementById('rendicontazione_incidenti');
        if (esgData['205-2-3']['incidentData'].length > 0) {
          let filtered_in_data = esgData['205-2-3']['incidentData'].map(
            elemento => ({
              'N. Incidenti': elemento.confirmedIncidents,
              'Azioni intraprese': elemento.actionsTaken,
              Anno: elemento.year,
            })
          );

          popolaTabella(table, filtered_in_data, ordered_keys_inc);
          setIncidentiTable(cloneDeep(esgData['205-2-3']['incidentData']));
        }
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('205-2-3', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Politiche Anticorruzione',
          initA4: AnticorruzionePopA4,
          init169: AnticorruzionePop169,
          keys_to_check: [
            'procedure_anticorruzione',
            'formazione_anticorruzione',
            'rendicontazione_incidenti',
          ],
          gri: '205-2-3',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('205-2-3', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Politiche Anticorruzione',
          initA4: Anticorruzione,
          init169: Anticorruzione169,
          keys_to_check: [
            'procedure_anticorruzione',
            'formazione_anticorruzione',
            'rendicontazione_incidenti',
          ],
          gri: '205-2-3',
        });
      }
    }
  };

  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData != '' ? templateData : anticorruzioneData;
      const parser = new DOMParser();
      const ordered_keys_act = [
        'Attività',
        'N. Dipendenti coinvolti',
        '% Coinvolgimento',
      ];
      const ordered_keys_inc = ['Anno', 'N. Incidenti', 'Azioni intraprese'];
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['205-2-3'] != undefined &&
          esgData['205-2-3']['descrizione_procedure_anticorruzione'] !=
            undefined &&
          esgData['205-2-3']['descrizione_procedure_anticorruzione'] !=
            descrizioneProcedure
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'procedure_anticorruzione'
          );
          fotoLeaderElement.innerHTML =
            esgData['205-2-3']['descrizione_procedure_anticorruzione'];
          setDescrizioneProcedure(
            esgData['205-2-3']['descrizione_procedure_anticorruzione']
          );
        }
        if (
          esgData['205-2-3'] != undefined &&
          esgData['205-2-3']['activityData'] != undefined &&
          !compareLists(esgData['205-2-3']['activityData'], formazioneTable)
        ) {
          const table = parsedHTML.getElementById('formazione_anticorruzione');
          if (esgData['205-2-3']['activityData'].length > 0) {
            let filtered_data = esgData['205-2-3']['activityData'].map(
              elemento => ({
                Attività: elemento.activityType,
                'N. Dipendenti coinvolti': elemento.employeesInvolved,
                '% Coinvolgimento':
                  (elemento.employeesInvolved /
                    esgData['102-1']['num_dipendenti']) *
                  100,
              })
            );
            popolaTabella(table, filtered_data, ordered_keys_act);
            setFormazioneTable(cloneDeep(esgData['205-2-3']['activityData']));
          }
        }
        if (
          esgData['205-2-3'] != undefined &&
          esgData['205-2-3']['incidentData'] != undefined &&
          !compareLists(esgData['205-2-3']['incidentData'], incidentiTable)
        ) {
          const table = parsedHTML.getElementById('rendicontazione_incidenti');
          if (esgData['205-2-3']['incidentData'].length > 0) {
            let filtered_in_data = esgData['205-2-3']['incidentData'].map(
              elemento => ({
                'N. Incidenti': elemento.confirmedIncidents,
                'Azioni intraprese': elemento.actionsTaken,
                Anno: elemento.year,
              })
            );

            popolaTabella(table, filtered_in_data, ordered_keys_inc);
            setIncidentiTable(cloneDeep(esgData['205-2-3']['incidentData']));
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['205-2-3'] != undefined &&
          esgData['205-2-3']['descrizione_procedure_anticorruzione'] !=
            undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'procedure_anticorruzione'
          );
          fotoLeaderElement.innerHTML =
            esgData['205-2-3']['descrizione_procedure_anticorruzione'];
          setDescrizioneProcedure(
            esgData['205-2-3']['descrizione_procedure_anticorruzione']
          );
        }
        if (
          esgData['205-2-3'] != undefined &&
          esgData['205-2-3']['activityData'] != undefined
        ) {
          const table = parsedHTML.getElementById('formazione_anticorruzione');
          if (esgData['205-2-3']['activityData'].length > 0) {
            let filtered_data = esgData['205-2-3']['activityData'].map(
              elemento => ({
                Attività: elemento.activityType,
                'N. Dipendenti coinvolti': elemento.employeesInvolved,
                '% Coinvolgimento': Number(
                  (elemento.employeesInvolved /
                    esgData['102-1']['num_dipendenti']) *
                    100
                ).toFixed(1),
              })
            );
            popolaTabella(table, filtered_data, ordered_keys_act);
            setFormazioneTable(cloneDeep(esgData['205-2-3']['activityData']));
          }
        }
        if (
          esgData['205-2-3'] != undefined &&
          esgData['205-2-3']['incidentData'] != undefined
        ) {
          const table = parsedHTML.getElementById('rendicontazione_incidenti');
          if (esgData['205-2-3']['incidentData'].length > 0) {
            let filtered_in_data = esgData['205-2-3']['incidentData'].map(
              elemento => ({
                'N. Incidenti': elemento.confirmedIncidents,
                'Azioni intraprese': elemento.actionsTaken,
                Anno: elemento.year,
              })
            );

            popolaTabella(table, filtered_in_data, ordered_keys_inc);
            setIncidentiTable(cloneDeep(esgData['205-2-3']['incidentData']));
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);
      updateDataUrl(blobURL);
      setIframeURL(blobURL);
      SetAnticorruzioneData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AnticorruzioneEngine;
