import React, { createContext, useContext, useReducer } from 'react';
import mappatura_json from '../../assets/mappatura_indice.json';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useESG } from '../ESGData/esgdata';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const HTMLContext = createContext();
//const pdfInstance = html2pdf();
const doc = new jsPDF('portrait', 'mm', 'a4');
const initialState = {
  pages: [],
};

const actionTypes = {
  ADD_PAGE: 'ADD_PAGE',
};

const htmlReducer = (state, action) => {
  switch (action.action) {
    case 'UPDATE_DATA':
      if (
        action.payload != undefined &&
        Object.keys(action.payload).length !== 0
      ) {
        return { ...state, [action.key]: action.payload };
      } else {
        return state;
      }
    case 'REMOVE_DATA':
      if (
        action.key != undefined &&
        Object.keys(state).length !== 0 &&
        Object.keys(state).includes(action.key)
      ) {
        var updated_state = state;
        delete updated_state[action.key];

        return updated_state;
      } else {
        return state;
      }
  }
};

export const HTMLProvider = ({ children }) => {
  const [state, dispatch] = useReducer(htmlReducer, initialState);

  const updatePageData = (key, newData) => {
    console.log('Aggiornamento dati pagina ' + key);
    dispatch({ action: 'UPDATE_DATA', key: key, payload: newData });
  };

  const delPageData = key => {
    dispatch({ action: 'REMOVE_DATA', key: key });
  };
  const { esgData } = useESG();
  const generatePDF = async () => {
    const zip = new JSZip();
    var list_pageA4 = [];
    var list_page169 = [];
    //il controllo pieno/vuoto lo facciamo prima
    for (const element of mappatura_json) {
      if (
        esgData['visibility'] !== undefined &&
        !esgData['visibility'].includes(element.gri)
      ) {
        if (Object.keys(state).includes(element.gri)) {
          const stateElement = state[element.gri];
          const pageA4 = await fetch(stateElement['A4']);
          const htmlStringA4 = await pageA4.text();
          const parser = new DOMParser();

          const parsedHTML = parser.parseFromString(htmlStringA4, 'text/html');
          const page169 = await fetch(stateElement['169']);
          const htmlString169 = await page169.text();
          html2canvas(parsedHTML).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save('generated-pdf.pdf');
          });
          //list_pageA4.push(await pdfInstance.from(htmlStringA4).output('blob'));

          //list_page169.push(await pdfInstance.from(htmlString169).output('blob'));
        }
      }
    }

    const pdf_name = `Report_Sostenibilita_${
      esgData['102-1'] !== undefined &&
      esgData['102-1']['ragione_azienda'] !== undefined
        ? esgData['102-1']['ragione_azienda']
        : ''
    }_${
      esgData['102-3'] !== undefined &&
      esgData['102-3']['periodo_rendicontazione'] !== undefined
        ? esgData['102-3']['periodo_rendicontazione']
        : ''
    }`;

    for (let i = 0; i < list_pageA4.length; i++) {
      zip.file(`${pdf_name}_A4_${i + 1}.pdf`, list_pageA4[i]);
    }

    for (let i = 0; i < list_page169.length; i++) {
      zip.file(`${pdf_name}_169_${i + 1}.pdf`, list_page169[i]);
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, pdf_name + '.zip');

    // Puoi fare qualcosa con il file zipBlob, ad esempio scaricarlo o inviarlo al backend

    //dispatch({ type: actionTypes.GENERATE_PDF });
  };

  return (
    <HTMLContext.Provider
      value={{ state, updatePageData, generatePDF, delPageData }}>
      {children}
    </HTMLContext.Provider>
  );
};

export const useHTML = () => {
  return useContext(HTMLContext);
};
