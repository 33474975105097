import React, { useState, useEffect } from 'react';
import { TextArea } from '@carbon/react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { Stack } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG102_28 = () => {
  const [esg102_28, setEsg102_28] = useState({});
  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-28'] != undefined) {
      setEsg102_28(esgData['102-28']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-28', esg102_28);
  }, [esg102_28]);

  const handleChanges = e => {
    setEsg102_28({ ...esg102_28, [e.target.id]: e.target.value });
  };

  return (
    <Stack gap={8}>
      <h1>Appartenenza ad associazioni</h1>
      <p>
        Rendicontare le associazioni di settore, le altre associazioni di
        categoria e le organizzazioni di advocacy nazionali o internazionali a
        cui partecipa con un ruolo importante.
      </p>
      <TextArea
        maxCount={2000}
        enableCounter={true}
        placeholder="Descrivere e rendicontare le associazioni e le organizzazioni utilizzando testo libero... "
        onChange={handleChanges}
        value={
          esg102_28['associazioni_settore'] != undefined
            ? esg102_28['associazioni_settore']
            : ''
        }
        id="associazioni_settore"
        labelText="Appartenenza ad Associazioni"
      />

      <h1>
        Azioni legali relative a comportamento anticompetitivo, attività di
        trust e prassi monopolistiche{' '}
      </h1>

      <p>
        La presente informativa si riferisce ad azioni legali iniziate
        nell’ambito di leggi nazionali o internazionali concepite principalmente
        con lo scopo di regolamentare comportamento anticompetitivo, attività di
        trust e prassi monopolistiche.
      </p>

      <TextArea
        maxCount={2000}
        enableCounter={true}
        id="comportamento_anticompetitivo"
        value={
          esg102_28['comportamento_anticompetitivo'] != undefined
            ? esg102_28['comportamento_anticompetitivo']
            : ''
        }
        placeholder="Descrivere con testo libero le principali azioni legali in corso o completate durante il periodo di rendicontazione
      riguardanti il comportamento anticompetitivo e violazioni di leggi anti-trust e contro il
      monopolio in cui l’organizzazione sia stata identificata come partecipante."
        onChange={handleChanges}
        labelText="Azioni legali comportamento anticompetitivo"
      />

      <footer style={{ fontSize: 10, marginRight: 0 }}>
        [GRI 102-28 e GRI 206-1]
      </footer>
    </Stack>
  );
};

export default ESG102_28;
