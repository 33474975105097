import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Select, RadioButton, RadioButtonGroup, TextArea } from '@carbon/react';
import { Stack } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG102_18 = () => {
  const [esg102_18, setEsg102_18] = useState({});
  const [rowData, setRowData] = useState([
    {
      desc: '',
      freq: 0,
      mod: '',
    },
  ]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [isDocumented, setIsDocumented] = useState(false);
  useEffect(() => {
    setEsg102_18({ ...esg102_18, isPresident: isDocumented });
  }, [isDocumented]);
  useEffect(() => {
    setEsg102_18({ ...esg102_18, valutazione_performance: rowData });
  }, [rowData]);
  useEffect(() => {
    pushESGData('102-18', esg102_18);
  }, [esg102_18]);

  useEffect(() => {
    if (esgData['102-18'] != undefined) {
      setEsg102_18(esgData['102-18']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  const handleSelectChange = event => {
    setIsDocumented(event);

    if (!event) {
      setRowData([
        {
          desc: '',
          freq: 0,
          mod: '',
        },
      ]);
    }
  };

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = params => {
    setGridApi(params.api);
  };
  const columnDefs = [
    {
      headerName: 'Descrivere la procedura di valutazione',
      field: 'desc',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Frequenza della valutazione (mesi)',
      field: 'freq',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName:
        'Descrivere le modifiche che sono state apportate dopo aver effettuato la valutazione',
      field: 'mod',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
  };
  const handleChanges = e => {
    setEsg102_18({ ...esg102_18, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Valutazione della performance della Dirigenza</h1>

      <TextArea
        maxCount={500}
        enableCounter={true}
        id="descrizione_misure"
        value={
          esg102_18 != undefined && esg102_18['descrizione_misure'] != undefined
            ? esg102_18['descrizione_misure']
            : ''
        }
        labelText="Descrivere le misure di valutazione in ambito compliance ESG della Dirigenza"
        placeholder="Descrivere con testo libero le principali misure/procedure adottate per la valutazione della compliance ESG"
        onChange={handleChanges}
      />
      <div
        className="ag-theme-alpine"
        style={{
          height: '150px',
          width: '100%',
        }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          onCellValueChanged={onChangeRowValue}
        />
      </div>
      <div />
      <footer style={{ fontSize: 10, marginRight: 0 }}>
        GRI 102-18 / ESRS GOV-2
      </footer>
    </Stack>
  );
};

export default ESG102_18;
