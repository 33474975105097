import {
  Stack,
  Grid,
  TextInput,
  Column,
  StructuredListCell,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListWrapper,
  Button,
  Modal,
} from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconChoice from '../../components/IconChoice';
import {
  ArrowRight,
  EarthSoutheastAsiaFilled,
  FolderOpen,
  FolderShared,
  TaskAdd,
} from '@carbon/react/icons';
import { InfoSection, InfoCard } from '../../components/Info';
import LoadingOverlay from '../../components/Loader';
import { useESG } from '../../action/ESGData/esgdata';
import BuyItem from '../../components/BuyItem';
import { useUser } from '../../action/UserData/UserData';
import { cloneDeep } from 'lodash';
import { update_user_details_firebase } from '../../action/firebase_communication/firebaseManager';
import { auth, storage } from '../../action/fiirebase_config/firebase_config';
import { analytics } from '../../action/fiirebase_config/firebase_config';
import { logEvent } from 'firebase/analytics';
const UserDetails = () => {
  const history = useHistory();
  const [modalBuy, setModalBuy] = useState(false);
  const [numPractices, setNumPratices] = useState(0);
  const { state, updateUserData } = useUser();
  const [isModalUserDataOpen, SetIsModalUserDataOpen] = useState(false);
  const [internalUserOpt, setInternalUserOpt] = useState(cloneDeep(state));

  const navigationHandlerNew = event => {
    //history.push('/new');
    logEvent(analytics, 'PRESS BUY BUTTON');
    if (
      internalUserOpt['pec'] == '' ||
      internalUserOpt['pec'] == undefined ||
      internalUserOpt['sdi'] == undefined ||
      internalUserOpt['sdi'] == ''
    ) {
      SetIsModalUserDataOpen(true);
    } else {
      setModalBuy(true);
    }
  };

  const navigationHandlerHistory = event => {
    history.push('/history');
  };
  const setNewData = () => {
    if (auth.currentUser) {
      setIsLoading(true);
      update_user_details_firebase(auth.currentUser.uid, internalUserOpt)
        .then(() => {
          setIsLoading(false);
        })
        .catch(e => {
          setIsLoading(false);
        });
      updateUserData(internalUserOpt);
      SetIsModalUserDataOpen(false);
      navigationHandlerNew();
    }
  };

  useEffect(() => {
    setInternalUserOpt(cloneDeep(state));
  }, [state]);
  const handleChanges = e => {
    setInternalUserOpt({ ...internalUserOpt, [e.target.id]: e.target.value });
  };
  const closeModal = () => {
    SetIsModalUserDataOpen(false);
  };
  const structuredListGenerator = (data, handleChange) => {
    var slim_report_data = [];

    data.forEach(val => {
      if (val.report_status == 'working') {
        slim_report_data.push(val);
      }
    });

    return slim_report_data.map(n => (
      <StructuredListRow key={`row-${n.id}`}>
        <StructuredListCell>{n.visible_name}</StructuredListCell>
        <StructuredListCell>{n.report_creation_date}</StructuredListCell>
        <StructuredListCell>
          {n.report_status == 'working' ? 'In Lavorazione' : n.report_status}
        </StructuredListCell>
        <StructuredListCell>
          <Button
            kind="ghost"
            onClick={() => handleChange(n.id, n)}
            renderIcon={ArrowRight}
          />
        </StructuredListCell>
      </StructuredListRow>
    ));
  };

  const context_obj = useESG();
  const [reportData, SetReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (id, jsondata) => {
    setIsLoading(true);
    context_obj.setReportIDOnCallback(id, jsondata);
    history.push('esgstd');
  };
  useEffect(() => {
    setIsLoading(true);
    context_obj.return_docs_uid().then(value => {
      if (value == 'KO') {
      } else {
        SetReportData(value);
        if (value.length == 0) {
          console.log('No report');
          setModalBuy(true);
        }
        setIsLoading(false);
      }
    });
  }, []);
  return (
    <Stack>
      <div className="landing-page__r3">
        <Grid>
          <Column lg={10} md={10} sm={10} className="landing-page__r3">
            <h1>Pratiche Attive</h1>
            <p>
              In questa sezione sono presenti le pratiche aperte. <br />
              Clicca sull'icona <ArrowRight /> per aprirle
            </p>
          </Column>
          <Column lg={6} md={6} sm={6} className="landing-page__r3">
            <img
              width="40%"
              src={`${process.env.PUBLIC_URL}/onworking.svg`}
              alt="Pratiche Aperte"
            />
          </Column>
    {/*       <Column lg={10} md={10} sm={10}>
            <Button
              style={{ backgroundColor: 'green' }}
              onClick={navigationHandlerNew}>
              Acquista Nuovo Report
            </Button>
          </Column> */}
          <Column lg={6} md={6} sm={6}>
            <InfoSection heading="Altre Opzioni">
              {/*<IconChoice
        text_icon="Nuovo Report"
        icon={() => <EarthSoutheastAsiaFilled size={64} />}
        onClick={navigationHandlerNew}
/>
      <IconChoice
        text_icon="Pratiche in corso"
        icon={() => <FolderShared size={64} />}
        onClick={navigationHandlerOnWorking}
      />
   */}
              <IconChoice
                text_icon="Pratiche completate"
                icon={() => <FolderOpen size={30} />}
                onClick={navigationHandlerHistory}
              />
            </InfoSection>
          </Column>
        </Grid>
      </div>

      <Column lg={16} md={8} sm={4}>
        <Stack gap={7}>
          <StructuredListWrapper className="OnWorkingContainer">
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Nome Report</StructuredListCell>
                <StructuredListCell head>Data Creazione</StructuredListCell>
                <StructuredListCell head>Stato Report</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              {structuredListGenerator(reportData, handleChange)}
            </StructuredListBody>
          </StructuredListWrapper>
        </Stack>
        {isLoading && (
          <LoadingOverlay main_text={'Caricamento pratiche in corso'} />
        )}
      </Column>

      {isLoading && (
        <LoadingOverlay main_text={'Caricamento pratiche in corso'} />
      )}

      <Modal
        open={modalBuy}
        modalLabel={'Congratulazioni'}
        modalHeading={
          'Sei ad un passo dalla creazione del tuo report di sostenibilità.'
        }
        passiveModal
        onRequestClose={() => setModalBuy(false)}
        size="lg">
        {' '}
        <BuyItem />
      </Modal>
      <Modal
        open={isModalUserDataOpen}
        onRequestClose={closeModal}
        primaryButtonText="Conferma"
        secondaryButtonText="Salta"
        onRequestSubmit={() => setNewData()}
        onSecondarySubmit={closeModal}
        modalHeading={'Buongiorno ' + state['firstname']}
        modalLabel="Informazioni Account">
        <p
          style={{
            marginBottom: '1rem',
          }}>
          Mancano i dati necessari per la fatturazione, per favore assicurati
          che PEC e SDI siano valorizzati. Modifica i tuoi dati e premi su
          Conferma per aggiornarli.
        </p>

        <TextInput
          id="firstname"
          onInput={handleChanges}
          labelText="Nome"
          value={
            internalUserOpt['firstname'] != undefined
              ? internalUserOpt['firstname']
              : ''
          }
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="lastname"
          onChange={handleChanges}
          labelText="Cognome"
          value={
            internalUserOpt['lastname'] != undefined
              ? internalUserOpt['lastname']
              : ''
          }
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="company"
          onChange={handleChanges}
          labelText="Ragione Sociale Azienda"
          value={
            internalUserOpt['company'] != undefined
              ? internalUserOpt['company']
              : ''
          }
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="role"
          onChange={handleChanges}
          labelText="Ruolo Aziendale"
          value={
            internalUserOpt['role'] != undefined ? internalUserOpt['role'] : ''
          }
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="pec"
          onChange={handleChanges}
          labelText="PEC Aziendale"
          value={
            internalUserOpt['pec'] != undefined ? internalUserOpt['pec'] : ''
          }
          style={{
            marginBottom: '1rem',
          }}
        />
        <TextInput
          id="sdi"
          onChange={handleChanges}
          labelText="Codice Destinatario (SDI)"
          value={
            internalUserOpt['sdi'] != undefined ? internalUserOpt['sdi'] : ''
          }
          style={{
            marginBottom: '1rem',
          }}
        />
      </Modal>
    </Stack>
  );
};

export default UserDetails;
