import { storage } from '../fiirebase_config/firebase_config';

const randomString = length => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const create_new_report_firebase = async (uid, clData = null) => {
  try {
    const x = randomString(5);
    const actDate = new Date()
      .toISOString()
      .replace(/[-:.T]/g, '')
      .slice(0, 14);
    const reportId = `${uid}_${x}_${actDate}`;
    console.log(uid);
    const payload = {
      visible_name: reportId,
      id: reportId,
      report_status: 'working',
      report_creation_date: new Date()
        .toLocaleDateString('en-GB')
        .replace(/\//g, '-'),
      report_completion_date: '',
    };

    if (clData && Object.keys(clData).length > 0) {
      payload['102-1'] = clData;
    }
    const reportRef = storage.collection('report_details').doc(reportId);
    await reportRef.set(payload);
    return payload;
  } catch (error) {
    console.error('Errore durante la generazione del report:', error.message);
    throw error;
  }
};

export const update_report_firebase = async (reportID, payload) => {
  try {
    const reportRef = storage.collection('report_details').doc(reportID);
    await reportRef.set(payload);
    return payload;
  } catch (error) {
    console.error('Errore durante l aggiornamento del report:', error.message);
    throw error;
  }
};

const addUserInfo = uid => {
  var payload = {
    company: '',
    firstname: '',
    lastname: '',
    role: '',
  };
  return storage
    .collection('users_details')
    .doc(uid)
    .set(payload);
};
export const manage_user_details_firebase = async uid => {
  try {
    var reportData = await storage
      .collection('users_details')
      .doc(uid)
      .get();
    console.log(reportData.exists);

    if (!reportData.exists) {
      await addUserInfo(uid);
      reportData = await storage
        .collection('users_details')
        .doc(uid)
        .get();
    }

    return reportData.data();
  } catch (error) {
    console.error(
      'Errore durante la ricezione dei dati utente:',
      error.message
    );
    throw error;
  }
};

export const return_reportinfo_by_uid = async uid => {
  try {
    var reportsCollection = storage.collection('report_details');
    //const querySnapshot = await reportsCollection.where(firebase.firestore.FieldPath.documentId(), "array-contains", uid).get();
    const querySnapshot = await reportsCollection
      .where('id', '>=', uid)
      .where('id', '<=', uid + '\uf8ff')
      .get();
    //const querySnapshot = query(reportsCollection, where('id', '>=', uidSubstring).where('id', '<', uidSubstring + '\uf8ff'));

    const reportsData = [];
    console.log(querySnapshot);
    querySnapshot.forEach(doc => {
      if (doc.exists) {
        const report = {
          id: doc.id,
          ...doc.data(),
        };
        reportsData.push(report);
      }
    });

    console.log(reportsData);

    return reportsData;
  } catch (error) {
    console.error(
      'Errore durante la ricezione dei report per UID:',
      error.message
    );
    //throw error;
    return 'KO';
  }
};

export const update_user_details_firebase = async (uid, payload) => {
  try {
    await storage
      .collection('users_details')
      .doc(uid)
      .set(payload);
    console.log("Dati salvati con successo")
    return payload;
  } catch (error) {
    console.error("Errore nell'aggiornamento dei dati utente:", error.message);
    throw error;
  }
};

export const create_esg_report_firebase = async (reportID, esgData) => {
  try {
    if (esgData['report_status'] == 'completed') {
      if (esgData['download'] != undefined) {
        esgData['download'] = esgData['download'] + 1;
      } else {
        esgData['download'] = 1;
      }
    } else {
      esgData['report_status'] = 'completed';
      esgData['report_completion_date'] = new Date()
        .toLocaleDateString('en-GB')
        .replace(/\//g, '-');
      esgData['download'] = 1;
    }

    await update_report_firebase(reportID, esgData);
    return esgData;
  } catch (error) {
    console.error('Errore nel completamento del report:', error.message);
    throw error;
  }
};
