import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Add, Subtract } from '@carbon/icons-react';
import {
  Column,
  Modal,
  Button,
  TextInput,
  Stack,
  RadioButtonGroup,
  RadioButton,
  FormGroup,
  Dropdown,
  FilterableMultiSelect,
  Grid,
  TextArea,
  DatePicker,
} from '@carbon/react';
import { ArrowDown } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
import UploadImage from '../../UploadImage';
const opzioni_settore = [
  { label: 'Settore Pubblico', value: 'settore_pubblico' },
  { label: 'Settore Privato', value: 'settore_privato' },
  { label: 'Settore di Formazione', value: 'settore_formazione' },
  { label: 'Settore Finanziario', value: 'settore_finanziario' },
  { label: 'Agricoltura', value: 'agricoltura' },
  { label: 'Manifattura', value: 'manifattura' },
  { label: "Tecnologia dell'informazione", value: 'tecnologia_informazione' },
  { label: 'Sanità', value: 'sanita' },
  { label: 'Ingegneria e Costruzione', value: 'ingegneria_costruzione' },
  { label: 'Trasporti e Logistica', value: 'trasporti_logistica' },
  { label: 'Energia', value: 'energia' },
  { label: 'Ristorazione', value: 'ristorazione' },
  { label: 'Media e Intrattenimento', value: 'media_intrattenimento' },
  { label: 'Altro', value: 'altro' },
];
function ESG102_6(props) {
  const [esg102_6, setEsg102_6] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-6'] != undefined) {
      setEsg102_6(esgData['102-6']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-6', esg102_6);
  }, [esg102_6]);
  const handleChangesSelect = e => {
    setEsg102_6({ ...esg102_6, settore_operativi: e });
  };
  const retrieveListOfSelectedValues = () => {
    return [opzioni_settore[0], opzioni_settore[1]];
  };
  const handleChanges = e => {
    setEsg102_6({ ...esg102_6, [e.target.id]: e.target.value });
  };
  const setImgData = data => {
    setEsg102_6({ ...esg102_6, ['foto_prodotti']: data });
  };
  const setImgFile = data => {
    setEsg102_6({ ...esg102_6, ['file_fotoProdotti']: data });
  };

  return (
    <Stack gap={8}>
      <h1>Descrizione dei prodotti/servizi</h1>
      <p>
        Quando descrive le sue attività, l’organizzazione dovrebbe rendicontare
        il numero totale di operazioni e spiegare come definisce una
        ‘operazione’. Quando descrive i suoi prodotti e servizi,
        l’organizzazione dovrebbe rendicontare:
        <br />
        • la quantità di prodotti o servizi forniti durante il periodo di
        rendicontazione (per es., il numero di prodotti o servizi forniti, il
        fatturato netto relativo ai prodotti o servizi forniti);
        <br />
        • se vende prodotti o servizi vende prodotti o servizi che sono vietati
        in determinati mercati o che sollevano preoccupazioni o dibattiti
        pubblici tra gli stakeholder, è necessario spiegare il motivo del
        divieto o delle preoccupazioni, oltre a descrivere la risposta
        dell'organizzazione a tali questioni.
        <br />
        Quando descrive i mercati serviti, l’organizzazione può rendicontare:
        <br />
        • le regioni geografiche in cui i prodotti e servizi vengono offerti;
        <br />
        • le caratteristiche demografiche o di altro tipo dei mercati;
        <br />• dati sulle dimensioni e sull’importanza relativa dei mercati
        (per es., fatturato netto, ricavi netti).
      </p>
      <Grid>
        <Column sm={6} md={6} lg={6}>
          <TextArea
            maxCount={1000}
            enableCounter={true}
            id="prodotti_e_servizi"
            value={
              esg102_6['prodotti_e_servizi'] != undefined &&
              esg102_6 != undefined
                ? esg102_6['prodotti_e_servizi']
                : ''
            }
            placeholder="Descrivere con testo libero i principali prodotti/servizi dell'organizzazione e i principali mercati forniti"
            onChange={handleChanges}
            labelText="Prodotti e Servizi"
          />
        </Column>
        <Column sm={6} md={6} lg={6}>
          <UploadImage
            setImgData={setImgData}
            setImgFile={setImgFile}
            selectedItem={
              esgData['102-6'] != undefined &&
              esgData['102-6']['foto_prodotti'] != undefined
                ? esgData['102-6']['foto_prodotti']
                : {}
            }
            filename={'prodotti'}
            reportId={esgData.id}
            tipo={
              "rappresentante i principali prodotti/servizi forniti dall'azienda"
            }
          />
        </Column>
      </Grid>

      <h1>Attività, catena del valore e altri rapporti di business</h1>
      <p>
        La catena del valore dell’organizzazione include la gamma di attività
        svolte sia dall’organizzazione stessa che dalle entità che operano a
        monte e a valle dell’organizzazione, per portare i prodotti e servizi
        dell’organizzazione dalla fase di concezione a quella di uso finale. Le
        entità a monte dell’organizzazione forniscono prodotti o servizi
        utilizzati nello sviluppo di prodotti o servizi dell’organizzazione
        stessa, mentre le entità a valle sono quelle che ricevono
        dall'organizzazione prodotti o servizi Le entità presenti nella catena
        del valore includono quelle oltre il primo livello, sia a monte che a
        valle.
      </p>

      <FilterableMultiSelect
        ariaLabel="Rendicontare i settori di attività dell'azienda"
        id="settori_attivita"
        titleText="Settori di Attività"
        items={opzioni_settore}
        itemToString={item => (item ? item.label : '')}
        label="In quali settori opera ?"
        onChange={handleChangesSelect}
        //initialSelectedItems={()=>retrieveListOfSelectedValues()}
        initialSelectedItems={
          esgData['102-6'] != undefined &&
          esgData['102-6']['settore_operativi'] != undefined &&
          esgData['102-6']['settore_operativi']['selectedItems'] != undefined
            ? esgData['102-6']['settore_operativi']['selectedItems']
            : []
        }
      />

      <h3>Catena del Valore</h3>
      <Stack gap={4}>
        <TextArea
          maxCount={500}
          enableCounter={true}
          id="catena_fornitura"
          value={
            esg102_6['catena_fornitura'] != undefined
              ? esg102_6['catena_fornitura']
              : ''
          }
          placeholder="Descrivere con testo libero la catena di fornitura dell'organizzazione"
          onChange={handleChanges}
          labelText="Catena di fornitura"
        />

        <ArrowDown />

        <TextArea
          maxCount={500}
          enableCounter={true}
          id="entita_valle"
          value={
            esg102_6['entita_valle'] != undefined
              ? esg102_6['entita_valle']
              : ''
          }
          placeholder="Descrivere con testo libero le entità a valle dell'organizzazione e le loro attività"
          onChange={handleChanges}
          labelText="Principali Clienti"
        />
      </Stack>

      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 102-6</footer>
    </Stack>
  );
}

export default ESG102_6;
