import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  Stack,
  Button,
  Tooltip,
  TextArea,
  Grid,
  Column,
  TextInput,
  Select,
} from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
import UploadImage from '../../UploadImage';

const ESG_Obiettivi = () => {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [esg_obiettivi, setesg_obiettivi] = useState();
  const selectOptions = [
    { value: 'Ambientale', label: 'Ambientale' },
    { value: 'Sociale', label: 'Sociale' },
    { value: 'Governance', label: 'Governance' },
    { value: 'Altro', label: 'Altro' },
  ];
  const frameworkComponents = {
    agSelectCellRenderer: Select,
  };
  const columnDefs = [
    {
      headerName: 'Descrizione Obiettivo',
      field: 'obiettivo',
      headerTooltip:
        "Fornisci una descrizione dettagliata dell'obiettivo di sostenibilità, includendo le azioni specifiche che verranno intraprese per raggiungerlo",
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Dimensione',
      headerTooltip:
        "Seleziona la dimensione dell'obiettivo di sostenibilità tra Ambientale, Governance, Sociale o Altro",
      field: 'dimensione',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Ambientale', 'Governance', 'Sociale', 'Altro'],
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      editable: true,
    },
    {
      headerName: 'Termine di Raggiungimento Obiettivo',
      field: 'termine',
      headerTooltip:
        "Inserisci il termine previsto per il raggiungimento dell'obiettivo di sostenibilità",
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];
  useEffect(() => {
    if (esgData['obiettivi'] != undefined) {
      setesg_obiettivi(esgData['obiettivi']);
      if (esgData['obiettivi']['table_obiettivi'] != undefined) {
        setRowData(esgData['obiettivi']['table_obiettivi']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);

  useEffect(() => {
    pushESGData('obiettivi', esg_obiettivi);
  }, [esg_obiettivi]);

  const defaultRowData = {
    obiettivo: '',
    dimensione: '',
    termine: '',
  };

  const onAddRow = () => {
    if (rowData.length < 5) {
      setRowData([...rowData, defaultRowData]);
      setesg_obiettivi({
        ...esg_obiettivi,
        table_obiettivi: [...rowData, defaultRowData],
      });
    }
  };

  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setesg_obiettivi({ ...esg_obiettivi, table_obiettivi: updatedRowData });
    }
  };

  const gridOptions = {
    onRowClicked: event => {
      if (event.event.target.className.includes('delete-row')) {
        onDeleteRow();
      }
    },
  };
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setesg_obiettivi({ ...esg_obiettivi, table_obiettivi: ex_rowData });
    //setForceModified(!forceModified);
  };
  const onGridReady = params => {
    gridApiRef.current = params.api;
  };

  const handleChanges = e => {
    setesg_obiettivi({ ...esg_obiettivi, [e.target.id]: e.target.value });
  };

  return (
    <Stack gap={8}>
      <h1>Obiettivi di sostenibilità ambientale, sociale e di governance</h1>

      <p>
        Rendicontare gli obiettivi che si pone l'organizzazione in termini di
        sostenibilità Ambientale, Sociale e di Governance. è importante
        identificare gli obiettivi specifici di sostenibilità che
        l'organizzazione desidera raggiungere. Questi obiettivi dovrebbero
        essere allineati con le sfide ambientali, sociali ed economiche
        rilevanti per l'azienda e dovrebbero essere misurabili e coerenti con le
        strategie aziendali di breve, medio e lungo termine.
      </p>
      <Grid>
        <Column sm={16} md={16} lg={16}>
          <TextArea
            maxCount={1000}
            enableCounter={true}
            id="rendicontazione_obiettivi"
            value={
              esgData['obiettivi'] != undefined &&
              esgData['obiettivi']['rendicontazione_obiettivi'] != undefined
                ? esgData['obiettivi']['rendicontazione_obiettivi']
                : ''
            }
            placeholder="Rendicontare con testo libero gli obiettivi che si pone l'organizzazione in termini di sostenibilità Ambientale, Sociale e di Governance."
            onChange={handleChanges}
            labelText="Obiettivi ESG"
          />
        </Column>
      </Grid>

      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValue}
          frameworkComponents={frameworkComponents}
        />
        <Button kind="ghost" onClick={onAddRow}>
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga. E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>

      <footer style={{ fontSize: 10, marginRight: 0 }}>Obiettivi ESG</footer>
    </Stack>
  );
};

export default ESG_Obiettivi;
