import AggiornamentoESG from '../../../../assets/html_template/professional/A4/07_6-informativa-generale-gri102-17-aggiornamentoesg.html';
import AggiornamentoESG169 from '../../../../assets/html_template/professional/169/07_6-informativa-generale-gri102-17-aggiornamentoesg.html';
import AggiornamentoESGPopA4 from '../../../../assets/html_template/pop/A4/07_6-informativa-generale-gri102-17-aggiornamentoesg.html';
import AggiornamentoESGPop169 from '../../../../assets/html_template/pop/169/07_6-informativa-generale-gri102-17-aggiornamentoesg.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { Button, Column, Grid } from '@carbon/react';
import { ViewOff } from '@carbon/react/icons';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const AggiornamentoESGEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString =
        type == 'A4' ? AggiornamentoESG : AggiornamentoESG169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? AggiornamentoESGPopA4 : AggiornamentoESGPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString =
        type == 'A4' ? AggiornamentoESG : AggiornamentoESG169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);

  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [descrizione_misure, setDescrizioneMisura] = useState('');
  const [misureTable, setMisureTable] = useState('');

  const [aggiornamentoESGData, SetAggiornamentoESGData] = useState(
    AggiornamentoESG
  );

  const [aggiornamentoESGDataA4, setAggiornamentoESGDataA4] = useState();
  const [aggiornamentoESGData169, setAggiornamentoESGData169] = useState();
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetAggiornamentoESGData(AggiornamentoESGPopA4);
    setAggiornamentoESGDataA4(AggiornamentoESGPopA4);
    setAggiornamentoESGData169(AggiornamentoESGPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetAggiornamentoESGData(AggiornamentoESG);
    setAggiornamentoESGDataA4(AggiornamentoESG);
    setAggiornamentoESGData169(AggiornamentoESG169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetAggiornamentoESGData(AggiornamentoESG);
    setAggiornamentoESGDataA4(AggiornamentoESG);
    setAggiornamentoESGData169(AggiornamentoESG169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAggiornamentoESGData(AggiornamentoESGPopA4);
      setAggiornamentoESGDataA4(AggiornamentoESGPopA4);
      setAggiornamentoESGData169(AggiornamentoESGPop169);
      force_update(
        type == 'A4' ? AggiornamentoESGPopA4 : AggiornamentoESGPop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAggiornamentoESGData(AggiornamentoESG);
      setAggiornamentoESGDataA4(AggiornamentoESG);
      setAggiornamentoESGData169(AggiornamentoESG169);
      force_update(type == 'A4' ? AggiornamentoESG : AggiornamentoESG169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAggiornamentoESGData(AggiornamentoESG);
      setAggiornamentoESGDataA4(AggiornamentoESG);
      setAggiornamentoESGData169(AggiornamentoESG169);
      force_update(type == 'A4' ? AggiornamentoESG : AggiornamentoESG169);
    }
    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('102-17')
    ) {
      setIsVisible(false);
      delPageData('102-17');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(aggiornamentoESGData);
    } else {
      if (
        esgData['102-17'] != undefined &&
        esgData['102-17']['descrizione_misure'] != undefined &&
        esgData['102-17']['descrizione_misure'] != descrizione_misure
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-17'] != undefined &&
        esgData['102-17']['misure_aggiornamentoesg'] != undefined &&
        !compareLists(esgData['102-17']['misure_aggiornamentoesg'], misureTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const popolaTabella = (table, data, ordered_keys) => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      var compareString = '';
      if (type == 'A4') {
        templateHTMLString = aggiornamentoESGData169;
        to_init = '169';
        compareString = AggiornamentoESGPop169;
      } else {
        templateHTMLString = aggiornamentoESGData169;
        to_init = 'A4';
        compareString = AggiornamentoESGPopA4;
      }
      const parser_compare = new DOMParser();
      let orderedKey = ['Procedura', 'Descrizione'];
      const compareHTML = parser_compare.parseFromString(
        compareString,
        'text/html'
      );
      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['102-17'] != undefined &&
        esgData['102-17']['descrizione_misure'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'descrizione_misure'
        );
        const compareElement = compareHTML.getElementById('descrizione_misure');

        fotoLeaderElement.innerHTML = esgData['102-17']['descrizione_misure'];
        setDescrizioneMisura(esgData['102-17']['descrizione_misure']);
      }
      //&&!compareLists(tabellacriticita,esgData["102-17"]["rendicontazione_criticita"])

      if (
        esgData['102-17'] != undefined &&
        esgData['102-17']['misure_aggiornamentoesg'] != undefined
      ) {
        const table = parsedHTML.getElementById('misure_aggiornamentoesg');
        const tableCompare = parsedHTML.getElementById(
          'misure_aggiornamentoesg'
        );

        if (esgData['102-17']['misure_aggiornamentoesg'].length > 0) {
          let datiModificati = esgData['102-17']['misure_aggiornamentoesg'].map(
            elemento => ({
              Procedura: elemento.name,
              Descrizione: elemento.desc,
            })
          );

          popolaTabella(table, datiModificati, orderedKey);
          setMisureTable(
            cloneDeep(esgData['102-17']['misure_aggiornamentoesg'])
          );
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);
      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('102-17', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Conoscenze collettive della Dirigenza',
          initA4: AggiornamentoESGPopA4,
          init169: AggiornamentoESGPop169,
          keys_to_check: ['descrizione_misure', 'misure_aggiornamentoesg'],
          gri: '102-17',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('102-17', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Conoscenze collettive della Dirigenza',
          initA4: AggiornamentoESG,
          init169: AggiornamentoESG169,
          keys_to_check: ['descrizione_misure', 'misure_aggiornamentoesg'],
          gri: '102-17',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? aggiornamentoESGData : templateData;
      const parser = new DOMParser();
      let orderedKey = ['Procedura', 'Descrizione'];
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['102-17'] != undefined &&
          esgData['102-17']['descrizione_misure'] != undefined &&
          esgData['102-17']['descrizione_misure'] != descrizione_misure
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_misure'
          );
          fotoLeaderElement.innerHTML = esgData['102-17']['descrizione_misure'];
          setDescrizioneMisura(esgData['102-17']['descrizione_misure']);
        }
        //&&!compareLists(tabellacriticita,esgData["102-17"]["rendicontazione_criticita"])

        if (
          esgData['102-17'] != undefined &&
          esgData['102-17']['misure_aggiornamentoesg'] != undefined &&
          !compareLists(
            esgData['102-17']['misure_aggiornamentoesg'],
            misureTable
          )
        ) {
          const table = parsedHTML.getElementById('misure_aggiornamentoesg');
          if (esgData['102-17']['misure_aggiornamentoesg'].length > 0) {
            let datiModificati = esgData['102-17'][
              'misure_aggiornamentoesg'
            ].map(elemento => ({
              Procedura: elemento.name,
              Descrizione: elemento.desc,
            }));

            popolaTabella(table, datiModificati, orderedKey);
            setMisureTable(
              cloneDeep(esgData['102-17']['misure_aggiornamentoesg'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['102-17'] != undefined &&
          esgData['102-17']['descrizione_misure'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_misure'
          );
          fotoLeaderElement.innerHTML = esgData['102-17']['descrizione_misure'];
          setDescrizioneMisura(esgData['102-17']['descrizione_misure']);
        }
        //&&!compareLists(tabellacriticita,esgData["102-17"]["rendicontazione_criticita"])

        if (
          esgData['102-17'] != undefined &&
          esgData['102-17']['misure_aggiornamentoesg'] != undefined
        ) {
          const table = parsedHTML.getElementById('misure_aggiornamentoesg');
          if (esgData['102-17']['misure_aggiornamentoesg'].length > 0) {
            let datiModificati = esgData['102-17'][
              'misure_aggiornamentoesg'
            ].map(elemento => ({
              Procedura: elemento.name,
              Descrizione: elemento.desc,
            }));

            popolaTabella(table, datiModificati, orderedKey);
            setMisureTable(
              cloneDeep(esgData['102-17']['misure_aggiornamentoesg'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);
      setIframeURL(blobURL);

      SetAggiornamentoESGData(parsedHTML.documentElement.outerHTML);
      updateDataUrl(blobURL);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AggiornamentoESGEngine;
