import React, { Component, useEffect, useState } from 'react';
import './app.scss';
import { getAnalytics } from 'firebase/analytics';
import app from './action/fiirebase_config/firebase_config';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
// TODO: Add SDKs for Firebase products that you want to use
import { Content, Theme } from '@carbon/react';
import LandingPage from './content/LandingPage';
import RepoPage from './content/RepoPage';
import LoginPage from './content/LoginPage';
import TutorialHeader from './components/TutorialHeader';
import Footer from './components/Footer';
import UserDetails from './content/UserDetails';
import NewReport from './content/NewReport';
import SignOnPage from './content/SignOnPage';
import OnWorking from './content/OnWorking';
import History from './content/History';
import ESGStd from './content/ESGStd';
import Userfront from '@userfront/core';
import ResetPassword from './content/ResetPassword';
import Reset from './content/Reset';
import UserOptions from './content/UserOptions';

import { ESGProvider } from './action/ESGData/esgdata';
import { auth } from './action/fiirebase_config/firebase_config';
import { HTMLProvider } from './action/HTMLContext/HTMLContext';
import { OutputVisualizer } from './components/OutputVisualizer/OutputVisualizer';
import { UserDataProvider } from './action/UserData/UserData';  
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//const analytics = getAnalytics(app);
const App = () => {
  const [canGo, setCanGo] = useState(true);

  auth.onAuthStateChanged(function(user) {
    if (user) {
      setCanGo(false);
    } else {
      setCanGo(true);
    }
  });
  return (
    <BrowserRouter>
    <UserDataProvider>
      <ESGProvider>
        <HTMLProvider>
        <Theme theme="white">
          <TutorialHeader logged={canGo} />
        </Theme>
        <Content>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route
              path="/repos"
              exact
              render={() => (canGo ? <Redirect to="/login" /> : <RepoPage />)}
            />
            <Route path="/login" component={LoginPage} />
            <Route
              path="/main_user"
              exact
              render={() =>
                canGo ? <Redirect to="/login" /> : <UserDetails />
              }
            />
            <Route
  exact
              path="/new/:sessionId" component={NewReport}
             
            />
                    
            <Route path="/signon" component={SignOnPage} />
            <Route
              path="/history"
              exact
              render={() => (canGo ? <Redirect to="/login" /> : <History />)}
            />
            <Route
              path="/onworking"
              exact
              render={() => (canGo ? <Redirect to="/login" /> : <OnWorking />)}
            />
            <Route
              path="/esgstd"
              exact
              render={() => (canGo ? <Redirect to="/login" /> : <ESGStd />)}
            />
            <Route path="/resetlink" component={ResetPassword} />
            <Route path="/reset" component={Reset} />
            <Route
              path="/user"
              exact
              render={() =>
                canGo ? <Redirect to="/login" /> : <UserOptions />
              }
            />
            <Route
              path="/output"
              exact
              render={() =>
                canGo ? <Redirect to="/login" /> : <OutputVisualizer />
              }
            />
          </Switch>
        </Content>
        </HTMLProvider>
      </ESGProvider>
      </UserDataProvider>
    </BrowserRouter>
  );
};

export default App;
