import DettaglioLavoratori from '../../../../assets/html_template/professional/A4/07_5-informativa-generale-gri102-8-dettagliodipendenti.html';
import DettaglioLavoratori169 from '../../../../assets/html_template/professional/169/07_5-informativa-generale-gri102-8-dettagliodipendenti.html';
import DettaglioLavoratoriPopA4 from '../../../../assets/html_template/pop/A4/07_5-informativa-generale-gri102-8-dettagliodipendenti.html';
import DettaglioLavoratoriPop169 from '../../../../assets/html_template/pop/169/07_5-informativa-generale-gri102-8-dettagliodipendenti.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useESG } from '../../../../action/ESGData/esgdata';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
import { cloneDeep } from 'lodash';
const DettaglioLavoratoriEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };

  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString =
        type == 'A4' ? DettaglioLavoratori : DettaglioLavoratori169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? DettaglioLavoratoriPopA4 : DettaglioLavoratoriPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString =
        type == 'A4' ? DettaglioLavoratori : DettaglioLavoratori169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [dettaglioLavoratoriData, setDettaglioLavoratoriData] = useState(
    DettaglioLavoratori
  );
  const [dettaglioLavoratoriDataA4, setdettaglioLavoratoriDataA4] = useState(
    DettaglioLavoratori
  );
  const [dettaglioLavoratoriData169, setdettaglioLavoratoriData169] = useState(
    DettaglioLavoratori169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    setDettaglioLavoratoriData(DettaglioLavoratoriPopA4);
    setdettaglioLavoratoriDataA4(DettaglioLavoratoriPopA4);
    setdettaglioLavoratoriData169(DettaglioLavoratoriPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    setDettaglioLavoratoriData(DettaglioLavoratori);
    setdettaglioLavoratoriDataA4(DettaglioLavoratori);
    setdettaglioLavoratoriData169(DettaglioLavoratori169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    setDettaglioLavoratoriData(DettaglioLavoratori);
    setdettaglioLavoratoriDataA4(DettaglioLavoratori);
    setdettaglioLavoratoriData169(DettaglioLavoratori169);
  }
  const [tableDipendenti, setTableDipendenti] = useState({});
  const [tableNotDipendenti, setTableNotDipendenti] = useState({});

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  const compareLists = (list1, list2) => {
    try {
      // Verifica se le lunghezze delle liste sono diverse
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  useEffect(() => {
    console.log('IN ESG DATA');
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      console.log('SETTO POP');
      setDettaglioLavoratoriData(DettaglioLavoratoriPopA4);
      setdettaglioLavoratoriDataA4(DettaglioLavoratoriPopA4);
      setdettaglioLavoratoriData169(DettaglioLavoratoriPop169);
      force_update(
        type == 'A4' ? DettaglioLavoratoriPopA4 : DettaglioLavoratoriPop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setDettaglioLavoratoriData(DettaglioLavoratori);
      setdettaglioLavoratoriDataA4(DettaglioLavoratori);
      setdettaglioLavoratoriData169(DettaglioLavoratori169);
      force_update(type == 'A4' ? DettaglioLavoratori : DettaglioLavoratori169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setDettaglioLavoratoriData(DettaglioLavoratori);
      setdettaglioLavoratoriDataA4(DettaglioLavoratori);
      setdettaglioLavoratoriData169(DettaglioLavoratori169);
      force_update(type == 'A4' ? DettaglioLavoratori : DettaglioLavoratori169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('102-8')
    ) {
      setIsVisible(false);
      delPageData('102-8');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(dettaglioLavoratoriData);
    } else {
      if (
        esgData['102-7'] != undefined &&
        !compareLists(esgData['102-7'], tableDipendenti)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-8'] != undefined &&
        !compareLists(esgData['102-8'], tableNotDipendenti)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  //prova img
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };

  const updateDataUrl = (formed_url = '') => {
    const ordered_key_lav = ['Nazionalità', 'Uomini', 'Donne', 'Altro'];
    const ordered_key_coll = [
      'tipologiaCollaboratore',
      'numero',
      'mansioniSvolte',
    ];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = dettaglioLavoratoriData169;
        to_init = '169';
      } else {
        templateHTMLString = dettaglioLavoratoriDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (esgData['102-7'] != undefined) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'tabella_dipendenti'
        );
        const filteredData = esgData['102-7']
          .filter(obj => Object.keys(obj).length !== 0)
          .map(obj =>
            Object.fromEntries(
              Object.entries(obj).filter(([key]) =>
                ['maschi', 'female', 'other', 'nationality'].includes(key)
              )
            )
          );
        const remapped_data = filteredData.map(elemento => ({
          Uomini: elemento.maschi,
          Donne: elemento.female,
          Altro: elemento.other,
          Nazionalità: elemento.nationality,
        }));
        popolaTabella(fotoLeaderElement, remapped_data, ordered_key_lav);

        setTableDipendenti(cloneDeep(esgData['102-7']));
        const grafico = parsedHTML.getElementById('chart_dipendenti');
        grafico.textContent = grafico.textContent.replace(
          /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
          ''
        );

        // Inserisci la nuova definizione di data nello script
        //modificare i dati per la percentuale
        grafico.textContent =
          `
            //TOSTART
            var data = ${JSON.stringify(esgData['102-7'], null, 2)};
            //TOEND;
  
          ` + grafico.textContent;
      }
      //&&!compareLists(tabellacriticita,esgData["102-8"]["rendicontazione_criticita"])

      if (esgData['102-8'] != undefined) {
        const table = parsedHTML.getElementById('tabella_collaboratori');

        if (esgData['102-8'].length > 0) {
          popolaTabella(table, esgData['102-8'], ordered_key_coll);
          const grafico = parsedHTML.getElementById('chart_collaboratori');
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
                //TOSTART
                const data2 = ${JSON.stringify(esgData['102-8'], null, 2)};
                //TOEND;
      
              ` + grafico.textContent;
        }
        setTableNotDipendenti(cloneDeep(esgData['102-8']));
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('102-8', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Struttura Organizzativa- Dettaglio dipendenti',
          initA4: DettaglioLavoratoriPopA4,
          init169: DettaglioLavoratoriPop169,
          keys_to_check: ['tabella_dipendenti', 'tabella_collaboratori'],
          gri: '102-8',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('102-8', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Struttura Organizzativa- Dettaglio dipendenti',
          initA4: DettaglioLavoratori,
          init169: DettaglioLavoratori169,
          keys_to_check: ['tabella_dipendenti', 'tabella_collaboratori'],
          gri: '102-8',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    const ordered_key_lav = ['Nazionalità', 'Uomini', 'Donne', 'Altro'];
    const ordered_key_coll = [
      'tipologiaCollaboratore',
      'numero',
      'mansioniSvolte',
    ];
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? dettaglioLavoratoriData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
        if (
          esgData['102-7'] != undefined &&
          !compareLists(esgData['102-7'], tableDipendenti)
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'tabella_dipendenti'
          );
          const filteredData = esgData['102-7']
            .filter(obj => Object.keys(obj).length !== 0)
            .map(obj =>
              Object.fromEntries(
                Object.entries(obj).filter(([key]) =>
                  ['maschi', 'female', 'other', 'nationality'].includes(key)
                )
              )
            );
          const remapped_data = filteredData.map(elemento => ({
            Uomini: elemento.maschi,
            Donne: elemento.female,
            Altro: elemento.other,
            Nazionalità: elemento.nationality,
          }));
          popolaTabella(fotoLeaderElement, remapped_data, ordered_key_lav);

          setTableDipendenti(cloneDeep(esgData['102-7']));
          const grafico = parsedHTML.getElementById('chart_dipendenti');
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
                //TOSTART
                var data =${JSON.stringify(esgData['102-7'], null, 2)};
                //TOEND;
      
              ` + grafico.textContent;
        }

        if (
          esgData['102-8'] != undefined &&
          !compareLists(esgData['102-8'], tableNotDipendenti)
        ) {
          const table = parsedHTML.getElementById('tabella_collaboratori');

          if (esgData['102-8'].length > 0) {
            popolaTabella(table, esgData['102-8'], ordered_key_coll);
            const grafico = parsedHTML.getElementById('chart_collaboratori');
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
                    //TOSTART
                    const data2 = ${JSON.stringify(esgData['102-8'], null, 2)};
                    //TOEND;
          
                  ` + grafico.textContent;
          }
          setTableNotDipendenti(cloneDeep(esgData['102-8']));
        }
      } else {
        if (esgData['102-7'] != undefined) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'tabella_dipendenti'
          );
          const filteredData = esgData['102-7']
            .filter(obj => Object.keys(obj).length !== 0)
            .map(obj =>
              Object.fromEntries(
                Object.entries(obj).filter(([key]) =>
                  ['maschi', 'female', 'other', 'nationality'].includes(key)
                )
              )
            );
          const remapped_data = filteredData.map(elemento => ({
            Uomini: elemento.maschi,
            Donne: elemento.female,
            Altro: elemento.other,
            Nazionalità: elemento.nationality,
          }));
          popolaTabella(fotoLeaderElement, remapped_data, ordered_key_lav);

          setTableDipendenti(cloneDeep(esgData['102-7']));
          const grafico = parsedHTML.getElementById('chart_dipendenti');
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
                //TOSTART
                var data =${JSON.stringify(esgData['102-7'], null, 2)};
                //TOEND;
      
              ` + grafico.textContent;
        }
        //&&!compareLists(tabellacriticita,esgData["102-8"]["rendicontazione_criticita"])

        if (esgData['102-8'] != undefined) {
          const table = parsedHTML.getElementById('tabella_collaboratori');

          if (esgData['102-8'].length > 0) {
            popolaTabella(table, esgData['102-8'], ordered_key_coll);
            const grafico = parsedHTML.getElementById('chart_collaboratori');
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
                    //TOSTART
                    const data2 = ${JSON.stringify(esgData['102-8'], null, 2)};
                    //TOEND;
          
                  ` + grafico.textContent;
          }
          setTableNotDipendenti(cloneDeep(esgData['102-8']));
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      setDettaglioLavoratoriData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };

  return <iframe title="Report" src={iframeURL} style={imageRes} />;
};

export default DettaglioLavoratoriEngine;
