import FornitoriLocali from '../../../../assets/html_template/professional/A4/10-governance_fornitori_locali.html';
import FornitoriLocali169 from '../../../../assets/html_template/professional/169/10-governance_fornitori_locali.html';

import FornitoriLocaliPopA4 from '../../../../assets/html_template/pop/A4/10-governance_fornitori_locali.html';
import FornitoriLocaliPop169 from '../../../../assets/html_template/pop/169/10-governance_fornitori_locali.html';

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
import { cloneDeep } from 'lodash';

const FornitoriLocaliEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? FornitoriLocali : FornitoriLocali169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? FornitoriLocaliPopA4 : FornitoriLocaliPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? FornitoriLocali : FornitoriLocali169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);

  const [strategiaSelezione, setStrategiaSelezione] = useState('');

  const [fornitoriLocaliData, SetFornitoriLocaliData] = useState(
    FornitoriLocali
  );
  const [fornitoriLocaliDataA4, SetFornitoriLocaliDataA4] = useState(
    FornitoriLocali
  );
  const [fornitoriLocaliData169, SetFornitoriLocaliData169] = useState(
    FornitoriLocali169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetFornitoriLocaliData(FornitoriLocaliPopA4);
    SetFornitoriLocaliDataA4(FornitoriLocaliPopA4);
    SetFornitoriLocaliData169(FornitoriLocaliPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetFornitoriLocaliData(FornitoriLocali);
    SetFornitoriLocaliDataA4(FornitoriLocali);
    SetFornitoriLocaliData169(FornitoriLocali169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetFornitoriLocaliData(FornitoriLocali);
    SetFornitoriLocaliDataA4(FornitoriLocali);
    SetFornitoriLocaliData169(FornitoriLocali169);
  }

  const [fornitoriLocaliTable, setFornitoriLocaliTable] = useState([]);
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetFornitoriLocaliData(FornitoriLocaliPopA4);
      SetFornitoriLocaliDataA4(FornitoriLocaliPopA4);
      SetFornitoriLocaliData169(FornitoriLocaliPop169);
      force_update(type == 'A4' ? FornitoriLocaliPopA4 : FornitoriLocaliPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetFornitoriLocaliData(FornitoriLocali);
      SetFornitoriLocaliDataA4(FornitoriLocali);
      SetFornitoriLocaliData169(FornitoriLocali169);
      force_update(type == 'A4' ? FornitoriLocali : FornitoriLocali169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetFornitoriLocaliData(FornitoriLocali);
      SetFornitoriLocaliDataA4(FornitoriLocali);
      SetFornitoriLocaliData169(FornitoriLocali169);
      force_update(type == 'A4' ? FornitoriLocali : FornitoriLocali169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('204-1')
    ) {
      setIsVisible(false);
      delPageData('204-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(fornitoriLocaliData);
    } else {
      if (
        esgData['204-1'] != undefined &&
        esgData['204-1']['impegno_fornitori_locali'] != undefined &&
        esgData['204-1']['impegno_fornitori_locali'] != strategiaSelezione
      ) {
        hasUpdate = true;
      }

      if (
        esgData['204-1'] != undefined &&
        esgData['204-1']['tabella_valutazione_fornitori'] != undefined &&
        !compareLists(
          esgData['204-1']['tabella_valutazione_fornitori'],
          fornitoriLocaliTable
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };

  const updateDataUrl = (formed_url = '') => {
    let ordered_keys = ['Tipologia', 'Portata', '% Locale', 'Totale Annua'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = fornitoriLocaliData169;
        to_init = '169';
      } else {
        templateHTMLString = fornitoriLocaliDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['204-1'] != undefined &&
        esgData['204-1']['impegno_fornitori_locali'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'impegno_fornitori_locali'
        );
        fotoLeaderElement.innerHTML =
          esgData['204-1']['impegno_fornitori_locali'];
        setStrategiaSelezione(esgData['204-1']['impegno_fornitori_locali']);
      }
      if (
        esgData['204-1'] != undefined &&
        esgData['204-1']['tabella_valutazione_fornitori'] != undefined
      ) {
        const table = parsedHTML.getElementById(
          'tabella_valutazione_fornitori'
        );
        if (esgData['204-1']['tabella_valutazione_fornitori'].length > 0) {
          let filtered_data = esgData['204-1'][
            'tabella_valutazione_fornitori'
          ].map(elemento => ({
            '% Locale': elemento.localPercentage,
            'Totale Annua': elemento.annualScope,
            Tipologia: elemento.type,
            Portata: elemento.annualScope,
          }));
          popolaTabella(table, filtered_data, ordered_keys);
          setFornitoriLocaliTable(
            cloneDeep(esgData['204-1']['tabella_valutazione_fornitori'])
          );
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('204-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Valutazione dei Fornitori',
          initA4: FornitoriLocaliPopA4,
          init169: FornitoriLocaliPop169,
          keys_to_check: [
            'tabella_valutazione_fornitori',
            'impegno_fornitori_locali',
          ],
          gri: '204-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('204-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Valutazione dei Fornitori',
          initA4: FornitoriLocali,
          init169: FornitoriLocali169,
          keys_to_check: [
            'tabella_valutazione_fornitori',
            'impegno_fornitori_locali',
          ],
          gri: '204-1',
        });
      }
    }
  };

  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? fornitoriLocaliData : templateData;
      const parser = new DOMParser();
      let ordered_keys = ['Tipologia', 'Portata', '% Locale', 'Totale Annua'];
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['204-1'] != undefined &&
          esgData['204-1']['impegno_fornitori_locali'] != undefined &&
          esgData['204-1']['impegno_fornitori_locali'] != strategiaSelezione
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'impegno_fornitori_locali'
          );
          fotoLeaderElement.innerHTML =
            esgData['204-1']['impegno_fornitori_locali'];
          setStrategiaSelezione(esgData['204-1']['impegno_fornitori_locali']);
        }
        if (
          esgData['204-1'] != undefined &&
          esgData['204-1']['tabella_valutazione_fornitori'] != undefined &&
          !compareLists(
            esgData['204-1']['tabella_valutazione_fornitori'],
            fornitoriLocaliTable
          )
        ) {
          const table = parsedHTML.getElementById(
            'tabella_valutazione_fornitori'
          );
          if (esgData['204-1']['tabella_valutazione_fornitori'].length > 0) {
            let filtered_data = esgData['204-1'][
              'tabella_valutazione_fornitori'
            ].map(elemento => ({
              '% Locale': elemento.localPercentage,
              'Totale Annua': elemento.annualScope,
              Tipologia: elemento.type,
              Portata: elemento.annualScope,
            }));
            popolaTabella(table, filtered_data, ordered_keys);
            setFornitoriLocaliTable(
              cloneDeep(esgData['204-1']['tabella_valutazione_fornitori'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['204-1'] != undefined &&
          esgData['204-1']['impegno_fornitori_locali'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'impegno_fornitori_locali'
          );
          fotoLeaderElement.innerHTML =
            esgData['204-1']['impegno_fornitori_locali'];
          setStrategiaSelezione(esgData['204-1']['impegno_fornitori_locali']);
        }
        if (
          esgData['204-1'] != undefined &&
          esgData['204-1']['tabella_valutazione_fornitori'] != undefined
        ) {
          const table = parsedHTML.getElementById(
            'tabella_valutazione_fornitori'
          );
          if (esgData['204-1']['tabella_valutazione_fornitori'].length > 0) {
            let filtered_data = esgData['204-1'][
              'tabella_valutazione_fornitori'
            ].map(elemento => ({
              '% Locale': elemento.localPercentage,
              'Totale Annua': elemento.annualScope,
              Tipologia: elemento.type,
              Portata: elemento.annualScope,
            }));
            popolaTabella(table, filtered_data, ordered_keys);
            setFornitoriLocaliTable(
              cloneDeep(esgData['204-1']['tabella_valutazione_fornitori'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      setIframeURL(blobURL);

      SetFornitoriLocaliData(parsedHTML.documentElement.outerHTML);
      updateDataUrl(blobURL);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default FornitoriLocaliEngine;
