import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Add, Subtract } from '@carbon/icons-react';
import {
  TextInput,
  Button,
  Stack,
  TextArea,
  Grid,
  Column,
} from '@carbon/react';
import ESGTableDipendenti from '../ESGTableDipendenti/ESGTableDipendenti';
import ESGTableNotDipendenti from '../ESGTableNotDipendenti/ESGTableNotDipendenti';
import UploadImage from '../../UploadImage';
import { useESG } from '../../../action/ESGData/esgdata';

function ESG102_8() {
  const { esgData, pushESGData } = useESG();
  const [esg102_7, setEsg102_7] = useState({});
  const handleChanges = e => {
    setEsg102_7({ ...esg102_7, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    if (esgData['102-7main'] != undefined) {
      setEsg102_7(esgData['102-7main']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);

  useEffect(() => {
    pushESGData('102-7main', esg102_7);
  }, [esg102_7]);

  const setImgData = data => {
    setEsg102_7({ ...esg102_7, ['foto_organigramma']: data });
  };
  const setImgFile = data => {
    setEsg102_7({ ...esg102_7, ['fileOrganigramma']: data });
  };

  return (
    <Stack gap={8}>
      <h1>Struttura e composizione dell'organigramma</h1>
      <p>
        Insieme all'Informativa ESG_102-8, la presente informativa fornisce dati
        approfonditi e utili sull’approccio adottato dall’organizzazione
        riguardo all’occupazione, compresi l’ambito e la natura degli impatti
        risultanti dalle sue prassi di assunzione.
      </p>
      <Grid>
        <Column sm={6} md={6} lg={6}>
          <TextArea
            id="presentazione_team"
            maxCount={500}
            enableCounter={true}
            value={
              esgData['102-7main'] != undefined &&
              esgData['102-7main']['presentazione_team'] != undefined
                ? esgData['102-7main']['presentazione_team']
                : ''
            }
            labelText="Presentazione del Team"
            placeholder="Descrivere brevemente il team"
            onChange={handleChanges}
          />
        </Column>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="didascalia_organigramma"
            value={
              esgData['102-7main'] != undefined &&
              esgData['102-7main']['didascalia_organigramma'] != undefined
                ? esgData['102-7main']['didascalia_organigramma']
                : ''
            }
            onChange={handleChanges}
            labelText="Inserire didascalia per immagine organigramma"
          />
          <UploadImage
            setImgData={setImgData}
            setImgFile={setImgFile}
            selectedItem={
              esgData['102-7main'] != undefined &&
              esgData['102-7main']['foto_organigramma']
                ? esgData['102-7main']['foto_organigramma']
                : {}
            }
            filename={'organigramma'}
            reportId={esgData.id}
            tipo={'Organigramma'}
          />
        </Column>
      </Grid>

      <ESGTableDipendenti />
      <ESGTableNotDipendenti />

      <footer style={{ fontSize: 10, marginRight: 0 }}>
        GRI 102-7 e GRI 102-8
      </footer>
    </Stack>
  );
}

function LargeTextCellEditor(props) {
  const { value, onValueChange } = props;

  const handleTextInputChange = e => {
    onValueChange(e.target.value);
  };

  return <TextInput value={value} onChange={handleTextInputChange} />;
}

export default ESG102_8;
