import companyProfile from '../../../../assets/html_template/professional/A4/01_5-company-profile.html';
import companyProfile169 from '../../../../assets/html_template/professional/169/03-company-profile.html';
import companyProfilePopA4 from '../../../../assets/html_template/pop/A4/03-company-profile.html';
import companyProfilePop169 from '../../../../assets/html_template/pop/169/03-company-profile.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const CompanyProfileEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };

  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? companyProfile : companyProfile169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? companyProfilePopA4 : companyProfilePop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? companyProfile : companyProfile169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [storiaAzienda, setStoriaAzienda] = useState('');
  const [indirizzo, setIndirizzo] = useState('');
  const [codiceAteco, setCodiceAteco] = useState('');
  const [anniattivita, setAnniAttivita] = useState();
  const [numDipendenti, setNumDipendenti] = useState();
  const [nazioniOperative, setNazioniOperative] = useState('');
  const [contattoRendicontazione, setContattoRendicontazione] = useState();
  const [companyProfileData, setCompanyProfileData] = useState(companyProfile);
  const [companyProfileDataA4, setcompanyProfileDataA4] = useState(
    companyProfile
  );
  const [companyProfileData169, setcompanyProfileData169] = useState(
    companyProfile169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    setCompanyProfileData(companyProfilePopA4);
    setcompanyProfileDataA4(companyProfilePopA4);
    setcompanyProfileData169(companyProfilePop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    setCompanyProfileData(companyProfile);
    setcompanyProfileDataA4(companyProfile);
    setcompanyProfileData169(companyProfile169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    setCompanyProfileData(companyProfile);
    setcompanyProfileDataA4(companyProfile);
    setcompanyProfileData169(companyProfile169);
  }
  const [scopoReport, setScopoReport] = useState('');
  const [ragioneSociale, setRagioneSociale] = useState('');

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    //all'aggiornamento
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setCompanyProfileData(companyProfilePopA4);
      setcompanyProfileDataA4(companyProfilePopA4);
      setcompanyProfileData169(companyProfilePop169);
      force_update(type == 'A4' ? companyProfilePopA4 : companyProfilePop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setCompanyProfileData(companyProfile);
      setcompanyProfileDataA4(companyProfile);
      setcompanyProfileData169(companyProfile169);
      force_update(type == 'A4' ? companyProfile : companyProfile169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setCompanyProfileData(companyProfile);
      setcompanyProfileDataA4(companyProfile);
      setcompanyProfileData169(companyProfile169);
      force_update(type == 'A4' ? companyProfile : companyProfile169);
    }
    var hasUpdate = false;
    if (
      esgData['102-1'] != undefined &&
      esgData['102-1']['anni_attivita'] != undefined &&
      esgData['102-1']['anni_attivita'] != anniattivita
    ) {
      hasUpdate = true;
    }
    if (
      esgData['102-1'] != undefined &&
      esgData['102-1']['num_dipendenti'] != undefined &&
      esgData['102-1']['num_dipendenti'] != numDipendenti
    ) {
      hasUpdate = true;
    }
    if (
      esgData['102-1'] != undefined &&
      esgData['102-1']['storia_azienda'] != undefined &&
      esgData['102-1']['storia_azienda'] != storiaAzienda
    ) {
      hasUpdate = true;
    }

    if (
      esgData['102-1'] != undefined &&
      esgData['102-1']['0'] != undefined &&
      esgData['102-1']['0'] != indirizzo
    ) {
      hasUpdate = true;
    }

    if (
      esgData['102-1'] != undefined &&
      esgData['102-1']['codice_ateco'] != undefined &&
      esgData['102-1']['codice_ateco'] != codiceAteco
    ) {
      hasUpdate = true;
    }
    if (
      esgData['102-1'] != undefined &&
      esgData['102-1']['nazioni_operanti'] != undefined &&
      esgData['102-1']['nazioni_operanti'] != nazioniOperative
    ) {
      hasUpdate = true;
    }
    if (
      esgData['102-1'] != undefined &&
      esgData['102-1']['ragione_azienda'] != undefined &&
      esgData['102-1']['ragione_azienda'] != ragioneSociale
    ) {
      hasUpdate = true;
    }

    if (
      esgData['102-3'] != undefined &&
      esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
    ) {
      hasUpdate = true;
    }

    if (
      esgData['102-3'] != undefined &&
      esgData['102-3']['contatto_email'] != undefined &&
      esgData['102-3']['contatto_email'] != contattoRendicontazione
    ) {
      hasUpdate = true;
    }

    if (
      esgData['102-3'] != undefined &&
      esgData['102-3']['scopo_documento'] != undefined &&
      esgData['102-3']['scopo_documento'] != scopoReport
    ) {
      hasUpdate = true;
    }
    if (hasUpdate) {
      renderHTMLtoiFrame();
    }
  }, [esgData]);
  //prova img

  function divideString(inputString) {
    // Verifica se la stringa è più lunga di 500 caratteri
    if (inputString.length > 500) {
      // Estrai la prima parte (primi 500 caratteri)
      let firstPart = inputString.substring(0, 500);

      // Cerca un punto o uno spazio entro una tolleranza di 15 caratteri dalla fine della prima parte
      const delimiterIndex = Math.min(
        firstPart.lastIndexOf('.'),
        firstPart.lastIndexOf(' ')
      );

      // Se trova un punto o uno spazio entro la tolleranza, suddividi la stringa
      if (delimiterIndex !== -1 && delimiterIndex >= 500 - 15) {
        firstPart = firstPart.substring(0, delimiterIndex + 1);
      }

      // La seconda parte inizia dopo la fine della prima parte
      const secondPart = inputString.substring(firstPart.length);

      return [firstPart, secondPart];
    } else {
      // Se la stringa è più corta di 500 caratteri, restituisci la stringa intera come prima parte
      return [inputString, ''];
    }
  }

  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = companyProfileData169;
        to_init = '169';
      } else {
        templateHTMLString = companyProfileDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['102-1'] != undefined &&
        esgData['102-1']['anni_attivita'] != undefined
      ) {
        const anniAttivita = parsedHTML.getElementById('anni_attivita');
        anniAttivita.innerHTML = esgData['102-1']['anni_attivita'];
        setAnniAttivita(esgData['102-1']['anni_attivita']);
      }
      if (
        esgData['102-1'] != undefined &&
        esgData['102-1']['num_dipendenti'] != undefined
      ) {
        const num_dipendenti = parsedHTML.getElementById('num_dipendenti');
        num_dipendenti.innerHTML = esgData['102-1']['num_dipendenti'];
        setNumDipendenti(esgData['102-1']['num_dipendenti']);
      }
      if (
        esgData['102-1'] != undefined &&
        esgData['102-1']['storia_azienda'] != undefined
      ) {
        const stringList = divideString(esgData['102-1']['storia_azienda']);
        const first_500 = parsedHTML.getElementById('first_500');
        first_500.innerHTML = stringList[0];
        const after_500 = parsedHTML.getElementById('after_500');
        after_500.innerHTML = stringList[1];
        setStoriaAzienda(esgData['102-1']['storia_azienda']);
      }
      if (esgData['102-1'] != undefined && esgData['102-1']['0'] != undefined) {
        const sede_legale = parsedHTML.getElementById('sede_legale');
        sede_legale.innerHTML = esgData['102-1']['0'];
        setIndirizzo(esgData['102-1']['0']);
      }

      if (
        esgData['102-1'] != undefined &&
        esgData['102-1']['codice_ateco'] != undefined
      ) {
        const codice_ateco = parsedHTML.getElementById('codice_ateco');
        codice_ateco.innerHTML = esgData['102-1']['codice_ateco'];
        setCodiceAteco(esgData['102-1']['codice_ateco']);
      }
      if (
        esgData['102-1'] != undefined &&
        esgData['102-1']['nazioni_operanti'] != undefined
      ) {
        const naz_ope = parsedHTML.getElementById('nazioni_operative');
        var join_string = '';
        esgData['102-1']['nazioni_operanti']['selectedItems'].forEach(el => {
          join_string = join_string + ' ' + el.label;
        });
        naz_ope.innerHTML = join_string;
        setNazioniOperative(esgData['102-1']['nazioni_operanti']);
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['contatto_email'] != undefined
      ) {
        const contatto_email = parsedHTML.getElementById(
          'contatto_rendicontazione'
        );
        contatto_email.innerHTML = esgData['102-3']['contatto_email'];
        setContattoRendicontazione(esgData['102-3']['contatto_email']);
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['scopo_documento'] != undefined
      ) {
        const contatto_email = parsedHTML.getElementById('scopo_report');
        contatto_email.innerHTML = esgData['102-3']['scopo_documento'];
        setScopoReport(esgData['102-3']['scopo_documento']);
      }
      if (
        esgData['102-1'] != undefined &&
        esgData['102-1']['ragione_azienda'] != undefined
      ) {
        const ragione_sociale = parsedHTML.getElementById('ragione_sociale');
        ragione_sociale.innerHTML = esgData['102-1']['ragione_azienda'];

        setRagioneSociale(esgData['102-1']['ragione_azienda']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('102-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Dettagli Organizzativi',
          initA4: companyProfilePopA4,
          init169: companyProfilePop169,
          keys_to_check: [
            'anni_attivita',
            'num_dipendenti',
            'first_500',
            'sede_legale',
            'codice_ateco',
            'nazioni_operative',
            'contatto_rendicontazione',
            'scopo_report',
            'ragione_sociale',
          ],
          gri: '102-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('102-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Dettagli Organizzativi',
          initA4: companyProfile,
          init169: companyProfile169,
          keys_to_check: [
            'anni_attivita',
            'num_dipendenti',
            'first_500',
            'sede_legale',
            'codice_ateco',
            'nazioni_operative',
            'contatto_rendicontazione',
            'scopo_report',
            'ragione_sociale',
          ],
          gri: '102-1',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? companyProfileData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['anni_attivita'] != undefined &&
          esgData['102-1']['anni_attivita'] != anniattivita
        ) {
          const anniAttivita = parsedHTML.getElementById('anni_attivita');
          anniAttivita.innerHTML = esgData['102-1']['anni_attivita'];
          setAnniAttivita(esgData['102-1']['anni_attivita']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['num_dipendenti'] != undefined &&
          esgData['102-1']['num_dipendenti'] != numDipendenti
        ) {
          const num_dipendenti = parsedHTML.getElementById('num_dipendenti');
          num_dipendenti.innerHTML = esgData['102-1']['num_dipendenti'];
          setNumDipendenti(esgData['102-1']['num_dipendenti']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['storia_azienda'] != undefined &&
          esgData['102-1']['storia_azienda'] != storiaAzienda
        ) {
          const stringList = divideString(esgData['102-1']['storia_azienda']);
          const first_500 = parsedHTML.getElementById('first_500');
          first_500.innerHTML = stringList[0];
          const after_500 = parsedHTML.getElementById('after_500');
          after_500.innerHTML = stringList[1];
          setStoriaAzienda(esgData['102-1']['storia_azienda']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['0'] != undefined &&
          esgData['102-1']['0'] != indirizzo
        ) {
          const sede_legale = parsedHTML.getElementById('sede_legale');
          /*
            var via = '';
            var ncivico = '';
            var citta = '';
            var prov = '';
            var reg = '';
            var cap = '';
            var com = '';
            var naz = '';
            if (esgData["102-1"]["0"].via !== undefined && esgData["102-1"]["0"].via!="" ) {
                via = 'Via ' + esgData["102-1"]["0"].via;
              }
              if (esgData["102-1"]["0"].numeroCivico !== undefined&&esgData["102-1"]["0"].numeroCivico!="" ) {
                ncivico = ' N. ' + esgData["102-1"]["0"].numeroCivico;
              }
              if (esgData["102-1"]["0"].citta !== undefined && esgData["102-1"]["0"].citta!=undefined) {
                com = ', ' + esgData["102-1"]["0"].citta;
              }
              if (esgData["102-1"]["0"].cap !== undefined && esgData["102-1"]["0"].cap!="") {
                cap = ' ' + esgData["102-1"]["0"].cap;
              }
              if (esgData["102-1"]["0"].provincia !== undefined && esgData["102-1"]["0"].provincia!="") {
                prov = ', (' + esgData["102-1"]["0"].provincia+'),';
              }
              if (esgData["102-1"]["0"].regione !== undefined && esgData["102-1"]["0"]!="") {
                reg = ' ' + esgData["102-1"]["0"].regione;
              }
              if (esgData["102-1"]["0"].nazione !== undefined && esgData["102-1"]["0"].nazione!="") {
                naz = ' ,' + esgData["102-1"]["0"].nazione;
              }
              var address_string = via + ncivico + citta + cap + prov + reg + naz;
              */
          sede_legale.innerHTML = esgData['102-1']['0'];
          setIndirizzo(esgData['102-1']['0']);
        }

        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['codice_ateco'] != undefined &&
          esgData['102-1']['codice_ateco'] != codiceAteco
        ) {
          const codice_ateco = parsedHTML.getElementById('codice_ateco');
          codice_ateco.innerHTML = esgData['102-1']['codice_ateco'];
          setCodiceAteco(esgData['102-1']['codice_ateco']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['nazioni_operanti'] != undefined &&
          esgData['102-1']['nazioni_operanti'] != nazioniOperative
        ) {
          const naz_ope = parsedHTML.getElementById('nazioni_operative');
          var join_string = '';
          esgData['102-1']['nazioni_operanti']['selectedItems'].forEach(el => {
            join_string = join_string + ' ' + el.label;
          });
          naz_ope.innerHTML = join_string;
          setNazioniOperative(esgData['102-1']['nazioni_operanti']);
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['contatto_email'] != undefined &&
          esgData['102-3']['contatto_email'] != contattoRendicontazione
        ) {
          const contatto_email = parsedHTML.getElementById(
            'contatto_rendicontazione'
          );
          contatto_email.innerHTML = esgData['102-3']['contatto_email'];
          setContattoRendicontazione(esgData['102-3']['contatto_email']);
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['scopo_documento'] != undefined &&
          esgData['102-3']['scopo_documento'] != scopoReport
        ) {
          const contatto_email = parsedHTML.getElementById('scopo_report');
          contatto_email.innerHTML = esgData['102-3']['scopo_documento'];
          setScopoReport(esgData['102-3']['scopo_documento']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['ragione_azienda'] != undefined &&
          esgData['102-1']['ragione_azienda'] != ragioneSociale
        ) {
          const ragione_sociale = parsedHTML.getElementById('ragione_sociale');
          ragione_sociale.innerHTML = esgData['102-1']['ragione_azienda'];

          setRagioneSociale(esgData['102-1']['ragione_azienda']);
        }
      } else {
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['anni_attivita'] != undefined
        ) {
          const anniAttivita = parsedHTML.getElementById('anni_attivita');
          anniAttivita.innerHTML = esgData['102-1']['anni_attivita'];
          setAnniAttivita(esgData['102-1']['anni_attivita']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['num_dipendenti'] != undefined
        ) {
          const num_dipendenti = parsedHTML.getElementById('num_dipendenti');
          num_dipendenti.innerHTML = esgData['102-1']['num_dipendenti'];
          setNumDipendenti(esgData['102-1']['num_dipendenti']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['storia_azienda'] != undefined
        ) {
          const stringList = divideString(esgData['102-1']['storia_azienda']);
          const first_500 = parsedHTML.getElementById('first_500');
          first_500.innerHTML = stringList[0];
          const after_500 = parsedHTML.getElementById('after_500');
          after_500.innerHTML = stringList[1];
          setStoriaAzienda(esgData['102-1']['storia_azienda']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['0'] != undefined
        ) {
          const sede_legale = parsedHTML.getElementById('sede_legale');
          sede_legale.innerHTML = esgData['102-1']['0'];
          setIndirizzo(esgData['102-1']['0']);
        }

        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['codice_ateco'] != undefined
        ) {
          const codice_ateco = parsedHTML.getElementById('codice_ateco');
          codice_ateco.innerHTML = esgData['102-1']['codice_ateco'];
          setCodiceAteco(esgData['102-1']['codice_ateco']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['nazioni_operanti'] != undefined
        ) {
          const naz_ope = parsedHTML.getElementById('nazioni_operative');
          var join_string = '';
          esgData['102-1']['nazioni_operanti']['selectedItems'].forEach(el => {
            join_string = join_string + ' ' + el.label;
          });
          naz_ope.innerHTML = join_string;
          setNazioniOperative(esgData['102-1']['nazioni_operanti']);
        }

        if (esgData['102-3'] != undefined) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['contatto_email'] != undefined
        ) {
          const contatto_email = parsedHTML.getElementById(
            'contatto_rendicontazione'
          );
          contatto_email.innerHTML = esgData['102-3']['contatto_email'];
          setContattoRendicontazione(esgData['102-3']['contatto_email']);
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['scopo_documento'] != undefined
        ) {
          const contatto_email = parsedHTML.getElementById('scopo_report');
          contatto_email.innerHTML = esgData['102-3']['scopo_documento'];
          setScopoReport(esgData['102-3']['scopo_documento']);
        }
        if (
          esgData['102-1'] != undefined &&
          esgData['102-1']['ragione_azienda'] != undefined
        ) {
          const ragione_sociale = parsedHTML.getElementById('ragione_sociale');
          ragione_sociale.innerHTML = esgData['102-1']['ragione_azienda'];

          setRagioneSociale(esgData['102-1']['ragione_azienda']);
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      setCompanyProfileData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };

  return <iframe title="Report" src={iframeURL} style={imageRes} />;
};

export default CompanyProfileEngine;
