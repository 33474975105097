import NonDiscriminazione from '../../../../assets/html_template/professional/A4/12-sociale-gri406-1-discriminazione.html';
import NonDiscriminazione169 from '../../../../assets/html_template/professional/169/12-sociale-gri406-1-discriminazione.html';
import NonDiscriminazionePopA4 from '../../../../assets/html_template/pop/A4/12-sociale-gri406-1-discriminazione.html';
import NonDiscriminazionePop169 from '../../../../assets/html_template/pop/169/12-sociale-gri406-1-discriminazione.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const NonDiscriminazioneEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString =
        type == 'A4' ? NonDiscriminazione : NonDiscriminazione169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? NonDiscriminazionePopA4 : NonDiscriminazionePop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString =
        type == 'A4' ? NonDiscriminazione : NonDiscriminazione169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [misureND, setMisureND] = useState('');

  const [NDData, SetNDData] = useState(NonDiscriminazione);
  const [NDDataA4, setNDDataA4] = useState(NonDiscriminazione);
  const [NDData169, setNDData169] = useState(NonDiscriminazione169);
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetNDData(NonDiscriminazionePopA4);
    setNDDataA4(NonDiscriminazionePopA4);
    setNDData169(NonDiscriminazionePop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetNDData(NonDiscriminazione);
    setNDDataA4(NonDiscriminazione169);
    setNDData169(NonDiscriminazione169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetNDData(NonDiscriminazione);
    setNDDataA4(NonDiscriminazione169);
    setNDData169(NonDiscriminazione169);
  }
  const [episodiTable, setEpisodiTable] = useState([]);
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetNDData(NonDiscriminazionePopA4);
      setNDDataA4(NonDiscriminazionePopA4);
      setNDData169(NonDiscriminazionePop169);
      force_update(
        type == 'A4' ? NonDiscriminazionePopA4 : NonDiscriminazionePop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetNDData(NonDiscriminazione);
      setNDDataA4(NonDiscriminazione);
      setNDData169(NonDiscriminazione169);
      force_update(type == 'A4' ? NonDiscriminazione : NonDiscriminazione169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetNDData(NonDiscriminazione);
      setNDDataA4(NonDiscriminazione);
      setNDData169(NonDiscriminazione169);
      force_update(type == 'A4' ? NonDiscriminazione : NonDiscriminazione169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('406-1')
    ) {
      setIsVisible(false);
      delPageData('406-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(NDData);
    } else {
      if (
        esgData['406-1'] != undefined &&
        esgData['406-1']['misure_non_discriminazione'] != undefined &&
        esgData['406-1']['misure_non_discriminazione'] != misureND
      ) {
        hasUpdate = true;
      }
      if (
        esgData['406-1'] != undefined &&
        esgData['406-1']['tabella_episodi'] != undefined &&
        !compareLists(esgData['406-1']['tabella_episodi'], episodiTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = ['Epsodio', 'Numero Episodi', 'Misure Adottate'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = NDData169;
        to_init = '169';
      } else {
        templateHTMLString = NDDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['406-1'] != undefined &&
        esgData['406-1']['misure_non_discriminazione'] != undefined
      ) {
        const stringList = esgData['406-1']['misure_non_discriminazione'];
        const first_500 = parsedHTML.getElementById(
          'misure_non_discriminazione'
        );
        first_500.innerHTML = stringList;
        setMisureND(esgData['406-1']['misure_non_discriminazione']);
      }

      if (
        esgData['406-1'] != undefined &&
        esgData['406-1']['tabella_episodi'] != undefined
      ) {
        const table = parsedHTML.getElementById('tabella_episodi');
        if (esgData['406-1']['tabella_episodi'].length > 0) {
          const filtered_data = esgData['406-1']['tabella_episodi'].map(
            elemento => ({
              Episodio: elemento.episodio,
              'Numero Episodi': elemento.numepisodi,
              'Misure Adottate': elemento.measures,
            })
          );
          popolaTabella(table, filtered_data, ordered_keys);
          setEpisodiTable(cloneDeep(esgData['406-1']['tabella_episodi']));
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('406-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Episodi di Discriminazione',
          initA4: NonDiscriminazionePopA4,
          init169: NonDiscriminazionePop169,
          keys_to_check: ['misure_non_discriminazione', 'tabella_episodi'],
          gri: '406-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('406-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Episodi di Discriminazione',
          initA4: NonDiscriminazione,
          init169: NonDiscriminazione169,
          keys_to_check: ['misure_non_discriminazione', 'tabella_episodi'],
          gri: '406-1',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const ordered_keys = ['Epsodio', 'Numero Episodi', 'Misure Adottate'];
      const templateHTMLString = templateData == '' ? NDData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['406-1'] != undefined &&
          esgData['406-1']['misure_non_discriminazione'] != undefined &&
          esgData['406-1']['misure_non_discriminazione'] != misureND
        ) {
          const stringList = esgData['406-1']['misure_non_discriminazione'];
          const first_500 = parsedHTML.getElementById(
            'misure_non_discriminazione'
          );
          first_500.innerHTML = stringList;
          setMisureND(esgData['406-1']['misure_non_discriminazione']);
        }

        if (
          esgData['406-1'] != undefined &&
          esgData['406-1']['tabella_episodi'] != undefined &&
          !compareLists(esgData['406-1']['tabella_episodi'], episodiTable)
        ) {
          const table = parsedHTML.getElementById('tabella_episodi');
          if (esgData['406-1']['tabella_episodi'].length > 0) {
            const filtered_data = esgData['406-1']['tabella_episodi'].map(
              elemento => ({
                Episodio: elemento.episodio,
                'Numero Episodi': elemento.numepisodi,
                'Misure Adottate': elemento.measures,
              })
            );
            popolaTabella(table, filtered_data, ordered_keys);
            setEpisodiTable(cloneDeep(esgData['406-1']['tabella_episodi']));
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['406-1'] != undefined &&
          esgData['406-1']['misure_non_discriminazione'] != undefined
        ) {
          const stringList = esgData['406-1']['misure_non_discriminazione'];
          const first_500 = parsedHTML.getElementById(
            'misure_non_discriminazione'
          );
          first_500.innerHTML = stringList;
          setMisureND(esgData['406-1']['misure_non_discriminazione']);
        }

        if (
          esgData['406-1'] != undefined &&
          esgData['406-1']['tabella_episodi'] != undefined
        ) {
          const table = parsedHTML.getElementById('tabella_episodi');
          if (esgData['406-1']['tabella_episodi'].length > 0) {
            const filtered_data = esgData['406-1']['tabella_episodi'].map(
              elemento => ({
                Episodio: elemento.episodio,
                'Numero Episodi': elemento.numepisodi,
                'Misure Adottate': elemento.measures,
              })
            );
            popolaTabella(table, filtered_data, ordered_keys);
            setEpisodiTable(cloneDeep(esgData['406-1']['tabella_episodi']));
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);
      SetNDData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default NonDiscriminazioneEngine;
