import React, { useState, useEffect, useRef } from 'react';
import { Information } from '@carbon/icons-react';
import { Stack, Button, Tooltip } from '@carbon/react';
import { AgGridReact } from 'ag-grid-react';
import { useESG } from '../../../action/ESGData/esgdata';
const initialData = [
  { anno: 2022, totaleFornitori: 0, fornitoriSelezionati: 0 },
];

function ESG308_1() {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState(initialData);
  const { esgData, pushESGData, refreshESGData } = useESG();

  const addRow = () => {
    setRowData([
      ...rowData,
      { anno: 2022, totaleFornitori: 0, fornitoriSelezionati: 0 },
    ]);
  };

  const columnDefs = [
    {
      headerName: 'Anno Riferimento',
      field: 'anno',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: 'Numero Totale Fornitori',
      field: 'totaleFornitori',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: 'Numero Fornitori selezionati secondo criteri ambientali',
      field: 'fornitoriSelezionati',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
  ];
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
  };
  useEffect(() => {
    if (esgData['308-1'] != undefined) {
      setRowData(esgData['308-1']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('308-1', rowData);
  }, [rowData]);
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };

  return (
    <Stack gap={8}>
      <h1>GRI 308-1</h1>
      <h2>Valutazione ambientale dei fornitori</h2>
      <p>
        Questa informativa presenta agli stakeholder la percentuale di fornitori
        selezionati o con i quali si è stipulato un contratto soggetti a
        processi di due diligence in relazione agli impatti ambientali. Ci si
        aspetta che un’organizzazione inizi la due diligence quanto prima
        possibile nello sviluppo di un nuovo rapporto con un fornitore. Gli
        impatti possono essere prevenuti o mitigati nella fase di strutturazione
        di contratti o altri accordi, oltre che attraverso una collaborazione
        continua con i fornitori.
      </p>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValue}
        />
        <Button kind="ghost" onClick={addRow}>
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
    </Stack>
  );
}

export default ESG308_1;
