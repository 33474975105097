import React, { Component } from 'react';

const LoadingOverlay = props => {
  return (
    <div className="loading-overlay">
      <div className="overlay-content">
        <div className="spinner" />
        <p>
          {props.main_text == undefined
            ? 'Stiamo preparando i temi materiali per il tuo report'
            : props.main_text}
        </p>
        <label>Attendi...</label>
      </div>
    </div>
  );
};

export default LoadingOverlay;
