import { Button, Column, Row, Grid, Stack } from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { Document, Outline, Page, Thumbnail } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { useESG } from '../../../action/ESGData/esgdata';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {
  ArrowRight,
  ArrowLeft,
  TabletLandscape,
  Tablet,
  CheckmarkOutline,
} from '@carbon/react/icons';
function TemplateChoice(props) {
  const [selectedTemplate, setSelectedTemplate] = useState(-1);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const { esgData, pushESGData, refreshESGData } = useESG();
  const linearToAdd = [
    {
      id: 0,
      name: 'Linear',
      desc:
        "Un template semplice ed accattivante, volto a mettere in risalto i risultati in termini aziendali sull'impegno rivolto all'ambiente",
      name_copertina: 'linear',
      name_sample: 'linear',
    },
  ];
  const template_data = [
    {
      id: 0,
      name: 'Pop',
      desc:
        "Un template esuberante e giovanile, adatto a mostrare l'impegno ESG in ambienti dinamici",
      name_copertina: 'pop',
      name_sample: 'pop',
    },

    {
      id: 1,
      name: 'Professional',
      desc: 'Template adatto a realtà aziendali affermate sul territorio.',
      name_copertina: 'professional',
      name_sample: 'professional',
    },
  ];

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const [templateVersion, setTemplateVersion] = useState('A4');

  function onDocumentLoadSuccess(numPages) {
    setNumPages(numPages._transport._numPages);
  }

  function changePageoff(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePageoff(-1);
  }

  function nextPage() {
    changePageoff(1);
  }
  const selectTemplate = (id, sample) => {
    setSelectedTemplate(id);
    setSelectedTemplateName(`${sample}.pdf`);
    setPageNumber(1);
  };

  const changeVisualVersion = () => {
    setPageNumber(1);
    if (templateVersion === 'A4') {
      setTemplateVersion('169');
    } else {
      setTemplateVersion('A4');
    }
  };

  const signTemplate = () => {
    if (
      esgData['template'] != undefined &&
      esgData['template']['variante_cover'] != undefined &&
      esgData['template']['variante_cover'] != ''
    ) {
      template_data[selectedTemplate]['variante_cover'] =
        esgData['template']['variante_cover'];
    }
    pushESGData('template', template_data[selectedTemplate]);
  };

  return (
    <Stack gap={8}>
      <h1>I Template di VASTUM ESG</h1>
      <div>
        <p>
          I report generabili tramite piattaforma sono altamente customizzabili.
          <br />
          Seleziona un template per iniziare a compilare il tuo bilancio di
          sostenibilità.
        </p>
      </div>

      <Stack gap={7}>
        <Grid fullWidth>
          <Column sm={8} md={8} lg={8}>
            {template_data.map((template, index) => (
              <div
                onClick={() =>
                  selectTemplate(template.id, template.name_sample)
                }
                key={template.id}>
                <div
                  className={` 'card card-block-template' ${
                    index === selectedTemplate
                      ? ' card card-block-template selected-template'
                      : ' card card-block-template'
                  }`}>
                  <h4>{template.name}</h4>
                  <small>{template.desc}</small>
                </div>
              </div>
            ))}
          </Column>
          <Column sm={6} md={6} lg={6}>
            <Document
              style={{ paddingTop: '15px' }}
              file={
                selectedTemplateName != ''
                  ? `${
                      process.env.PUBLIC_URL
                    }/PDFTemplate/${templateVersion}/${selectedTemplateName}`
                  : ''
              }
              loading={'Caricamento file in corso...'}
              noData={"Seleziona un template per visualizzarne l'anteprima"}
              onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                wrap
                height={templateVersion == 'A4' ? 450 : 250}
                key={`page_${pageNumber}`}
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={1.2}
              />
            </Document>
            <Row
              style={{
                visibility: selectedTemplateName !== '' ? 'visible' : 'hidden',
                width: '100%',
              }}>
              <Column sm={6} md={6} lg={6}>
                <div>
                  <small>
                    Pagina {pageNumber || (numPages ? 1 : '--')} di{' '}
                    {numPages || '--'}
                  </small>
                  <br />
                  <Button
                    size="sm"
                    kind="tertiary"
                    renderIcon={ArrowLeft}
                    hasIconOnly
                    disabled={pageNumber <= 1}
                    onClick={previousPage}>
                    Previous
                  </Button>
                  <Button
                    size="sm"
                    kind="tertiary"
                    renderIcon={ArrowRight}
                    hasIconOnly
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}>
                    Next
                  </Button>
                </div>
              </Column>
              <Column sm={6} md={6} lg={6}>
                <p />
                <br />
                <Button
                  size="sm"
                  kind="tertiary"
                  renderIcon={
                    templateVersion == 'A4' ? TabletLandscape : Tablet
                  }
                  onClick={changeVisualVersion}
                  iconDescription="Clicca qui per visualizzare le altre versioni del template!">
                  {templateVersion == 'A4' ? '16:9' : 'A4'}
                </Button>
              </Column>
            </Row>
          </Column>
        </Grid>
      </Stack>
      <div style={{ marginLeft: '75%' }}>
        <Button
          disabled={selectedTemplate == -1 ? true : false}
          size="sm"
          renderIcon={CheckmarkOutline}
          kind="secondary"
          onClick={signTemplate}>
          Seleziona template
        </Button>
      </div>
    </Stack>
  );
}

export default TemplateChoice;
