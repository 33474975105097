import React from 'react';
import { Grid, Column } from '@carbon/react';
import { InfoSection, InfoCard } from '../../components/Info';
import { useHistory } from 'react-router-dom';
import { Globe, Application, PersonFavorite } from '@carbon/react/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from '@carbon/react';
import { analytics } from '../../action/fiirebase_config/firebase_config';
import { logEvent } from 'firebase/analytics';
import background from '../../assets/img_background_landing.jpg';
const LandingPage = () => {
  const history = useHistory();
  const navigateForward = () => {
    logEvent(analytics, 'PRESS MAIN BUTTON');
    history.push('main_user');
  };
  /*style={{ 
      backgroundImage: `url(${background})` 
    }} */
  return (
    <Grid className="landing-page" fullWidth>
      <Column lg={16} md={8} sm={4} className="landing-page__banner">
        <Column md={4} lg={7} sm={4} className="landing-page__tab-content">
          <h1 className="landing-page__heading" />
        </Column>
        <Column
          lg={8}
          md={8}
          sm={8}
          className="landing-page__r3"
          style={{ backgroundColor: 'none' }}>
          <h2 className="landing-page__heading" style={{ color: 'black' }}>
            Scopri il partner ideale per aziende che guidano il cambiamento
            verso un futuro sostenibile.{' '}
          </h2>
        </Column>
      </Column>
      <Column lg={16} md={8} sm={4} className="landing-page__r2">
        <Tabs defaultSelectedIndex={0}>
          <TabList className="tabs-group" aria-label="Tab navigation">
            <Tab>Qualità</Tab>
            <Tab>Semplicità</Tab>
            <Tab>Convenienza</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Grid className="tabs-group-content">
                <Column
                  md={4}
                  lg={7}
                  sm={6}
                  className="landing-page__tab-content">
                  <h2 className="landing-page__subheading">
                    Qualità e sicurezza al primo posto
                  </h2>
                  <p className="landing-page__p">
                    La nostra priorità assoluta è garantire la massima qualità
                    del software e la sicurezza dei vostri dati. Siamo impegnati
                    a fornire un servizio affidabile e in linea con i più
                    elevati standard di settore.
                  </p>
                </Column>
                <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
                  <img
                    className="landing-page__illo_img"
                    src={process.env.PUBLIC_URL + '/among_nature.svg'}
                    alt="Affidabilità"
                  />
                </Column>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid className="tabs-group-content">
                <Column
                  lg={7}
                  md={4}
                  sm={4}
                  className="landing-page__tab-content">
                  <h2 className="landing-page__subheading">
                    Semplicità ed efficienza a portata di clic
                  </h2>
                  <p className="landing-page__p">
                    Mettiamo a vostra disposizione strumenti intuitivi che vi
                    guideranno passo dopo passo attraverso il processo,
                    consentendovi di raccogliere, analizzare e presentare dati
                    di sostenibilità in modo accurato ed efficace.
                  </p>
                </Column>
                <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
                  <img
                    className="landing-page__illo_img"
                    src={`${process.env.PUBLIC_URL}/green_plant+.svg`}
                    alt="Strumenti intuitivi"
                  />
                </Column>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid className="tabs-group-content">
                <Column
                  lg={7}
                  md={4}
                  sm={4}
                  className="landing-page__tab-content">
                  <h2 className="landing-page__subheading">
                    Report ESG reso accessibile
                  </h2>
                  <p className="landing-page__p">
                    Redigere report di sostenibilità non è mai stato così
                    conveniente. Grazie alla piattaforma VASTUM ESG, tutte le
                    tipologie di aziende avranno la possibilità di creare ad un
                    costo contenuto il proprio report di sostenibilità.
                  </p>
                </Column>
                <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
                  <img
                    className="landing-page__illo_img"
                    src={`${process.env.PUBLIC_URL}/eco-friendly.svg`}
                    alt="Green First of All"
                  />
                </Column>
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Column>

      <Column>
        <div className="verticalCenter">
          <Button className="startButton" onClick={navigateForward}>
            Inizia il tuo viaggio verso la sostenibilità
          </Button>
        </div>
      </Column>

      <Column lg={16} md={8} sm={4} className="landing-page__r3">
        <InfoSection heading="I Principi" className="landing-page__r3">
          <InfoCard
            heading="Un futuro migliore"
            body="IUn futuro migliore è possibile quando l'impegno per la sostenibilità si traduce in azioni concrete. VASTUM ESG è il tuo partner ideale per concretizzare il tuo impegno."
            icon={() => <PersonFavorite size={32} />}
          />
          <InfoCard
            heading="Memoria Sotenibile"
            body="VASTUM ESG conserva lo storico dei report passati per costruire un futuro ancora più responsabile, grazie alla consapevolezza del passato."
            icon={() => <Application size={32} />}
          />
          <InfoCard
            heading="Democratizzare la sostenibilità"
            body="VASTUM ESG offre soluzioni di qualità a costi accessibili, rendendo la sostenibilità alla portata di tutte le aziende."
            icon={() => <Globe size={32} />}
          />
        </InfoSection>
      </Column>
    </Grid>
  );
};

export default LandingPage;
