import LetteraApertura from '../../../../assets/html_template/professional/A4/03-lettera-apertura.html';
import LetteraApertura169 from '../../../../assets/html_template/professional/169/04-lettera-apertura.html';
import LetteraAperturaPopA4 from '../../../../assets/html_template/pop/A4/04-lettera-apertura.html';
import LetteraAperturaPop169 from '../../../../assets/html_template/pop/169/04-lettera-apertura.html';

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { Button, Column, Grid } from '@carbon/react';
import { ViewOff } from '@carbon/react/icons';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';

const MessaggioCeoEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState();
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? LetteraApertura : LetteraApertura169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? LetteraAperturaPopA4 : LetteraAperturaPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? LetteraApertura : LetteraApertura169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [letteraApertura, setLetteraApertua] = useState('');
  const [citazioneCeo, setCitazioneCeo] = useState('');
  const [fotoCeo, setFotoCeo] = useState('');
  const [firmaCeo, setFirmaCeo] = useState('');
  const [nomeLeader, setNomeLeader] = useState('');
  const [letteraAperturaData, setLetteraAperturaData] = useState(
    LetteraApertura
  );
  const [letteraAperturaDataA4, setletteraAperturaA4] = useState(
    LetteraApertura
  );
  const [letteraAperturaData169, setletteraApertura169] = useState(
    LetteraApertura169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    setLetteraAperturaData(LetteraAperturaPopA4);
    setletteraAperturaA4(LetteraAperturaPopA4);
    setletteraApertura169(LetteraAperturaPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    setLetteraAperturaData(LetteraApertura);
    setletteraAperturaA4(LetteraApertura);
    setletteraApertura169(LetteraApertura169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    setLetteraAperturaData(LetteraApertura);
    setletteraAperturaA4(LetteraApertura);
    setletteraApertura169(LetteraApertura169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');
  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const imgRef = useRef();
  const { state, updatePageData, delPageData } = useHTML();
  useEffect(() => {
    //all'aggiornamento
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setLetteraAperturaData(LetteraAperturaPopA4);
      setletteraAperturaA4(LetteraAperturaPopA4);
      setletteraApertura169(LetteraAperturaPop169);
      force_update(type == 'A4' ? LetteraAperturaPopA4 : LetteraAperturaPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setLetteraAperturaData(LetteraApertura);
      setletteraAperturaA4(LetteraApertura);
      setletteraApertura169(LetteraApertura169);
      force_update(type == 'A4' ? LetteraApertura : LetteraApertura169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setLetteraAperturaData(LetteraApertura);
      setletteraAperturaA4(LetteraApertura);
      setletteraApertura169(LetteraApertura169);
      force_update(type == 'A4' ? LetteraApertura : LetteraApertura169);
    }
    if (
      esgData['102-22'] != undefined &&
      esgData['102-22']['lettera_apertura'] != letteraApertura
    ) {
      setLetteraApertua(esgData['102-22']['lettera_apertura']);
      hasUpdate = true;
    }
    if (
      esgData['102-22'] != undefined &&
      esgData['102-22']['citazione_ceo'] != citazioneCeo
    ) {
      setCitazioneCeo(esgData['102-22']['citazione_ceo']);
      hasUpdate = true;
    }
    if (
      esgData['102-22'] != undefined &&
      esgData['102-22']['foto_ceo'] != fotoCeo
    ) {
      setFotoCeo(esgData['102-22']['foto_ceo']);
      hasUpdate = true;
    }
    if (
      esgData['102-22'] != undefined &&
      esgData['102-22']['firma_ceo'] != firmaCeo
    ) {
      setFirmaCeo(esgData['102-22']['firma_ceo']);
      hasUpdate = true;
    }

    if (
      esgData['102-22'] != undefined &&
      esgData['102-22']['nome_leader'] != nomeLeader
    ) {
      setNomeLeader(esgData['102-22']['nome_leader']);
      hasUpdate = true;
    }

    if (
      esgData['102-3'] != undefined &&
      esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
    ) {
      setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      hasUpdate = true;
    }

    if (hasUpdate) {
      renderHTMLtoiFrame();
    }
  }, [esgData]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  function divideString(inputString) {
    // Verifica se la stringa è più lunga di 500 caratteri
    if (inputString.length > 500) {
      // Estrai la prima parte (primi 500 caratteri)
      let firstPart = inputString.substring(0, 500);

      // Cerca un punto o uno spazio entro una tolleranza di 15 caratteri dalla fine della prima parte
      const delimiterIndex = Math.min(
        firstPart.lastIndexOf('.'),
        firstPart.lastIndexOf(' ')
      );

      // Se trova un punto o uno spazio entro la tolleranza, suddividi la stringa
      if (delimiterIndex !== -1 && delimiterIndex >= 500 - 15) {
        firstPart = firstPart.substring(0, delimiterIndex + 1);
      }

      // La seconda parte inizia dopo la fine della prima parte
      const secondPart = inputString.substring(firstPart.length);

      return [firstPart, secondPart];
    } else {
      // Se la stringa è più corta di 500 caratteri, restituisci la stringa intera come prima parte
      return [inputString, ''];
    }
  }
  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      const templateHTMLString =
        templateData != '' ? templateData : letteraAperturaData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      // Ottieni il tuo template HTML dall'import
      if (templateData == '') {
        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['foto_ceo'] != fotoCeo
        ) {
          const fotoLeaderElement = parsedHTML.getElementById('foto_leader');
          fotoLeaderElement.src = esgData['102-22']['foto_ceo'];
        }

        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['citazione_ceo'] != citazioneCeo
        ) {
          const citazioneP = parsedHTML.getElementById('citazione_leader');
          citazioneP.innerHTML = esgData['102-22']['citazione_ceo'];
        }
        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['firma_ceo'] != firmaCeo
        ) {
          const firma_ceo = parsedHTML.getElementById('firma_leader');
          firma_ceo.src = esgData['102-22']['firma_ceo'];
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
        }
        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['lettera_apertura'] != letteraApertura
        ) {
          const stringList = divideString(
            esgData['102-22']['lettera_apertura']
          );
          const first_500 = parsedHTML.getElementById('first_500');
          first_500.innerHTML = stringList[0];
          const after_500 = parsedHTML.getElementById('after_500');
          after_500.innerHTML = stringList[1];
        }

        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['nome_leader'] != undefined &&
          esgData['102-22']['nome_leader'] != nomeLeader
        ) {
          const nome_leader = parsedHTML.getElementById('nome_leader');
          nome_leader.innerHTML = esgData['102-22']['nome_leader'];
        }

        // Crea un oggetto Blob dai risultati dell'analisi
      } else {
        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['foto_ceo'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById('foto_leader');
          fotoLeaderElement.src = esgData['102-22']['foto_ceo'];
        }

        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['citazione_ceo'] != undefined
        ) {
          const citazioneP = parsedHTML.getElementById('citazione_leader');
          citazioneP.innerHTML = esgData['102-22']['citazione_ceo'];
        }
        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['firma_ceo'] != undefined
        ) {
          const firma_ceo = parsedHTML.getElementById('firma_leader');
          firma_ceo.src = esgData['102-22']['firma_ceo'];
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
        }
        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['lettera_apertura'] != undefined
        ) {
          const stringList = divideString(
            esgData['102-22']['lettera_apertura']
          );
          const first_500 = parsedHTML.getElementById('first_500');
          first_500.innerHTML = stringList[0];
          const after_500 = parsedHTML.getElementById('after_500');
          after_500.innerHTML = stringList[1];
        }

        if (
          esgData['102-22'] != undefined &&
          esgData['102-22']['nome_leader'] != undefined &&
          esgData['102-22']['nome_leader'] != undefined
        ) {
          const nome_leader = parsedHTML.getElementById('nome_leader');
          nome_leader.innerHTML = esgData['102-22']['nome_leader'];
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      if (imgRef.current) {
        // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
        imgRef.current.src = blobURL;
      }

      setLetteraAperturaData(parsedHTML.documentElement.outerHTML);
      updateDataUrl(blobURL);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };

  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = letteraAperturaData169;
        to_init = '169';
      } else {
        templateHTMLString = letteraAperturaDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['102-22'] != undefined &&
        esgData['102-22']['foto_ceo'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById('foto_leader');
        fotoLeaderElement.src = esgData['102-22']['foto_ceo'];
      }

      if (
        esgData['102-22'] != undefined &&
        esgData['102-22']['citazione_ceo'] != undefined
      ) {
        const citazioneP = parsedHTML.getElementById('citazione_leader');
        citazioneP.innerHTML = esgData['102-22']['citazione_ceo'];
      }
      if (
        esgData['102-22'] != undefined &&
        esgData['102-22']['firma_ceo'] != undefined
      ) {
        const firma_ceo = parsedHTML.getElementById('firma_leader');
        firma_ceo.src = esgData['102-22']['firma_ceo'];
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
      }
      if (
        esgData['102-22'] != undefined &&
        esgData['102-22']['lettera_apertura'] != undefined
      ) {
        const stringList = divideString(esgData['102-22']['lettera_apertura']);
        const first_500 = parsedHTML.getElementById('first_500');
        first_500.innerHTML = stringList[0];
        const after_500 = parsedHTML.getElementById('after_500');
        after_500.innerHTML = stringList[1];
      }

      if (
        esgData['102-22'] != undefined &&
        esgData['102-22']['nome_leader'] != undefined &&
        esgData['102-22']['nome_leader'] != undefined
      ) {
        const nome_leader = parsedHTML.getElementById('nome_leader');
        nome_leader.innerHTML = esgData['102-22']['nome_leader'];
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('102-22', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Lettera di Apertura',
          initA4: LetteraAperturaPopA4,
          init169: LetteraAperturaPop169,
          keys_to_check: [
            'nome_leader',
            'foto_leader',
            'citazione_leader',
            'firma_leader',
            'first_500',
          ],
          gri: '102-22',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('102-22', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Lettera di Apertura',
          initA4: LetteraApertura,
          init169: LetteraApertura169,
          keys_to_check: [
            'nome_leader',
            'foto_leader',
            'citazione_leader',
            'firma_leader',
            'first_500',
          ],
          gri: '102-22',
        });
      }
    }
  };
  /*
 useEffect(() => {
    // Funzione per convertire il contenuto HTML in un oggetto renderizzabile e visualizzarlo nell'anteprima della sidebar
    const convertHTMLToRenderable = () => {
      try {
        // Ottieni il tuo template HTML dall'import
        const templateHTMLString = cover;
        const parser = new DOMParser();
        var modifiedHTML = templateHTMLString.replace('<<height_page>>', '297mm');  // Crea un oggetto Blob dal contenuto HTML

        // Analizza il testo HTML modificato
        const parsedHTML = parser.parseFromString(modifiedHTML, 'text/html');

        // Crea un oggetto Blob dai risultati dell'analisi
        const blob = new Blob([parsedHTML.documentElement.outerHTML], { type: 'text/html' });

        // Ottieni l'URL dell'oggetto Blob
        const blobURL = URL.createObjectURL(blob);

        if(esgData["template"]["variante_cover"]!=undefined){
            updateCoverVariant(esgData["template"]["variante_cover"]);
        }
        else{
        // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
        if (imgRef.current) {
            // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
            imgRef.current.src = blobURL;

          }
        }
    



      } catch (error) {
        console.error('Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:', error);
      }
    };

    // Chiamata alla funzione per convertire il contenuto HTML in un oggetto renderizzabile
    convertHTMLToRenderable();
  }, []);
*/
  //{renderTemplate('cover', { name: 'John' })}
  return (
    <div style={{ display: 'block' }}>
      <iframe title="Report" ref={imgRef} style={imageRes} />
    </div>
  );
};

export default MessaggioCeoEngine;
