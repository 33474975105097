import { Folder, Document } from '@carbon/react/icons';

function renderComponent(id, jsonData) {
  var data_item = [];
  // Cerca il nodo con l'ID corrispondente nell'array di nodi
  if (id == 'GRI_all') {
    var data_item = [
      {
        question_title: 'Riferimenti',
        type: 'ESG_all',
      },
    ];
  } else if (id == 'templateChoice') {
    var data_item = [
      {
        question_title: 'Scelta Template',
        type: 'templateChoice',
      },
    ];
  } else {
    jsonData.forEach(node => {
      if (node['id'] == id) {
        node.question_settings.forEach(child => {
          data_item.push(child);
        });
      }
    });
  }

  // Verifica se il nodo è stato trovato
  return data_item;
}
export default renderComponent;
