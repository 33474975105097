import React, { useState, useEffect } from 'react';

import { TextArea, Stack, RadioButton, RadioButtonGroup } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG206_1 = props => {
  const [esg206_1, setEsg206_1] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['206-1'] != undefined) {
      setEsg206_1(esgData['206-1']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('206-1', esg206_1);
  }, [esg206_1]);

  const handleChanges = e => {
    setEsg206_1({ ...esg206_1, [e.target.id]: e.target.value });
  };
  const handleChangesRadio = e => {
    if (e) {
      setEsg206_1({
        ...esg206_1,
        ['responsabilita_aog_rendicontazione']: 'si',
      });
    } else {
      setEsg206_1({
        ...esg206_1,
        ['responsabilita_aog_rendicontazione']: 'no',
      });
    }
  };
  return (
    <Stack gap={8}>
      <h1>
        Azioni legali relative a comportamento anticompetitivo, attività di
        trust e prassi monopolistiche{' '}
      </h1>

      <p>
        La presente informativa si riferisce ad azioni legali iniziate
        nell’ambito di leggi nazionali o internazionali concepite principalmente
        con lo scopo di regolamentare comportamento anticompetitivo, attività di
        trust e prassi monopolistiche.
      </p>

      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="comportamento_anticompetitivo"
        value={
          esg206_1['comportamento_anticompetitivo'] != undefined
            ? esg206_1['comportamento_anticompetitivo']
            : ''
        }
        placeholder="Descrivere con testo libero le principali azioni legali in corso o completate durante il periodo di rendicontazione
      riguardanti il comportamento anticompetitivo e violazioni di leggi anti-trust e contro il
      monopolio in cui l’organizzazione sia stata identificata come partecipante."
        onChange={handleChanges}
        labelText="Azioni legali comportamento anticompetitivo"
      />
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 206-1 </footer>
    </Stack>
  );
};

export default ESG206_1;
