import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Information } from '@carbon/icons-react';
import { Button, Stack, Tooltip, TextInput } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';
function ESG203_1() {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([
    { description: '', scope: '', impact: '' },
  ]);

  const columnDefs = [
    {
      headerName: "Descrizione dell'investimento",
      field: 'description',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: "Portata dell'investimento",
      field: 'scope',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: 'Impatti previsti sulla comunità',
      field: 'impact',
      cellEditor: 'agLargeTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
  ];
  const { esgData, pushESGData, refreshESGData } = useESG();

  const addRow = () => {
    setRowData([...rowData, { description: '', scope: '', impact: '' }]);
  };
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
  };
  useEffect(() => {
    if (esgData['203-1'] != undefined) {
      setRowData(esgData['203-1']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('203-1', rowData);
  }, [rowData]);
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };

  return (
    <Stack gap={8}>
      <h1>GRI 203-1</h1>
      <h2>Investimenti in infrastrutture e servizi supportati</h2>
      <p>
        La presente informativa riguarda l’impatto degli investimenti in
        infrastrutture e dei servizi supportati dall’organizzazione sui suoi
        stakeholder e sull’economia. Gli impatti di investimenti in
        infrastrutture possono estendersi oltre l’ambito delle operazioni di
        un’organizzazione e su un periodo di tempo più lungo. Tali investimenti
        possono includere collegamenti per trasporti, servizi di utenza,
        impianti sociali per comunità, centri sanitari e per aiuti statali, e
        centri sportivi. Insieme all’investimento nelle sue operazioni, questa è
        una misura del contributo di capitali dell’organizzazione all’economia.
      </p>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValue}
        />
        <Button kind="ghost" onClick={addRow}>
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
    </Stack>
  );
}

export default ESG203_1;
