import AttoriLocali from '../../../../assets/html_template/professional/A4/12-sociale-gri413-1-attoriLocali.html';
import AttoriLocali169 from '../../../../assets/html_template/professional/169/12-sociale-gri413-1-attoriLocali.html';
import AttoriLocaliPopA4 from '../../../../assets/html_template/pop/A4/12-sociale-gri413-1-attoriLocali.html';
import AttoriLocaliPop169 from '../../../../assets/html_template/pop/169/12-sociale-gri413-1-attoriLocali.html';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const AttoriLocaliEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? AttoriLocali : AttoriLocali169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? AttoriLocaliPopA4 : AttoriLocaliPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? AttoriLocali : AttoriLocali169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [descrizioneAttoriLocali, setDescrizioneAttoriLocali] = useState('');
  const [attoriLocaliTable, setAttoriLocaliTable] = useState('');
  const [didascalia_eventi, set_didascalia_eventi] = useState('');
  const [foto_eventi, set_foto_eventi] = useState({});
  const [attoriLocaliData, SetAttoriLocaliData] = useState(AttoriLocali);
  const [attoriLocaliDataA4, setattoriLocaliDataA4] = useState(AttoriLocali);
  const [attoriLocaliData169, setattoriLocaliData169] = useState(
    AttoriLocali169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetAttoriLocaliData(AttoriLocaliPopA4);
    setattoriLocaliDataA4(AttoriLocaliPopA4);
    setattoriLocaliData169(AttoriLocaliPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetAttoriLocaliData(AttoriLocali);
    setattoriLocaliDataA4(AttoriLocali);
    setattoriLocaliData169(AttoriLocali169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetAttoriLocaliData(AttoriLocali);
    setattoriLocaliDataA4(AttoriLocali);
    setattoriLocaliData169(AttoriLocali169);
  }
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAttoriLocaliData(AttoriLocaliPopA4);
      setattoriLocaliDataA4(AttoriLocaliPopA4);
      setattoriLocaliData169(AttoriLocaliPop169);
      force_update(type == 'A4' ? AttoriLocaliPopA4 : AttoriLocaliPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAttoriLocaliData(AttoriLocali);
      setattoriLocaliDataA4(AttoriLocali);
      setattoriLocaliData169(AttoriLocali169);
      force_update(type == 'A4' ? AttoriLocali : AttoriLocali169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAttoriLocaliData(AttoriLocali);
      setattoriLocaliDataA4(AttoriLocali);
      setattoriLocaliData169(AttoriLocali169);
      force_update(type == 'A4' ? AttoriLocali : AttoriLocali169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('413-1')
    ) {
      setIsVisible(false);
      delPageData('413-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(attoriLocaliData);
    } else {
      if (
        esgData['413-1'] != undefined &&
        esgData['413-1']['attori_sviluppo'] != undefined &&
        esgData['413-1']['attori_sviluppo'] != descrizioneAttoriLocali
      ) {
        hasUpdate = true;
      }
      if (
        esgData['413-1'] != undefined &&
        esgData['413-1']['table_coinvolgimento'] != undefined &&
        !compareLists(
          esgData['413-1']['table_coinvolgimento'],
          attoriLocaliTable
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['413-1'] != undefined &&
        esgData['413-1']['didascalia_interventi'] != undefined &&
        esgData['413-1']['didascalia_interventi'] != didascalia_eventi
      ) {
        hasUpdate = true;
      }
      if (
        esgData['413-1'] != undefined &&
        esgData['413-1']['foto_iniziative'] != undefined &&
        esgData['413-1']['foto_iniziative'] != foto_eventi
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = ['Operazione', 'Portata', 'Impatto'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = attoriLocaliData169;
        to_init = '169';
      } else {
        templateHTMLString = attoriLocaliDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['413-1'] != undefined &&
        esgData['413-1']['attori_sviluppo'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById('attori_sviluppo');
        fotoLeaderElement.innerHTML = esgData['413-1']['attori_sviluppo'];
        setDescrizioneAttoriLocali(esgData['413-1']['attori_sviluppo']);
      }

      if (
        esgData['413-1'] != undefined &&
        esgData['413-1']['foto_iniziative'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById('foto_iniziative');
        fotoLeaderElement.src = esgData['413-1']['foto_iniziative'];
        set_foto_eventi(esgData['413-1']['foto_iniziative']);
      }
      if (
        esgData['413-1'] != undefined &&
        esgData['413-1']['didascalia_interventi'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'didascalia_interventi'
        );
        fotoLeaderElement.innerHTML = esgData['413-1']['didascalia_interventi'];
        set_didascalia_eventi(esgData['413-1']['didascalia_interventi']);
      }

      //&&!compareLists(tabellacriticita,esgData["413-1"]["rendicontazione_criticita"])

      if (
        esgData['413-1'] != undefined &&
        esgData['413-1']['table_coinvolgimento'] != undefined
      ) {
        const table = parsedHTML.getElementById('table_coinvolgimento');
        if (esgData['413-1']['table_coinvolgimento'].length > 0) {
          const dati_modificati = esgData['413-1']['table_coinvolgimento'].map(
            elemento => ({
              Operazione: elemento.operation,
              Portata: elemento.scope,
              Impatto: elemento.impact,
            })
          );
          popolaTabella(table, dati_modificati, ordered_keys);
          setAttoriLocaliTable(
            cloneDeep(esgData['413-1']['table_coinvolgimento'])
          );
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('413-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Coinvolgimento della Comunità Locale',
          initA4: AttoriLocaliPopA4,
          init169: AttoriLocaliPop169,
          keys_to_check: [
            'attori_sviluppo',
            'table_coinvolgimento',
            'didascalia_interventi',
            'foto_iniziative',
          ],
          gri: '413-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('413-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Coinvolgimento della Comunità Locale',
          initA4: AttoriLocali,
          init169: AttoriLocali169,
          keys_to_check: [
            'attori_sviluppo',
            'table_coinvolgimento',
            'didascalia_interventi',
            'foto_iniziative',
          ],
          gri: '413-1',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? attoriLocaliData : templateData;
      const parser = new DOMParser();
      const ordered_keys = ['Operazione', 'Portata', 'Impatto'];
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['413-1'] != undefined &&
          esgData['413-1']['attori_sviluppo'] != undefined &&
          esgData['413-1']['attori_sviluppo'] != descrizioneAttoriLocali
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'attori_sviluppo'
          );
          fotoLeaderElement.innerHTML = esgData['413-1']['attori_sviluppo'];
          setDescrizioneAttoriLocali(esgData['413-1']['attori_sviluppo']);
        }

        if (
          esgData['413-1'] != undefined &&
          esgData['413-1']['foto_iniziative'] != undefined &&
          esgData['413-1']['foto_iniziative'] != foto_eventi
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'foto_iniziative'
          );
          fotoLeaderElement.src = esgData['413-1']['foto_iniziative'];
          set_foto_eventi(esgData['413-1']['foto_iniziative']);
        }
        if (
          esgData['413-1'] != undefined &&
          esgData['413-1']['didascalia_interventi'] != undefined &&
          esgData['413-1']['didascalia_interventi'] != didascalia_eventi
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'didascalia_interventi'
          );
          fotoLeaderElement.innerHTML =
            esgData['413-1']['didascalia_interventi'];
          set_didascalia_eventi(esgData['413-1']['didascalia_interventi']);
        }

        //&&!compareLists(tabellacriticita,esgData["413-1"]["rendicontazione_criticita"])

        if (
          esgData['413-1'] != undefined &&
          esgData['413-1']['table_coinvolgimento'] != undefined &&
          !compareLists(
            esgData['413-1']['table_coinvolgimento'],
            attoriLocaliTable
          )
        ) {
          const table = parsedHTML.getElementById('table_coinvolgimento');
          if (esgData['413-1']['table_coinvolgimento'].length > 0) {
            const dati_modificati = esgData['413-1'][
              'table_coinvolgimento'
            ].map(elemento => ({
              Operazione: elemento.operation,
              Portata: elemento.scope,
              Impatto: elemento.impact,
            }));
            popolaTabella(table, dati_modificati, ordered_keys);
            setAttoriLocaliTable(
              cloneDeep(esgData['413-1']['table_coinvolgimento'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['413-1'] != undefined &&
          esgData['413-1']['attori_sviluppo'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'attori_sviluppo'
          );
          fotoLeaderElement.innerHTML = esgData['413-1']['attori_sviluppo'];
          setDescrizioneAttoriLocali(esgData['413-1']['attori_sviluppo']);
        }

        if (
          esgData['413-1'] != undefined &&
          esgData['413-1']['foto_iniziative'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'foto_iniziative'
          );
          fotoLeaderElement.src = esgData['413-1']['foto_iniziative'];
          set_foto_eventi(esgData['413-1']['foto_iniziative']);
        }
        if (
          esgData['413-1'] != undefined &&
          esgData['413-1']['didascalia_interventi'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'didascalia_interventi'
          );
          fotoLeaderElement.innerHTML =
            esgData['413-1']['didascalia_interventi'];
          set_didascalia_eventi(esgData['413-1']['didascalia_interventi']);
        }

        //&&!compareLists(tabellacriticita,esgData["413-1"]["rendicontazione_criticita"])

        if (
          esgData['413-1'] != undefined &&
          esgData['413-1']['table_coinvolgimento'] != undefined
        ) {
          const table = parsedHTML.getElementById('table_coinvolgimento');
          if (esgData['413-1']['table_coinvolgimento'].length > 0) {
            const dati_modificati = esgData['413-1'][
              'table_coinvolgimento'
            ].map(elemento => ({
              Operazione: elemento.operation,
              Portata: elemento.scope,
              Impatto: elemento.impact,
            }));
            popolaTabella(table, dati_modificati, ordered_keys);
            setAttoriLocaliTable(
              cloneDeep(esgData['413-1']['table_coinvolgimento'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetAttoriLocaliData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AttoriLocaliEngine;
