import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Add, Subtract } from '@carbon/icons-react';
import {
  Column,
  Modal,
  Button,
  TextInput,
  Stack,
  RadioButtonGroup,
  RadioButton,
  FormGroup,
  Dropdown,
  FilterableMultiSelect,
  Grid,
  TextArea,
  DatePicker,
} from '@carbon/react';
import { ArrowDown } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
const opzioni_settore = [
  { label: 'Settore Pubblico', value: 'settore_pubblico' },
  { label: 'Settore Privato', value: 'settore_privato' },
  { label: 'Settore di Formazione', value: 'settore_formazione' },
  { label: 'Settore Finanziario', value: 'settore_finanziario' },
  { label: 'Agricoltura', value: 'agricoltura' },
  { label: 'Manifattura', value: 'manifattura' },
  { label: "Tecnologia dell'informazione", value: 'tecnologia_informazione' },
  { label: 'Sanità', value: 'sanita' },
  { label: 'Ingegneria e Costruzione', value: 'ingegneria_costruzione' },
  { label: 'Trasporti e Logistica', value: 'trasporti_logistica' },
  { label: 'Energia', value: 'energia' },
  { label: 'Ristorazione', value: 'ristorazione' },
  { label: 'Media e Intrattenimento', value: 'media_intrattenimento' },
  { label: 'Altro', value: 'altro' },
];
function ESG102_6a(props) {
  const [esg102_6a, setEsg102_6a] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-6'] != undefined) {
      setEsg102_6a(esgData['102-6']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-6', esg102_6a);
  }, [esg102_6a]);
  const handleChangesSelect = e => {
    setEsg102_6a({ ...esg102_6a, settore_operativi: e });
  };
  const handleChanges = e => {
    setEsg102_6a({ ...esg102_6a, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>GRI 102-6a</h1>
      <h2>Descrizione dei prodotti/servizi</h2>
      <p>
        Quando descrive le sue attività, l’organizzazione dovrebbe rendicontare
        il numero totale di operazioni e spiegare come definisce una
        ‘operazione’. Quando descrive i suoi prodotti e servizi,
        l’organizzazione dovrebbe rendicontare:
        <br />
        • la quantità di prodotti o servizi forniti durante il periodo di
        rendicontazione (per es., il numero di prodotti o servizi forniti, il
        fatturato netto relativo ai prodotti o servizi forniti);
        <br />
        • se vende prodotti o servizi vietati in certi mercati o che sono
        oggetto di preoccupazione o di discussioni pubbliche da parte degli
        stakeholder, compreso il motivo del divieto o delle preoccupazioni e
        come l’organizzazione ha risposto a queste ultime.
        <br />
        Quando descrive i mercati serviti, l’organizzazione può rendicontare:
        <br />
        • le regioni geografiche in cui i prodotti e servizi vengono offerti;
        <br />
        • le caratteristiche demografiche o di altro tipo dei mercati;
        <br />• dati sulle dimensioni e sull’importanza relativa dei mercati
        (per es., fatturato netto, ricavi netti).
      </p>

      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="prodotti_e_servizi"
        value={
          esg102_6a['prodotti_e_servizi'] != undefined
            ? esg102_6a['prodotti_e_servizi']
            : ''
        }
        placeholder="Descrivere con testo libero i principali prodotti/servizi dell'organizzazione e i principali mercati forniti"
        onChange={handleChanges}
        labelText="Prodotti e Servizi"
      />
    </Stack>
  );
}

export default ESG102_6a;
