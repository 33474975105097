import Obiettivi from '../../../../assets/html_template/professional/A4/04-informativa-generale-gri102-obiettivi.html';
import Obiettivi169 from '../../../../assets/html_template/professional/169/05-informativa-generale-gri102-obiettivi.html';
import ObiettiviPopA4 from '../../../../assets/html_template/pop/A4/05-informativa-generale-gri102-obiettivi.html';
import ObiettiviPop169 from '../../../../assets/html_template/pop/169/04-obiettivi.html';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
import { cloneDeep } from 'lodash';

const ObiettiviEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? Obiettivi : Obiettivi169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString = type == 'A4' ? ObiettiviPopA4 : ObiettiviPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? Obiettivi : Obiettivi169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [descrizioneObiettivi, setDescrizioneObiettivi] = useState('');
  const [obiettiviTable, setObiettiviTable] = useState();
  const [obiettiviData, SetObiettiviData] = useState(Obiettivi);
  const [obiettiviDataA4, setObiettiviDataA4] = useState(Obiettivi);
  const [obiettiviData169, setObiettiviData169] = useState(Obiettivi169);
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetObiettiviData(ObiettiviPopA4);
    setObiettiviDataA4(ObiettiviPopA4);
    setObiettiviData169(ObiettiviPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetObiettiviData(Obiettivi);
    setObiettiviDataA4(Obiettivi);
    setObiettiviData169(Obiettivi169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetObiettiviData(Obiettivi);
    setObiettiviDataA4(Obiettivi);
    setObiettiviData169(Obiettivi169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetObiettiviData(ObiettiviPopA4);
      setObiettiviDataA4(ObiettiviPopA4);
      setObiettiviData169(ObiettiviPop169);
      force_update(type == 'A4' ? ObiettiviPopA4 : ObiettiviPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetObiettiviData(Obiettivi);
      setObiettiviDataA4(Obiettivi);
      setObiettiviData169(Obiettivi169);
      force_update(type == 'A4' ? Obiettivi : Obiettivi169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetObiettiviData(Obiettivi);
      setObiettiviDataA4(Obiettivi);
      setObiettiviData169(Obiettivi169);
      force_update(type == 'A4' ? Obiettivi : Obiettivi169);
    }
    if (
      esgData['obiettivi'] != undefined &&
      esgData['obiettivi']['rendicontazione_obiettivi'] != undefined &&
      esgData['obiettivi']['rendicontazione_obiettivi'] != descrizioneObiettivi
    ) {
      hasUpdate = true;
    }
    if (
      esgData['obiettivi'] != undefined &&
      esgData['obiettivi']['table_obiettivi'] != undefined &&
      !compareLists(esgData['obiettivi']['table_obiettivi'], obiettiviTable)
    ) {
      hasUpdate = true;
    }
    if (
      esgData['102-3'] != undefined &&
      esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
    ) {
      hasUpdate = true;
    }

    if (hasUpdate) {
      renderHTMLtoiFrame();
    }
  }, [esgData]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = obiettiviData169;
        to_init = '169';
      } else {
        templateHTMLString = obiettiviDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['obiettivi'] != undefined &&
        esgData['obiettivi']['rendicontazione_obiettivi'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'rendicontazione_obiettivi'
        );
        fotoLeaderElement.innerHTML =
          esgData['obiettivi']['rendicontazione_obiettivi'];
        setDescrizioneObiettivi(
          esgData['obiettivi']['rendicontazione_obiettivi']
        );
      }

      if (
        esgData['obiettivi'] != undefined &&
        esgData['obiettivi']['table_obiettivi'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById('table_obiettivi');
        popolaTabella(
          fotoLeaderElement,
          esgData['obiettivi']['table_obiettivi']
        );
        setObiettiviTable(cloneDeep(esgData['obiettivi']['table_obiettivi']));
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('obiettivi', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Obiettivi',
          initA4: ObiettiviPopA4,
          init169: ObiettiviPop169,
          keys_to_check: ['rendicontazione_obiettivi', 'table_obiettivi'],
          gri: 'obiettivi',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('obiettivi', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Obiettivi',
          initA4: Obiettivi,
          init169: Obiettivi169,
          keys_to_check: ['rendicontazione_obiettivi', 'table_obiettivi'],
          gri: 'obiettivi',
        });
      }
    }
  };

  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    try {
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData != '' ? templateData : obiettiviData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['obiettivi'] != undefined &&
          esgData['obiettivi']['rendicontazione_obiettivi'] != undefined &&
          esgData['obiettivi']['rendicontazione_obiettivi'] !=
            descrizioneObiettivi
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'rendicontazione_obiettivi'
          );
          fotoLeaderElement.innerHTML =
            esgData['obiettivi']['rendicontazione_obiettivi'];
          setDescrizioneObiettivi(
            esgData['obiettivi']['rendicontazione_obiettivi']
          );
        }

        if (
          esgData['obiettivi'] != undefined &&
          esgData['obiettivi']['table_obiettivi'] != undefined &&
          !compareLists(esgData['obiettivi']['table_obiettivi'], obiettiviTable)
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'table_obiettivi'
          );
          popolaTabella(
            fotoLeaderElement,
            esgData['obiettivi']['table_obiettivi']
          );
          setObiettiviTable(cloneDeep(esgData['obiettivi']['table_obiettivi']));
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['obiettivi'] != undefined &&
          esgData['obiettivi']['rendicontazione_obiettivi'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'rendicontazione_obiettivi'
          );
          fotoLeaderElement.innerHTML =
            esgData['obiettivi']['rendicontazione_obiettivi'];
          setDescrizioneObiettivi(
            esgData['obiettivi']['rendicontazione_obiettivi']
          );
        }

        if (
          esgData['obiettivi'] != undefined &&
          esgData['obiettivi']['table_obiettivi'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'table_obiettivi'
          );
          popolaTabella(
            fotoLeaderElement,
            esgData['obiettivi']['table_obiettivi']
          );
          setObiettiviTable(cloneDeep(esgData['obiettivi']['table_obiettivi']));
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);
      SetObiettiviData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      <iframe title="Report" src={iframeURL} style={imageRes} />
    </div>
  );
};

export default ObiettiviEngine;
