import React, { useState } from 'react';
const questionsFromDB = [
  {
    id: 1,
    question_title: 'Qual è il tuo nome?',
    type: 'text',
    pattern: '',
  },
  {
    id: 2,
    question_title: 'Seleziona il tuo paese',
    type: 'select',
    values: ['Italia', 'Francia', 'Germania', 'Spagna'],
  },
  {
    id: 3,
    question_title: 'Quanti anni hai?',
    type: 'number',
    pattern: '\\d+',
  },
  {
    id: 4,
    question_title: 'Qual è la tua lingua madre?',
    type: 'text',
    pattern: '',
  },
  {
    id: 5,
    question_title: 'Seleziona le tue lingue straniere (multiplo)',
    type: 'select',
    values: [
      'Inglese',
      'Francese',
      'Spagnolo',
      'Tedesco',
      'Cinese',
      'Giapponese',
    ],
  },
  {
    id: 6,
    question_title: 'Hai esperienza nel settore IT?',
    type: 'select',
    values: ['Sì', 'No'],
  },
  {
    id: 7,
    question_title: 'Quale posizione lavorativa preferisci?',
    type: 'select',
    values: [
      'Sviluppatore web',
      'Designer UI/UX',
      'Analista di dati',
      'Gestione del progetto',
    ],
  },
  {
    id: 8,
    question_title: 'Quale sistema operativo preferisci?',
    type: 'select',
    values: ['Windows', 'macOS', 'Linux'],
  },
  {
    id: 9,
    question_title: 'Indirizzo email',
    type: 'text',
    pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}',
  },
  {
    id: 10,
    question_title: 'Quale framework JavaScript preferisci?',
    type: 'select',
    values: ['React', 'Angular', 'Vue.js', 'Ember.js', 'Svelte'],
  },
  // Aggiungi ulteriori domande secondo le tue esigenze
];

function CustomForm() {
  // State per tenere traccia delle risposte
  const [answers, setAnswers] = useState({});

  // Funzione per gestire il cambio di valore delle domande
  const handleInputChange = (e, id) => {
    const { value } = e.target;
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [id]: value,
    }));
  };

  // Funzione per gestire la sottomissione del modulo
  const handleSubmit = e => {
    e.preventDefault();
    // Puoi fare qualcosa con le risposte qui, come inviarle a un server
  };

  return (
    <form onSubmit={handleSubmit}>
      {questionsFromDB.map(question => {
        const { id, question_title, type, values, pattern } = question;

        // Renderizza il campo in base al tipo
        switch (type) {
          case 'text':
          case 'number':
            return (
              <div key={id}>
                <label htmlFor={id}>{question_title}</label>
                <input
                  type={type}
                  id={id}
                  value={answers[id] || ''}
                  onChange={e => handleInputChange(e, id)}
                  pattern={pattern}
                />
              </div>
            );
          case 'select':
            return (
              <div key={id}>
                <label htmlFor={id}>{question_title}</label>
                <select
                  id={id}
                  value={answers[id] || ''}
                  onChange={e => handleInputChange(e, id)}>
                  <option value="">Seleziona una opzione</option>
                  {values.map(value => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            );
          default:
            return null;
        }
      })}
      <button type="submit">Invia</button>
    </form>
  );
}

export default CustomForm;
