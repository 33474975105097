import React from 'react';
import ersrImage from '../../../assets/img/efragref.png';
import { Grid, Column, TextInput, Select, TextArea } from '@carbon/react';

const ESG_all = () => {
  return (
    <Grid fullWidth className="esgall">
      <Column sm={6} md={6} lg={6}>
        <h1>Riferimenti</h1>
        <strong>ESRS</strong>
        <p>
          La redazione del documento è disposta secondo gli "European
          Sustainability Reporting Standards" (ESRS), gli standard concepiti e
          adottati nel 2023 con l'obiettivo di migliorare la trasparenza e la
          coerenza delle informazioni sulla sostenibilità fornite dalle aziende.
          Gli ESRS rappresentano un insieme di standard di rendicontazione della
          sostenibilità sviluppati dalla Commissione Europea, in collaborazione
          stretta con l’International Sustainability Standards Board (ISSB), la
          Global Reporting Initiative (GRI) per garantire un elevato grado di
          interoperabilità tra gli standard dell’UE e quelli globali già
          esistenti ed eliminando lo spettro di una doppia rendicontazione da
          parte delle imprese. Riguardo alla configurazione di questo primo
          insieme di standard, si conferma la struttura proposta dall'European
          Financial Reporting Advisory Group (EFRAG), un organismo consultivo
          della Commissione Europea per lo sviluppo degli standard. Questa
          struttura prevede due standard trasversali e dieci standard tematici,
          suddivisi ulteriormente in cinque aspetti ambientali, quattro sociali
          e uno riguardante la governance.
        </p>
        <img className="esg_all_img" src={ersrImage} alt="ESR Standards" />
      </Column>

      <Column sm={6} md={6} lg={6}>
        <h1>&nbsp;</h1>
        <strong>Agenda 2030 ONU</strong>
        <p>
          L’Agenda 2030 per lo sviluppo sostenibile è un piano d'azione globale
          adottato dalle Nazioni Unite nel settembre del 2015 dedicato alle
          persone, al pianeta e alla prosperità per raggiungere la
          trasformazione sostenibile nel 2030. L'Agenda 2030 è composta da 17
          Obiettivi di Sviluppo Sostenibile (SDG), spesso chiamati anche "Global
          Goals" o "Obiettivi Globali", e da un totale di 169 target specifici.
          Questi obiettivi coprono una vasta gamma di questioni interconnesse
          che riguardano sia l'aspetto sociale, sia quello economico e
          ambientale. L'azienda si impegna ad adattare i propri obiettivi agli
          obiettivi di sviluppo sostenibile delle Nazioni Unite (SDGs) i quali
          saranno riportati insieme ai temi materiali di appartenenza. Obiettivi
          di sviluppo sostenibili:{' '}
          <a href="https://sdgs.un.org/es/goals" target="_blank">
            https://sdgs.un.org/es/goals
          </a>
        </p>

        <img
          className="esg_all_img"
          src={process.env.PUBLIC_URL + '/ag2030_icon.jpg'}
          alt="Agenda 2030"
        />
      </Column>
    </Grid>
  );
};

export default ESG_all;
