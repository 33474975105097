import Congedo from '../../../../assets/html_template/professional/A4/12-sociale-gri401-1-Congedo.html';
import Congedo169 from '../../../../assets/html_template/professional/169/12-sociale-gri401-1-Congedo.html';
import CongedoPopA4 from '../../../../assets/html_template/pop/A4/12-sociale-gri402-1-congedoparentale.html';
import CongedoPop169 from '../../../../assets/html_template/pop/169/12-sociale-gri406-1-congedo.html';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const CongedoEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? Congedo : Congedo169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString = type == 'A4' ? CongedoPopA4 : CongedoPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? Congedo : Congedo169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [descrizionecongedo, setDescrizioneCongedo] = useState('');
  const [congedoTable, setCongedoTable] = useState([]);
  const [ritornoTable, setRitorno] = useState([]);

  const [CongedoData, SetCongedoData] = useState(Congedo);
  const [CongedoDataA4, setCongedoDataA4] = useState(Congedo);
  const [CongedoData169, setCongedoData169] = useState(Congedo169);
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetCongedoData(CongedoPopA4);
    setCongedoDataA4(CongedoPopA4);
    setCongedoData169(CongedoPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetCongedoData(Congedo);
    setCongedoDataA4(Congedo);
    setCongedoData169(Congedo169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetCongedoData(Congedo);
    setCongedoDataA4(Congedo);
    setCongedoData169(Congedo169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();
  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetCongedoData(CongedoPopA4);
      setCongedoDataA4(CongedoPopA4);
      setCongedoData169(CongedoPop169);
      force_update(type == 'A4' ? CongedoPopA4 : CongedoPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetCongedoData(Congedo);
      setCongedoDataA4(Congedo);
      setCongedoData169(Congedo169);
      force_update(type == 'A4' ? Congedo : Congedo169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetCongedoData(Congedo);
      setCongedoDataA4(Congedo);
      setCongedoData169(Congedo169);
      force_update(type == 'A4' ? Congedo : Congedo169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('401-3')
    ) {
      setIsVisible(false);
      delPageData('401-3');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(CongedoData);
    } else {
      if (
        esgData['401-3'] != undefined &&
        esgData['401-3']['descrizione_congedo'] != undefined &&
        esgData['401-3']['descrizione_congedo'] != descrizionecongedo
      ) {
        hasUpdate = true;
      }
      if (
        esgData['401-3'] != undefined &&
        esgData['401-3']['usocongedo'] != undefined &&
        !compareLists(esgData['401-3']['usocongedo'], congedoTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['401-3'] != undefined &&
        esgData['401-3']['ritornocongedo'] != undefined &&
        !compareLists(esgData['401-3']['ritornocongedo'], ritornoTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    try {
      // Verifica se le lunghezze delle liste sono diverse
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = CongedoData169;
        to_init = '169';
      } else {
        templateHTMLString = CongedoDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['401-3'] != undefined &&
        esgData['401-3']['descrizione_congedo'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'descrizione_congedo'
        );
        fotoLeaderElement.innerHTML = esgData['401-3']['descrizione_congedo'];
        setDescrizioneCongedo(esgData['401-3']['descrizione_congedo']);
      }
      //&&!compareLists(tabellacriticita,esgData["401-3"]["rendicontazione_criticita"])

      if (
        esgData['401-3'] != undefined &&
        esgData['401-3']['usocongedo'] != undefined
      ) {
        const table = parsedHTML.getElementById('tabella_usocongedo');
        const grafico = parsedHTML.getElementById('usocongedo');
        if (esgData['401-3']['usocongedo'].length > 0) {
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          const oggettoMappato = esgData['401-3']['usocongedo'].map(
            elemento => ({
              Anno: elemento.Anno,
              Uomini: elemento.Men,
              Donne: elemento.Women,
            })
          );
          popolaTabella(table, oggettoMappato);
          const sumByYear = esgData['401-3']['usocongedo'].reduce(
            (acc, { Men, Women }) => ({
              Men: acc.Men + Men,
              Women: acc.Women + Women,
            }),
            { Men: 0, Women: 0 }
          );
          console.log(sumByYear);
          const oggettoConAnnoMassimo = esgData['401-3']['usocongedo'].reduce(
            (acc, cur) => (cur.Anno > acc.Anno ? cur : acc),
            esgData['401-3']['usocongedo'][0]
          );
          grafico.textContent =
            `
          //TOSTART
          var data = ${JSON.stringify(sumByYear, null, 2)};
          //TOEND;

        ` + grafico.textContent;
        }
        setCongedoTable(cloneDeep(esgData['401-3']['usocongedo']));
      }
      if (
        esgData['401-3'] != undefined &&
        esgData['401-3']['ritornocongedo'] != undefined
      ) {
        const table = parsedHTML.getElementById('tabella_ritornocongedo');
        const grafico = parsedHTML.getElementById('ritornocongedo');
        if (esgData['401-3']['ritornocongedo'].length > 0) {
          const oggettoMappato = esgData['401-3']['ritornocongedo'].map(
            elemento => ({
              Anno: elemento.Anno,
              Uomini: elemento.Men,
              Donne: elemento.Women,
            })
          );
          popolaTabella(table, oggettoMappato);
          const sumByYear = esgData['401-3']['ritornocongedo'].reduce(
            (acc, { Men, Women }) => ({
              Men: acc.Men + Men,
              Women: acc.Women + Women,
            }),
            { Men: 0, Women: 0 }
          );
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
        //TOSTART
        var data2 = ${JSON.stringify(sumByYear, null, 2)};
        //TOEND;

      ` + grafico.textContent;
        }
        setRitorno(cloneDeep(esgData['401-3']['ritornocongedo']));
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('401-3', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Congedo Parentale',
          initA4: CongedoPopA4,
          init169: CongedoPop169,
          keys_to_check: ['tabella_usocongedo', 'usocongedo', 'ritornocongedo'],
          gri: '401-3',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('401-3', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Congedo Parentale',
          initA4: Congedo,
          init169: Congedo169,
          keys_to_check: ['tabella_usocongedo', 'usocongedo', 'ritornocongedo'],
          gri: '401-3',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? CongedoData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['401-3'] != undefined &&
          esgData['401-3']['descrizione_congedo'] != undefined &&
          esgData['401-3']['descrizione_congedo'] != descrizionecongedo
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_congedo'
          );
          fotoLeaderElement.innerHTML = esgData['401-3']['descrizione_congedo'];
          setDescrizioneCongedo(esgData['401-3']['descrizione_congedo']);
        }
        if (
          esgData['401-3'] != undefined &&
          esgData['401-3']['usocongedo'] != undefined &&
          !compareLists(esgData['401-3']['usocongedo'], congedoTable)
        ) {
          const table = parsedHTML.getElementById('tabella_usocongedo');
          const grafico = parsedHTML.getElementById('usocongedo');
          if (esgData['401-3']['usocongedo'].length > 0) {
            const oggettoMappato = esgData['401-3']['usocongedo'].map(
              elemento => ({
                Anno: elemento.Anno,
                Uomini: elemento.Men,
                Donne: elemento.Women,
              })
            );
            popolaTabella(table, oggettoMappato);
            const sumByYear = esgData['401-3']['usocongedo'].reduce(
              (acc, { Men, Women }) => ({
                Men: acc.Men + Men,
                Women: acc.Women + Women,
              }),
              { Men: 0, Women: 0 }
            );
            console.log(sumByYear);
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );
            const oggettoConAnnoMassimo = esgData['401-3']['usocongedo'].reduce(
              (acc, cur) => (cur.Anno > acc.Anno ? cur : acc),
              esgData['401-3']['usocongedo'][0]
            );
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
                    //TOSTART
                    var data = ${JSON.stringify(sumByYear, null, 2)};
                    //TOEND;
          
                  ` + grafico.textContent;
          }
          setCongedoTable(cloneDeep(esgData['401-3']['usocongedo']));
        }
        if (
          esgData['401-3'] != undefined &&
          esgData['401-3']['ritornocongedo'] != undefined &&
          !compareLists(esgData['401-3']['ritornocongedo'], ritornoTable)
        ) {
          const table = parsedHTML.getElementById('tabella_ritornocongedo');
          const grafico = parsedHTML.getElementById('ritornocongedo');
          if (esgData['401-3']['ritornocongedo'].length > 0) {
            const oggettoMappato = esgData['401-3']['ritornocongedo'].map(
              elemento => ({
                Anno: elemento.Anno,
                Uomini: elemento.Men,
                Donne: elemento.Women,
              })
            );
            popolaTabella(table, oggettoMappato);
            const sumByYear = esgData['401-3']['ritornocongedo'].reduce(
              (acc, { Men, Women }) => ({
                Men: acc.Men + Men,
                Women: acc.Women + Women,
              }),
              { Men: 0, Women: 0 }
            );
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
                  //TOSTART
                  var data2 = ${JSON.stringify(sumByYear, null, 2)};
                  //TOEND;
          
                ` + grafico.textContent;
          }
          setRitorno(cloneDeep(esgData['401-3']['ritornocongedo']));
        }
        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['401-3'] != undefined &&
          esgData['401-3']['descrizione_congedo'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_congedo'
          );
          fotoLeaderElement.innerHTML = esgData['401-3']['descrizione_congedo'];
          setDescrizioneCongedo(esgData['401-3']['descrizione_congedo']);
        }
        if (
          esgData['401-3'] != undefined &&
          esgData['401-3']['usocongedo'] != undefined
        ) {
          const table = parsedHTML.getElementById('tabella_usocongedo');
          const grafico = parsedHTML.getElementById('usocongedo');
          if (esgData['401-3']['usocongedo'].length > 0) {
            const oggettoMappato = esgData['401-3']['usocongedo'].map(
              elemento => ({
                Anno: elemento.Anno,
                Uomini: elemento.Men,
                Donne: elemento.Women,
              })
            );
            popolaTabella(table, oggettoMappato);
            const sumByYear = esgData['401-3']['usocongedo'].reduce(
              (acc, { Men, Women }) => ({
                Men: acc.Men + Men,
                Women: acc.Women + Women,
              }),
              { Men: 0, Women: 0 }
            );
            console.log(sumByYear);
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );
            const oggettoConAnnoMassimo = esgData['401-3']['usocongedo'].reduce(
              (acc, cur) => (cur.Anno > acc.Anno ? cur : acc),
              esgData['401-3']['usocongedo'][0]
            );
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
                //TOSTART
                var data = ${JSON.stringify(sumByYear, null, 2)};
                //TOEND;
      
              ` + grafico.textContent;
          }
          setCongedoTable(cloneDeep(esgData['401-3']['usocongedo']));
        }
        if (
          esgData['401-3'] != undefined &&
          esgData['401-3']['ritornocongedo'] != undefined
        ) {
          const table = parsedHTML.getElementById('tabella_ritornocongedo');
          const grafico = parsedHTML.getElementById('ritornocongedo');
          if (esgData['401-3']['ritornocongedo'].length > 0) {
            const oggettoMappato = esgData['401-3']['ritornocongedo'].map(
              elemento => ({
                Anno: elemento.Anno,
                Uomini: elemento.Men,
                Donne: elemento.Women,
              })
            );
            popolaTabella(table, oggettoMappato);
            const sumByYear = esgData['401-3']['ritornocongedo'].reduce(
              (acc, { Men, Women }) => ({
                Men: acc.Men + Men,
                Women: acc.Women + Women,
              }),
              { Men: 0, Women: 0 }
            );

            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
              //TOSTART
              var data2 = ${JSON.stringify(sumByYear, null, 2)};
              //TOEND;
      
            ` + grafico.textContent;
          }
          setRitorno(cloneDeep(esgData['401-3']['ritornocongedo']));
        }
        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetCongedoData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CongedoEngine;
