import React, { useState, useEffect } from 'react';

import { TextArea, Stack } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG102_13 = props => {
  const [esg102_13, setEsg102_13] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-13'] != undefined) {
      setEsg102_13(esgData['102-13']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-13', esg102_13);
  }, [esg102_13]);
  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  const handleChanges = e => {
    setEsg102_13({ ...esg102_13, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>GRI 102-13 </h1>
      <h2>Delega di responsabilità per la gestione di impatti</h2>
      <p>Completare i campi con le informazioni richieste.</p>

      <TextArea
        id="delega_aog_gestioneimpatti"
        maxCount={500}
        enableCounter={true}
        value={
          esg102_13['delega_aog_gestioneimpatti'] != undefined
            ? esg102_13['delega_aog_gestioneimpatti']
            : ''
        }
        placeholder="Descrivere con testo libero come il massimo organo di governo delega la responsibilità della gestione
      degli impatti dell’organizzazione sull’economia, sull’ambiente e sulle persone"
        onChange={handleChanges}
        labelText="Deleghe alto Organo di Governo nella Gestione degli Impatti"
      />

      <TextArea
        id="ruolo_aog_duediligence"
        maxCount={500}
        enableCounter={true}
        value={
          esg102_13['ruolo_aog_duediligence'] != undefined
            ? esg102_13['ruolo_aog_duediligence']
            : ''
        }
        placeholder="Descrivere con testo libero descrivere il processo e la frequenza con cui gli alti dirigenti o altri dipendenti devono
      riferire al massimo organo di governo sulla gestione degli impatti dell’organizzazione
      sull’economia, sull’ambiente e sulle persone."
        onChange={handleChanges}
        labelText="Riferimento alto Organo di Governo per la Gestione degli Impatti"
      />
    </Stack>
  );
};

export default ESG102_13;
