import PariOpportunita from '../../../../assets/html_template/professional/A4/12-sociale-gri406-1-salariuomodonna.html';
import PariOpportunitaPopA4 from '../../../../assets/html_template/pop/A4/12-sociale-gri406-1-salariuomodonna.html';
import PariOpportunita169 from '../../../../assets/html_template/professional/169/12-sociale-gri406-1-salariuomodonna.html';
import PariOpportunitaPop169 from '../../../../assets/html_template/pop/169/12-sociale-gri406-1-salariuomodonna.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { Button, Column, Grid } from '@carbon/react';
import { ViewOff } from '@carbon/react/icons';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';

const PariOpportunitaEngine = ({ isOpen, type }) => {
  const [template, setTemplate] = useState(false);
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? PariOpportunita : PariOpportunita169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? PariOpportunitaPopA4 : PariOpportunitaPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? PariOpportunita : PariOpportunita169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [descrizionePariOpportunita, setDescrizionePariOpportunita] = useState(
    ''
  );
  const [operaiTable, setOperaiTable] = useState([]);
  const [dirigentiTable, setDirigentiTable] = useState([]);
  const [impiegatiTable, setImpiegatiTable] = useState([]);
  const [pariOpportunitaData, SetPariOpportunitaData] = useState(
    PariOpportunita
  );
  const [pariOpportunitaA4, setpariOpportunitaA4] = useState(PariOpportunita);
  const [pariOpportunita169, setpariOpportunita169] = useState(
    PariOpportunita169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetPariOpportunitaData(PariOpportunitaPopA4);
    setpariOpportunitaA4(PariOpportunitaPopA4);
    setpariOpportunita169(PariOpportunitaPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetPariOpportunitaData(PariOpportunita);
    setpariOpportunitaA4(PariOpportunita);
    setpariOpportunita169(PariOpportunita169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetPariOpportunitaData(PariOpportunita);
    setpariOpportunitaA4(PariOpportunita);
    setpariOpportunita169(PariOpportunita169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();
  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetPariOpportunitaData(PariOpportunitaPopA4);
      setpariOpportunitaA4(PariOpportunitaPopA4);
      setpariOpportunita169(PariOpportunitaPop169);
      force_update(type == 'A4' ? PariOpportunitaPopA4 : PariOpportunitaPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetPariOpportunitaData(PariOpportunita);
      setpariOpportunitaA4(PariOpportunita);
      setpariOpportunita169(PariOpportunita169);
      force_update(type == 'A4' ? PariOpportunita : PariOpportunita169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetPariOpportunitaData(PariOpportunita);
      setpariOpportunitaA4(PariOpportunita);
      setpariOpportunita169(PariOpportunita169);
      force_update(type == 'A4' ? PariOpportunita : PariOpportunita169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('405-1')
    ) {
      setIsVisible(false);
      delPageData('405-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(pariOpportunitaData);
    } else {
      if (
        esgData['405-2'] != undefined &&
        esgData['405-2']['misure_paropp'] != undefined &&
        esgData['405-2']['misure_paropp'] != descrizionePariOpportunita
      ) {
        hasUpdate = true;
      }
      if (
        esgData['405-2'] != undefined &&
        esgData['405-2']['bluecollars'] != undefined &&
        !compareLists(esgData['405-2']['bluecollars'], operaiTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['405-2'] != undefined &&
        esgData['405-2']['managers'] != undefined &&
        !compareLists(esgData['405-2']['managers'], dirigentiTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['405-2'] != undefined &&
        esgData['405-2']['whitecollars'] != undefined &&
        !compareLists(esgData['405-2']['whitecollars'], impiegatiTable)
      ) {
        hasUpdate = true;
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    try {
      // Verifica se le lunghezze delle liste sono diverse
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys =
      ordered_keys == '' ? ['anno', 'uomini', 'donne'] : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = [
      'categoria',
      'quantita',
      'um',
      'conferitiInDiscarica',
      'nonConferitiInDiscarica',
    ];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = pariOpportunita169;
        to_init = '169';
      } else {
        templateHTMLString = pariOpportunitaA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['405-2'] != undefined &&
        esgData['405-2']['misure_paropp'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById('misure_paropp');
        fotoLeaderElement.innerHTML = esgData['405-2']['misure_paropp'];
        setDescrizionePariOpportunita(esgData['405-2']['misure_paropp']);
      }
      //&&!compareLists(tabellacriticita,esgData["405-2"]["rendicontazione_criticita"])

      if (
        (esgData['405-2'] != undefined &&
          esgData['405-2']['managers'] != undefined) ||
        (esgData['405-2'] != undefined &&
          esgData['405-2']['bluecollars'] != undefined) ||
        (esgData['405-2'] != undefined &&
          esgData['405-2']['whitecollars'] != undefined)
      ) {
        const tableDir = parsedHTML.getElementById('tabella_dirigenti');
        const tableOp = parsedHTML.getElementById('tabella_operai');
        const tableImp = parsedHTML.getElementById('tabella_impiegati');
        if (
          esgData['405-2']['managers'] != undefined &&
          esgData['405-2']['managers'].length > 0
        ) {
          popolaTabella(tableDir, esgData['405-2']['managers']);
        }
        if (
          esgData['405-2']['bluecollars'] != undefined &&
          esgData['405-2']['bluecollars'].length > 0
        ) {
          popolaTabella(tableOp, esgData['405-2']['bluecollars']);
        }
        if (
          esgData['405-2']['whitecollars'] != undefined &&
          esgData['405-2']['whitecollars'].length > 0
        ) {
          popolaTabella(tableImp, esgData['405-2']['whitecollars']);
        }

        setDirigentiTable(cloneDeep(esgData['405-2']['managers']));
        setOperaiTable(cloneDeep(esgData['405-2']['bluecollars']));
        setImpiegatiTable(cloneDeep(esgData['405-2']['whitecollars']));
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }

      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('405-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Salario di base e Retribuzione',
          initA4: PariOpportunitaPopA4,
          init169: PariOpportunitaPop169,
          keys_to_check: [
            'misure_paropp',
            'tabella_dirigenti',
            'tabella_operai',
            'tabella_impiegati',
          ],
          gri: '405-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('405-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Salario di base e Retribuzione',
          initA4: PariOpportunita,
          init169: PariOpportunita169,
          keys_to_check: [
            'misure_paropp',
            'tabella_dirigenti',
            'tabella_operai',
            'tabella_impiegati',
          ],
          gri: '405-1',
        });
      }
    }
  };

  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? pariOpportunitaData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['405-2'] != undefined &&
          esgData['405-2']['misure_paropp'] != undefined &&
          esgData['405-2']['misure_paropp'] != descrizionePariOpportunita
        ) {
          const fotoLeaderElement = parsedHTML.getElementById('misure_paropp');
          fotoLeaderElement.innerHTML = esgData['405-2']['misure_paropp'];
          setDescrizionePariOpportunita(esgData['405-2']['misure_paropp']);
        }
        //&&!compareLists(tabellacriticita,esgData["405-2"]["rendicontazione_criticita"])
        if (
          (esgData['405-2'] != undefined &&
            esgData['405-2']['managers'] != undefined &&
            !compareLists(esgData['405-2']['managers'], dirigentiTable)) ||
          (esgData['405-2'] != undefined &&
            esgData['405-2']['bluecollars'] != undefined &&
            !compareLists(esgData['405-2']['bluecollars'], operaiTable)) ||
          (esgData['405-2'] != undefined &&
            esgData['405-2']['whitecollars'] != undefined &&
            !compareLists(esgData['405-2']['whitecollars'], impiegatiTable))
        ) {
          const tableDir = parsedHTML.getElementById('tabella_dirigenti');
          const tableOp = parsedHTML.getElementById('tabella_operai');
          const tableImp = parsedHTML.getElementById('tabella_impiegati');
          if (
            esgData['405-2']['managers'] != undefined &&
            esgData['405-2']['managers'].length > 0
          ) {
            popolaTabella(tableDir, esgData['405-2']['managers']);
          }
          if (
            esgData['405-2']['bluecollars'] != undefined &&
            esgData['405-2']['bluecollars'].length > 0
          ) {
            popolaTabella(tableOp, esgData['405-2']['bluecollars']);
          }
          if (
            esgData['405-2']['whitecollars'] != undefined &&
            esgData['405-2']['whitecollars'].length > 0
          ) {
            popolaTabella(tableImp, esgData['405-2']['whitecollars']);
          }
          setDirigentiTable(cloneDeep(esgData['405-2']['managers']));
          setOperaiTable(cloneDeep(esgData['405-2']['bluecollars']));
          setImpiegatiTable(cloneDeep(esgData['405-2']['whitecollars']));
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['405-2'] != undefined &&
          esgData['405-2']['misure_paropp'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById('misure_paropp');
          fotoLeaderElement.innerHTML = esgData['405-2']['misure_paropp'];
          setDescrizionePariOpportunita(esgData['405-2']['misure_paropp']);
        }
        //&&!compareLists(tabellacriticita,esgData["405-2"]["rendicontazione_criticita"])

        if (
          (esgData['405-2'] != undefined &&
            esgData['405-2']['managers'] != undefined) ||
          (esgData['405-2'] != undefined &&
            esgData['405-2']['bluecollars'] != undefined) ||
          (esgData['405-2'] != undefined &&
            esgData['405-2']['whitecollars'] != undefined)
        ) {
          const tableDir = parsedHTML.getElementById('tabella_dirigenti');
          const tableOp = parsedHTML.getElementById('tabella_operai');
          const tableImp = parsedHTML.getElementById('tabella_impiegati');
          if (
            esgData['405-2']['managers'] != undefined &&
            esgData['405-2']['managers'].length > 0
          ) {
            popolaTabella(tableDir, esgData['405-2']['managers']);
          }
          if (
            esgData['405-2']['bluecollars'] != undefined &&
            esgData['405-2']['bluecollars'].length > 0
          ) {
            popolaTabella(tableOp, esgData['405-2']['bluecollars']);
          }
          if (
            esgData['405-2']['whitecollars'] != undefined &&
            esgData['405-2']['whitecollars'].length > 0
          ) {
            popolaTabella(tableImp, esgData['405-2']['whitecollars']);
          }

          setDirigentiTable(cloneDeep(esgData['405-2']['managers']));
          setOperaiTable(cloneDeep(esgData['405-2']['bluecollars']));
          setImpiegatiTable(cloneDeep(esgData['405-2']['whitecollars']));
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetPariOpportunitaData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PariOpportunitaEngine;
