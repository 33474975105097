import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Stack, TextArea } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG402_1 = () => {
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [benefits, setBenefits] = useState([
    {
      tipologia: 'Assicurazione Sulla Vita',
      impiegati: 0,
      operai: 0,
      dirigenti: 0,
      descrizione: '',
    },
    {
      tipologia: 'Assistenza Sanitaria',
      impiegati: 0,
      operai: 0,
      dirigenti: 0,
      descrizione: '',
    },
    {
      tipologia: 'Copertura Disabilita',
      impiegati: 0,
      operai: 0,
      dirigenti: 0,
      descrizione: '',
    },
    {
      tipologia: 'Congedo Parentale',
      impiegati: 0,
      operai: 0,
      dirigenti: 0,
      descrizione: '',
    },
    {
      tipologia: 'Contributi Pensione',
      impiegati: 0,
      operai: 0,
      dirigenti: 0,
      descrizione: '',
    },
    {
      tipologia: 'Partecipazione Azionaria',
      impiegati: 0,
      operai: 0,
      dirigenti: 0,
      descrizione: '',
    },
    {
      tipologia: 'altro',
      impiegati: 0,
      operai: 0,
      dirigenti: 0,
      descrizione: '',
    },
  ]);
  const [esg402_1, setEsg402_1] = useState();
  const onChangeRowValue = e => {
    const ex_rowData = benefits;
    ex_rowData[e.rowIndex] = e.data;
    setBenefits(ex_rowData);
    setEsg402_1({ ...esg402_1, ['tabella_benefit']: ex_rowData });
  };

  const columnDefs = [
    {
      headerName: 'Nome/descrizione del piano',
      field: 'descrizione',
      cellEditor: 'agLargeTextCellEditor',
      editable: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Tipologia piano',
      field: 'tipologia',
      editable: true,
      flex: 1,
    },
    { headerName: 'Impiegati', field: 'impiegati', editable: true, flex: 1 },
    { headerName: 'Operai', field: 'operai', editable: true, flex: 1 },
    { headerName: 'Dirigenti', field: 'dirigenti', editable: true, flex: 1 },
  ];

  useEffect(() => {
    if (esgData['402-1'] != undefined) {
      setEsg402_1(esgData['402-1']);
      if (esgData['402-1']['tabella_benefit'] != undefined) {
        setBenefits(esgData['402-1']['tabella_benefit']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('402-1', esg402_1);
  }, [esg402_1]);
  const handleChanges = e => {
    setEsg402_1({ ...esg402_1, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>BENEFIT per i DIPENDENTI</h1>
      <p>
        I dati rendicontati in questa informativa offrono uno strumento di
        misurazione degli investimenti dell'organizzazione nelle risorse umane e
        dei benefici minimi che offre ai dipendenti a tempo pieno. La qualità
        dei benefici offerti ai dipendenti a tempo pieno è un fattore essenziale
        per la fidelizzazione dei dipendenti.
      </p>
      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="descrizione_benefit"
        value={
          esg402_1 && esg402_1['descrizione_benefit'] != undefined
            ? esg402_1['descrizione_benefit']
            : ''
        }
        labelText="Descrivere i principali Benefit che l'organizzazione offre ai suoi dipendenti."
        onChange={handleChanges}
      />
      <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={benefits}
          domLayout="autoHeight"
          onCellValueChanged={onChangeRowValue}
        />
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 402-1 </footer>
    </Stack>
  );
};

export default ESG402_1;
