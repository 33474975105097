import CrescitaProfessionale from '../../../../assets/html_template/professional/A4/12-sociale-gri402-1-crescitaprofessionale.html';
import CrescitaProfessionale169 from '../../../../assets/html_template/professional/169/12-sociale-gri402-1-crescitaprofessionale.html';
import CrescitaProfessionalePopA4 from '../../../../assets/html_template/pop/A4/12-sociale-gri402-1-crescitaprofessionale.html';
import CrescitaProfessionalePop169 from '../../../../assets/html_template/pop/169/12-sociale-gri402-1-crescitaprofessionale.html';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const CrescitaProfessionaleEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString =
        type == 'A4' ? CrescitaProfessionale : CrescitaProfessionale169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? CrescitaProfessionalePopA4 : CrescitaProfessionalePop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString =
        type == 'A4' ? CrescitaProfessionale : CrescitaProfessionale169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [fomrazionesessoTable, setfomrazionesessoTable] = useState([]);
  const [
    formazioneobbligatoriaTable,
    setformazioneobbligatoriaTable,
  ] = useState([]);
  const [
    formazionenonobbligatoriaTable,
    setformazionenonobbligatoriaTable,
  ] = useState([]);
  const [dichiarazioneFormazione, setDichiarazioneFormazione] = useState('');
  const [crescitaProfessionaleData, SetcrescitaProfessionaleData] = useState(
    CrescitaProfessionale
  );
  const [
    crescitaProfessionaleDataA4,
    setcrescitaProfessionaleDataA4,
  ] = useState(CrescitaProfessionale);
  const [
    crescitaProfessionaleData169,
    setcrescitaProfessionaleData169,
  ] = useState(CrescitaProfessionale169);
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetcrescitaProfessionaleData(CrescitaProfessionalePopA4);
    setcrescitaProfessionaleDataA4(CrescitaProfessionalePopA4);
    setcrescitaProfessionaleData169(CrescitaProfessionalePop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetcrescitaProfessionaleData(CrescitaProfessionale);
    setcrescitaProfessionaleDataA4(CrescitaProfessionale);
    setcrescitaProfessionaleData169(CrescitaProfessionale169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetcrescitaProfessionaleData(CrescitaProfessionale);
    setcrescitaProfessionaleDataA4(CrescitaProfessionale);
    setcrescitaProfessionaleData169(CrescitaProfessionale169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();
  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetcrescitaProfessionaleData(CrescitaProfessionalePopA4);
      setcrescitaProfessionaleDataA4(CrescitaProfessionalePopA4);
      setcrescitaProfessionaleData169(CrescitaProfessionalePop169);
      force_update(
        type == 'A4' ? CrescitaProfessionalePopA4 : CrescitaProfessionalePop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetcrescitaProfessionaleData(CrescitaProfessionale);
      setcrescitaProfessionaleDataA4(CrescitaProfessionale);
      setcrescitaProfessionaleData169(CrescitaProfessionale169);
      force_update(
        type == 'A4' ? CrescitaProfessionale : CrescitaProfessionale169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetcrescitaProfessionaleData(CrescitaProfessionale);
      setcrescitaProfessionaleDataA4(CrescitaProfessionale);
      setcrescitaProfessionaleData169(CrescitaProfessionale169);
      force_update(
        type == 'A4' ? CrescitaProfessionale : CrescitaProfessionale169
      );
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('404-1')
    ) {
      setIsVisible(false);
      delPageData('404-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(crescitaProfessionaleData);
    } else {
      if (
        esgData['404-1'] != undefined &&
        esgData['404-1']['dichiarazione_formazione'] != undefined &&
        esgData['404-1']['dichiarazione_formazione'] != dichiarazioneFormazione
      ) {
        hasUpdate = true;
      }
      if (
        esgData['404-1'] != undefined &&
        esgData['404-1']['formazionesesso'] != undefined &&
        !compareLists(esgData['404-1']['formazionesesso'], fomrazionesessoTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['404-1'] != undefined &&
        esgData['404-1']['formazionenonobbligatoria'] != undefined &&
        !compareLists(
          esgData['404-1']['formazionenonobbligatoria'],
          formazionenonobbligatoriaTable
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['404-1'] != undefined &&
        esgData['404-1']['formazioneobbligatoria'] != undefined &&
        !compareLists(
          esgData['404-1']['formazioneobbligatoria'],
          formazioneobbligatoriaTable
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    try {
      // Verifica se le lunghezze delle liste sono diverse
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    let ordered_keyssesso = [
      'anno',
      'uomini',
      'donne',
      'oreMedie',
      'oreTotali',
    ];
    let ordered_keys = ['anno', 'uomini', 'donne', 'oreMedie', 'argomento'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = crescitaProfessionaleData169;
        to_init = '169';
      } else {
        templateHTMLString = crescitaProfessionaleDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['404-1'] != undefined &&
        esgData['404-1']['dichiarazione_formazione'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'dichiarazione_formazione'
        );
        fotoLeaderElement.innerHTML =
          esgData['404-1']['dichiarazione_formazione'];
        setDichiarazioneFormazione(
          esgData['404-1']['dichiarazione_formazione']
        );
      }
      //&&!compareLists(tabellacriticita,esgData["404-1"]["rendicontazione_criticita"])

      if (
        esgData['404-1'] != undefined &&
        esgData['404-1']['formazionesesso'] != undefined
      ) {
        const table = parsedHTML.getElementById('tabella_formazionesesso');
        const grafico = parsedHTML.getElementById('formazionesesso');
        if (esgData['404-1']['formazionesesso'].length > 0) {
          //const filtered_data=esgData["404-1"]["formazionesesso"].filter((elemento)=>elemento.nonConferitiInDiscarica!==0||elemento.quantita!==0||elemento.conferitiInDiscarica!==0)

          popolaTabella(
            table,
            esgData['404-1']['formazionesesso'],
            ordered_keyssesso
          );
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          const sumByYear = esgData['404-1']['formazionesesso'].reduce(
            (acc, { uomini, donne }) => ({
              uomini: acc.uomini + uomini,
              donne: acc.donne + donne,
            }),
            { uomini: 0, donne: 0 }
          );
          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
          //TOSTART
          var data = ${JSON.stringify(sumByYear, null, 2)};
          //TOEND;

        ` + grafico.textContent;
        }
        setfomrazionesessoTable(cloneDeep(esgData['404-1']['formazionesesso']));
      }

      if (
        esgData['404-1'] != undefined &&
        esgData['404-1']['formazioneobbligatoria'] != undefined
      ) {
        const table = parsedHTML.getElementById(
          'tabella_formazioneobbligatoria'
        );
        const grafico = parsedHTML.getElementById('formazioneobbligatoria');
        if (esgData['404-1']['formazioneobbligatoria'].length > 0) {
          //const filtered_data=esgData["404-1"]["formazionesesso"].filter((elemento)=>elemento.nonConferitiInDiscarica!==0||elemento.quantita!==0||elemento.conferitiInDiscarica!==0)

          popolaTabella(
            table,
            esgData['404-1']['formazioneobbligatoria'],
            ordered_keys
          );
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );
          const sumByYear = esgData['404-1']['formazioneobbligatoria'].reduce(
            (acc, { uomini, donne }) => ({
              uomini: acc.uomini + uomini,
              donne: acc.donne + donne,
            }),
            { uomini: 0, donne: 0 }
          );
          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
          //TOSTART
          var data2 = ${JSON.stringify(sumByYear, null, 2)};
          //TOEND;

        ` + grafico.textContent;
        }
        setformazioneobbligatoriaTable(
          cloneDeep(esgData['404-1']['formazioneobbligatoria'])
        );
      }

      if (
        esgData['404-1'] != undefined &&
        esgData['404-1']['formazionenonobbligatoria'] != undefined
      ) {
        const table = parsedHTML.getElementById(
          'tabella_formazionenonobbligatoria'
        );
        const grafico = parsedHTML.getElementById('formazionenonobbligatoria');
        if (esgData['404-1']['formazionenonobbligatoria'].length > 0) {
          //const filtered_data=esgData["404-1"]["formazionesesso"].filter((elemento)=>elemento.nonConferitiInDiscarica!==0||elemento.quantita!==0||elemento.conferitiInDiscarica!==0)

          popolaTabella(
            table,
            esgData['404-1']['formazionenonobbligatoria'],
            ordered_keys
          );
          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );
          const sumByYear = esgData['404-1'][
            'formazionenonobbligatoria'
          ].reduce(
            (acc, { uomini, donne }) => ({
              uomini: acc.uomini + uomini,
              donne: acc.donne + donne,
            }),
            { uomini: 0, donne: 0 }
          );
          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
          //TOSTART
          var data3 = ${JSON.stringify(sumByYear, null, 2)};
          //TOEND;

        ` + grafico.textContent;
        }
        setformazionenonobbligatoriaTable(
          cloneDeep(esgData['404-1']['formazionenonobbligatoria'])
        );
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('404-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Crescita Professionale',
          initA4: CrescitaProfessionalePopA4,
          init169: CrescitaProfessionalePop169,
          keys_to_check: [
            'formazionenonobbligatoria',
            'formazioneobbligatoria',
            'formazionesesso',
          ],
          gri: '404-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('404-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Crescita Professionale',
          initA4: CrescitaProfessionale,
          init169: CrescitaProfessionale169,
          keys_to_check: [
            'formazionenonobbligatoria',
            'formazioneobbligatoria',
            'formazionesesso',
          ],
          gri: '404-1',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    let ordered_keyssesso = [
      'anno',
      'uomini',
      'donne',
      'oreMedie',
      'oreTotali',
    ];
    try {
      // Ottieni il tuo template HTML dall'import
      let ordered_keys = ['anno', 'uomini', 'donne', 'oreMedie', 'argomento'];
      const templateHTMLString =
        templateData == '' ? crescitaProfessionaleData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['404-1'] != undefined &&
          esgData['404-1']['dichiarazione_formazione'] != undefined &&
          esgData['404-1']['dichiarazione_formazione'] !=
            dichiarazioneFormazione
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'dichiarazione_formazione'
          );
          fotoLeaderElement.innerHTML =
            esgData['404-1']['dichiarazione_formazione'];
          setDichiarazioneFormazione(
            esgData['404-1']['dichiarazione_formazione']
          );
        }
        //&&!compareLists(tabellacriticita,esgData["404-1"]["rendicontazione_criticita"])

        if (
          esgData['404-1'] != undefined &&
          esgData['404-1']['formazionesesso'] != undefined &&
          !compareLists(
            esgData['404-1']['formazionesesso'],
            fomrazionesessoTable
          )
        ) {
          const table = parsedHTML.getElementById('tabella_formazionesesso');
          const grafico = parsedHTML.getElementById('formazionesesso');
          if (esgData['404-1']['formazionesesso'].length > 0) {
            //const filtered_data=esgData["404-1"]["formazionesesso"].filter((elemento)=>elemento.nonConferitiInDiscarica!==0||elemento.quantita!==0||elemento.conferitiInDiscarica!==0)

            popolaTabella(
              table,
              esgData['404-1']['formazionesesso'],
              ordered_keyssesso
            );
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );
            const sumByYear = esgData['404-1']['formazionesesso'].reduce(
              (acc, { uomini, donne }) => ({
                uomini: acc.uomini + uomini,
                donne: acc.donne + donne,
              }),
              { uomini: 0, donne: 0 }
            );
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
            //TOSTART
            var data = ${JSON.stringify(sumByYear, null, 2)};
            //TOEND;
  
          ` + grafico.textContent;
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
          }
          setfomrazionesessoTable(
            cloneDeep(esgData['404-1']['formazionesesso'])
          );
        }

        if (
          esgData['404-1'] != undefined &&
          esgData['404-1']['formazioneobbligatoria'] != undefined &&
          !compareLists(
            esgData['404-1']['formazioneobbligatoria'],
            formazioneobbligatoriaTable
          )
        ) {
          const table = parsedHTML.getElementById(
            'tabella_formazioneobbligatoria'
          );
          const grafico = parsedHTML.getElementById('formazioneobbligatoria');
          if (esgData['404-1']['formazioneobbligatoria'].length > 0) {
            //const filtered_data=esgData["404-1"]["formazionesesso"].filter((elemento)=>elemento.nonConferitiInDiscarica!==0||elemento.quantita!==0||elemento.conferitiInDiscarica!==0)

            popolaTabella(
              table,
              esgData['404-1']['formazioneobbligatoria'],
              ordered_keys
            );
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );
            const sumByYear = esgData['404-1']['formazioneobbligatoria'].reduce(
              (acc, { uomini, donne }) => ({
                uomini: acc.uomini + uomini,
                donne: acc.donne + donne,
              }),
              { uomini: 0, donne: 0 }
            );
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
            //TOSTART
            var data2 = ${JSON.stringify(sumByYear, null, 2)};
            //TOEND;
  
          ` + grafico.textContent;
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
          }
          setformazioneobbligatoriaTable(
            cloneDeep(esgData['404-1']['formazioneobbligatoria'])
          );
        }

        if (
          esgData['404-1'] != undefined &&
          esgData['404-1']['formazionenonobbligatoria'] != undefined &&
          !compareLists(
            esgData['404-1']['formazionenonobbligatoria'],
            formazionenonobbligatoriaTable
          )
        ) {
          const table = parsedHTML.getElementById(
            'tabella_formazionenonobbligatoria'
          );
          const grafico = parsedHTML.getElementById(
            'formazionenonobbligatoria'
          );
          if (esgData['404-1']['formazionenonobbligatoria'].length > 0) {
            //const filtered_data=esgData["404-1"]["formazionesesso"].filter((elemento)=>elemento.nonConferitiInDiscarica!==0||elemento.quantita!==0||elemento.conferitiInDiscarica!==0)

            popolaTabella(
              table,
              esgData['404-1']['formazionenonobbligatoria'],
              ordered_keys
            );

            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );
            const sumByYear = esgData['404-1'][
              'formazionenonobbligatoria'
            ].reduce(
              (acc, { uomini, donne }) => ({
                uomini: acc.uomini + uomini,
                donne: acc.donne + donne,
              }),
              { uomini: 0, donne: 0 }
            );
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
            //TOSTART
            var data3 = ${JSON.stringify(sumByYear, null, 2)};
            //TOEND;
  
          ` + grafico.textContent;
          }
          setformazionenonobbligatoriaTable(
            cloneDeep(esgData['404-1']['formazionenonobbligatoria'])
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['404-1'] != undefined &&
          esgData['404-1']['dichiarazione_formazione'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'dichiarazione_formazione'
          );
          fotoLeaderElement.innerHTML =
            esgData['404-1']['dichiarazione_formazione'];
          setDichiarazioneFormazione(
            esgData['404-1']['dichiarazione_formazione']
          );
        }
        //&&!compareLists(tabellacriticita,esgData["404-1"]["rendicontazione_criticita"])

        if (
          esgData['404-1'] != undefined &&
          esgData['404-1']['formazionesesso'] != undefined
        ) {
          const table = parsedHTML.getElementById('tabella_formazionesesso');
          const grafico = parsedHTML.getElementById('formazionesesso');
          if (esgData['404-1']['formazionesesso'].length > 0) {
            //const filtered_data=esgData["404-1"]["formazionesesso"].filter((elemento)=>elemento.nonConferitiInDiscarica!==0||elemento.quantita!==0||elemento.conferitiInDiscarica!==0)

            popolaTabella(
              table,
              esgData['404-1']['formazionesesso'],
              ordered_keyssesso
            );
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );
            const sumByYear = esgData['404-1']['formazionesesso'].reduce(
              (acc, { uomini, donne }) => ({
                uomini: acc.uomini + uomini,
                donne: acc.donne + donne,
              }),
              { uomini: 0, donne: 0 }
            );
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
          //TOSTART
          var data = ${JSON.stringify(sumByYear, null, 2)};
          //TOEND;

        ` + grafico.textContent;
            // Inserisci la nuova definizione di data nello script
          }
          setfomrazionesessoTable(
            cloneDeep(esgData['404-1']['formazionesesso'])
          );
        }

        if (
          esgData['404-1'] != undefined &&
          esgData['404-1']['formazioneobbligatoria'] != undefined
        ) {
          const table = parsedHTML.getElementById(
            'tabella_formazioneobbligatoria'
          );
          const grafico = parsedHTML.getElementById('formazioneobbligatoria');
          if (esgData['404-1']['formazioneobbligatoria'].length > 0) {
            //const filtered_data=esgData["404-1"]["formazionesesso"].filter((elemento)=>elemento.nonConferitiInDiscarica!==0||elemento.quantita!==0||elemento.conferitiInDiscarica!==0)

            popolaTabella(
              table,
              esgData['404-1']['formazioneobbligatoria'],
              ordered_keys
            );
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );
            const sumByYear = esgData['404-1']['formazioneobbligatoria'].reduce(
              (acc, { uomini, donne }) => ({
                uomini: acc.uomini + uomini,
                donne: acc.donne + donne,
              }),
              { uomini: 0, donne: 0 }
            );
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
            //TOSTART
            var data2 = ${JSON.stringify(sumByYear, null, 2)};
            //TOEND;
  
          ` + grafico.textContent;
          }
          setformazioneobbligatoriaTable(
            cloneDeep(esgData['404-1']['formazioneobbligatoria'])
          );
        }

        if (
          esgData['404-1'] != undefined &&
          esgData['404-1']['formazionenonobbligatoria'] != undefined
        ) {
          const table = parsedHTML.getElementById(
            'tabella_formazionenonobbligatoria'
          );
          const grafico = parsedHTML.getElementById(
            'formazionenonobbligatoria'
          );
          if (esgData['404-1']['formazionenonobbligatoria'].length > 0) {
            //const filtered_data=esgData["404-1"]["formazionesesso"].filter((elemento)=>elemento.nonConferitiInDiscarica!==0||elemento.quantita!==0||elemento.conferitiInDiscarica!==0)

            popolaTabella(
              table,
              esgData['404-1']['formazionenonobbligatoria'],
              ordered_keys
            );
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );
            const sumByYear = esgData['404-1'][
              'formazionenonobbligatoria'
            ].reduce(
              (acc, { uomini, donne }) => ({
                uomini: acc.uomini + uomini,
                donne: acc.donne + donne,
              }),
              { uomini: 0, donne: 0 }
            );
            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
            //TOSTART
            var data3 = ${JSON.stringify(sumByYear, null, 2)};
            //TOEND;
  
          ` + grafico.textContent;
          }
          setformazionenonobbligatoriaTable(
            cloneDeep(esgData['404-1']['formazionenonobbligatoria'])
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetcrescitaProfessionaleData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CrescitaProfessionaleEngine;
