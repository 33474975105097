import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Information } from '@carbon/icons-react';
import { TextInput, Tooltip, Stack, TextArea } from '@carbon/react';
import ESGTableComitati from '../ESGTableComitati/ESGTableComitati';
import ESGTableMaxGoverno from '../ESGTableMaxGoverno/ESGMaxGoverno';
import { useESG } from '../../../action/ESGData/esgdata';

function ESG102_9() {
  const gridApiRef = useRef(null);
  const [esg102_9, setEsg102_9] = useState({});
  const { esgData, pushESGData, refreshESGData } = useESG();
  useEffect(() => {
    if (esgData['102-9'] != undefined) {
      setEsg102_9(esgData['102-9']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  const setStateFun = (key, value) => {
    setEsg102_9({ ...esg102_9, [key]: value });
  };
  useEffect(() => {
    pushESGData('102-9', esg102_9);
  }, [esg102_9]);

  const handleChanges = e => {
    setEsg102_9({ ...esg102_9, [e.target.id]: e.target.value });
  };

  return (
    <Stack gap={8}>
      <h1>GRI 102-9 </h1>
      <h2>Struttura e composizione della governance.</h2>
      <p>Descrivere la composizione del governo e dei suoi comitati.</p>
      <TextArea
        onChange={handleChanges}
        id="composizione_governo"
        value={
          esg102_9['composizione_governo'] != undefined
            ? esg102_9['composizione_governo']
            : ''
        }
        maxCount={1000}
        enableCounter={true}
        placeholder="L’organizzazione può descrivere la composizione del massimo organo di governo e dei suoi comitati mediante indicatori aggiuntivi di diversità, 
      come età, ascendenza e origine etnica,cittadinanza, credo religioso, disabilità o qualsiasi altro indicatore di diversità che sia importante ai fini della rendicontazione."
      />
      <ESGTableComitati esg102_9={esg102_9} setStateFun={setStateFun} />
      <ESGTableMaxGoverno esg102_9={esg102_9} setStateFun={setStateFun} />
    </Stack>
  );
}

function LargeTextCellEditor(props) {
  const { value, onValueChange } = props;

  const handleTextInputChange = e => {
    onValueChange(e.target.value);
  };

  return <TextInput value={value} onChange={handleTextInputChange} />;
}

export default ESG102_9;
