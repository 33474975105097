import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Tooltip, Button, Stack, TextArea, Grid, Column } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useESG } from '../../../action/ESGData/esgdata';
import UploadImage from '../../UploadImage';
const ESG301_1 = () => {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);

  const [esg301_1, setEsg301_1] = useState();
  const { esgData, pushESGData, refreshESGData } = useESG();
  const columnDefs = [
    {
      headerName: 'Nome Materiale',
      field: 'name',
      headerTooltip:
        'Inserisci il nome del materiale (es. legno, vetro, plastica, carta, metallo, etc.)',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Quantità Materiale in peso o volume',
      headerTooltip: 'Inserisci la quantità del materiale in peso o volume',

      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      field: 'quantity',
      flex: 1,
    },
    {
      headerName: '% Rinnovabile',
      field: 'renewable',
      headerTooltip:
        'Inserisci la percentuale del materiale che è rinnovabile (es. 100% se completamente rinnovabile, 50% se metà del materiale è rinnovabile, etc.)',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];

  const handleAddRow = () => {
    if (rowData.length < 5) {
      const newRow = { name: '', quantity: 0, renewable: 0 };
      setRowData([...rowData, newRow]);
      setEsg301_1({
        ...esg301_1,
        ['peso_volume_tabella']: [...rowData, newRow],
      });
    }
  };
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg301_1({ ...esg301_1, ['peso_volume_tabella']: ex_rowData });
  };
  useEffect(() => {
    if (esgData['301-1'] != undefined) {
      setEsg301_1(esgData['301-1']);
      if (esgData['301-1']['peso_volume_tabella']) {
        setRowData(esgData['301-1']['peso_volume_tabella']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('301-1', esg301_1);
  }, [esg301_1]);
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg301_1({ ...esg301_1, ['peso_volume_tabella']: updatedRowData });
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const handleChanges = e => {
    setEsg301_1({ ...esg301_1, [e.target.id]: e.target.value });
  };

  return (
    <Stack gap={8}>
      <h1>Peso/Volume Totale Materiali</h1>
      <p>
        Rendicontare in riferimento all'utilizzo rivolto alla produzione e al
        confezionamento di prodotti e dei servizi principali durante il periodo
        di rendicontazione
      </p>
      <TextArea
        maxCount={1500}
        enableCounter={true}
        id="descrizione_materiali"
        value={
          esg301_1 && esg301_1['descrizione_materiali'] != undefined
            ? esg301_1['descrizione_materiali']
            : ''
        }
        labelText="Descrivere i principali materiali utilizzati nel processo produttivo/confezionamento."
        onChange={handleChanges}
      />

      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          onCellValueChanged={onChangeRowValue}
        />
        <Button kind="ghost" onClick={handleAddRow}>
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga.  E' possibile popolare fino a 5 righe.">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 301-1 </footer>
    </Stack>
  );
};

export default ESG301_1;
