import { Folder, Document } from '@carbon/react/icons';

class JsonToReactConverter {
  constructor(jsonData) {
    this.jsonData = jsonData;

    this.nodes = [
      {
        id: 'GRI_all',
        value: 'std-GRI',
        label: 'Riferimenti',
        isExpanded: true,
        active: true,
        renderIcon: Folder,
        children: [],
      },
    ];
    this.mapping_parent = {
      '102': 'Informativa Generale',
      '201': 'Performance economica',
      '203': 'Impatti economici indiretti',
      '204': 'Valutazione dei Fornitori',
      '205': 'Anticorruzione',
      '206': 'Comportamento anticompetitivo',
      '301': 'Economia Circolare',
      '302': 'Consumo di Energia',
      '303': 'Consumo Idrico',
      '305': 'Emissioni',
      '306': 'Rifiuti',
      '308': 'Valutazione ambientale dei fornitori',
      '401': 'Occupazione',
      '402': 'Benefit per i Dipendenti',
      '403': 'Salute e sicurezza sul lavoro',
      '404': 'Formazione e istruzione',
      '405': 'Diversità e pari opportunità',
      '406': 'Non discriminazione',
      '408': 'Lavoro minorile e lavoro forzato e obbligatorio',
      '413': 'Comunità locale',
      obi: 'Obiettivi ESG',
    };
  }

  groupByGriPrefix() {
    const groupedData = {};

    this.jsonData.forEach(item => {
      const griPrefix = item.gri.substring(0, 3);
      if (!groupedData[griPrefix]) {
        groupedData[griPrefix] = [];
      }
      groupedData[griPrefix].push(item);
    });

    return groupedData;
  }

  convert() {
    const groupedData = this.groupByGriPrefix();

    for (const griPrefix in groupedData) {
      const griNodes = groupedData[griPrefix];

      const parentNode = {
        id: `GRI${griPrefix}_group`,
        value: `info-aziendali-${griPrefix}`,
        label: ` ${this.mapping_parent[griPrefix]}`,
        active: true,
        isExpanded: true,
        renderIcon: Folder,
        children: [],
      };
      //${item.gri}
      griNodes.forEach(item => {
        const node = {
          id: `${item.gri}`,
          value: `anagrafica-azienda`,
          label: ` ${item.title}`,
          renderIcon: Document,
          question_settings: [
            {
              id: `${item.gri}`,
              type: item.description,
              question_title: item.question,
              pattern: '',
            },
          ],
        };

        parentNode.children.push(node);
      });

      this.nodes[0].children.push(parentNode);
    }

    return this.nodes;
  }
}
export default JsonToReactConverter;
