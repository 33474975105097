import React, { useState, useEffect } from 'react';

import { TextArea, Stack, RadioButton, RadioButtonGroup } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG102_14 = props => {
  const [esg102_14, setEsg102_14] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-14'] != undefined) {
      setEsg102_14(esgData['102-14']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-14', esg102_14);
  }, [esg102_14]);

  //si utilizza useEffect per caricare i dati in ingresso se ci sono

  const handleChanges = e => {
    setEsg102_14({ ...esg102_14, [e.target.id]: e.target.value });
  };
  const handleChangesRadioSI = e => {
    var old_data = esg102_14;
    old_data['responsabilita_aog_rendicontazione'] = 'SI';

    setEsg102_14(old_data);
  };
  const handleChangesRadioNO = e => {
    var old_data = esg102_14;
    old_data['responsabilita_aog_rendicontazione'] = 'NO';

    setEsg102_14(old_data);
  };
  return (
    <Stack gap={8}>
      <h1>GRI 102-14 </h1>
      <h2>
        Ruolo del massimo organo di governo nella rendicontazione di
        sostenibilità
      </h2>
      <p>Completare i campi con le informazioni richieste.</p>

      <RadioButtonGroup
        legendText="Fa capo al massimo organo di governo la responsabilità di rivedere e approvare le informazioni riferite in ambito di questa rendicontazione?"
        name="Responsabilità ESG Massimo Organo di Governo">
        <RadioButton
          labelText="SI"
          onClick={handleChangesRadioSI}
          value={true}
          id="si"
          defaultChecked={
            esg102_14['responsabilita_aog_rendicontazione'] == 'SI'
              ? true
              : false
          }
        />
        <RadioButton
          labelText="NO"
          onClick={handleChangesRadioNO}
          value={false}
          id="no"
          defaultChecked={
            esg102_14['responsabilita_aog_rendicontazione'] != 'SI'
              ? false
              : true
          }
        />
      </RadioButtonGroup>

      {
        (esg102_14['responsabilita_aog_rendicontazione'] = 'no' ? (
          <TextArea
            id="responsabilita_aog_rendicontazione_no"
            maxCount={500}
            enableCounter={true}
            value={
              esg102_14['responsabilita_aog_rendicontazione_no'] != undefined
                ? esg102_14['responsabilita_aog_rendicontazione_no']
                : ''
            }
            placeholder="Con testo libero, se la responsabilità di rivedere e approvare le informazioni riferite, compresi i temi
      materiali, non fa capo al massimo organo di governo, spiegarne il motivo"
            onChange={handleChanges}
            labelText="Approvazione del Massimo Organo di Governo verso le tematiche ESG"
          />
        ) : (
          <TextArea
            id="responsabilita_aog_rendicontazione_si"
            maxCount={500}
            enableCounter={true}
            value={
              esg102_14['responsabilita_aog_rendicontazione_si'] != undefined
                ? esg102_14['responsabilita_aog_rendicontazione_si']
                : ''
            }
            placeholder="Descrivere con testo libero la procedura di revisione e approvazione delle informazioni"
            onChange={handleChanges}
            labelText="Approvazione del Massimo Organo di Governo verso le tematiche ESG"
          />
        ))
      }
    </Stack>
  );
};

export default ESG102_14;
