import React, { useState } from 'react';
import { ColorExtractor } from 'react-color-extractor';
import { Button, FileUploader, Stack } from '@carbon/react';
import { isEqual } from 'lodash';
import { bucket, auth } from '../../action/fiirebase_config/firebase_config';
const IMAGE_ONE = 'https://i.imgur.com/OCyjHNF.jpg';

const IMAGE = IMAGE_ONE;

const IMAGE_STYLES = { width: '50%' };

const SWATCHES_STYLES = {
  marginTop: 20,
  display: 'flex',
  justifyContent: 'center',
  width: '50%',
};

class CustomColorExtractor extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      colors: [],
      selected_img: [],
      logo_url: '',
      img_data: [],
      isImageSelected: false,
      error: false,
      error_msg: '',
      canSelectImage: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedItem != undefined &&
      !isEqual(this.props.selectedItem, prevProps.selectedItem)
    ) {
      console.log(this.props.selectedItem);
      if (
        this.props.selectedItem['logo_url'] != undefined &&
        this.props.selectedItem['logo_url'] != ''
      ) {
        this.setState(
          state => (
            (state.logo_url = this.props.selectedItem['logo_url']), state
          ),
          async () => {
            var imageRef = bucket
              .ref()
              .child(
                auth.currentUser.uid +
                  '/data/' +
                  this.props.selectedItem['reportId'] +
                  '/img/logoData'
              );
            // Get the download URL
            const url = await imageRef.getDownloadURL();
            console.log("SETTO URL")
            this.setState(
              state => (
                (state.img_data = this.props.selectedItem['img_data']), state
              )
            );
          }
        );
      } else {
        this.setState(
          state => (
            (state.img_data = this.props.selectedItem['img_data']), state
          )
        );
      }

      this.setState(
        state => ((state.colors = this.props.selectedItem['colors']), state)
      );
      this.setState(
        state => (
          (state.isImageSelected = this.props.selectedItem['isImageSelected']),
          state
        )
      );
      this.setState(
        state => (
          (state.selected_img = this.props.selectedItem['selected_img']), state
        )
      );
    }
  }

  renderSwatches = () => {
    return this.state.colors.map((color, id) => {
      return (
        <div
          key={id}
          style={{
            backgroundColor: color,
            width: 100,
            height: 100,
          }}
        />
      );
    });
  };
  reader_img = file => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      this.setState(state => ((state.img_data = reader.result), state));
    });
    reader.addEventListener('error', error => {
      // Errore durante la lettura del file
      console.error('Errore durante la lettura del file:', error);
    });
    reader.readAsDataURL(file);
  };

  getColors = colors => {
    if (this.state.colors.length != 6) {
      this.setState(
        state => ({ colors: [...state.colors, ...colors] }),
        () => {
          this.props.setImage(this.state);
        }
      );
    }
  };

  setimg = async path => {
    console.log("eccomi in path")
    console.log(path);
    this.setState(state => ((state.canSelectImage = false), state));
    if (path.type.split('/')[0] == 'image') {
      if (path.size <= 5200506) {
        await bucket
          .ref(
            auth.currentUser.uid +
              '/data/' +
              this.props.selectedItem['reportId'] +
              '/img/logoData'
          )
          .put(path);
        this.setState(
          state => (
            (state.logo_url =
              auth.currentUser.uid +
              '/data/' +
              this.props.selectedItem['reportId'] +
              '/img/logoData'),
            state
          )
        );
        this.setState(state => ((state.error = false), state));
        this.setState(state => ((state.error_msg = ''), state));
        var imageRef = bucket
          .ref()
          .child(
            auth.currentUser.uid +
              '/data/' +
              this.props.selectedItem['reportId'] +
              '/img/logoData'
          );
        // Get the download URL
        const url = await imageRef.getDownloadURL();
        console.log('Prodotta nuova url');
        console.log(url)
        this.setState(state => ((state.selected_img = path), state));
        this.setState(state => ((state.img_data = url), state));
        /*  this.setState(
            state => ((state.selected_img = path), state),
            () => {
              this.reader_img(this.state.selected_img);
            }
          ); */
        this.setState(state => ((state.isImageSelected = true), state));
        
      } else {
        this.setState(state => ((state.isImageSelected = false), state));
        this.setState(state => ((state.error = true), state));
        this.setState(
          state => (
            (state.error_msg = 'Il file caricato è troppo grande.'), state
          )
        );
      }
    } else {
      this.setState(state => ((state.isImageSelected = false), state));
      this.setState(state => ((state.error = true), state));
      this.setState(
        state => (
          (state.error_msg = 'Il file caricato è troppo grande.'), state
        )
      );
    }
  };

  /*           <h3>Colori Palette Powerpoint:</h3>
            <div style={SWATCHES_STYLES}>{this.renderSwatches()}</div> */
  render() {
    return (
      <div>
        <FileUploader
          labelTitle={'Carica il logo aziendale'}
          onChange={event => {
            this.setimg(event.target.files[0]);
          }}
          onDelete={event => {
            this.setState(state => ((state.canSelectImage = true), state));
            this.setState(state => ((state.error = false), state));
            this.setState(state => ((state.error_msg = ''), state));
            this.setState(state => ((state.selected_img = []), state));
            this.setState(state => ((state.img_data = []), state));
            try {
              bucket
                .ref()
                .child(this.state.logo_url)
                .delete()
                .then(this.setState(state => ((state.logo_url = ''), state)))
                .catch(e => {
                  console.log(e);
                });
            } catch (e) {
              console.log(e);
              this.setState(state => ((state.logo_url = ''), state));
            }
            this.setState(
              state => ((state.colors = []), state),
              () => {
                this.props.setImage(this.state);
              }
            );
            this.setState(state => ((state.isImageSelected = false), state));
          }}
          labelDescription="Soltanto file in formato immagine sono supportati, fino a 5Mb."
          buttonLabel="Aggiungi file"
          disabled={!this.state.canSelectImage}
          buttonKind="ghost"
          size="md"
          filenameStatus="edit"
          accept={['.jpg', '.jpeg', '.png']}
          multiple={false}
          iconDescription="Elimina file"
          name=""
        />

        {this.state.isImageSelected && !this.state.error ? (
          <Stack gap={4}>
            <ColorExtractor getColors={this.getColors}>
              <img src={this.state.img_data} style={IMAGE_STYLES} />
            </ColorExtractor>
          </Stack>
        ) : (
          <p style={{ fontSize: 8, color: 'red' }}>{this.state.error_msg}</p>
        )}
      </div>
    );
  }
}
export default CustomColorExtractor;
