import Stakeholder from '../../../../assets/html_template/professional/A4/08-informativa-generale-gri102-29-stakeholder.html';
import Stakeholder169 from '../../../../assets/html_template/professional/169/09-informativa-generale-gri102-29-stakeholder.html';
import StakeholderPopA4 from '../../../../assets/html_template/pop/A4/08-informativa-generale-gri102-29-stakeholder.html';
import StakeholderPop169 from '../../../../assets/html_template/pop/169/09-informativa-generale-gri102-29-stakeholder.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';

const StakeholderEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? Stakeholder : Stakeholder169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString = type == 'A4' ? StakeholderPopA4 : StakeholderPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? Stakeholder : Stakeholder169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [scopoCoinvolgimento, setScopoCoinvolgimento] = useState('');
  const [comeCoinvolgimento, setComeCoinvolgimento] = useState('');

  const [stakeholderData, SetStakeholderData] = useState(Stakeholder);
  const [stakeholderDataA4, SetStakeholderDataA4] = useState(Stakeholder);
  const [stakeholderData169, SetStakeholderData169] = useState(Stakeholder169);
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetStakeholderData(StakeholderPopA4);
    SetStakeholderDataA4(StakeholderPopA4);
    SetStakeholderData169(StakeholderPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetStakeholderData(Stakeholder);
    SetStakeholderDataA4(Stakeholder);
    SetStakeholderData169(Stakeholder169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetStakeholderData(Stakeholder);
    SetStakeholderDataA4(Stakeholder);
    SetStakeholderData169(Stakeholder169);
  }

  const [stakeholderTable, setStakeholderTable] = useState([]);
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;

    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetStakeholderData(StakeholderPopA4);
      SetStakeholderDataA4(StakeholderPopA4);
      SetStakeholderData169(StakeholderPop169);
      force_update(type == 'A4' ? StakeholderPopA4 : StakeholderPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetStakeholderData(Stakeholder);
      SetStakeholderDataA4(Stakeholder);
      SetStakeholderData169(Stakeholder169);
      force_update(type == 'A4' ? Stakeholder : Stakeholder169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetStakeholderData(Stakeholder);
      SetStakeholderDataA4(Stakeholder);
      SetStakeholderData169(Stakeholder169);
      force_update(type == 'A4' ? Stakeholder : Stakeholder169);
    }
    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('102-29')
    ) {
      setIsVisible(false);
      delPageData('102-29');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(stakeholderData);
    } else {
      if (
        esgData['102-29'] != undefined &&
        esgData['102-29']['scopo_stakeholder'] != undefined &&
        esgData['102-29']['scopo_stakeholder'] != scopoCoinvolgimento
      ) {
        hasUpdate = true;
      }

      if (
        esgData['102-29'] != undefined &&
        esgData['102-29']['come_stakeholder'] != undefined &&
        esgData['102-29']['come_stakeholder'] != comeCoinvolgimento
      ) {
        hasUpdate = true;
      }

      if (
        esgData['102-29'] != undefined &&
        esgData['102-29']['tabella_stakeholder'] != undefined &&
        !compareLists(
          esgData['102-29']['tabella_stakeholder'],
          stakeholderTable
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = stakeholderData169;
        to_init = '169';
      } else {
        templateHTMLString = stakeholderDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['102-29'] != undefined &&
        esgData['102-29']['scopo_stakeholder'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'scopo_coinvolgimento'
        );
        fotoLeaderElement.innerHTML = esgData['102-29']['scopo_stakeholder'];
        setScopoCoinvolgimento(esgData['102-29']['scopo_stakeholder']);
      }
      //&&!compareLists(tabellacriticita,esgData["102-29"]["rendicontazione_criticita"])
      if (
        esgData['102-29'] != undefined &&
        esgData['102-29']['come_stakeholder'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'come_coinvolgimento'
        );
        fotoLeaderElement.innerHTML = esgData['102-29']['come_stakeholder'];
        setComeCoinvolgimento(esgData['102-29']['come_stakeholder']);
      }

      if (
        esgData['102-29'] != undefined &&
        esgData['102-29']['tabella_stakeholder'] != undefined
      ) {
        const table = parsedHTML.getElementById('tabella_stakeholder');
        if (esgData['102-29']['tabella_stakeholder'].length > 0) {
          popolaTabella(table, esgData['102-29']['tabella_stakeholder']);
          setStakeholderTable(
            cloneDeep(esgData['102-29']['tabella_stakeholder'])
          );
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('102-29', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Stakeholder Engagement',
          initA4: StakeholderPopA4,
          init169: StakeholderPopA4,
          keys_to_check: [
            'tabella_stakeholder',
            'scopo_coinvolgimento',
            'come_coinvolgimento',
          ],
          gri: '102-29',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('102-29', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Stakeholder Engagement',
          initA4: Stakeholder,
          init169: Stakeholder169,
          keys_to_check: [
            'tabella_stakeholder',
            'scopo_coinvolgimento',
            'come_coinvolgimento',
          ],
          gri: '102-29',
        });
      }
    }
  };
  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData != '' ? templateData : stakeholderData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['102-29'] != undefined &&
          esgData['102-29']['scopo_stakeholder'] != undefined &&
          esgData['102-29']['scopo_stakeholder'] != scopoCoinvolgimento
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'scopo_coinvolgimento'
          );
          fotoLeaderElement.innerHTML = esgData['102-29']['scopo_stakeholder'];
          setScopoCoinvolgimento(esgData['102-29']['scopo_stakeholder']);
        }
        //&&!compareLists(tabellacriticita,esgData["102-29"]["rendicontazione_criticita"])
        if (
          esgData['102-29'] != undefined &&
          esgData['102-29']['come_stakeholder'] != undefined &&
          esgData['102-29']['come_stakeholder'] != comeCoinvolgimento
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'come_coinvolgimento'
          );
          fotoLeaderElement.innerHTML = esgData['102-29']['come_stakeholder'];
          setComeCoinvolgimento(esgData['102-29']['come_stakeholder']);
        }

        if (
          esgData['102-29'] != undefined &&
          esgData['102-29']['tabella_stakeholder'] != undefined &&
          !compareLists(
            esgData['102-29']['tabella_stakeholder'],
            stakeholderTable
          )
        ) {
          const table = parsedHTML.getElementById('tabella_stakeholder');
          if (esgData['102-29']['tabella_stakeholder'].length > 0) {
            popolaTabella(table, esgData['102-29']['tabella_stakeholder']);
            setStakeholderTable(
              cloneDeep(esgData['102-29']['tabella_stakeholder'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['102-29'] != undefined &&
          esgData['102-29']['scopo_stakeholder'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'scopo_coinvolgimento'
          );
          fotoLeaderElement.innerHTML = esgData['102-29']['scopo_stakeholder'];
          setScopoCoinvolgimento(esgData['102-29']['scopo_stakeholder']);
        }
        //&&!compareLists(tabellacriticita,esgData["102-29"]["rendicontazione_criticita"])
        if (
          esgData['102-29'] != undefined &&
          esgData['102-29']['come_stakeholder'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'come_coinvolgimento'
          );
          fotoLeaderElement.innerHTML = esgData['102-29']['come_stakeholder'];
          setComeCoinvolgimento(esgData['102-29']['come_stakeholder']);
        }

        if (
          esgData['102-29'] != undefined &&
          esgData['102-29']['tabella_stakeholder'] != undefined
        ) {
          const table = parsedHTML.getElementById('tabella_stakeholder');
          if (esgData['102-29']['tabella_stakeholder'].length > 0) {
            popolaTabella(table, esgData['102-29']['tabella_stakeholder']);
            setStakeholderTable(
              cloneDeep(esgData['102-29']['tabella_stakeholder'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);
      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetStakeholderData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default StakeholderEngine;
