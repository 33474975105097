import Anticompetitivo from '../../../../assets/html_template/professional/A4/10-governance205-2-3-anticompetitivo.html';
import Anticompetitivo169 from '../../../../assets/html_template/professional/169/10-governance205-2-3-anticompetitivo.html';
import AnticompetitivoPopA4 from '../../../../assets/html_template/pop/A4/10-governance205-2-3-anticompetitivo.html';
import AnticompetitivoPop169 from '../../../../assets/html_template/pop/169/10-governance205-2-3-anticompetitivo.html';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';

const AnticompetitivoEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? Anticompetitivo : Anticompetitivo169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? AnticompetitivoPopA4 : AnticompetitivoPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? Anticompetitivo : Anticompetitivo169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [descrizioneProcedure, setDescrizioneProcedure] = useState('');
  const [descrizioneAssociazioni, setDescrizioneAssociazioni] = useState('');
  const [anticompetitivoData, SetAnticompetitivoData] = useState(
    Anticompetitivo
  );
  const [anticompetitivoDataA4, SetAnticompetitivoDataA4] = useState(
    Anticompetitivo
  );
  const [anticompetitivoData169, SetAnticompetitivoData169] = useState(
    Anticompetitivo169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetAnticompetitivoData(AnticompetitivoPopA4);
    SetAnticompetitivoDataA4(AnticompetitivoPopA4);
    SetAnticompetitivoData169(AnticompetitivoPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetAnticompetitivoData(Anticompetitivo);
    SetAnticompetitivoDataA4(Anticompetitivo);
    SetAnticompetitivoData169(Anticompetitivo169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetAnticompetitivoData(Anticompetitivo);
    SetAnticompetitivoDataA4(Anticompetitivo);
    SetAnticompetitivoData169(Anticompetitivo169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAnticompetitivoData(AnticompetitivoPopA4);
      SetAnticompetitivoDataA4(AnticompetitivoPopA4);
      SetAnticompetitivoData169(AnticompetitivoPop169);
      force_update(type == 'A4' ? AnticompetitivoPopA4 : AnticompetitivoPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAnticompetitivoData(Anticompetitivo);
      SetAnticompetitivoDataA4(Anticompetitivo);
      SetAnticompetitivoData169(Anticompetitivo169);
      force_update(type == 'A4' ? Anticompetitivo : Anticompetitivo169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetAnticompetitivoData(Anticompetitivo);
      SetAnticompetitivoDataA4(Anticompetitivo);
      SetAnticompetitivoData169(Anticompetitivo169);
      force_update(type == 'A4' ? Anticompetitivo : Anticompetitivo169);
    }
    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('102-28')
    ) {
      setIsVisible(false);
      delPageData('102-28');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(anticompetitivoData);
    } else {
      if (
        esgData['102-28'] != undefined &&
        esgData['102-28']['comportamento_anticompetitivo'] != undefined &&
        esgData['102-28']['comportamento_anticompetitivo'] !=
          descrizioneProcedure
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-28'] != undefined &&
        esgData['102-28']['associazioni_settore'] != undefined &&
        esgData['102-28']['associazioni_settore'] != descrizioneAssociazioni
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = anticompetitivoData169;
        to_init = '169';
      } else {
        templateHTMLString = anticompetitivoData;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['102-28'] != undefined &&
        esgData['102-28']['comportamento_anticompetitivo'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'comportamento_anticompetitivo'
        );
        fotoLeaderElement.innerHTML =
          esgData['102-28']['comportamento_anticompetitivo'];
        setDescrizioneProcedure(
          esgData['102-28']['comportamento_anticompetitivo']
        );
      }

      if (
        esgData['102-28'] != undefined &&
        esgData['102-28']['associazioni_settore'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'associazioni_settore'
        );
        fotoLeaderElement.innerHTML = esgData['102-28']['associazioni_settore'];
        setDescrizioneAssociazioni(esgData['102-28']['associazioni_settore']);
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('102-28', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Associazioni e Comportamento Anticompetitivo',
          initA4: AnticompetitivoPopA4,
          init169: AnticompetitivoPop169,
          keys_to_check: [
            'comportamento_anticompetitivo',
            'associazioni_settore',
          ],
          gri: '102-28',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('102-28', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Associazioni e Comportamento Anticompetitivo',
          initA4: Anticompetitivo,
          init169: Anticompetitivo169,
          keys_to_check: [
            'comportamento_anticompetitivo',
            'associazioni_settore',
          ],
          gri: '102-28',
        });
      }
    }
  };
  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData != '' ? templateData : anticompetitivoData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['102-28'] != undefined &&
          esgData['102-28']['comportamento_anticompetitivo'] != undefined &&
          esgData['102-28']['comportamento_anticompetitivo'] !=
            descrizioneProcedure
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'comportamento_anticompetitivo'
          );
          fotoLeaderElement.innerHTML =
            esgData['102-28']['comportamento_anticompetitivo'];
          setDescrizioneProcedure(
            esgData['102-28']['comportamento_anticompetitivo']
          );
        }

        if (
          esgData['102-28'] != undefined &&
          esgData['102-28']['associazioni_settore'] != undefined &&
          esgData['102-28']['associazioni_settore'] != descrizioneAssociazioni
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'associazioni_settore'
          );
          fotoLeaderElement.innerHTML =
            esgData['102-28']['associazioni_settore'];
          setDescrizioneAssociazioni(esgData['102-28']['associazioni_settore']);
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['102-28'] != undefined &&
          esgData['102-28']['comportamento_anticompetitivo'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'comportamento_anticompetitivo'
          );
          fotoLeaderElement.innerHTML =
            esgData['102-28']['comportamento_anticompetitivo'];
          setDescrizioneProcedure(
            esgData['102-28']['comportamento_anticompetitivo']
          );
        }

        if (
          esgData['102-28'] != undefined &&
          esgData['102-28']['associazioni_settore'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'associazioni_settore'
          );
          fotoLeaderElement.innerHTML =
            esgData['102-28']['associazioni_settore'];
          setDescrizioneAssociazioni(esgData['102-28']['associazioni_settore']);
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);
      SetAnticompetitivoData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AnticompetitivoEngine;
