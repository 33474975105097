import Materiali from '../../../../assets/html_template/professional/A4/11-ambiente-gri301-materiali.html';
import Materiali169 from '../../../../assets/html_template/professional/169/11-ambiente-gri301-materiali.html';
import MaterialiPopA4 from '../../../../assets/html_template/pop/A4/11-ambiente-gri301-materiali.html';
import MaterialiPop169 from '../../../../assets/html_template/pop/169/11-ambiente-gri301-materiali.html';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const MaterialiEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? Materiali : Materiali169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString = type == 'A4' ? MaterialiPopA4 : MaterialiPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? Materiali : Materiali169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [descrizioneMateriali, setDescrizioneMateriali] = useState('');

  const [materialiData, SetMaterialiData] = useState(Materiali);
  const [materialiDataA4, SetMaterialiDataA4] = useState(Materiali);
  const [materialiData169, SetMaterialiData169] = useState(Materiali169);
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetMaterialiData(MaterialiPopA4);
    SetMaterialiDataA4(MaterialiPopA4);
    SetMaterialiData169(MaterialiPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetMaterialiData(Materiali);
    SetMaterialiDataA4(Materiali169);
    SetMaterialiData169(Materiali169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetMaterialiData(Materiali);
    SetMaterialiDataA4(Materiali169);
    SetMaterialiData169(Materiali169);
  }

  const [materialiTable, setMaterialiTable] = useState({});
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const imgRef = useRef();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetMaterialiData(MaterialiPopA4);
      SetMaterialiDataA4(MaterialiPopA4);
      SetMaterialiData169(MaterialiPop169);
      force_update(type == 'A4' ? MaterialiPopA4 : MaterialiPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetMaterialiData(Materiali);
      SetMaterialiDataA4(Materiali169);
      SetMaterialiData169(Materiali169);
      force_update(type == 'A4' ? Materiali : Materiali169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetMaterialiData(Materiali);
      SetMaterialiDataA4(Materiali169);
      SetMaterialiData169(Materiali169);
      force_update(type == 'A4' ? Materiali : Materiali169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('301-1')
    ) {
      setIsVisible(false);
      delPageData('301-1');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(materialiData);
    } else {
      if (
        esgData['301-1'] != undefined &&
        esgData['301-1']['descrizione_materiali'] != undefined &&
        esgData['301-1']['descrizione_materiali'] != descrizioneMateriali
      ) {
        hasUpdate = true;
      }

      if (
        esgData['301-1'] != undefined &&
        esgData['301-1']['peso_volume_tabella'] != undefined &&
        !compareLists(esgData['301-1']['peso_volume_tabella'], materialiTable)
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    try {
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  function divideString(inputString) {
    // Verifica se la stringa è più lunga di 500 caratteri
    if (inputString.length > 500) {
      // Estrai la prima parte (primi 500 caratteri)
      let firstPart = inputString.substring(0, 500);

      // Cerca un punto o uno spazio entro una tolleranza di 15 caratteri dalla fine della prima parte
      const delimiterIndex = Math.min(
        firstPart.lastIndexOf('.'),
        firstPart.lastIndexOf(' ')
      );

      // Se trova un punto o uno spazio entro la tolleranza, suddividi la stringa
      if (delimiterIndex !== -1 && delimiterIndex >= 500 - 15) {
        firstPart = firstPart.substring(0, delimiterIndex + 1);
      }

      // La seconda parte inizia dopo la fine della prima parte
      const secondPart = inputString.substring(firstPart.length);

      return [firstPart, secondPart];
    } else {
      // Se la stringa è più corta di 500 caratteri, restituisci la stringa intera come prima parte
      return [inputString, ''];
    }
  }
  //prova img

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = [
      'Nome Materiale',
      '% Rinnovabile',
      'Quantità Materiale',
    ];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = materialiData169;
        to_init = '169';
      } else {
        templateHTMLString = materialiDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['301-1'] != undefined &&
        esgData['301-1']['descrizione_materiali'] != undefined
      ) {
        const stringList = divideString(
          esgData['301-1']['descrizione_materiali']
        );
        const first_500 = parsedHTML.getElementById('first-500');
        first_500.innerHTML = stringList[0];
        const after_500 = parsedHTML.getElementById('after-500');
        after_500.innerHTML = stringList[1];
        setDescrizioneMateriali(esgData['301-1']['descrizione_materiali']);
      }

      if (
        esgData['301-1'] != undefined &&
        esgData['301-1']['peso_volume_tabella'] != undefined
      ) {
        const table = parsedHTML.getElementById('peso_volume_tabella');
        if (esgData['301-1']['peso_volume_tabella'].length > 0) {
          const risultatoFiltrato = esgData['301-1'][
            'peso_volume_tabella'
          ].filter(
            item =>
              (item.renewable !== 0 || item.quantity !== 0) && item.name !== ''
          );
          let filtered_data = risultatoFiltrato.map(elemento => ({
            'Nome Materiale': elemento.name,
            '% Rinnovabile': elemento.renewable,
            'Quantità Materiale': elemento.quantity,
          }));
          popolaTabella(table, filtered_data, ordered_keys);
          setMaterialiTable(cloneDeep(esgData['301-1']['peso_volume_tabella']));
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('301-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Peso/Volume Totale Materiali',
          initA4: MaterialiPopA4,
          init169: MaterialiPop169,
          keys_to_check: ['first-500', 'peso_volume_tabella'],
          gri: '301-1',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('301-1', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Peso/Volume Totale Materiali',
          initA4: Materiali,
          init169: Materiali169,
          keys_to_check: ['first-500', 'peso_volume_tabella'],
          gri: '301-1',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData != '' ? templateData : materialiData;
      const parser = new DOMParser();
      const ordered_keys = [
        'Nome Materiale',
        '% Rinnovabile',
        'Quantità Materiale',
      ];
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['301-1'] != undefined &&
          esgData['301-1']['descrizione_materiali'] != undefined &&
          esgData['301-1']['descrizione_materiali'] != descrizioneMateriali
        ) {
          const stringList = divideString(
            esgData['301-1']['descrizione_materiali']
          );
          const first_500 = parsedHTML.getElementById('first-500');
          first_500.innerHTML = stringList[0];
          const after_500 = parsedHTML.getElementById('after-500');
          after_500.innerHTML = stringList[1];
          setDescrizioneMateriali(esgData['301-1']['descrizione_materiali']);
        }

        if (
          esgData['301-1'] != undefined &&
          esgData['301-1']['peso_volume_tabella'] != undefined &&
          !compareLists(esgData['301-1']['peso_volume_tabella'], materialiTable)
        ) {
          const table = parsedHTML.getElementById('peso_volume_tabella');
          if (esgData['301-1']['peso_volume_tabella'].length > 0) {
            const risultatoFiltrato = esgData['301-1'][
              'peso_volume_tabella'
            ].filter(
              item =>
                (item.renewable !== 0 || item.quantity !== 0) &&
                item.name !== ''
            );
            let filtered_data = risultatoFiltrato.map(elemento => ({
              'Nome Materiale': elemento.name,
              '% Rinnovabile': elemento.renewable,
              'Quantità Materiale': elemento.quantity,
            }));
            popolaTabella(table, filtered_data, ordered_keys);
            setMaterialiTable(
              cloneDeep(esgData['301-1']['peso_volume_tabella'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['301-1'] != undefined &&
          esgData['301-1']['descrizione_materiali'] != undefined
        ) {
          const stringList = divideString(
            esgData['301-1']['descrizione_materiali']
          );
          const first_500 = parsedHTML.getElementById('first-500');
          first_500.innerHTML = stringList[0];
          const after_500 = parsedHTML.getElementById('after-500');
          after_500.innerHTML = stringList[1];
          setDescrizioneMateriali(esgData['301-1']['descrizione_materiali']);
        }

        if (
          esgData['301-1'] != undefined &&
          esgData['301-1']['peso_volume_tabella'] != undefined
        ) {
          const table = parsedHTML.getElementById('peso_volume_tabella');
          if (esgData['301-1']['peso_volume_tabella'].length > 0) {
            const risultatoFiltrato = esgData['301-1'][
              'peso_volume_tabella'
            ].filter(
              item =>
                (item.renewable !== 0 || item.quantity !== 0) &&
                item.name !== ''
            );
            let filtered_data = risultatoFiltrato.map(elemento => ({
              'Nome Materiale': elemento.name,
              '% Rinnovabile': elemento.renewable,
              'Quantità Materiale': elemento.quantity,
            }));
            popolaTabella(table, filtered_data);
            setMaterialiTable(
              cloneDeep(esgData['301-1']['peso_volume_tabella'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      if (imgRef.current) {
        // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
        imgRef.current.src = blobURL;
      }
      updateDataUrl(blobURL);
      setIframeURL(blobURL);
      SetMaterialiData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default MaterialiEngine;
