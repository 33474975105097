import RapportoRetributivo from '../../../../assets/html_template/professional/A4/07_7-informativa-generale-gri102-21-rapportoRetributivo.html';
import RapportoRetributivo169 from '../../../../assets/html_template/professional/169/07_7-informativa-generale-gri102-21-rapportoRetributivo.html';
import RapportoRetributivoPopA4 from '../../../../assets/html_template/pop/A4/07_7-informativa-generale-gri102-21-rapportoRetributivo.html';
import RapportoRetributivoPop169 from '../../../../assets/html_template/pop/169/07_7-informativa-generale-gri102-21-rapportoRetributivo.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';

const RapportoRetributivoEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };

  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString =
        type == 'A4' ? RapportoRetributivo : RapportoRetributivo169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? RapportoRetributivoPopA4 : RapportoRetributivoPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString =
        type == 'A4' ? RapportoRetributivo : RapportoRetributivo169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const [rapportoretributivoTabella, setTabellaRapportoRetributivo] = useState(
    []
  );
  const [
    descrizione_rapporto_retribuzione,
    set_descrizione_rapporto_retribuzione,
  ] = useState('');

  const [rapportoRetributivoData, SetRapportoRetributivoData] = useState(
    RapportoRetributivo
  );
  const [rapportoRetributivoDataA4, SetRapportoRetributivoDataA4] = useState(
    RapportoRetributivo
  );
  const [rapportoRetributivoData169, SetRapportoRetributivoData169] = useState(
    RapportoRetributivo169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetRapportoRetributivoData(RapportoRetributivoPopA4);
    SetRapportoRetributivoDataA4(RapportoRetributivoPopA4);
    SetRapportoRetributivoData169(RapportoRetributivoPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetRapportoRetributivoData(RapportoRetributivo);
    SetRapportoRetributivoDataA4(RapportoRetributivo);
    SetRapportoRetributivoData169(RapportoRetributivo169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetRapportoRetributivoData(RapportoRetributivo);
    SetRapportoRetributivoDataA4(RapportoRetributivo);
    SetRapportoRetributivoData169(RapportoRetributivo169);
  }

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetRapportoRetributivoData(RapportoRetributivoPopA4);
      SetRapportoRetributivoDataA4(RapportoRetributivoPopA4);
      SetRapportoRetributivoData169(RapportoRetributivoPop169);
      force_update(
        type == 'A4' ? RapportoRetributivoPopA4 : RapportoRetributivoPop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetRapportoRetributivoData(RapportoRetributivo);
      SetRapportoRetributivoDataA4(RapportoRetributivo);
      SetRapportoRetributivoData169(RapportoRetributivo169);
      force_update(type == 'A4' ? RapportoRetributivo : RapportoRetributivo169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetRapportoRetributivoData(RapportoRetributivo);
      SetRapportoRetributivoDataA4(RapportoRetributivo);
      SetRapportoRetributivoData169(RapportoRetributivo169);
      force_update(type == 'A4' ? RapportoRetributivo : RapportoRetributivo169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('102-21')
    ) {
      setIsVisible(false);
      delPageData('102-21');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(rapportoRetributivoData);
    } else {
      if (
        esgData['102-21'] != undefined &&
        esgData['102-21']['descrizione_rapporto_retribuzione'] != undefined &&
        esgData['102-21']['descrizione_rapporto_retribuzione'] !=
          descrizione_rapporto_retribuzione
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-21'] != undefined &&
        esgData['102-21']['tabella_rapporto_retributivo'] != undefined &&
        !compareLists(
          esgData['102-21']['tabella_rapporto_retributivo'],
          rapportoretributivoTabella
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const popolaTabella = (table, data, ordered_keys) => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  const updateDataUrl = (formed_url = '') => {
    const orderedKey = ['Anno', 'Massima Retribuzione', 'Altre Retribuzioni'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = rapportoRetributivoData169;
        to_init = '169';
      } else {
        templateHTMLString = rapportoRetributivoDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['102-21'] != undefined &&
        esgData['102-21']['descrizione_rapporto_retribuzione'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'descrizione_rapporto_retribuzione'
        );
        fotoLeaderElement.innerHTML =
          esgData['102-21']['descrizione_rapporto_retribuzione'];
        set_descrizione_rapporto_retribuzione(
          esgData['102-21']['descrizione_rapporto_retribuzione']
        );
      }
      //&&!compareLists(tabellacriticita,esgData["102-21"]["rendicontazione_criticita"])

      if (
        esgData['102-21'] != undefined &&
        esgData['102-21']['tabella_rapporto_retributivo'] != undefined
      ) {
        const table = parsedHTML.getElementById('tabella_rapporto_retributivo');
        const grafico = parsedHTML.getElementById('creaGrafico');
        if (esgData['102-21']['tabella_rapporto_retributivo'].length > 0) {
          let datiModificati = esgData['102-21'][
            'tabella_rapporto_retributivo'
          ].map(elemento => ({
            'Massima Retribuzione': elemento.totalA,
            'Altre Retribuzioni': elemento.totalB,
            Anno: elemento.year,
          }));

          popolaTabella(table, datiModificati, orderedKey);

          //creaGrafico(grafico,esgData["102-21"]["tabella_rapporto_retributivo"] )

          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script

          grafico.textContent =
            `
          //TOSTART
          const data = ${JSON.stringify(
            esgData['102-21']['tabella_rapporto_retributivo'],
            null,
            2
          )};
          //TOEND;

        ` + grafico.textContent;

          setTabellaRapportoRetributivo(
            cloneDeep(esgData['102-21']['tabella_rapporto_retributivo'])
          );
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('102-21', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Indicatori di Compensazione',
          initA4: RapportoRetributivoPopA4,
          init169: RapportoRetributivoPop169,
          keys_to_check: [
            'creaGrafico',
            'descrizione_rapporto_retribuzione',
            'tabella_rapporto_retributivo',
          ],
          gri: '102-21',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('102-21', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Indicatori di Compensazione',
          initA4: RapportoRetributivo,
          init169: RapportoRetributivo169,
          keys_to_check: [
            'creaGrafico',
            'descrizione_rapporto_retribuzione',
            'tabella_rapporto_retributivo',
          ],
          gri: '102-21',
        });
      }
    }
  };

  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? rapportoRetributivoData : templateData;
      const parser = new DOMParser();
      const orderedKey = ['Anno', 'Massima Retribuzione', 'Altre Retribuzioni'];
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['102-21'] != undefined &&
          esgData['102-21']['descrizione_rapporto_retribuzione'] != undefined &&
          esgData['102-21']['descrizione_rapporto_retribuzione'] !=
            descrizione_rapporto_retribuzione
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_rapporto_retribuzione'
          );
          fotoLeaderElement.innerHTML =
            esgData['102-21']['descrizione_rapporto_retribuzione'];
          set_descrizione_rapporto_retribuzione(
            esgData['102-21']['descrizione_rapporto_retribuzione']
          );
        }
        //&&!compareLists(tabellacriticita,esgData["102-21"]["rendicontazione_criticita"])

        if (
          esgData['102-21'] != undefined &&
          esgData['102-21']['tabella_rapporto_retributivo'] != undefined &&
          !compareLists(
            esgData['102-21']['tabella_rapporto_retributivo'],
            rapportoretributivoTabella
          )
        ) {
          const table = parsedHTML.getElementById(
            'tabella_rapporto_retributivo'
          );
          const grafico = parsedHTML.getElementById('creaGrafico');
          if (esgData['102-21']['tabella_rapporto_retributivo'].length > 0) {
            let datiModificati = esgData['102-21'][
              'tabella_rapporto_retributivo'
            ].map(elemento => ({
              'Massima Retribuzione': elemento.totalA,
              'Altre Retribuzioni': elemento.totalB,
              Anno: elemento.year,
            }));

            popolaTabella(table, datiModificati, orderedKey);
            //creaGrafico(grafico,esgData["102-21"]["tabella_rapporto_retributivo"] )

            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script

            grafico.textContent =
              `
                //TOSTART
                const data = ${JSON.stringify(
                  esgData['102-21']['tabella_rapporto_retributivo'],
                  null,
                  2
                )};
                //TOEND;

              ` + grafico.textContent;

            setTabellaRapportoRetributivo(
              cloneDeep(esgData['102-21']['tabella_rapporto_retributivo'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['102-21'] != undefined &&
          esgData['102-21']['descrizione_rapporto_retribuzione'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_rapporto_retribuzione'
          );
          fotoLeaderElement.innerHTML =
            esgData['102-21']['descrizione_rapporto_retribuzione'];
          set_descrizione_rapporto_retribuzione(
            esgData['102-21']['descrizione_rapporto_retribuzione']
          );
        }
        //&&!compareLists(tabellacriticita,esgData["102-21"]["rendicontazione_criticita"])

        if (
          esgData['102-21'] != undefined &&
          esgData['102-21']['tabella_rapporto_retributivo'] != undefined
        ) {
          const table = parsedHTML.getElementById(
            'tabella_rapporto_retributivo'
          );
          const grafico = parsedHTML.getElementById('creaGrafico');
          if (esgData['102-21']['tabella_rapporto_retributivo'].length > 0) {
            let datiModificati = esgData['102-21'][
              'tabella_rapporto_retributivo'
            ].map(elemento => ({
              'Massima Retribuzione': elemento.totalA,
              'Altre Retribuzioni': elemento.totalB,
              Anno: elemento.year,
            }));

            popolaTabella(table, datiModificati, orderedKey);
            //creaGrafico(grafico,esgData["102-21"]["tabella_rapporto_retributivo"] )

            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script

            grafico.textContent =
              `
              //TOSTART
              const data = ${JSON.stringify(
                esgData['102-21']['tabella_rapporto_retributivo'],
                null,
                2
              )};
              //TOEND;

            ` + grafico.textContent;

            setTabellaRapportoRetributivo(
              cloneDeep(esgData['102-21']['tabella_rapporto_retributivo'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      setIframeURL(blobURL);

      SetRapportoRetributivoData(parsedHTML.documentElement.outerHTML);

      updateDataUrl(blobURL);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default RapportoRetributivoEngine;
