import MaterialiRiciclatiRecuperati from '../../../../assets/html_template/professional/A4/11-ambiente-gri301-2-3-materialiriciclatirecuperati.html';
import MaterialiRiciclatiRecuperati169 from '../../../../assets/html_template/professional/169/11-ambiente-gri301-2-3-materialiriciclatirecuperati.html';
import MaterialiRiciclatiRecuperatiPopA4 from '../../../../assets/html_template/pop/A4/11-ambiente-gri301-2-3-materialiriciclatirecuperati.html';
import MaterialiRiciclatiRecuperatiPop169 from '../../../../assets/html_template/pop/169/11-ambiente-gri301-2-3-materialiriciclatirecuperati.html';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep, deepCopy } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const MaterialiRicRecEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString =
        type == 'A4'
          ? MaterialiRiciclatiRecuperati
          : MaterialiRiciclatiRecuperati169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4'
          ? MaterialiRiciclatiRecuperatiPopA4
          : MaterialiRiciclatiRecuperatiPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString =
        type == 'A4'
          ? MaterialiRiciclatiRecuperati
          : MaterialiRiciclatiRecuperati169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };

  const [procedureRiciclo, setProcedureRiciclo] = useState('');
  const [tableRiciclati, setTableRiciclati] = useState([]);
  const [tableRecuperati, setTableRecuperati] = useState([]);
  const [materialiRicRecData, setMaterialiRicRecData] = useState(
    MaterialiRiciclatiRecuperati
  );

  const [materialiRicRecDataA4, setMaterialiRicRecDataA4] = useState(
    MaterialiRiciclatiRecuperati
  );
  const [materialiRicRecData169, setMaterialiRicRecData169] = useState(
    MaterialiRiciclatiRecuperati169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    setMaterialiRicRecData(MaterialiRiciclatiRecuperatiPopA4);
    setMaterialiRicRecDataA4(MaterialiRiciclatiRecuperatiPopA4);
    setMaterialiRicRecData169(MaterialiRiciclatiRecuperatiPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    setMaterialiRicRecData(MaterialiRiciclatiRecuperati);
    setMaterialiRicRecDataA4(MaterialiRiciclatiRecuperati);
    setMaterialiRicRecData169(MaterialiRiciclatiRecuperati169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    setMaterialiRicRecData(MaterialiRiciclatiRecuperati);
    setMaterialiRicRecDataA4(MaterialiRiciclatiRecuperati);
    setMaterialiRicRecData169(MaterialiRiciclatiRecuperati169);
  }
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();
  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    if (list1.length !== list2.length) {
      return false;
    }

    // Confronto degli oggetti all'interno delle liste
    for (let i = 0; i < list1.length; i++) {
      const obj1 = list1[i];
      const obj2 = list2[i];

      // Comparazione delle proprietà degli oggetti
      const objKeys1 = Object.keys(obj1);
      const objKeys2 = Object.keys(obj2);

      if (
        objKeys1.length !== objKeys2.length ||
        !objKeys1.every(key => objKeys2.includes(key))
      ) {
        return false;
      }

      // Confronta i valori di tutte le proprietà degli oggetti
      for (const key of objKeys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const popolaTabella = (table, data) => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';
    // Popola l'intestazione
    Object.keys(data[0]).forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';
    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      Object.values(riga).forEach(valore => {
        const td = document.createElement('td');
        td.textContent = valore;
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  useEffect(() => {
    //all'aggiornamento
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setMaterialiRicRecData(MaterialiRiciclatiRecuperatiPopA4);
      setMaterialiRicRecDataA4(MaterialiRiciclatiRecuperatiPopA4);
      setMaterialiRicRecData169(MaterialiRiciclatiRecuperatiPop169);
      force_update(
        type == 'A4'
          ? MaterialiRiciclatiRecuperatiPopA4
          : MaterialiRiciclatiRecuperatiPop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setMaterialiRicRecData(MaterialiRiciclatiRecuperati);
      setMaterialiRicRecDataA4(MaterialiRiciclatiRecuperati);
      setMaterialiRicRecData169(MaterialiRiciclatiRecuperati169);
      force_update(
        type == 'A4'
          ? MaterialiRiciclatiRecuperati
          : MaterialiRiciclatiRecuperati169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setMaterialiRicRecData(MaterialiRiciclatiRecuperati);
      setMaterialiRicRecDataA4(MaterialiRiciclatiRecuperati);
      setMaterialiRicRecData169(MaterialiRiciclatiRecuperati169);
      force_update(
        type == 'A4'
          ? MaterialiRiciclatiRecuperati
          : MaterialiRiciclatiRecuperati169
      );
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('301-2')
    ) {
      setIsVisible(false);
      delPageData('301-2');
    } else if (!isVisible) {
      setIsVisible(true);

      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(materialiRicRecData);
    } else {
      if (
        esgData['301-2-3'] != undefined &&
        esgData['301-2-3']['descrizione_riciclo_materiali'] != undefined &&
        esgData['301-2-3']['descrizione_riciclo_materiali'] != procedureRiciclo
      ) {
        hasUpdate = true;
      }
      if (
        esgData['301-2-3'] != undefined &&
        esgData['301-2-3']['materiali_riciclati_tabella'] != undefined &&
        !compareLists(
          esgData['301-2-3']['materiali_riciclati_tabella'],
          tableRiciclati
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['301-2-3'] != undefined &&
        esgData['301-2-3']['materiali_recuperati_tabella'] != undefined &&
        !compareLists(
          esgData['301-2-3']['materiali_recuperati_tabella'],
          tableRecuperati
        )
      ) {
        hasUpdate = true;
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);
  //prova img
  const updateDataUrl = (formed_url = '') => {
    const ordered_kesy_recuperati = ['Categoria', 'Mat. Confezionamento'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = materialiRicRecData169;
        to_init = '169';
      } else {
        templateHTMLString = materialiRicRecDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['301-2-3'] != undefined &&
        esgData['301-2-3']['descrizione_riciclo_materiali'] != undefined
      ) {
        const anniAttivita = parsedHTML.getElementById(
          'descrizione_riciclo_materiali'
        );
        anniAttivita.innerHTML =
          esgData['301-2-3']['descrizione_riciclo_materiali'];
        setProcedureRiciclo(
          esgData['301-2-3']['descrizione_riciclo_materiali']
        );
      }
      if (
        esgData['301-2-3'] != undefined &&
        esgData['301-2-3']['materiali_riciclati_tabella'] != undefined
      ) {
        const table = parsedHTML.getElementById('peso_volume_tabella');
        const grafico = parsedHTML.getElementById('chart_riciclati');
        if (esgData['301-2-3']['materiali_riciclati_tabella'].length > 0) {
          let oggettoFiltrato = esgData['301-2-3'][
            'materiali_riciclati_tabella'
          ].filter(item => item.materialName !== '');
          const filteredData = oggettoFiltrato.map(item => ({
            Nome: item.materialName,
            '%Riciclo': item.recyclingPercentage,
          }));

          popolaTabella(table, filteredData);

          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
          //TOSTART
          var data3 = ${JSON.stringify(oggettoFiltrato, null, 2)};
          //TOEND

        ` + grafico.textContent;

          setTableRiciclati(
            cloneDeep(esgData['301-2-3']['materiali_riciclati_tabella'])
          );
        }
      }

      if (
        esgData['301-2-3'] != undefined &&
        esgData['301-2-3']['materiali_recuperati_tabella'] != undefined
      ) {
        const table = parsedHTML.getElementById('tabella_recuperati');
        const grafico = parsedHTML.getElementById('chart_recuperati');
        if (esgData['301-2-3']['materiali_recuperati_tabella'].length > 0) {
          const filteredData = esgData['301-2-3'][
            'materiali_recuperati_tabella'
          ].filter(item => item.CategoriaProdotto !== '');
          const oggettoFiltrato = filteredData.map(item => ({
            Categoria: item.CategoriaProdotto,
            'Mat. Confezionamento': item.MaterialiConfezionamento,
          }));
          popolaTabella(table, oggettoFiltrato);
          //creaGrafico(grafico,esgData["102-21"]["tabella_rapporto_retributivo"] )

          grafico.textContent = grafico.textContent.replace(
            /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
            ''
          );

          // Inserisci la nuova definizione di data nello script
          //modificare i dati per la percentuale
          grafico.textContent =
            `
          //TOSTART
          var data2 = ${JSON.stringify(filteredData, null, 2)};
          //TOEND

        ` + grafico.textContent;

          setTableRecuperati(
            cloneDeep(esgData['301-2-3']['materiali_recuperati_tabella'])
          );
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }

      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('301-2-3', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Riciclo e Recupero Materiali',
          initA4: MaterialiRiciclatiRecuperatiPopA4,
          init169: MaterialiRiciclatiRecuperatiPop169,
          keys_to_check: [
            'descrizione_riciclo_materiali',
            'peso_volume_tabella',
            'chart_riciclati',
            'tabella_recuperati',
            'chart_recuperati',
          ],
          gri: '301-2-3',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('301-2-3', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Riciclo e Recupero Materiali',
          initA4: MaterialiRiciclatiRecuperati,
          init169: MaterialiRiciclatiRecuperati169,
          keys_to_check: [
            'descrizione_riciclo_materiali',
            'peso_volume_tabella',
            'chart_riciclati',
            'tabella_recuperati',
            'chart_recuperati',
          ],
          gri: '301-2-3',
        });
      }
    }
  };
  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData != '' ? templateData : materialiRicRecData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['301-2-3'] != undefined &&
          esgData['301-2-3']['descrizione_riciclo_materiali'] != undefined &&
          esgData['301-2-3']['descrizione_riciclo_materiali'] !=
            procedureRiciclo
        ) {
          const anniAttivita = parsedHTML.getElementById(
            'descrizione_riciclo_materiali'
          );
          anniAttivita.innerHTML =
            esgData['301-2-3']['descrizione_riciclo_materiali'];
          setProcedureRiciclo(
            esgData['301-2-3']['descrizione_riciclo_materiali']
          );
        }
        if (
          esgData['301-2-3'] != undefined &&
          esgData['301-2-3']['materiali_riciclati_tabella'] != undefined &&
          !compareLists(
            esgData['301-2-3']['materiali_riciclati_tabella'],
            tableRiciclati
          )
        ) {
          const table = parsedHTML.getElementById('peso_volume_tabella');
          const grafico = parsedHTML.getElementById('chart_riciclati');
          if (esgData['301-2-3']['materiali_riciclati_tabella'].length > 0) {
            let oggettoFiltrato = esgData['301-2-3'][
              'materiali_riciclati_tabella'
            ].filter(item => item.materialName !== '');
            const filteredData = oggettoFiltrato.map(item => ({
              Nome: item.materialName,
              '%Riciclo': item.recyclingPercentage,
            }));

            popolaTabella(table, oggettoFiltrato);

            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
                //TOSTART
                var data3 = ${JSON.stringify(oggettoFiltrato, null, 2)};
                //TOEND

              ` + grafico.textContent;

            setTableRiciclati(
              cloneDeep(esgData['301-2-3']['materiali_riciclati_tabella'])
            );
          }
        }

        if (
          esgData['301-2-3'] != undefined &&
          esgData['301-2-3']['materiali_recuperati_tabella'] != undefined &&
          !compareLists(
            esgData['301-2-3']['materiali_recuperati_tabella'],
            tableRecuperati
          )
        ) {
          const table = parsedHTML.getElementById('tabella_recuperati');
          const grafico = parsedHTML.getElementById('chart_recuperati');
          if (esgData['301-2-3']['materiali_recuperati_tabella'].length > 0) {
            const filteredData = esgData['301-2-3'][
              'materiali_recuperati_tabella'
            ].filter(item => item.CategoriaProdotto !== '');
            const oggettoFiltrato = filteredData.map(item => ({
              Categoria: item.CategoriaProdotto,
              'Mat. Confezionamento': item.MaterialiConfezionamento,
            }));
            popolaTabella(table, oggettoFiltrato);
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
                //TOSTART
                var data2 = ${JSON.stringify(filteredData, null, 2)};
                //TOEND

              ` + grafico.textContent;

            setTableRecuperati(
              cloneDeep(esgData['301-2-3']['materiali_recuperati_tabella'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['301-2-3'] != undefined &&
          esgData['301-2-3']['descrizione_riciclo_materiali'] != undefined
        ) {
          const anniAttivita = parsedHTML.getElementById(
            'descrizione_riciclo_materiali'
          );
          anniAttivita.innerHTML =
            esgData['301-2-3']['descrizione_riciclo_materiali'];
          setProcedureRiciclo(
            esgData['301-2-3']['descrizione_riciclo_materiali']
          );
        }
        if (
          esgData['301-2-3'] != undefined &&
          esgData['301-2-3']['materiali_riciclati_tabella'] != undefined
        ) {
          const table = parsedHTML.getElementById('peso_volume_tabella');
          const grafico = parsedHTML.getElementById('chart_riciclati');
          if (esgData['301-2-3']['materiali_riciclati_tabella'].length > 0) {
            let oggettoFiltrato = esgData['301-2-3'][
              'materiali_riciclati_tabella'
            ].filter(item => item.materialName !== '');
            const filteredData = oggettoFiltrato.map(item => ({
              Nome: item.materialName,
              '%Riciclo': item.recyclingPercentage,
            }));

            popolaTabella(table, filteredData);
            //creaGrafico(grafico,esgData["102-21"]["tabella_rapporto_retributivo"] )

            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
              //TOSTART
              var data3 = ${JSON.stringify(oggettoFiltrato, null, 2)};
              //TOEND

            ` + grafico.textContent;

            setTableRiciclati(
              cloneDeep(esgData['301-2-3']['materiali_riciclati_tabella'])
            );
          }
        }

        if (
          esgData['301-2-3'] != undefined &&
          esgData['301-2-3']['materiali_recuperati_tabella'] != undefined
        ) {
          const table = parsedHTML.getElementById('tabella_recuperati');
          const grafico = parsedHTML.getElementById('chart_recuperati');
          if (esgData['301-2-3']['materiali_recuperati_tabella'].length > 0) {
            const filteredData = esgData['301-2-3'][
              'materiali_recuperati_tabella'
            ].filter(item => item.CategoriaProdotto !== '');
            const oggettoFiltrato = filteredData.map(item => ({
              Categoria: item.CategoriaProdotto,
              'Mat. Confezionamento': item.MaterialiConfezionamento,
            }));
            popolaTabella(table, oggettoFiltrato);

            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
              //TOSTART
              var data2 = ${JSON.stringify(filteredData, null, 2)};
              //TOEND

            ` + grafico.textContent;

            setTableRecuperati(
              cloneDeep(esgData['301-2-3']['materiali_recuperati_tabella'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      setIframeURL(blobURL);
      updateDataUrl(blobURL);

      setMaterialiRicRecData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };

  return (
    <>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}{' '}
    </>
  );
};

export default MaterialiRicRecEngine;
