import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button, FileUploader } from '@carbon/react';
import { bucket, auth } from '../../action/fiirebase_config/firebase_config';
const UploadImage = props => {
  const [file, setFile] = useState();
  const labelText = 'Inserisci immagine ' + props['tipo'];
  const [error, setError] = useState(false);
  const [errormsg, setErrorMsg] = useState('');
  const [canUpload, setCanUpload] = useState(true);
  useEffect(() => {
    if (props.selectedItem != undefined) {
      setFile(props.selectedItem);
    }
  }, [props]);
  async function handleChange(e) {
    if (e.target.files[0].size <= 5200506) {
      await bucket
        .ref(
          auth.currentUser.uid +
            '/data/' +
            props.reportId +
            '/img/' +
            props.filename
        )
        .put(e.target.files[0]);
      setError(false);
      setErrorMsg('');
      const path = e.target.files[0];
      var imageRef = bucket
        .ref()
        .child(
          auth.currentUser.uid +
            '/data/' +
            props.reportId +
            '/img/' +
            props.filename
        );
      // Get the download URL
      const url = await imageRef.getDownloadURL();
      setFile(url);
      props.setImgData(url);
      setCanUpload(false);
    } else {
      setError(true);
      setCanUpload(false);
      setErrorMsg('Il file caricato è troppo grande.');
    }
  }
  function deleteFile(e) {
    setFile({});
    props.setImgData({});

    setError(false);
    setCanUpload(true);
  }

  return (
    <div>
      <FileUploader
        labelTitle={labelText}
        onChange={handleChange}
        onDelete={deleteFile}
        labelDescription="Soltanto file in formato immagine sono supportati, fino a 5Mb."
        buttonLabel="Aggiungi file"
        buttonKind="ghost"
        size="md"
        filenameStatus="edit"
        accept={['.jpg', '.jpeg', '.png']}
        multiple={false}
        disabled={!canUpload}
        iconDescription="Elimina file"
        name=""
      />
      {!error ? (
        <img style={{ maxHeight: '150px' }} src={file} />
      ) : (
        <p style={{ fontSize: 8, color: 'red' }}>{errormsg}</p>
      )}
    </div>
  );
};
export default UploadImage;
