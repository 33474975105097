import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { TextInput, Stack, TextArea, Button, Tooltip } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';

const ESGTableNotDipendenti = () => {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const columnDefs = [
    {
      headerName: 'Tipologia Collaboratore',
      headerTooltip: 'Inserire la tipologia del collaboratore',
      field: 'tipologiaCollaboratore',
      cellEditor: 'agTextEditor',
      editable: true,
    },
    {
      headerName: 'Numero',
      headerTooltip:
        'Inserire il numero di collaboratori per la data tipologia',
      field: 'numero',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
    },
    {
      headerName: 'Mansioni svolte',
      headerTooltip: 'Inserire le mansioni svolte dai collaboratori',
      field: 'mansioniSvolte',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 250,
        rows: 10,
        cols: 50,
      },
    },
  ];
  useEffect(() => {
    if (esgData['102-8'] != undefined) {
      setRowData(esgData['102-8']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-8', rowData);
  }, [rowData]);
  const onChangeRowValue = e => {
    const ex_rowData = cloneDeep(rowData);
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
  };
  const gridOptions = {
    onCellValueChanged: onChangeRowValue,
  };

  const defaultColDef = {
    editable: true,
    resizable: true,
  };

  const frameworkComponents = {
    textInputEditor: TextInputEditor,
    numericInputEditor: NumericInputEditor,
    textAreaEditor: TextAreaEditor,
  };
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };

  const addNewRow = () => {
    if (rowData.length < 5) {
      const newRow = {
        tipologiaCollaboratore: '',
        numero: 0,
        mansioniSvolte: '',
      };
      setRowData([...rowData, newRow]);
    }
  };

  return (
    <Stack gap={8}>
      <h2>Collaboratori Esterni</h2>
      <p>
        La presente informativa illustra la misura in cui l’organizzazione conta
        su lavoratori non dipendenti per svolgere la sua attività, rispetto ai
        dipendenti.
      </p>
      <div className="ag-theme-alpine" style={{ height: 300, width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          overlayNoRowsTemplate="Non è ancora stata inserita nessuna riga"
          onCellValueChanged={onChangeRowValue}
        />
        <Button onClick={addNewRow} kind="ghost" variant="contained">
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga.  E' possibile popolare fino a 5 righe">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
    </Stack>
  );
};

export default ESGTableNotDipendenti;

// Custom Editors
const TextInputEditor = props => {
  return <TextInput {...props} />;
};

const NumericInputEditor = props => {
  const value = parseFloat(props.value) || 0;
  const handleChange = e => {
    const newValue = parseFloat(e.target.value) || 0;
    props.setValue(newValue);
  };

  return <TextInput type="number" value={value} onChange={handleChange} />;
};

const TextAreaEditor = props => {
  return <TextArea {...props} />;
};
