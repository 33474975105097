import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Tooltip, Stack } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
const ESG203_2 = () => {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([
    {
      indirectImpact: '',
      scope: '',
    },
  ]);
  const { esgData, pushESGData, refreshESGData } = useESG();
  const columnDefs = [
    {
      headerName: 'Impatto economico indiretto',
      field: 'indirectImpact',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: 'Portata (se misurabile)',
      field: 'scope',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
  ];

  const addRow = () => {
    setRowData([...rowData, { indirectImpact: '', scope: '' }]);
  };

  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
  };
  useEffect(() => {
    if (esgData['203-2'] != undefined) {
      setRowData(esgData['203-2']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('203-2', rowData);
  }, [rowData]);

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
    }
  };
  return (
    <Stack gap={8}>
      <h1>GRI 203-2</h1>
      <h2>Impatti economici indiretti significativi</h2>
      <div>
        <p>
          Esempi di impatti indiretti:
          <br />
          Cambiamenti nella produttività di organizzazioni, settori o
          dell’intera economia (ad esempio attraverso un’adozione più ampia
          dell’informatica);
          <br />
          Sviluppo economico in aree di alta povertà (come modifiche del numero
          totale di dipendenti sostenuti attraverso il reddito di un solo
          lavoro);
          <br />
        </p>
      </div>

      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowSelection={'multiple'}
          domLayout="autoHeight"
          onCellValueChanged={onChangeRowValue}
        />
        <Button kind="ghost" iconDescription="Aggiungi riga" onClick={addRow}>
          Aggiungi riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
    </Stack>
  );
};

export default ESG203_2;
