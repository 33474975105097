import { Stack, Grid, Button, Column } from '@carbon/react';
import React from 'react';
import IconChoice from '../../components/IconChoice';
import {
  EarthSoutheastAsiaFilled,
  FolderOpen,
  FolderShared,
} from '@carbon/react/icons';
import { InfoSection, InfoCard } from '../../components/Info';
import { useESG } from '../../action/ESGData/esgdata';
import BuyItem from '../../components/BuyItem';

const UserOptions = () => {
  
  const context_obj = useESG();
  const userData = context_obj.userData;
  return (
    <div>
      <Grid>
        <Column lg={10} md={10} sm={10} className="landing-page__r3">
          <h1>
            Benvenuto{' '}
            {userData['firstname'] != undefined ? userData['firstname'] : ''}
          </h1>
          <InfoSection heading="Personalizza le tue informazioni" />
        </Column>
        <Column lg={6} md={6} sm={6} className="landing-page__r3">
          <img
            width="40%"
            src={`${process.env.PUBLIC_URL}/user-options.svg`}
            alt="Storico Pratiche"
          />
        </Column>

        <Column lg={3} md={3} sm={3}>
          <div>
            <Stack className="usercard" gap={6}>
              <img
                width="40%"
                src={`${process.env.PUBLIC_URL}/user.svg`}
                alt="Storico Pratiche"
              />
              <p>
                {userData['firstname'] != undefined
                  ? userData['firstname']
                  : ''}
              </p>
              <p>
                {userData['lastname'] != undefined ? userData['lastname'] : ''}
              </p>
              <p>
                {userData['company'] != undefined ? userData['company'] : ''}
              </p>
              <p>{userData['role'] != undefined ? userData['role'] : ''}</p>
            </Stack>
          </div>
        </Column>
        <Column lg={13} md={13} sm={13}>
            <Stack gap={8}>
              <Grid>
                <Column lg={13} md={13} sm={13}>
                  <BuyItem />
                </Column>
              </Grid>
            </Stack>
        </Column>
      </Grid>
    </div>
  );
};

export default UserOptions;
