import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import {
  Form,
  TextInput,
  Stack,
  Button,
  ToastNotification,
} from '@carbon/react';
import { Home } from '@carbon/icons-react';
import { useHistory } from 'react-router-dom';
const ResetPassword = () => {
  const history = useHistory();
  const [sent, setSent] = useState(false);
  const TextInputProps = {
    className: 'input',
    id: 'email',
    labelText: 'Username',
    placeholder: 'youremail@email.com',
  };
  const handleSubmit = event => {
    event.preventDefault();

    firebase
      .auth()
      .sendPasswordResetEmail(event.target.email.value)
      .then(() => {
        setSent(true);
      })
      .catch(error => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ..
      });
  };
  return (
    <Stack as="div" gap={8} className="stack_container">
      <Form className="stack_container2" onSubmit={handleSubmit}>
        <img
          className="LogoLogin"
          src={`${process.env.PUBLIC_URL}/headerlogo.png`}
        />
        {!sent ? (
          <Stack>
            <TextInput {...TextInputProps} />
            <Button className="ButtonForm" type="submit">
              Invia link reset
            </Button>
          </Stack>
        ) : (
          <Stack>
            <p>Controlla la tua mail per ottenere il link di Reset </p>
            <Button
              renderIcon={Home}
              kind="ghost"
              onClick={() => history.push('/')}
            />
          </Stack>
        )}
      </Form>
    </Stack>
  );
};
export default ResetPassword;
