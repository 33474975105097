import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Stack, Button, Tooltip, TextArea } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG401_1 = () => {
  const gridApiRef = useRef(null);
  const gridApiRefCessazioni = useRef(null);
  const gridApiRefTurnover = useRef(null);
  const { esgData, pushESGData, refreshESGData } = useESG();

  const [rowData, setRowData] = useState([getDefaultRow()]);
  const [rowDataCessazioni, setRowDataCessazioni] = useState([
    getDefaultRowCessazioni(),
  ]);
  const [rowDataTurnover, setRowDataTurnover] = useState([
    getDefaultRowTurnover(),
  ]);
  const [esg401_1, setEsg401_1] = useState({});
  const columnDefs = [
    {
      headerName: 'Anno',
      field: 'anno',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'N. Assunzioni totali',
      field: 'assunzioniTotali',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Uomini',
      field: 'uomini',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Donne',
      field: 'donne',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Under 30',
      field: 'under30',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Fascia 30-50',
      field: 'fascia3050',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Oltre 50',
      field: 'oltre50',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];

  const columnDefsCessazioni = [
    {
      headerName: 'Anno',
      field: 'anno',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'N. Cessazioni totali',
      field: 'cessazioniTotali',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Uomini',
      field: 'uomini',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Donne',
      field: 'donne',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Under 30',
      field: 'under30',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Fascia 30-50',
      field: 'fascia3050',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Oltre 50',
      field: 'oltre50',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];
  const columnDefsTurnover = [
    {
      headerName: 'Anno',
      field: 'annoint',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Tasso Turnover Totale',
      field: 'turnovertotale',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Tasso Turnover uomini',
      field: 'turnoveruomini',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },

    {
      headerName: 'Tasso Turnover Donne',
      field: 'turnoverdonne',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Tasso Turnover Under 30',
      field: 'turnoverunder30',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Tasso Turnover Fascia 30-50',
      field: 'turnoverfascia3050',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
    {
      headerName: 'Tasso Turnover oltre 50',
      field: 'turnoveroltre50',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
      flex: 1,
    },
  ];
  function getDefaultRow() {
    return {
      anno: 2022,
      assunzioniTotali: 0,
      uomini: 0,
      donne: 0,
      under30: 0,
      fascia3050: 0,
      oltre50: 0,
    };
  }

  function getDefaultRowTurnover() {
    return {
      annoint: '2022',
      turnovertotale: 0,
      turnoveruomini: 0,
      turnoverdonne: 0,
      turnoverunder30: 0,
      turnoverfascia3050: 0,
      turnoveroltre50: 0,
    };
  }
  function getDefaultRowCessazioni() {
    return {
      anno: 2022,
      cessazioniTotali: 0,
      uomini: 0,
      donne: 0,
      under30: 0,
      fascia3050: 0,
      oltre50: 0,
    };
  }
  useEffect(() => {
    if (esgData['401-1'] != undefined) {
      setEsg401_1(esgData['401-1']);
      if (esgData['401-1']['assunzioni'] != undefined) {
        setRowData(esgData['401-1']['assunzioni']);
      }
      if (esgData['401-1']['cessazioni'] != undefined) {
        setRowDataCessazioni(esgData['401-1']['cessazioni']);
      }
      if (esgData['401-1']['turnover'] != undefined) {
        setRowDataTurnover(esgData['401-1']['turnover']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('401-1', esg401_1);
  }, [esg401_1]);

  const handleAddRow = () => {
    setRowData([...rowData, getDefaultRow()]);
  };
  const handleAddRowCessazioni = () => {
    setRowDataCessazioni([...rowDataCessazioni, getDefaultRowCessazioni()]);
  };
  const handleAddRowTurnover = () => {
    setRowDataTurnover([...rowDataTurnover, getDefaultRowTurnover()]);
  };
  const onCellValueChangedAssunzioni = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg401_1({ ...esg401_1, ['assunzioni']: ex_rowData });
  };

  const onCellValueChangedCessazioni = e => {
    const ex_rowData = rowDataCessazioni;
    ex_rowData[e.rowIndex] = e.data;
    setRowDataCessazioni(ex_rowData);
    setEsg401_1({ ...esg401_1, ['cessazioni']: ex_rowData });
  };
  const onCellValueChangedTurnover = e => {
    const ex_rowData = rowDataTurnover;
    ex_rowData[e.rowIndex] = e.data;
    setRowDataTurnover(ex_rowData);
    setEsg401_1({ ...esg401_1, ['turnover']: ex_rowData });
  };
  const onGridReadyAssunzioni = params => {
    gridApiRef.current = params.api;
  };
  const onGridReadyCessazioni = params => {
    gridApiRefCessazioni.current = params.api;
  };

  const onGridReadyTurnover = params => {
    gridApiRefTurnover.current = params.api;
  };

  const onDeleteRowAssunzioni = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg401_1({ ...esg401_1, ['assunzioni']: updatedRowData });
    }
  };

  const onDeleteRowCessazioni = () => {
    const selectedRows = gridApiRefCessazioni.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowDataCessazioni.filter(
        row => !selectedRows.includes(row)
      );
      setRowDataCessazioni(updatedRowData);
      setEsg401_1({ ...esg401_1, ['cessazioni']: updatedRowData });
    }
  };

  const onDeleteRowTurnover = () => {
    const selectedRows = gridApiRefTurnover.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowDataTurnover.filter(
        row => !selectedRows.includes(row)
      );
      setRowDataTurnover(updatedRowData);
      setEsg401_1({ ...esg401_1, ['turnover']: updatedRowData });
    }
  };
  const handleChanges = e => {
    setEsg401_1({ ...esg401_1, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Valutazione del Turnover</h1>
      <p>
        Il numero di nuovi dipendenti assunti, la loro età, il genere e la
        regione di provenienza possono essere indicativi della strategia
        dell'organizzazione e della sua capacità di attrarre dipendenti
        qualificati e con profili diversificati. Questa informazione può essere
        indicativa dello sforzo di un'organizzazione per attuare pratiche di
        assunzione inclusive sulla base dell'età e del genere. Può anche
        evidenziare l'uso ottimale della manodopera e dei talenti disponibili
        nelle varie regioni geografiche.
      </p>
      <TextArea
        id="descrizione_turnover"
        maxCount={1000}
        enableCounter={true}
        value={
          esgData['401-1'] != undefined &&
          esgData['401-1']['descrizione_turnover'] != undefined
            ? esgData['401-1']['descrizione_turnover']
            : ''
        }
        placeholder="Rendicontare le iniziative riguardo assunzioni/cessazioni messe in atto dall'organizzazione"
        onChange={handleChanges}
        labelText="Iniziative Turnover"
      />

      <Stack gap={4}>
        <h3>Riepilogo Assunzioni</h3>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            domLayout="autoHeight"
            onGridReady={onGridReadyAssunzioni}
            rowSelection={'multiple'}
            onCellValueChanged={onCellValueChangedAssunzioni}
          />

          <Button kind="ghost" onClick={handleAddRow}>
            Aggiungi Riga
          </Button>
          <Button
            kind="ghost"
            onClick={onDeleteRowAssunzioni}
            className="delete-row">
            Elimina Riga
          </Button>
          <Tooltip
            align="bottom"
            label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
            <Button kind="ghost" className="sb-tooltip-trigger" type="button">
              <Information />
            </Button>
          </Tooltip>
        </div>
      </Stack>
      <Stack gap={4}>
        <h3>Riepilogo Cessazioni</h3>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={rowDataCessazioni}
            columnDefs={columnDefsCessazioni}
            domLayout="autoHeight"
            onGridReady={onGridReadyCessazioni}
            rowSelection={'multiple'}
            onCellValueChanged={onCellValueChangedCessazioni}
          />

          <Button kind="ghost" onClick={handleAddRowCessazioni}>
            Aggiungi Riga
          </Button>
          <Button
            kind="ghost"
            onClick={onDeleteRowCessazioni}
            className="delete-row">
            Elimina Riga
          </Button>
          <Tooltip
            align="bottom"
            label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
            <Button kind="ghost" className="sb-tooltip-trigger" type="button">
              <Information />
            </Button>
          </Tooltip>
        </div>
      </Stack>
      <Stack gap={4}>
        <h3>Turnover</h3>

        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={rowDataTurnover}
            columnDefs={columnDefsTurnover}
            domLayout="autoHeight"
            onGridReady={onGridReadyTurnover}
            rowSelection={'multiple'}
            onCellValueChanged={onCellValueChangedTurnover}
          />

          <Button kind="ghost" onClick={handleAddRowTurnover}>
            Aggiungi Riga
          </Button>
          <Button
            kind="ghost"
            onClick={onDeleteRowTurnover}
            className="delete-row">
            Elimina Riga
          </Button>
          <Tooltip
            align="bottom"
            label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
            <Button kind="ghost" className="sb-tooltip-trigger" type="button">
              <Information />
            </Button>
          </Tooltip>
        </div>
      </Stack>
      <footer style={{ fontSize: 10, marginRight: 0 }}>401-1</footer>
    </Stack>
  );
};

export default ESG401_1;
