import React, { useEffect, useState } from 'react';

import {
  Stack,
  TextArea,
  Accordion,
  AccordionItem,
  OrderedList,
  ListItem,
  Grid,
  Column,
  TextInput,
} from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';
import UploadImage from '../../UploadImage';

function ESG102_22(props) {
  const [esg102_22, setEsg102_22] = useState({});
  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-22'] != undefined) {
      setEsg102_22(esgData['102-22']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-22', esg102_22);
  }, [esg102_22]);
  const handleChanges = e => {
    setEsg102_22({ ...esg102_22, [e.target.id]: e.target.value });
  };

  //si utilizza useEffect per caricare i dati in ingresso se ci sono

  const setImgData = data => {
    setEsg102_22({ ...esg102_22, ['foto_ceo']: data });
  };
  const setImgFile = data => {
    setEsg102_22({ ...esg102_22, ['file_fotoCeo']: data });
  };

  const setImgDataFirmaCeo = data => {
    setEsg102_22({ ...esg102_22, ['firma_ceo']: data });
  };
  const setImgFileFirmaCeo = data => {
    setEsg102_22({ ...esg102_22, ['file_firmaCeo']: data });
  };

  return (
    <Stack gap={8}>
      <h1>Lettera di apertura</h1>

      <Accordion>
        <AccordionItem title="Guida alla compilazione">
          <div>
            L’organizzazione dovrebbe descrivere:
            <OrderedList>
              <ListItem>
                la sua strategia e vision a breve, medio e lungo termine per
                gestire i suoi impatti sull’economia, sull’ambiente e sulle
                persone, compresi quelli sui loro diritti umani, in tutte le
                attività e i rapporti di business dell’organizzazione.
              </ListItem>
              <ListItem>
                come il suo scopo, la strategia e il modello di business mirino
                a prevenire impatti negativi e avere impatti positivi
                sull’economia, sull’ambiente e sulle persone;
              </ListItem>
              <ListItem>
                le sue priorità strategiche a breve e medio termine per
                contribuire allo sviluppo sostenibile, compreso come le priorità
                siano coerenti con autorevoli disposizioni di enti
                intergovernativi;
              </ListItem>
              <ListItem>
                le tendenze più vaste (per es., macroeconomiche, sociali,
                politiche) che influiscono sull’organizzazione e sulla sua
                strategia volta a contribuire a uno sviluppo sostenibile;
              </ListItem>
              <ListItem>
                gli eventi, i risultati conseguiti e quelli mancati più
                importanti associati al contributo dell’organizzazione a uno
                sviluppo sostenibile durante il periodo di rendicontazione;
              </ListItem>
              <ListItem>
                una descrizione dei risultati rispetto agli obiettivi correlati
                ai temi materiali dell’organizzazione durante il periodo di
                rendicontazione;
              </ListItem>
              <ListItem>
                le principali sfide, gli obiettivi e i traguardi
                dell'organizzazione per quanto riguarda il suo contributo allo
                sviluppo sostenibile per il prossimo anno e per i prossimi
                tre-cinque anni.
              </ListItem>
            </OrderedList>
          </div>
        </AccordionItem>
      </Accordion>
      <TextArea
        id="lettera_apertura"
        maxCount={3000}
        enableCounter={true}
        value={
          esg102_22['lettera_apertura'] != undefined
            ? esg102_22['lettera_apertura']
            : ''
        }
        labelText="Lettera di apertura del bilancio"
        placeholder="Produrre la lettera di apertura per il bilancio"
        onChange={handleChanges}
      />

      <TextInput
        id="citazione_ceo"
        value={
          esgData['102-22'] != undefined &&
          esgData['102-22']['citazione_ceo'] != undefined
            ? esgData['102-22']['citazione_ceo']
            : ''
        }
        placeholder="La natura ci ha donato, e ci sta donando....."
        onChange={handleChanges}
        labelText="Inserisci una citazione del leader"
      />
      <TextInput
        id="nome_leader"
        value={
          esgData['102-22'] != undefined &&
          esgData['102-22']['nome_leader'] != undefined
            ? esgData['102-22']['nome_leader']
            : ''
        }
        placeholder="Mario Rossi, COO [Nome Azienda]"
        onChange={handleChanges}
        labelText="Inserisci il nome e il ruolo del leader"
      />
      <Grid>
        <Column sm={6} md={6} lg={6}>
          <UploadImage
            setImgData={setImgData}
            setImgFile={setImgFile}
            selectedItem={
              esgData['102-22'] != undefined &&
              esgData['102-22']['foto_ceo'] != undefined
                ? esgData['102-22']['foto_ceo']
                : {}
            }
            reportId={esgData.id}
            filename={'fotoceo'}
            tipo={'rappresentante il leader'}
          />
        </Column>

        <Column sm={6} md={6} lg={6}>
          <UploadImage
            setImgData={setImgDataFirmaCeo}
            setImgFile={setImgFileFirmaCeo}
            reportId={esgData.id}
            selectedItem={
              esgData['102-22'] != undefined &&
              esgData['102-22']['firma_ceo'] != undefined
                ? esgData['102-22']['firma_ceo']
                : {}
            }
            filename={'firmaceo'}
            tipo={'rappresentante la firma del leader'}
          />
        </Column>
      </Grid>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 102-22 </footer>
    </Stack>
  );
}

export default ESG102_22;
