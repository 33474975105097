import { useState } from 'react';
import { getAuth } from 'firebase/auth';

import firebase from 'firebase/compat/app';
import { getAnalytics } from 'firebase/analytics';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

/* const firebaseConfig = {
  apiKey: 'AIzaSyA3dEjZ1jvx_0cOd-5APkmaIbmzs_Lpozo',

  authDomain: 'stillab-esg.firebaseapp.com',

  projectId: 'stillab-esg',

  storageBucket: 'stillab-esg.appspot.com',

  messagingSenderId: '205640958081',

  appId: '1:205640958081:web:00d3dbe7d6c279bb8b8d80',

  measurementId: 'G-713NEP2K5L',
}; */

const firebaseConfig = {
  apiKey: "AIzaSyDiaP8qOkuGMdXyNv0NZFFz8LGYxi0svGw",
  authDomain: "vastum-esg.firebaseapp.com",
  projectId: "vastum-esg",
  storageBucket: "vastum-esg.appspot.com",
  messagingSenderId: "1023742568793",
  appId: "1:1023742568793:web:50115528b5dc369a460448",
  measurementId: "G-8L93Y6YFR0"
};
export const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const createPdf = firebase
  .app()
  .functions('us-central1')
  .httpsCallable('createPdfCall', { timeout: 530 * 1000 });
export const storage = firebase.firestore();
export const bucket = firebase.storage();
