import React, { useState } from 'react';
import { ColorExtractor } from 'react-color-extractor';
import { Button } from '@carbon/react';
import pptxgen from 'pptxgenjs';
import Preview from 'react-pptx/preview';

const IMAGE_ONE = 'https://i.imgur.com/OCyjHNF.jpg';

const IMAGE = IMAGE_ONE;

const IMAGE_STYLES = { width: 700, height: 500 };

const SWATCHES_STYLES = {
  marginTop: 20,
  display: 'flex',
  justifyContent: 'center',
};

class PDFCreator extends React.Component {
  constructor(props) {
    super(props);

    this.setPresOpt();
    this.slideCreator();
  }
  setPresOpt() {
    this.pres.author = 'Brent Ely';
    this.pres.company = 'S.T.A.R. Laboratories';
    this.pres.revision = '15';
    this.pres.subject = 'Annual Report';
    this.pres.title = 'PptxGenJS Sample Presentation';
    this.pres.defineSlideMaster({
      title: 'TitleSlide',
      background: { color: 'FFFFFF' },
      objects: [
        {
          line: { x: 3.5, y: 1.0, w: 6.0, line: { color: '0088CC', width: 5 } },
        },
        { rect: { x: 0.0, y: 0, h: '100%', w: 3, fill: { color: '#353940' } } },
        {
          text: {
            text: "REPORT SOSTENIBILITA'",
            options: { x: 0, y: 0.3, w: 2.7, h: 0.75, color: '#FFFFFF' },
          },
        },
        {
          image: { x: 11.3, y: 6.4, w: 1.67, h: 0.75, path: 'images/logo.png' },
        },
      ],
      slideNumber: { x: 9, y: '93%' },
    });
  }

  slideCreator() {
    let slide = this.pres.addSlide({ masterName: 'TitleSlide' });
  }
  writePPT() {
    this.pres.writeFile({ fileName: 'CIAOOOO.pptx' }).then(fileName => {});
  }
  pres = new pptxgen();
  state = { colors: [], selected_img: [], img_data: [] };

  renderSwatches = () => {
    return this.state.colors.map((color, id) => {
      return (
        <div
          key={id}
          style={{
            backgroundColor: color,
            width: 100,
            height: 100,
          }}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <Button onClick={() => this.writePPT()}>Download</Button>
      </div>
    );
  }
}
export default PDFCreator;
