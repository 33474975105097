import {
  Stack,
  Grid,
  Column,
  ProgressIndicator,
  ProgressStep,
  Modal,
} from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PopUp } from '../../components/PopUp';
/* 
import { Button } from '@carbon/react';
import Anagrafica from '../../components/Checklist/Anagrafica';
import Governance from '../../components/Checklist/Governance';
import Ambiente from '../../components/Checklist/Ambiente';
import Sociale from '../../components/Checklist/Sociale';
import { useClToESGContext } from '../../action/ClToESG/cltoesg';


*/

import { useESG } from '../../action/ESGData/esgdata';
import LoadingOverlay from '../../components/Loader';
import { auth, storage } from '../../action/fiirebase_config/firebase_config';
import { useParams } from 'react-router-dom';
const NewReport = ({ match }) => {
  const [showModal, setShowModal] = useState(true);
  const [reportRef, setReportRef] = useState({});
  const [actualItem, setActualItem] = useState('Anagrafica');
  const [checklistData, setChecklistData] = useState({
    anagrafica: {},
    ambiente: {},
    sociale: {},
    governance: {},
  });
  const { sessionId } = useParams();

  const [objectCurrentMap, SetObjectCurrentMap] = useState({
    Anagrafica: false,
    Governance: false,
    Ambiente: false,
    Sociale: false,
    Riepilogo: false,
  });
  const [objectCompleteMap, SetObjectCompleteMap] = useState({
    Anagrafica: false,
    Governance: false,
    Ambiente: false,
    Sociale: false,
    Riepilogo: false,
  });
  const [objectInvalidMap, SetobjectInvalidMap] = useState({
    Anagrafica: false,
    Governance: false,
    Ambiente: false,
    Sociale: false,
    Riepilogo: false,
  });
  const [isInvalid, SetIsInvalid] = useState(false);
  const [isLoading, SetIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (sessionId == undefined) {
      //history.push("/main_user")
    }

    const reportRef = storage.collection('payments-internal').doc(sessionId);
    reportRef
      .get()
      .then(docSnapshot => {
        if (docSnapshot.exists) {
          if (docSnapshot.data()['payment_status'] == 'completed') {
            if (auth.currentUser) {
              setReportRef(reportRef);
            } else {
              //history.push("/main_user")
            }
          } else {
            //history.push("/main_user")
          }
          // Puoi accedere ai dati del documento tramite docSnapshot.data()
        } else {
          //history.push("/main_user")
        }
      })
      .catch(error => {
        console.error('Errore durante la verifica del documento:', error);
      });
  }, []);
  const [isModalError, setIsModalError] = useState(false);
  const anagrafica_ids = [
    '0',
    'ragione_azienda',
    'anni_attivita',
    'codice_ateco',
    'forma_giuridica',
    'num_dipendenti',
    'nazioni_operanti',
    'politiche_esg',
  ];
  const ambiente_ids = [
    'utilizzo_materie_prime',
    'utilizzo_acqua',
    'monitor_emissioni',
    'utilizzo_fonti_rinnovabili',
    'impatto_biodiversita',
    'smaltimento_rifiuti',
  ];
  const governance_ids = [
    'politiche_salariali',
    'fornitori_locali',
    'politiche_anticoncorrenziali',
    'certificazioni_aqs',
    'impatti_economici',
    'strategia_fiscale',
    'valutazione_fornitori',
    'politiche_anticorruzione',
  ];
  const sociale_ids = [
    'benefit_dipendenti',
    'politiche_inclusive',
    'popolazioniindigene',
    'politiche_impattisalute',
    'politiche_vioprivacy',
    'politiche_saluteesicurezza',
    'politiche_lavorominorile',
    'politiche_comunitalocali',
    'etichettatura_prodotti',
  ];
  let disabled = false;
  const context_obj = useESG();

  let dummy_current = {
    Anagrafica: false,
    Governance: false,
    Ambiente: false,
    Sociale: false,
    Riepilogo: false,
  };
  const openModal = () => {
    setShowModal(showModal => !showModal);
  };

  const checkIfComplete = () => {
    var found_all = true;
    if (actualItem == 'Anagrafica') {
      const to_verify = Object.keys(checklistData['anagrafica']);
      anagrafica_ids.map(id => {
        if (!to_verify.includes(id)) {
          found_all = false;
        }
      });
      if (found_all) {
        SetobjectInvalidMap({ ...objectInvalidMap, Anagrafica: false });
        SetObjectCompleteMap({ ...objectCompleteMap, Anagrafica: found_all });
        SetIsInvalid(false);
      } else {
        SetobjectInvalidMap({ ...objectInvalidMap, Anagrafica: true });
        SetIsInvalid(true);
      }
    } else if (actualItem == 'Governance') {
      const to_verify = Object.keys(checklistData['governance']);
      governance_ids.map(id => {
        if (!to_verify.includes(id)) {
          found_all = false;
        }
      });
      if (found_all) {
        SetobjectInvalidMap({ ...objectInvalidMap, Governance: false });
        SetObjectCompleteMap({ ...objectCompleteMap, Governance: found_all });
      } else {
        SetobjectInvalidMap({ ...objectInvalidMap, Governance: true });
      }
    } else if (actualItem == 'Ambiente') {
      const to_verify = Object.keys(checklistData['ambiente']);
      ambiente_ids.map(id => {
        if (!to_verify.includes(id)) {
          found_all = false;
        }
      });
      if (found_all) {
        SetobjectInvalidMap({ ...objectInvalidMap, Ambiente: false });
        SetObjectCompleteMap({ ...objectCompleteMap, Ambiente: found_all });
      } else {
        SetobjectInvalidMap({ ...objectInvalidMap, Ambiente: true });
      }
    } else if (actualItem == 'Sociale') {
      const to_verify = Object.keys(checklistData['sociale']);
      sociale_ids.map(id => {
        if (!to_verify.includes(id)) {
          found_all = false;
        }
      });
      if (found_all) {
        SetobjectInvalidMap({ ...objectInvalidMap, Sociale: false });
        SetObjectCompleteMap({ ...objectCompleteMap, Sociale: found_all });
      } else {
        SetobjectInvalidMap({ ...objectInvalidMap, Sociale: true });
      }
    }
  };
  const setClData = data => {
    setChecklistData({
      ...checklistData,
      [Object.keys(data)]: data[Object.keys(data)],
    });
  };
  const items_progress = [
    'Anagrafica',
    'Governance',
    'Ambiente',
    'Sociale',
    'Riepilogo',
  ];

  const performNavigation = param => {
    checkIfComplete();

    if (param == 'fw') {
      let actual_index = items_progress.indexOf(actualItem);

      if (actual_index == items_progress.length - 1) {
      } else {
        if (actual_index > 0) {
          disabled = false;
        }
        for (let key_current of Object.keys(dummy_current)) {
          if (key_current == items_progress[actual_index + 1]) {
            dummy_current[key_current] = true;
          } else {
            dummy_current[key_current] = false;
          }
        }
        SetObjectCurrentMap(objectCurrentMap => dummy_current);
        setActualItem(actualItem => items_progress[actual_index + 1]);
      }
    } else {
      let actual_index = items_progress.indexOf(actualItem);

      if (actual_index == 0) {
        disabled = true;
      } else {
        for (let key_current of Object.keys(dummy_current)) {
          if (key_current == items_progress[actual_index - 1]) {
            dummy_current[key_current] = true;
          } else {
            dummy_current[key_current] = false;
          }
        }
        SetObjectCurrentMap(objectCurrentMap => dummy_current);
        setActualItem(actualItem => items_progress[actual_index - 1]);
      }
    }
  };
  const calculate_classname = section => {
    let def = 'hidden';
    if (actualItem == 'Riepilogo') {
      def = 'visibleRiep';
    } else if (actualItem == section) {
      def = 'visible';
    }
    return def;
  };
  const returnHome = () => {
    history.push('/');
  };

  return (
    <>
      <div className="container_checklist">
        {/*{showModal == false && (
          <>
            {isLoading == true && <LoadingOverlay />}
            <Grid className="newreport-page" fullWidth>
              <Column
                lg={16}
                md={16}
                sm={16}
                className="newreport-page__banner">
                <h1>Checklist preparatoria</h1>
                <p>
                  Compila le domande per le varie sezioni, sceglieremo
                  accuratamente i punti dello standard applicabili alla tua
                  azienda
                </p>
              </Column>
            </Grid>
            <ProgressIndicator className="newreport-page__prgs">
              <ProgressStep
                invalid={objectInvalidMap['Anagrafica']}
                complete={objectCompleteMap['Anagrafica']}
                current={objectCurrentMap['Anagrafica']}
                label="Anagrafica"
                description="Step 1: Raccolta informazioni sull'azienda"
              />
              <ProgressStep
                invalid={objectInvalidMap['Governance']}
                complete={objectCompleteMap['Governance']}
                current={objectCurrentMap['Governance']}
                label="Governance"
                description="Step 2: Vision e Governance aziendali"
              />
              <ProgressStep
                invalid={objectInvalidMap['Ambiente']}
                complete={objectCompleteMap['Ambiente']}
                current={objectCurrentMap['Ambiente']}
                label="Ambiente"
                description="Step 3: Rapporti con l'ambiente"
              />
              <ProgressStep
                invalid={objectInvalidMap['Sociale']}
                complete={objectCompleteMap['Sociale']}
                current={objectCurrentMap['Sociale']}
                label="Sociale"
                description="Step 4: Vision Sociale"
              />
              <ProgressStep
                invalid={objectInvalidMap['Riepilogo']}
                complete={objectCompleteMap['Riepilogo']}
                current={objectCurrentMap['Riepilogo']}
                label="Riepilogo"
                description="Step 5: Riepilogo delle infromazioni raccolte"
              />
            </ProgressIndicator>
            <div className="progress-position">
              <div className="form_controller">
                <div className={calculate_classname('Anagrafica')}>
                  <Anagrafica setClData={setClData} />
                </div>
                <div className={calculate_classname('Governance')}>
                  <Governance setClData={setClData} />
                </div>
                <div className={calculate_classname('Ambiente')}>
                  <Ambiente setClData={setClData} />
                </div>
                <div className={calculate_classname('Sociale')}>
                  <Sociale setClData={setClData} />
                </div>



                <p
                  className={
                    isInvalid && actualItem == 'Riepilogo'
                      ? 'visibleInvalid'
                      : 'hidden'
                  }>
                  Alcune delle informazioni necessarie non sono state inserite.
                  Per favore ricontrolla le informazioni inserite nella sezione
                  Anagrafica.
                </p>
                <div className="hori_center">
                  <Button
                    disabled={disabled}
                    onClick={() => performNavigation('bw')}
                    id="BackButton"
                    kind="tertiary">
                    Indietro
                  </Button>
                  <Button
                    disabled={isInvalid && actualItem == 'Riepilogo'}
                    onClick={() => {
                      if (!isInvalid && actualItem == 'Riepilogo') {
                        SetIsLoading(true);
                        context_obj.pushclToESGData('checklist', checklistData);
                        context_obj
                          .create_new_report(checklistData)
                          .then(value => {
                            
                            if (value['visible_name'] != '') {
                              SetIsLoading(false);
                              history.push('/esgstd');
                            } else {
                              SetIsLoading(false);
                              setIsModalError(true);
                            }
                          })
                          .catch(error => {
                            SetIsLoading(false);
                            
                            setIsModalError(true);
                          });
                      } else {
                        performNavigation('fw');
                      }
                    }}>
                    Avanti
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      */}
        <PopUp
          className="popup-display"
          showModal={showModal}
          setShowModal={setShowModal}
          reportRef={reportRef}
        />
        <Modal
          open={isModalError}
          modalHeading="Si è verificato un errore"
          modalLabel="Errore piattaforma"
          primaryButtonText="Ritorna alla Home Page"
          onRequestSubmit={returnHome}>
          <p
            style={{
              marginBottom: '1rem',
            }}>
            Il server non risponde.
          </p>
        </Modal>
      </div>
    </>
  );
};

export default NewReport;
