import React from 'react';
import { useState, useEffect } from 'react';
import {
  Switcher,
  Menu,
  UserAvatar,
  Pen,
  Home,
  Logout,
} from '@carbon/react/icons';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  Button,
  Modal,
  TextInput,
  Form,
} from '@carbon/react';
import { Link, useHistory } from 'react-router-dom';
import { auth } from '../../action/fiirebase_config/firebase_config';
import { useESG } from '../../action/ESGData/esgdata';
import { useUser } from '../../action/UserData/UserData';
import { cloneDeep } from 'lodash';
import { update_user_details_firebase } from '../../action/firebase_communication/firebaseManager';
import LoadingOverlay from '../Loader';
const TutorialHeader = props => {
  const { refreshESGData } = useESG();
  const [isLogged, setIsLogged] = useState(props.isLogged);
  const { state, updateUserData, fetchUserData } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalUserDataOpen, SetIsModalUserDataOpen] = useState(false);
  const [internalUserOpt, setInternalUserOpt] = useState(cloneDeep(state));
  const [logOffUserModal, setLogOffUserModal] = useState(false);
  const [mainMenuModal, setMainMenuModal] = useState(false);

  useEffect(() => {
    setInternalUserOpt(cloneDeep(state));
  }, [state]);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        console.log('SETTO TRUE');
        setIsLogged(true);
      } else {
        console.log('SETTO FALSE');
        setIsLogged(false);
      }
    });

    return () => unsubscribe();
  }, []);
  const history = useHistory();
  const handleClick = event => {
    event.preventDefault();
    setLogOffUserModal(false);
    auth.signOut().then(history.push('/'));
    setIsLogged(false);
  };

  const handleClickMainMenu = () => {
    refreshESGData();
    setMainMenuModal(false);
    history.push('/main_user');
  };

  const setNewData = () => {
    if (auth.currentUser) {
      setIsLoading(true);
      update_user_details_firebase(auth.currentUser.uid, internalUserOpt)
        .then(() => {
          setIsLoading(false);
        })
        .catch(e => {
          setIsLoading(false);
        });
      updateUserData(internalUserOpt);
      SetIsModalUserDataOpen(false);
    }
  };

  const handleChanges = e => {
    setInternalUserOpt({ ...internalUserOpt, [e.target.id]: e.target.value });
  };
  const closeModal = () => {
    SetIsModalUserDataOpen(false);
  };

  const fetchUserDataDetails = () => {
    fetchUserData(auth.currentUser.uid);
    SetIsModalUserDataOpen(true);
  };
  const checkIfMain = () => {
    if (history.location.pathname != '/main_user') {
      setMainMenuModal(true);
    }
  };
  return (
    <>
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <Header aria-label="Carbon Tutorial">
            <SkipToContent />
            <HeaderMenuButton
              aria-label="Open menu"
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
            />
            <img
              className="headerLogo"
              src={`${process.env.PUBLIC_URL}/headerlogo.png`}
            />
            <HeaderName
              //as={Link}
              //to="/"
              //onClick={() => refreshESGData}
              prefix="">
              Piattaforma ESG
            </HeaderName>
            <HeaderNavigation aria-label="Carbon Tutorial" />
            <SideNav
              aria-label="Side navigation"
              expanded={isSideNavExpanded}
              isPersistent={false}>
              <SideNavItems>
                <HeaderSideNavItems>
                  <HeaderMenuItem as={Link} to="/repos">
                    Repositories
                  </HeaderMenuItem>
                </HeaderSideNavItems>
              </SideNavItems>
            </SideNav>

            <HeaderGlobalBar
              style={
                isLogged ? { visibility: 'visible' } : { visibility: 'hidden' }
              }>
              <HeaderGlobalAction
                onClick={checkIfMain}
                aria-label="Menu Principale"
                tooltipAlignment="center">
                <Switcher size={20} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                onClick={fetchUserDataDetails}
                aria-label="Opzioni Utente"
                tooltipAlignment="center">
                <UserAvatar size={20} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                aria-label="Log-out"
                tooltipAlignment="end"
                onClick={() => setLogOffUserModal(true)}>
                <Logout size={20} />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
          </Header>
        )}
      />
      {isLoading ? (
        <LoadingOverlay main_text="Aggiornamento dati utente in corso...." />
      ) : (
        <Modal
          open={isModalUserDataOpen}
          onRequestClose={closeModal}
          primaryButtonText="Conferma"
          secondaryButtonText="Salta"
          onRequestSubmit={() => setNewData()}
          onSecondarySubmit={closeModal}
          modalHeading={'Buongiorno ' + state['firstname']}
          modalLabel="Informazioni Account">
          <p
            style={{
              marginBottom: '1rem',
            }}>
            Trovi di seguito le tue informazioni. Modifica i tuoi dati e premi
            su Conferma per aggiornarli.
          </p>

          <TextInput
            id="firstname"
            onInput={handleChanges}
            labelText="Nome"
            value={
              internalUserOpt['firstname'] != undefined
                ? internalUserOpt['firstname']
                : ''
            }
            style={{
              marginBottom: '1rem',
            }}
          />
          <TextInput
            id="lastname"
            onChange={handleChanges}
            labelText="Cognome"
            value={
              internalUserOpt['lastname'] != undefined
                ? internalUserOpt['lastname']
                : ''
            }
            style={{
              marginBottom: '1rem',
            }}
          />
          <TextInput
            id="company"
            onChange={handleChanges}
            labelText="Ragione Sociale Azienda"
            value={
              internalUserOpt['company'] != undefined
                ? internalUserOpt['company']
                : ''
            }
            style={{
              marginBottom: '1rem',
            }}
          />
          <TextInput
            id="role"
            onChange={handleChanges}
            labelText="Ruolo Aziendale"
            value={
              internalUserOpt['role'] != undefined
                ? internalUserOpt['role']
                : ''
            }
            style={{
              marginBottom: '1rem',
            }}
          />
          <TextInput
            id="pec"
            onChange={handleChanges}
            labelText="PEC Aziendale"
            value={
              internalUserOpt['pec'] != undefined ? internalUserOpt['pec'] : ''
            }
            style={{
              marginBottom: '1rem',
            }}
          />
          <TextInput
            id="sdi"
            onChange={handleChanges}
            labelText="Codice Destinatario (SDI)"
            value={
              internalUserOpt['sdi'] != undefined ? internalUserOpt['sdi'] : ''
            }
            style={{
              marginBottom: '1rem',
            }}
          />
        </Modal>
      )}

      <Modal
        open={logOffUserModal}
        onRequestClose={() => setLogOffUserModal(false)}
        danger
        primaryButtonText="Conferma"
        secondaryButtonText="Rimani"
        onRequestSubmit={handleClick}
        onSecondarySubmit={() => setLogOffUserModal(false)}
        modalHeading={'Sei sicuro di voler uscire?'}
        modalLabel="Log-out">
        <p>
          Cliccando su "Conferma" uscirai dal tuo account e verrai reindirizzato
          alla home page. Clicca su "Rimani" per annullare. Eventuali dati non
          salvati potrebbero andar persi.
        </p>
      </Modal>

      <Modal
        open={mainMenuModal}
        onRequestClose={() => setMainMenuModal(false)}
        danger
        primaryButtonText="Conferma"
        secondaryButtonText="Rimani"
        onRequestSubmit={handleClickMainMenu}
        onSecondarySubmit={() => setMainMenuModal(false)}
        modalHeading={'Sei sicuro di voler tornare al menu pratiche?'}
        modalLabel="Menu pratiche"
        shouldSubmitOnEnter={false}>
        <p>
          Cliccando su "Conferma" tornerai al menu pratiche. Clicca su "Rimani"
          per annullare. Eventuali dati non salvati potrebbero andar persi.
        </p>
      </Modal>
    </>
  );
};

export default TutorialHeader;
