import RiduzioneEnergia from '../../../../assets/html_template/professional/A4/11-ambiente-gri302-4-RiduzioneEnergia.html';
import RiduzioneEnergia169 from '../../../../assets/html_template/professional/169/11-ambiente-gri302-4-RiduzioneEnergia.html';
import RiduzioneEnergiaPopA4 from '../../../../assets/html_template/pop/A4/11-ambiente-gri302-4-RiduzioneEnergia.html';
import RiduzioneEnergiaPop169 from '../../../../assets/html_template/pop/169/11-ambiente-gri302-4-RiduzioneEnergia.html';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';

import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';

const RiduzioneEnergiaEngine = ({ isOpen, type }) => {
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString =
        type == 'A4' ? RiduzioneEnergia : RiduzioneEnergia169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? RiduzioneEnergiaPopA4 : RiduzioneEnergiaPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString =
        type == 'A4' ? RiduzioneEnergia : RiduzioneEnergia169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };
  const [
    descrizioneRiduzioneEnergia,
    setDescrizioneRiduzioneEnergia,
  ] = useState('');
  const [riduzioneEnergiaTable, setRiduzioneEnergiaTable] = useState('');

  const [riduzioneEnergiaData, SetRiduzioneEnergiaData] = useState(
    RiduzioneEnergia
  );
  const [riduzioneEnergiaDataA4, SetRiduzioneEnergiaDataA4] = useState(
    RiduzioneEnergia
  );
  const [riduzioneEnergiaData169, SetRiduzioneEnergiaData169] = useState(
    RiduzioneEnergia169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    SetRiduzioneEnergiaData(RiduzioneEnergiaPopA4);
    SetRiduzioneEnergiaDataA4(RiduzioneEnergiaPopA4);
    SetRiduzioneEnergiaData169(RiduzioneEnergiaPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    SetRiduzioneEnergiaData(RiduzioneEnergia);
    SetRiduzioneEnergiaDataA4(RiduzioneEnergia);
    SetRiduzioneEnergiaData169(RiduzioneEnergia169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    SetRiduzioneEnergiaData(RiduzioneEnergia);
    SetRiduzioneEnergiaDataA4(RiduzioneEnergia);
    SetRiduzioneEnergiaData169(RiduzioneEnergia169);
  }
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');

  const { esgData } = useESG();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();

  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetRiduzioneEnergiaData(RiduzioneEnergiaPopA4);
      SetRiduzioneEnergiaDataA4(RiduzioneEnergiaPopA4);
      SetRiduzioneEnergiaData169(RiduzioneEnergiaPop169);
      force_update(
        type == 'A4' ? RiduzioneEnergiaPopA4 : RiduzioneEnergiaPop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetRiduzioneEnergiaData(RiduzioneEnergia);
      SetRiduzioneEnergiaDataA4(RiduzioneEnergia);
      SetRiduzioneEnergiaData169(RiduzioneEnergia169);
      force_update(type == 'A4' ? RiduzioneEnergia : RiduzioneEnergia169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      SetRiduzioneEnergiaData(RiduzioneEnergia);
      SetRiduzioneEnergiaDataA4(RiduzioneEnergia);
      SetRiduzioneEnergiaData169(RiduzioneEnergia169);
      force_update(type == 'A4' ? RiduzioneEnergia : RiduzioneEnergia169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('302-4')
    ) {
      setIsVisible(false);
      delPageData('302-4');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(riduzioneEnergiaData);
    } else {
      if (
        esgData['302-4'] != undefined &&
        esgData['302-4']['descrizione_riduzione_energia'] != undefined &&
        esgData['302-4']['descrizione_riduzione_energia'] !=
          descrizioneRiduzioneEnergia
      ) {
        hasUpdate = true;
      }
      if (
        esgData['302-4'] != undefined &&
        esgData['302-4']['riduzione_energia_tabella'] != undefined &&
        !compareLists(
          esgData['302-4']['riduzione_energia_tabella'],
          riduzioneEnergiaTable
        )
      ) {
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        hasUpdate = true;
      }

      if (hasUpdate) {
        renderHTMLtoiFrame();
      }
    }
  }, [esgData]);

  const compareLists = (list1, list2) => {
    // Verifica se le lunghezze delle liste sono diverse
    try {
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };
  //prova img

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = [
      'Misura di Riduzione',
      'Tipo Energia',
      '% Riduzione',
      'Note',
    ];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = riduzioneEnergiaData169;
        to_init = '169';
      } else {
        templateHTMLString = riduzioneEnergiaDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['302-4'] != undefined &&
        esgData['302-4']['descrizione_riduzione_energia'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'descrizione_riduzione_energia'
        );
        fotoLeaderElement.innerHTML =
          esgData['302-4']['descrizione_riduzione_energia'];
        setDescrizioneRiduzioneEnergia(
          esgData['302-4']['descrizione_riduzione_energia']
        );
      }
      //&&!compareLists(tabellacriticita,esgData["302-4"]["rendicontazione_criticita"])

      if (
        esgData['302-4'] != undefined &&
        esgData['302-4']['riduzione_energia_tabella'] != undefined
      ) {
        const table = parsedHTML.getElementById('riduzione_energia_tabella');
        const grafico = parsedHTML.getElementById('chart_recuperati');
        if (esgData['302-4']['riduzione_energia_tabella'].length > 0) {
          const dati_filtrati = esgData['302-4'][
            'riduzione_energia_tabella'
          ].filter(item => item.percentualeRiduzione != 0);
          const filtered_data = esgData['302-4'][
            'riduzione_energia_tabella'
          ].map(elemento => ({
            Note: elemento.note,
            'Misura di Riduzione': elemento.misuraDiRiduzione,
            '% Riduzione': elemento.percentualeRiduzione,
            'Tipo Energia': elemento.tipoDiEnergia,
          }));

          popolaTabella(table, filtered_data, ordered_keys);
          try {
            grafico.textContent = grafico.textContent.replace(
              /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
              ''
            );

            // Inserisci la nuova definizione di data nello script
            //modificare i dati per la percentuale
            grafico.textContent =
              `
            //TOSTART
            var data2 = ${JSON.stringify(dati_filtrati, null, 2)};
            //TOEND;
  
          ` + grafico.textContent;
          } catch {}
        }
        setRiduzioneEnergiaTable(
          cloneDeep(esgData['302-4']['riduzione_energia_tabella'])
        );
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
        setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('302-4', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Misure di Riduzione Energia',
          initA4: RiduzioneEnergiaPopA4,
          init169: RiduzioneEnergiaPop169,
          keys_to_check: [
            'chart_recuperati',
            'descrizione_riduzione_energia',
            'riduzione_energia_tabella',
          ],
          gri: '302-4',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('302-4', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Misure di Riduzione Energia',
          initA4: RiduzioneEnergia,
          init169: RiduzioneEnergia169,
          keys_to_check: [
            'chart_recuperati',
            'descrizione_riduzione_energia',
            'riduzione_energia_tabella',
          ],
          gri: '302-4',
        });
      }
    }
  };

  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      const ordered_keys = [
        'Misura di Riduzione',
        'Tipo Energia',
        '% Riduzione',
        'Note',
      ];
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? riduzioneEnergiaData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['302-4'] != undefined &&
          esgData['302-4']['descrizione_riduzione_energia'] != undefined &&
          esgData['302-4']['descrizione_riduzione_energia'] !=
            descrizioneRiduzioneEnergia
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_riduzione_energia'
          );
          fotoLeaderElement.innerHTML =
            esgData['302-4']['descrizione_riduzione_energia'];
          setDescrizioneRiduzioneEnergia(
            esgData['302-4']['descrizione_riduzione_energia']
          );
        }
        //&&!compareLists(tabellacriticita,esgData["302-4"]["rendicontazione_criticita"])

        if (
          esgData['302-4'] != undefined &&
          esgData['302-4']['riduzione_energia_tabella'] != undefined &&
          !compareLists(
            esgData['302-4']['consumo_enegia_tabella'],
            riduzioneEnergiaTable
          )
        ) {
          const table = parsedHTML.getElementById('riduzione_energia_tabella');
          const grafico = parsedHTML.getElementById('chart_recuperati');
          if (
            esgData['302-4']['riduzione_energia_tabella'] != undefined &&
            esgData['302-4']['riduzione_energia_tabella'].length > 0
          ) {
            const dati_filtrati = esgData['302-4'][
              'riduzione_energia_tabella'
            ].filter(item => item.percentualeRiduzione != 0);
            const filtered_data = esgData['302-4'][
              'riduzione_energia_tabella'
            ].map(elemento => ({
              Note: elemento.note,
              'Misura di Riduzione': elemento.misuraDiRiduzione,
              '% Riduzione': elemento.percentualeRiduzione,
              'Tipo Energia': elemento.tipoDiEnergia,
            }));

            popolaTabella(table, filtered_data, ordered_keys);
            try {
              grafico.textContent = grafico.textContent.replace(
                /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
                ''
              );

              // Inserisci la nuova definizione di data nello script
              //modificare i dati per la percentuale
              grafico.textContent =
                `
                //TOSTART
                const data2 = ${JSON.stringify(dati_filtrati, null, 2)};
                //TOEND;

              ` + grafico.textContent;
            } catch {}
          }
          setRiduzioneEnergiaTable(
            cloneDeep(esgData['302-4']['riduzione_energia_tabella'])
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      } else {
        if (
          esgData['302-4'] != undefined &&
          esgData['302-4']['descrizione_riduzione_energia'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'descrizione_riduzione_energia'
          );
          fotoLeaderElement.innerHTML =
            esgData['302-4']['descrizione_riduzione_energia'];
          setDescrizioneRiduzioneEnergia(
            esgData['302-4']['descrizione_riduzione_energia']
          );
        }
        //&&!compareLists(tabellacriticita,esgData["302-4"]["rendicontazione_criticita"])

        if (
          esgData['302-4'] != undefined &&
          esgData['302-4']['riduzione_energia_tabella'] != undefined
        ) {
          const table = parsedHTML.getElementById('riduzione_energia_tabella');
          const grafico = parsedHTML.getElementById('chart_recuperati');
          if (esgData['302-4']['riduzione_energia_tabella'].length > 0) {
            const dati_filtrati = esgData['302-4'][
              'riduzione_energia_tabella'
            ].filter(item => item.percentualeRiduzione != 0);
            const filtered_data = esgData['302-4'][
              'riduzione_energia_tabella'
            ].map(elemento => ({
              Note: elemento.note,
              'Misura di Riduzione': elemento.misuraDiRiduzione,
              '% Riduzione': elemento.percentualeRiduzione,
              'Tipo Energia': elemento.tipoDiEnergia,
            }));

            popolaTabella(table, filtered_data, ordered_keys);
            try {
              grafico.textContent = grafico.textContent.replace(
                /\/\/TOSTART([\s\S]*?)\/\/TOEND/,
                ''
              );

              // Inserisci la nuova definizione di data nello script
              //modificare i dati per la percentuale
              grafico.textContent =
                `
              //TOSTART
              const data2 = ${JSON.stringify(dati_filtrati, null, 2)};
              //TOEND;

            ` + grafico.textContent;
            } catch {}
          }
          setRiduzioneEnergiaTable(
            cloneDeep(esgData['302-4']['riduzione_energia_tabella'])
          );
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }

      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      updateDataUrl(blobURL);
      setIframeURL(blobURL);

      SetRiduzioneEnergiaData(parsedHTML.documentElement.outerHTML);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default RiduzioneEnergiaEngine;
