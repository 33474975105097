import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useReducer,
} from 'react';
import axios from 'axios';
import { auth } from '../fiirebase_config/firebase_config';
import { resetPassword } from '@userfront/core';
import {
  create_esg_report_firebase,
  create_new_report_firebase,
  manage_user_details_firebase,
  return_reportinfo_by_uid,
  update_report_firebase,
  update_user_details_firebase,
} from '../firebase_communication/firebaseManager';
// Crea un nuovo Context
const ESGContext = createContext();

// Hook personalizzato per accedere al Context
export function useESG() {
  return useContext(ESGContext);
}
const sideDataManagerReducerToken = (state, action) => {
  if (action != undefined) {
    switch (action.type) {
      case 'UPDATE_DATA':
        return action.payload;
      // Aggiungi altri casi se necessario

      default:
        return state;
    }
  }
};
const sideDataManagerReducerVisibleName = (state, action) => {
  switch (action.type) {
    case 'UPDATE_DATA':
      return action.payload;
    // Aggiungi altri casi se necessario

    default:
      return state;
  }
};
const sideDataManagerReducerReportID = (state, action) => {
  switch (action.type) {
    case 'UPDATE_DATA':
      return action.payload;
    // Aggiungi altri casi se necessario

    default:
      return state;
  }
};
const sideDataManagerReducerUserData = (state, action) => {
  if (action != undefined) {
    switch (action.type) {
      case 'UPDATE_DATA':
        return action.payload;
      // Aggiungi altri casi se necessario

      default:
        return state;
    }
  } else {
    console.log(state);
    return state;
  }
};

const esgDataManagerReducer = (state, action) => {
  switch (action.action) {
    case 'UPDATE_DATA':
      if (
        action.payload != undefined &&
        Object.keys(action.payload).length !== 0
      ) {
        console.log(action);
        return { ...state, [action.key]: action.payload };
      } else {
        return state;
      }

    // Aggiungi altri casi se necessario
    case 'UPDATE_ALL':
      return action.payload;
    case 'GET_DATA':
      return state[action.key];
    case 'CLEAR_DATA':
      return {};
    default:
      return state;
  }
};

export function ESGProvider({ children }) {
  //const [esgData, setESGData] = useState({});
  const [esgData, dispatchEsgData] = useReducer(esgDataManagerReducer, {});
  const [reportID, dispatchReportID] = useReducer(
    sideDataManagerReducerReportID,
    ''
  );
  const [visibleName, dispatchVisibleName] = useReducer(
    sideDataManagerReducerVisibleName,
    ''
  );
  const [userData, dispatchUserData] = useReducer(
    sideDataManagerReducerUserData,
    {}
  );
  const [token, dispatchToken] = useReducer(sideDataManagerReducerToken, '');
  const [clData, setclData] = useState({});
  const SERVER_URL = process.env.REACT_APP_BACKEND_URL;

  const create_new_report = async checklistData => {
    try {
      var new_token = await auth.currentUser.getIdToken(true);
      dispatchToken({ type: 'UPDATE_DATA', payload: new_token });
      /*       var response = await axios.post(SERVER_URL + 'createreport', {
        token: new_token,
        clData:
          checklistData != undefined
            ? checklistData['anagrafica'] != undefined
              ? checklistData['anagrafica']
              : {}
            : {},
      }); */
      var response = await create_new_report_firebase(
        auth.currentUser.uid,
        checklistData && checklistData['anagrafica']
          ? checklistData['anagrafica']
          : {}
      );
      dispatchReportID({
        type: 'UPDATE_DATA',
        payload: response.visible_name,
      });
      //setESGData(response.data);
      dispatchEsgData({ action: 'UPDATE_ALL', payload: response });
      return response;
    } catch (error) {
      throw error;
    }
  };

  /*   useEffect(() => {
    return_user_details(); // This is be executed when `loading` state changes
  }, [token]); */

  const return_user_details = async token => {
    try {
      var token = token;
      if (token == undefined) {
        token = token;
      }

      if (token !== '') {
        var payload = {
          token: token,
        };

        //const response = await axios.post(SERVER_URL + 'login', payload);
        const response = await manage_user_details_firebase(
          auth.currentUser.uid
        );

        dispatchUserData({ type: 'UPDATE_DATA', payload: response });
        return response;
      }
    } catch (error) {
      throw error; // Restituisci uno stato di errore
    }
  };
  const setReportIDOnCallback = (id, jsonData) => {
    //setESGData(jsonData);
    dispatchReportID({ type: 'UPDATE_DATA', payload: id });
    dispatchEsgData({ action: 'UPDATE_ALL', payload: jsonData });
  };
  const return_docs_uid = async () => {
    if (token != '') {
      try {
        var new_token = await auth.currentUser.getIdToken(true);
        dispatchToken({ type: 'UPDATE_DATA', payload: new_token });
        /*       var response = await axios.post(SERVER_URL + 'getreportbyuid', {
          token: new_token,
        }); */

        var response = await return_reportinfo_by_uid(auth.currentUser.uid);
        return response;
      } catch (error) {
        throw error;
      }
    }
  };

  const update_user_details = async data => {
    try {
      var new_token = await auth.currentUser.getIdToken(true);
      dispatchToken({ type: 'UPDATE_DATA', payload: new_token });
      const payload = {
        firstname: data['firstname'] ?? '',
        lastname: data['lastname'] ?? '',
        role: data['role'] ?? '',
        company: data['company'] ?? '',
        sdi: data['sdi'] ?? '',
        pec: data['pec'] ?? '',
      };
      var response = await update_user_details_firebase(
        auth.currentUser.uid,
        payload
      );
      dispatchUserData({ type: 'UPDATE_DATA', payload: response });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const update_user_data = data => {
    dispatchUserData({ type: 'UPDATE_DATA', payload: data });
  };
  useEffect(() => {
    //setESGData({});
    dispatchEsgData({ action: 'CLEAR_ALL' });
    auth.onAuthStateChanged(function(user) {
      if (user) {
        if (user['accessToken'] != token) {
          dispatchToken({ type: 'UPDATE_DATA', payload: user['accessToken'] });
        }
      } else {
        dispatchToken({ type: 'UPDATE_DATA', payload: '' });
      }
    });
  }, []);

  useEffect(() => {
    console.log('NOVO ESG DATAA');
    console.log(esgData);
  }, [esgData]);
  const getToken = () => {
    return token;
  };

  useEffect(() => {
    if (Object.keys(esgData).includes('id')) {
      var old_esgData = esgData;
      old_esgData['visible_name'] = esgData.visible_name;
      //setESGData(old_esgData);
      dispatchEsgData({ action: 'UPDATE_ALL', payload: old_esgData });
    }
    dispatchVisibleName({ type: 'UPDATE_DATA', payload: esgData.visible_name });
  }, [reportID]);
  /*
  useEffect(()=>{
      var old_esgData=esgData;
      console.log(esgData)
      setReportID(old_esgData["visible_name"])
      setVisibleName(reportID);
    


  },[esgData]);
*/
  const updateVisibleName = newName => {
    console.log('Update vi name');
    dispatchVisibleName({ type: 'UPDATE_DATA', payload: newName });
    console.log(esgData);
    var old_esgData = esgData;
    old_esgData['visible_name'] = newName;
    dispatchEsgData({ action: 'UPDATE_ALL', payload: old_esgData });
    //setESGData(old_esgData);
  };

  // Funzione per aggiungere nuovi dati ESG alla lista
  const pushESGData = (key, newData) => {
    console.log(key);
    console.log(newData);
    dispatchEsgData({ action: 'UPDATE_DATA', key: key, payload: newData });
    //setESGData({ ...esgData, [key]: newData });
    saveHistory();
  };
  const setHistory = () => {
    var esgHistory = localStorage.getItem('esg_data_history');
    dispatchEsgData({ action: 'UPDATE_ALL', payload: esgHistory });
    //setESGData(esgHistory);
  };

  const saveHistory = () => {
    var newHistory = esgData != undefined ? esgData : {};
    localStorage.setItem('esg_data_history', esgData);
  };
  const updateESGData = async () => {
    try {
      var new_token = await auth.currentUser.getIdToken(true);
      dispatchToken({ type: 'UPDATE_DATA', payload: new_token });
      /*       var response= await axios
        .post(SERVER_URL + 'updatereport', {
          token: new_token,
          reportID: reportID,
          payload: esgData,
        }); */

      var response = await update_report_firebase(reportID, esgData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const create_esg_report = async () => {
    try {
      var new_token = await auth.currentUser.getIdToken(true);
      dispatchToken({ type: 'UPDATE_DATA', payload: new_token });
      /*     return axios
      .post(
        SERVER_URL + 'createreportppt',
        {
          token: new_token,
          reportID: reportID,
          esgData: esgData,
        },
        {
          responseType: 'blob', // Indica che si aspetta un blob come risposta
        }
      )
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log('Errore ricevuto e lo passo');
        return 'KO';
      }); */

      var response = create_esg_report_firebase(reportID, esgData);
      return response;
    } catch (error) {
      throw error;
    }
  };
  // Funzione per svuotare la lista dei dati ESG
  const refreshESGData = () => {
    //setESGData([]);
    dispatchReportID({ type: 'UPDATE_DATA', payload: '' });
    saveHistory();
  };

  //funzioni per il mapping della cl

  const checklist_mapping = {
    strategia_fiscale: ['201'],
    fornitori_locali: ['204'],
    politiche_anticoncorrenziali: ['206'],
    certificazioni_aqs: [''],
    impatti_economici: ['202'],
    politiche_anticorruzione: ['205'],
    politiche_salariali: ['207'],
    valutazione_fornitori: ['308'],
    utilizzo_materie_prime: ['301'],
    utilizzo_acqua: ['303'],
    monitor_emissioni: ['305'],
    utilizzo_fonti_rinnovabili: ['302'],
    impatto_biodiversita: ['304'],
    smaltimento_rifiuti: ['306'],
    benefit_dipendenti: ['401'],
    politiche_inclusive: ['405'],
    popolazioniindigene: ['411'],
    politiche_impattisalute: ['416'],
    politiche_vioprivacy: ['418'],
    politiche_saluteesicurezza: ['410'],
    politiche_lavorominorile: ['408'],
    politiche_comunitalocali: ['413'],
    etichettatura_prodotti: ['417'],
  };

  const [esgTM, setEsgTM] = useState([
    '102-1',
    '102-2',
    '102-3',
    '102-6',
    '102-6a',
    '102-8',
    '102-9',
    '102-10',
    '102-11',
    '102-12',
    '102-13',
    '102-14',
    '102-15',
    '102-16',
    '102-17',
    '102-18',
    '102-19-20',
    '102-21',
    '102-22',
    '102-27',
    '102-28',
    '102-29',
    '201-1',
    '201-2',
    '203-1',
    '203-2',
    '204-1',
    '205-2-3',
    '301-1',
    '301-2',
    '301-3',
    '302-1-2-3',
    '302-4',
    '303-1',
    '305-1',
    '306-1',
    '308-1',
    '401-1',
    '401-2',
    '401-3',
    '403-1',
    '404-1',
    '405-1',
    '405-2',
    '406-1',
    '413-1',
  ]);

  // Funzione per aggiungere nuovi dati ESG alla lista
  const pushclToESGData = (key, newData) => {
    setclData({ ...clData, [key]: newData });
    //ok, ho inserito i dati della checklist, andiamo ad escludere per adesso anagrafica
    //concentriamoci sulle altre sezioni e vediamo quali elementi dello standard tenere
    // o eliminare
  };
  useEffect(() => {
    console.log('Ho settato i dati cl');
    console.log(clData);
  }, [clData]);
  const createUpdatedStd = () => {
    const plain_ambiente = clData['checklist']['ambiente'];
    const plain_sociale = clData['checklist']['sociale'];
    const plain_governance = clData['checklist']['governance'];
    const actual_temi = esgTM;

    Object.keys(plain_ambiente).forEach(key_ambiente => {
      if (
        plain_ambiente[key_ambiente].toLowerCase() == 'no' ||
        plain_ambiente[key_ambiente].toLowerCase() == 'na'
      ) {
        console.log('Dentro ambiente');
        checklist_mapping[key_ambiente].forEach(key_mapping => {
          actual_temi.forEach(temamateriale => {
            if (temamateriale.split('-')[0] == key_mapping) {
              if (temamateriale == '302-1-2-3') {
              } else {
                const index = actual_temi.indexOf(key_mapping);
                actual_temi.splice(index, 1);
              }
            }
          });
        });
      }
    });

    Object.keys(plain_sociale).forEach(key_sociale => {
      if (
        plain_sociale[key_sociale].toLowerCase() == 'no' ||
        plain_sociale[key_sociale].toLowerCase() == 'na'
      ) {
        console.log('Dentro scoaile');
        checklist_mapping[key_sociale].forEach(key_mapping => {
          //rimozione da esgTM
          actual_temi.forEach(temamateriale => {
            if (temamateriale.split('-')[0] == key_mapping) {
              const index = actual_temi.indexOf(key_mapping);
              actual_temi.splice(index, 1);
            }
          });
        });
      }
    });
    Object.keys(plain_governance).forEach(key_governance => {
      console.log('Dentro governance');
      if (
        plain_governance[key_governance].toLowerCase() == 'no' ||
        plain_governance[key_governance].toLowerCase() == 'na'
      ) {
        checklist_mapping[key_governance].forEach(key_mapping => {
          actual_temi.forEach(temamateriale => {
            if (temamateriale.split('-')[0] == key_mapping) {
              const index = actual_temi.indexOf(key_mapping);
              actual_temi.splice(index, 1);
            }
          });
        });
        //rimozione da esgTM
      }
    });

    setEsgTM(actual_temi);
    console.log(actual_temi);
    return actual_temi;
  };

  // Funzione per svuotare la lista dei dati ESG
  const refreshclToESGData = () => {
    setclData([]);
  };

  return (
    <ESGContext.Provider
      value={{
        esgData,
        pushESGData,
        refreshESGData,
        create_new_report,
        updateESGData,
        visibleName,
        updateVisibleName,
        return_user_details,
        dispatchUserData,
        userData,
        update_user_data,
        update_user_details,
        return_docs_uid,
        reportID,
        clData,
        pushclToESGData,
        refreshclToESGData,
        createUpdatedStd,
        create_esg_report,
        setReportIDOnCallback,
      }}>
      {children}
    </ESGContext.Provider>
  );
}
