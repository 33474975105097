import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';

import { Stack, TextArea } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const currentYear = new Date().getFullYear();
const years = [currentYear - 3, currentYear - 2, currentYear - 1];

const createDefaultDataCongedo = () => [
  {
    Anno: years[0],
    Men: 0,
    Women: 0,
    Total: 0,
  },
  {
    Anno: years[1],
    Men: 0,
    Women: 0,
    Total: 0,
  },
  {
    Anno: years[2],
    Men: 0,
    Women: 0,
    Total: 0,
  },
];
const createDefaultDataRientro = () => [
  {
    Anno: years[0],
    Men: 0,
    Women: 0,
    Total: 0,
  },
  {
    Anno: years[1],
    Men: 0,
    Women: 0,
    Total: 0,
  },
  {
    Anno: years[2],
    Men: 0,
    Women: 0,
    Total: 0,
  },
];

const ESG401_3 = () => {
  const [congedoParentaleData, setCongedoParentaleData] = useState(
    createDefaultDataCongedo()
  );
  const [ritornoLavoroData, setRitornoLavoroData] = useState(
    createDefaultDataRientro()
  );
  const [esg401_3, setEsg401_3] = useState();
  const { esgData, pushESGData, refreshESGData } = useESG();
  const gridOptionsCongedo = {
    defaultColDef: {
      editable: true,
      valueParser: params => {
        const value = parseFloat(params.newValue);
        return isNaN(value) || value <= 0 ? null : value;
      },
    },
  };

  const gridOptionsRitorno = {
    defaultColDef: {
      editable: true,
      valueParser: params => {
        const value = parseFloat(params.newValue);
        return isNaN(value) || value <= 0 ? null : value;
      },
    },
  };

  const onCellValueChanged = event => {
    const { data } = event;
    const newData = { ...data };
    setCongedoParentaleData(prevData => ({
      ...prevData,
      [data.Anno]: newData,
    }));
  };

  const columnDefsUso = [
    { headerName: 'Anno', field: 'Anno', flex: 1 },
    { headerName: 'Uomini', field: 'Men', editable: true, flex: 1 },
    { headerName: 'Donne', field: 'Women', editable: true, flex: 1 },
    {
      headerName: 'Tot',
      field: 'Total',
      flex: 1,
      valueGetter: params => params.data.Men + params.data.Women,
    },
  ];

  const columnDefsRitorno = [
    { headerName: 'Anno', field: 'Anno', flex: 1 },
    { headerName: 'Uomini', field: 'Men', editable: true, flex: 1 },
    { headerName: 'Donne', field: 'Women', editable: true, flex: 1 },
    {
      headerName: 'Tot',
      field: 'Total',
      flex: 1,
      valueGetter: params => params.data.Men + params.data.Women,
    },
  ];

  useEffect(() => {
    if (esgData['401-3'] != undefined) {
      setEsg401_3(esgData['401-3']);

      if (esgData['401-3']['usocongedo'] != undefined) {
        setCongedoParentaleData(esgData['401-3']['usocongedo']);
      }
      if (esgData['401-3']['ritornocongedo'] != undefined) {
        setRitornoLavoroData(esgData['401-3']['ritornocongedo']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('401-3', esg401_3);
  }, [esg401_3]);

  const onCellValueChangedUsoCongedo = e => {
    const ex_rowData = congedoParentaleData;
    ex_rowData[e.rowIndex] = e.data;
    setCongedoParentaleData(ex_rowData);
    setEsg401_3({ ...esg401_3, ['usocongedo']: ex_rowData });
  };

  const onCellValueChangedRitornoCongedo = e => {
    const ex_rowData = ritornoLavoroData;
    ex_rowData[e.rowIndex] = e.data;
    setRitornoLavoroData(ex_rowData);
    setEsg401_3({ ...esg401_3, ['ritornocongedo']: ex_rowData });
  };
  const handleChanges = e => {
    setEsg401_3({ ...esg401_3, [e.target.id]: e.target.value });
  };
  return (
    <Stack gap={8}>
      <h1>Congedo Parentale</h1>
      <p>
        Per 'dipendenti che avevano diritto al congedo parentale' si intende
        coloro che - ai sensi di normative, accordi o contratti con
        l'organizzazione - hanno diritto al congedo parentale.
      </p>
      <TextArea
        id="descrizione_congedo"
        maxCount={1000}
        enableCounter={true}
        value={
          esg401_3 != undefined && esg401_3['descrizione_congedo'] != undefined
            ? esg401_3['descrizione_congedo']
            : ''
        }
        placeholder="Rendicontare le iniziative riguardo il congedo parentale messe in atto dall'organizzazione oltre le prescrizioni di legge"
        onChange={handleChanges}
        labelText="Iniziative Congedo"
      />
      <h3>Uso Congedo</h3>
      <p>
        Per determinare quali dipendenti erano ritornati al lavoro al termine
        del congedo parentale ed erano ancora presso l'organizzazione 12 mesi
        più tardi, si potranno consultare i dati dei periodi di rendicontazione
        precedenti.
      </p>

      <div
        className="ag-theme-alpine"
        style={{ height: '200px', width: '100%' }}>
        <AgGridReact
          gridOptions={gridOptionsCongedo}
          columnDefs={columnDefsUso}
          onCellValueChanged={onCellValueChangedUsoCongedo}
          rowData={congedoParentaleData}
          domLayout="autoHeight"
        />
      </div>
      <h3>Ritorno Congedo</h3>
      <p>
        Numero totale di dipendenti che sono tornati a lavorare dopo la fine del
        congedo parentale (sia obbligatorio che facoltativo) nel periodo di
        rendicontazione
      </p>
      <div
        className="ag-theme-alpine"
        style={{ height: '200px', width: '100%' }}>
        <AgGridReact
          gridOptions={gridOptionsRitorno}
          onCellValueChanged={onCellValueChangedRitornoCongedo}
          rowData={ritornoLavoroData}
          columnDefs={columnDefsRitorno}
          domLayout="autoHeight"
        />
      </div>
      <footer style={{ fontSize: 10, marginRight: 0 }}>[GRI 401-3]</footer>
    </Stack>
  );
};

export default ESG401_3;
