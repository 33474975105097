import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Select, Stack, RadioButtonGroup, RadioButton } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';
function ESG102_19_20() {
  const currentYear = new Date().getFullYear();
  const [esg102_19_20, setEsg102_19_20] = useState({});
  // Calcola i valori per gli anni dinamici
  const yearMinus2 = currentYear - 2;
  const yearMinus1 = currentYear - 1;
  const year = currentYear;
  const [remunerazionenorme, setRemunerazioneNorme] = useState(false);
  const [proceduraremunerazione, setProceduraRemunerazione] = useState(false);
  const [coinvStakeholder, setCoinvStakeholder] = useState(false);
  const [selectsinoData, setSelectsinoData] = useState([
    {
      Domanda:
        'Nelle norme di remunerazione dei membri del massimo organo di governo sono contemplati aspetti relativi alla gestione degli impatti ESG?',
      Risposta: 'selectsino',
    },
    {
      Domanda:
        'Esiste una procedura interna per la determinazione della retribuzione?',
      Risposta: 'selectsino',
    },
    {
      Domanda:
        'Gli stakeholder vengono coinvolti nella definizione delle norme di remunerazione?',
      Risposta: 'selectsino',
    },
  ]);

  const [retributiveData, setRetributiveData] = useState([
    { Anno: `${yearMinus2}`, 'n. retribuzioni': 0, Tipologia: '' },
    { Anno: `${yearMinus1}`, 'n. retribuzioni': 0, Tipologia: '' },
    { Anno: `${year}`, 'n. retribuzioni': 0, Tipologia: '' },
  ]);

  const gridOptions = {
    defaultColDef: {
      editable: true,
    },
  };

  const onSelectsinoCellValueChanged = event => {
    const { data } = event.node;
    const { colDef } = event.column;
    const { field } = colDef;
    data[field] = event.newValue;
    setSelectsinoData([...selectsinoData]);
  };

  const onRetributiveCellValueChanged = event => {
    const { data } = event.node;
    const { colDef } = event.column;
    const { field } = colDef;
    data[field] = event.newValue;
    setRetributiveData([...retributiveData]);
  };

  const selectOptions = [
    { value: 'SI', label: 'SI' },
    { value: 'No', label: 'No' },
  ];

  const frameworkComponents = {
    agSelectCellRenderer: Select,
  };

  const selectsinoColumnDefs = [
    { headerName: 'Domanda', field: 'Domanda' },
    {
      headerName: 'Risposta',
      field: 'Risposta',
      cellRenderer: 'agSelectCellRenderer',
      cellRendererParams: {
        options: selectOptions,
      },
    },
  ];

  const retributiveColumnDefs = [
    { headerName: 'Anno', field: 'Anno' },
    {
      headerName: 'n. retribuzioni',
      field: 'n. retribuzioni',
      editable: remunerazionenorme,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: 'Tipologia',
      field: 'Tipologia',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [
          'retribuzione fissa e variabile',
          'bonus conferito all’inizio dell’incarico e incentivi per la nomina',
          'pagamenti al termine del contratto di lavoro',
          'recuperi',
          'benefit di pensionamento',
        ],
        valueListGap: 0,
        editable: remunerazionenorme,
        autoHeight: true,
        wrapText: true,
        resizable: true,
      },
    },
  ];
  const handleSelectChange = (event, param) => {
    if (param === 'remunerazionenorme') {
      setRemunerazioneNorme(event);
    } else if (param === 'proceduraremunerazione') {
      setProceduraRemunerazione(event);
    } else if (param === 'coinvStakeholder') {
      setCoinvStakeholder(event);
    }

    if (!event) {
      setRetributiveData([
        { Anno: `${yearMinus2} (Anno-2)`, 'n. retribuzioni': 0, Tipologia: '' },
        { Anno: `${yearMinus1} (Anno-1)`, 'n. retribuzioni': 0, Tipologia: '' },
        { Anno: `${year} (Anno)`, 'n. retribuzioni': 0, Tipologia: '' },
      ]);
    }
  };
  return (
    <Stack gap={8}>
      <h1>GRI 102-19 e 102-20</h1>
      <h2>Remunerazione del massimo organo di governo</h2>
      <RadioButtonGroup
        onChange={event => handleSelectChange(event, 'remunerazionenorme')}
        legendText="Nelle norme di remunerazione dei membri del massimo organo di governo sono contemplati aspetti relativi alla gestione degli impatti ESG?"
        name="radio-button-group_norme"
        defaultSelected="radio-2a">
        <RadioButton labelText="Si" value={true} id="radio-1a" />
        <RadioButton labelText="No" value={false} id="radio-2a" />
      </RadioButtonGroup>
      <RadioButtonGroup
        onChange={event => handleSelectChange(event, 'proceduraremunerazione')}
        legendText="Esiste una procedura interna per la determinazione della retribuzione?"
        name="radio-button-group_direttive"
        defaultSelected="radio-2b">
        <RadioButton labelText="Si" value={true} id="radio-1b" />
        <RadioButton labelText="No" value={false} id="radio-2b" />
      </RadioButtonGroup>
      <RadioButtonGroup
        onChange={event => handleSelectChange(event, 'coinvStakeholder')}
        legendText="Gli stakeholder vengono coinvolti nella definizione delle norme di remunerazione?"
        name="radio-button-group_stake"
        defaultSelected="radio-2c">
        <RadioButton labelText="Si" value={true} id="radio-1c" />
        <RadioButton labelText="No" value={false} id="radio-2c" />
      </RadioButtonGroup>
      <h2>Dinamiche Retributive del massimo organo di governo</h2>
      <small>Compilabile solo se esistono Norme di Remunerazione</small>
      <div className="ag-theme-alpine" style={{ height: '200px' }}>
        <AgGridReact
          gridOptions={gridOptions}
          rowData={retributiveData}
          columnDefs={retributiveColumnDefs}
          onCellValueChanged={onRetributiveCellValueChanged}
          frameworkComponents={frameworkComponents}
        />
      </div>
    </Stack>
  );
}

export default ESG102_19_20;
