import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Stack, TextArea } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const getCurrentYear = () => {
  const currentDate = new Date();
  return currentDate.getFullYear() - 1;
};

const initialFormData = {
  actualyear: getCurrentYear(),
  num_tot_dipendenti: 0,
  tableData: {
    yearMinus2: {
      uomini: 0,
      donne: 0,
      oreMedie: 0,
    },
    yearMinus1: {
      uomini: 0,
      donne: 0,
      oreMedie: 0,
    },
    year: {
      uomini: 0,
      donne: 0,
      oreMedie: 0,
    },
  },
  formazioneProfessionale: {
    operai: {
      uomini: 0,
      donne: 0,
      totale: 0,
    },
    impiegati: {
      uomini: 0,
      donne: 0,
      totale: 0,
    },
    middleManagers: {
      uomini: 0,
      donne: 0,
      totale: 0,
    },
  },
};

const createData = () => {
  var data = [];
  for (let i = getCurrentYear() - 2; i <= getCurrentYear(); i++) {
    data.push({
      anno: i,
      oreTotali: '',
      uomini: '',
      donne: '',
      oreMedie: '',
    });
  }
  return data;
};
const columnDefsTipologiaDipendente = [
  {
    headerName: new Date().getFullYear() - 1,
    children: [
      {
        headerName: 'Categoria',
        field: 'categoria',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      {
        headerName: 'Ore Uomini',
        field: 'uomini',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      {
        headerName: 'Ore Donne',
        field: 'donne',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      {
        field: 'totale',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
    ],
  },
  {
    headerName: new Date().getFullYear() - 2,
    children: [
      {
        headerName: 'Categoria',
        field: 'categoria',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      {
        headerName: 'Ore Uomini',
        field: 'uomini',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      {
        headerName: 'Ore Donne',
        field: 'donne',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      {
        field: 'totale',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
    ],
  },
  {
    headerName: new Date().getFullYear() - 3,
    children: [
      {
        headerName: 'Categoria',
        field: 'categoria',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      {
        headerName: 'Ore Uomini',
        field: 'uomini',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      {
        headerName: 'Ore Donne',
        field: 'donne',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
      {
        field: 'totale',
        cellEditor: 'agTextEditor',
        editable: true,
        autoHeight: true,
        wrapText: true,
        resizable: true,
        flex: 1,
      },
    ],
  },
];
const initialRowsDefTipologiaDipendente = [
  { categoria: 'Operai', uomini: 0, donne: 0, totale: 0 },
  { categoria: 'Impiegati', uomini: 0, donne: 0, totale: 0 },
  {
    categoria: 'Middle Managers',
    uomini: 0,
    donne: 0,
    totale: 0,
  },
];
const tableColumnDefsSesso = [
  {
    headerName: 'Anno',
    field: 'anno',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Ore totale',
    field: 'oreTotali',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Uomini',
    field: 'uomini',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Donne',
    field: 'donne',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Ore medie',
    field: 'oreMedie',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
];

const tableColumnDefsNonObbligatoria = [
  {
    headerName: 'Anno',
    field: 'anno',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Uomini',
    field: 'uomini',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Donne',
    field: 'donne',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Ore medie',
    field: 'oreMedie',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },

  {
    headerName: 'Argomenti',
    field: 'argomento',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
];

const initialRowsDefNonObbligatoria = [
  {
    anno: new Date().getFullYear() - 3,
    uomini: 0,
    donne: 0,
    oreMedie: 0,

    argomento: '',
  },
  {
    anno: new Date().getFullYear() - 2,
    uomini: 0,
    donne: 0,
    oreMedie: 0,

    argomento: '',
  },
  {
    anno: new Date().getFullYear() - 1,
    uomini: 0,
    donne: 0,
    oreMedie: 0,

    argomento: '',
  },
];

const tableColumnDefsObbligatoria = [
  {
    headerName: 'Anno',
    field: 'anno',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Uomini',
    field: 'uomini',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Donne',
    field: 'donne',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
  {
    headerName: 'Ore medie',
    field: 'oreMedie',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },

  {
    headerName: 'Argomenti',
    field: 'argomento',
    cellEditor: 'agTextEditor',
    editable: true,
    autoHeight: true,
    wrapText: true,
    resizable: true,
    flex: 1,
  },
];

const initialRowsDefObbligatoria = [
  {
    anno: new Date().getFullYear() - 3,
    uomini: 0,
    donne: 0,
    oreMedie: 0,

    argomento: '',
  },
  {
    anno: new Date().getFullYear() - 2,
    uomini: 0,
    donne: 0,
    oreMedie: 0,

    argomento: '',
  },
  {
    anno: new Date().getFullYear() - 1,
    uomini: 0,
    donne: 0,
    oreMedie: 0,

    argomento: '',
  },
];
const initialRowsDef = [
  {
    anno: new Date().getFullYear() - 3,
    oreTotali: 0,
    uomini: 0,
    donne: 0,
    oreMedie: 0,
  },
  {
    anno: new Date().getFullYear() - 2,
    oreTotali: 0,
    uomini: 0,
    donne: 0,
    oreMedie: 0,
  },
  {
    anno: new Date().getFullYear() - 1,
    oreTotali: 0,
    uomini: 0,
    donne: 0,
    oreMedie: 0,
  },
];
const ESG404_1 = () => {
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [esg404_1, setEsg404_1] = useState();
  const [tableData, setTableData] = useState({});
  const [formazionesesso, setFormazionesesso] = useState(initialRowsDef);
  const [formazioneobbligatoria, setFormazioneobbligatoria] = useState(
    initialRowsDefObbligatoria
  );
  const [formazionenonobbligatoria, setFormazionenonobbligatoria] = useState(
    initialRowsDefNonObbligatoria
  );
  const [formazionedipendenti, setFormazionedipendenti] = useState(
    initialRowsDefTipologiaDipendente
  );

  useEffect(() => {
    if (esgData['404-1'] != undefined) {
      setEsg404_1(esgData['404-1']);
      if (esgData['404-1']['formazionesesso'] != undefined) {
        setFormazionesesso(esgData['404-1']['formazionesesso']);
      }
      if (esgData['404-1']['formazioneobbligatoria'] != undefined) {
        setFormazioneobbligatoria(esgData['404-1']['formazioneobbligatoria']);
      }
      if (esgData['404-1']['formazionenonobbligatoria'] != undefined) {
        setFormazionenonobbligatoria(
          esgData['404-1']['formazionenonobbligatoria']
        );
      }
      if (esgData['404-1']['formazionedipendenti'] != undefined) {
        setFormazionedipendenti(esgData['404-1']['formazionedipendenti']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('404-1', esg404_1);
  }, [esg404_1]);

  const onCellValueChangedformazionesesso = e => {
    const ex_rowData = formazionesesso;
    ex_rowData[e.rowIndex] = e.data;
    setFormazionesesso(ex_rowData);
    setEsg404_1({ ...esg404_1, ['formazionesesso']: ex_rowData });
  };

  const onCellValueChangedformazioneobbligatoria = e => {
    const ex_rowData = formazioneobbligatoria;
    ex_rowData[e.rowIndex] = e.data;
    setFormazioneobbligatoria(ex_rowData);
    setEsg404_1({ ...esg404_1, ['formazioneobbligatoria']: ex_rowData });
  };

  const onCellValueChangedformazionenonobbligatoria = e => {
    const ex_rowData = formazionenonobbligatoria;
    ex_rowData[e.rowIndex] = e.data;
    setFormazionenonobbligatoria(ex_rowData);
    setEsg404_1({ ...esg404_1, ['formazionenonobbligatoria']: ex_rowData });
  };
  const onCellValueChangedformazionedipendenti = e => {
    const ex_rowData = formazionedipendenti;
    ex_rowData[e.rowIndex] = e.data;
    setFormazionedipendenti(ex_rowData);

    setEsg404_1({ ...esg404_1, ['formazionedipendenti']: ex_rowData });
  };

  const gridOptions = {
    pagination: true,
    paginationPageSize: 5,
  };
  const handleChanges = e => {
    setEsg404_1({ ...esg404_1, [e.target.id]: e.target.value });
  };

  return (
    <Stack gap={8}>
      <h1>Crescita e Formazione Professionale</h1>
      <p>
        Questa informativa fornisce indicazioni circa l’entità di investimento
        di un’organizzazione nella formazione e in che grado tale investimento è
        distribuito fra tutti i dipendenti. Nel contesto del presente Standard,
        il termine ’formazione’ fa riferimento a:
        <br />
        • ogni tipo di formazione e istruzione professionale;
        <br />
        • congedi retribuiti per motivi di studio offerti da un’organizzazione
        ai propri dipendenti;
        <br />
        • formazione o istruzione ricercate esternamente e finanziate,
        parzialmente o interamente, dall’organizzazione;
        <br />
        • formazione su argomenti specifici.
        <br />
        La formazione non comprende attività di addestramento in loco da parte
        dei supervisori.
      </p>
      <Stack gap={4}>
        <TextArea
          maxCount={1000}
          enableCounter={true}
          id="dichiarazione_formazione"
          value={
            esgData['404-1'] != undefined &&
            esgData['404-1']['dichiarazione_formazione'] != undefined
              ? esgData['404-1']['dichiarazione_formazione']
              : ''
          }
          placeholder="Descrivere l'investimento previsto verso la formazione delle risorse"
          onChange={handleChanges}
          labelText="Dichiarazione Investimento Formazione"
        />
        <h3>Ore di formazione annua per sesso</h3>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={formazionesesso}
            columnDefs={tableColumnDefsSesso}
            domLayout="autoHeight"
            onCellValueChanged={onCellValueChangedformazionesesso}
          />
        </div>
      </Stack>
      {/** 
      <Stack gap={4}>
        <h3>Ore di formazione annua per tipologia dipendente</h3>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={formazionedipendenti}
            columnDefs={columnDefsTipologiaDipendente}
            domLayout="autoHeight"
            onCellValueChanged={onCellValueChangedformazionedipendenti}
          />
        </div>
      </Stack>
*/}
      <Stack gap={4}>
        <h3>Ore di formazione non obbligatoria</h3>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={formazionenonobbligatoria}
            columnDefs={tableColumnDefsNonObbligatoria}
            domLayout="autoHeight"
            onCellValueChanged={onCellValueChangedformazionenonobbligatoria}
          />
        </div>
      </Stack>

      <Stack gap={4}>
        <h3>Ore di formazione obbligatoria</h3>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowData={formazioneobbligatoria}
            columnDefs={tableColumnDefsObbligatoria}
            domLayout="autoHeight"
            onCellValueChanged={onCellValueChangedformazioneobbligatoria}
          />
        </div>
      </Stack>
      <footer style={{ fontSize: 10, marginRight: 0 }}>GRI 404-1</footer>
    </Stack>
  );
};

export default ESG404_1;
