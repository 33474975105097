import React, { useState, useEffect } from 'react';

import { TextArea, Stack, RadioButton, RadioButtonGroup } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG408_1 = props => {
  const [esg408_1, setEsg408_1] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['408-1'] != undefined) {
      setEsg408_1(esgData['408-1']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('408-1', esg408_1);
  }, [esg408_1]);

  const handleChanges = e => {
    setEsg408_1({ ...esg408_1, [e.target.id]: e.target.value });
  };

  return (
    <Stack gap={8}>
      <h1> Lavoro Minorile e Forzato </h1>
      <p>
        Il processo di individuazione di attività e fornitori, come specificato
        nell'Informativa 408-1, può essere indicativo dell'approccio
        dell'organizzazione che rendiconta verso la valutazione del rischio
        sotto questo aspetto.
      </p>

      <TextArea
        id="esposizione_att_fornitori"
        maxCount={1000}
        enableCounter={true}
        value={
          esg408_1['esposizione_att_fornitori'] != undefined
            ? esg408_1['esposizione_att_fornitori']
            : ''
        }
        placeholder="Specificare con testo libero attività e fornitori che presentano un rischio significativo di episodi di lavoro minorile, specificando il tipo di attività e le zone geografiche coinvolte."
        onChange={handleChanges}
        labelText="Misure e Provvedimenti per abolire il lavoro forzato"
      />
      <TextArea
        id="misure_nolavmin"
        maxCount={1000}
        enableCounter={true}
        value={
          esg408_1['misure_nolavmin'] != undefined
            ? esg408_1['misure_nolavmin']
            : ''
        }
        placeholder="Descrivere le misure intraprese dall'organizzazione durante il periodo di rendicontazione per
      contribuire all'effettiva abolizione del lavoro minorile."
        onChange={handleChanges}
        labelText="Misure e provvedimenti per abolire il lavoro minorile"
      />
      <footer style={{ fontSize: 10, marginRight: 0 }}>408-1</footer>
    </Stack>
  );
};

export default ESG408_1;
