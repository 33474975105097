import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  TextArea,
  Select,
  RadioButton,
  RadioButtonGroup,
  Stack,
} from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG102_11 = () => {
  const [esg102_11, setEsg102_11] = useState({});

  const { esgData, pushESGData, refreshESGData } = useESG();

  //si utilizza useEffect per caricare i dati in ingresso se ci sono
  useEffect(() => {
    if (esgData['102-11'] != undefined) {
      setEsg102_11(esgData['102-11']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('102-11', esg102_11);
  }, [esg102_11]);
  const handleChangesSelect = e => {
    setEsg102_11({ ...esg102_11, is_president: e });
  };
  const handleChanges = e => {
    setEsg102_11({ ...esg102_11, [e.target.id]: e.target.value });
  };

  return (
    <Stack gap={8}>
      <h1>Presidente e Impatti ESG </h1>
      <p>Completare i campi di testo con le informazioni richieste.</p>
      <TextArea
        maxCount={1000}
        enableCounter={true}
        id="presidente_dirigente"
        value={
          esg102_11['presidente_dirigente'] != undefined
            ? esg102_11['presidente_dirigente']
            : ''
        }
        onChange={handleChanges}
        placeholder="Descrivere con testo libero, solo se il presidente è anche dirigente aziendale, spiegare i motivi di quest'organizzazione, le funzioni di management e come vengono mitigati i conflitti di interesse"
        labelText="Funzioni del Presidente"
      />
      <h1>Ruolo della dirigenza nel controllo della gestione degli impatti</h1>
      <TextArea
        id="ruolo_aog_gestioneimpatti"
        maxCount={700}
        enableCounter={true}
        value={
          esg102_11['ruolo_aog_gestioneimpatti'] != undefined
            ? esg102_11['ruolo_aog_gestioneimpatti']
            : ''
        }
        placeholder="Descrivere con testo libero il ruolo dei dirigenti nello sviluppo,
      nell'approvazione e nell'aggiornamento delle dichiarazioni di scopo, valore o missione
      dell'organizzazione, delle strategie, delle politiche e degli obiettivi relativi allo sviluppo
      sostenibile;"
        onChange={handleChanges}
        labelText="Ruolo Dirigenza nella Gestione degli Impatti"
      />
      <h1>Delega di responsabilità per la gestione di impatti</h1>

      <TextArea
        id="delega_aog_gestioneimpatti"
        maxCount={500}
        enableCounter={true}
        value={
          esg102_11['delega_aog_gestioneimpatti'] != undefined
            ? esg102_11['delega_aog_gestioneimpatti']
            : ''
        }
        placeholder="Descrivere con testo libero come la dirigenza delega la responsibilità della gestione
      degli impatti dell’organizzazione sull’economia, sull’ambiente e sulle persone"
        onChange={handleChanges}
        labelText="Deleghe dirigenza nella Gestione degli Impatti"
      />

      <TextArea
        id="ruolo_aog_duediligence"
        maxCount={500}
        enableCounter={true}
        value={
          esg102_11['ruolo_aog_duediligence'] != undefined
            ? esg102_11['ruolo_aog_duediligence']
            : ''
        }
        placeholder="Descrivere con testo libero descrivere il processo e la frequenza con cui gli alti dirigenti o altri dipendenti devono
      riferire alla dirigenza sulla gestione degli impatti dell’organizzazione
      sull’economia, sull’ambiente e sulle persone."
        onChange={handleChanges}
        labelText="Riferimento Dirigenza per la Gestione degli Impatti"
      />
      <footer style={{ fontSize: 10, marginRight: 0 }}>
        [ESRS 1] [GRI 102-11, GRI 102-12, GRI 102-13]
      </footer>
      {/* <TextArea id="presidente_dirigente" value={esg102_11["presidente_dirigente"]!=undefined?esg102_11["presidente_dirigente"]:""} placeholder="Descrivere con testo libero, solo se il presidente dell'alto organo di governo, è anche dirigente aziendale, spiegare i motivi di quest'organizzazione, le funzioni di management e come vengono mitigati i conflitti di interesse" onChange={handleChanges} labelText="Funzioni del Presidente dell'Alto Organo di Governo"></TextArea>
       */}
    </Stack>
  );
};

export default ESG102_11;
