import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Button } from '@carbon/react';
import LoadingOverlay from '../../Loader';
const IndirizzoComponent = ({ setAddess }) => {
  const [nazioni, setNazioni] = useState([]);
  const [regioni, setRegioni] = useState([]);
  const [province, setProvince] = useState([]);
  const [citta, setCitta] = useState([]);
  const [nazioniempty, setNazioniempty] = useState(true);
  const [regioniempty, setRegioniempty] = useState(true);
  const [provinceempty, setProvinceempty] = useState(true);
  const [cittaempty, setCittaempty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({
    nazione: '',
    regione: '',
    provincia: '',
    citta: '',
    via: '',
    cap: '',
    numeroCivico: '',
  });

  const username = 'stillab_esg';
  const getNations = async () => {
    setIsLoading(true);
    var response = await axios.get(
      'http://api.geonames.org/countryInfoJSON?username=stillab_esg&maxRows=100000'
    );
    const nazioniOptions = response.data.geonames.map(nazione => ({
      value: nazione.geonameId,
      label: nazione.countryName,
    }));
    setNazioni(nazioniOptions);
    setNazioniempty(nazioniOptions.length == 0 ? true : false);
    setRegioni([]);
    setIsLoading(false);
  };

  useEffect(() => {
    getNations();
  }, []);

  const handleNazioneChange = async selectedOption => {
    setIsLoading(true);
    setSelectedData({ ...selectedData, nazione: selectedOption.label });
    setAddess({ ...selectedData, nazione: selectedOption.label });
    var response = await axios.get(
      `http://api.geonames.org/childrenJSON?geonameId=${
        selectedOption.value
      }&username=stillab_esg&maxRows=100000`
    );
    const regioniOptions = response.data.geonames.map(regione => ({
      value: regione.geonameId,
      label: regione.toponymName,
    }));
    setRegioni(regioniOptions);
    setRegioniempty(regioniOptions.length == 0 ? true : false);
    setIsLoading(false);
  };

  const handleRegioneChange = async selectedOption => {
    setIsLoading(true);
    setSelectedData({ ...selectedData, regione: selectedOption.label });
    setAddess({ ...selectedData, regione: selectedOption.label });
    const response = await axios.get(
      `http://api.geonames.org/childrenJSON?geonameId=${
        selectedOption.value
      }&username=stillab_esg&maxRows=100000`
    );
    const provinceOptions = response.data.geonames.map(provincia => ({
      value: provincia.geonameId,
      label: provincia.toponymName,
    }));
    setProvince(provinceOptions);
    setProvinceempty(provinceOptions.length == 0 ? true : false);
    setIsLoading(false);
  };

  const handleProvinciaChange = async selectedOption => {
    setIsLoading(true);
    setSelectedData({ ...selectedData, provincia: selectedOption.label });
    setAddess({ ...selectedData, provincia: selectedOption.label });
    var response = await axios.get(
      `http://api.geonames.org/childrenJSON?geonameId=${
        selectedOption.value
      }&username=stillab_esg&maxRows=100000`
    );

    const cittaOptions = response.data.geonames.map(city => ({
      value: city.geonameId,
      label: city.toponymName,
    }));
    setCitta(cittaOptions);
    setCittaempty(cittaOptions.length == 0 ? true : false);
    setIsLoading(false);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setSelectedData({ ...selectedData, [name]: value });
    setAddess({ ...selectedData, [name]: value });
  };

  return (
    <div className="address-container">
      <div className="address-select">
        <label>Nazione</label>
        <Select
          options={nazioni}
          onChange={handleNazioneChange}
          isDisabled={nazioniempty}
        />
      </div>
      <div className="address-select">
        <label>Regione</label>
        <Select
          options={regioni}
          onChange={handleRegioneChange}
          isDisabled={regioniempty}
        />
      </div>
      <div className="address-select">
        <label>Provincia</label>
        <Select
          options={province}
          isDisabled={provinceempty}
          onChange={handleProvinciaChange}
        />
      </div>
      <div className="address-select">
        <label>Città</label>
        <Select
          options={citta}
          isDisabled={cittaempty}
          onChange={selectedOption => {
            setAddess({ ...selectedData, citta: selectedOption.label });
            setSelectedData({ ...selectedData, citta: selectedOption.label });
          }}
        />
      </div>
      <div className="address-input">
        <label>Via</label>
        <input
          type="text"
          name="via"
          value={selectedData.via}
          onChange={handleInputChange}
        />
      </div>
      <div className="address-input">
        <label>Cap</label>
        <input
          type="text"
          name="cap"
          value={selectedData.cap}
          onChange={handleInputChange}
        />
      </div>
      <div className="address-input">
        <label>Numero Civico</label>
        <input
          type="text"
          name="numeroCivico"
          value={selectedData.numeroCivico}
          onChange={handleInputChange}
        />
      </div>
      {isLoading && (
        <LoadingOverlay main_text={'Caricamento dati geografici'} />
      )}
    </div>
  );
};

export default IndirizzoComponent;
