import React from 'react';
import { Form, TextInput, Stack, Button } from '@carbon/react';
const Reset = () => {
  const handleSubmit = event => {
    event.preventDefault();
    // Verify that the passwords match
    if (event.target.password.value !== event.target.passwordverify.value) {
      return;
    }
    // Call Userfront.resetPassword()
  };
  const PasswordProps = {
    className: 'input',
    id: 'password',
    labelText: 'Password',
    invalidText:
      'Your password must be at least 6 characters as well as contain at least one uppercase, one lowercase, and one number.',
  };
  const PasswordVerifyProps = {
    className: 'input',
    id: 'passwordverify',
    labelText: 'Ripeti Password',
    invalidText:
      'Your password must be at least 6 characters as well as contain at least one uppercase, one lowercase, and one number.',
  };
  return (
    <Stack as="div" gap={8} className="stack_container">
      <Form className="LoginContainer" onSubmit={handleSubmit}>
        <img
          className="LogoLogin"
          src={`${process.env.PUBLIC_URL}/headerlogo.png`}
        />
        <TextInput
          type="password"
          required
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
          {...PasswordProps}
        />
        <TextInput
          type="password"
          required
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
          {...PasswordVerifyProps}
        />
        <Button className="ButtonForm" type="submit">
          Aggiorna Password
        </Button>
      </Form>
    </Stack>
  );
};
export default Reset;
