import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Tooltip, TextArea, Stack, Button } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useESG } from '../../../action/ESGData/esgdata';

const ESG201_2 = () => {
  const [rowData, setRowData] = useState([
    { Anno: 2022, NDirigenti: 0, PctAssunta: 0 },
  ]);
  const [defAltaDirigenza, setDefAltaDirigenza] = useState('');
  const [esg201_2, setEsg201_2] = useState({
    table: rowData,
    altadirigenza: {},
  });
  const { esgData, pushESGData, refreshESGData } = useESG();
  useEffect(() => {
    if (esgData['201-2'] != undefined) {
      if (esgData['201-2']['table'] != undefined) {
        setRowData(esgData['201-2']['table']);
      }
      if (esgData['201-2']['altadirigenza'] != undefined) {
        setDefAltaDirigenza(esgData['201-2']['altadirigenza']);
      }
      setEsg201_2(esgData['201-2']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('201-2', esg201_2);
  }, [esg201_2]);

  const gridApiRef = useRef(null);
  const addRow = () => {
    const newRow = { Anno: 2022, NDirigenti: 0, PctAssunta: 0 };
    setRowData([...rowData, newRow]);
  };
  const columnDefs = [
    {
      headerName: 'Anno',
      field: 'Anno',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: 'N Totale Dirigenti',
      field: 'NDirigenti',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: '% Assunta nella comunità locale',
      field: 'PctAssunta',
      cellEditor: 'agTextCellEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
  ];

  const onCellValueChanged = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
    setEsg201_2({ ...esg201_2, ['table']: ex_rowData });
  };
  const onGridReady = params => {
    gridApiRef.current = params.api;
  };
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
      setEsg201_2({ ...esg201_2, ['table']: updatedRowData });
    }
  };
  const handleChanges = e => {
    setDefAltaDirigenza(e.target.value);
    setEsg201_2({ ...esg201_2, [e.target.id]: e.target.value });
  };

  return (
    <Stack gap={8}>
      <h1>GRI 201-2</h1>
      <h2>
        Percentuale di alta dirigenza assunta attingendo dalla comunità locale
      </h2>
      <p>
        L’alta dirigenza assunta attingendo dalla comunità locale comprende le
        persone nate nello stesso mercato geografico in cui ha sede l’attività
        dell’organizzazione, oppure coloro che hanno diritto a risiedervi a
        tempo indeterminato (come ad esempio i cittadini naturalizzati o i
        titolari di visto permanente). La definizione geografica di ‘locale’ può
        includere la comunità circostante alla sede delle attività, una regione
        geografica all’interno di un Paese o un Paese stesso.
      </p>

      <div className="carbon-textareas">
        <TextArea
          maxCount={400}
          enableCounter={true}
          labelText="Descrivere con testo libero, l'importanza di assumere risorse che fanno parte della comunità locale"
          id="altadirigenza"
          value={defAltaDirigenza}
          onChange={handleChanges}
        />
      </div>

      <div
        className="ag-theme-alpine"
        style={{ height: '200px', width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          onCellValueChanged={onCellValueChanged}
        />
        <Button kind="ghost" onClick={addRow}>
          Aggiungi Riga
        </Button>
        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
    </Stack>
  );
};

export default ESG201_2;
