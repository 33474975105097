import Organigramma from '../../../../assets/html_template/professional/A4/07-informativa-gri102-8-organigramma.html';
import Organigramma169 from '../../../../assets/html_template/professional/169/08-informativa-gri102-8-organigramma.html';
import OrganigrammaPopA4 from '../../../../assets/html_template/pop/A4/07-informativa-gri102-8-organigramma.html';
import OrganigrammaPop169 from '../../../../assets/html_template/pop/169/08-informativa-gri102-8-organigramma.html';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { Button } from '@carbon/react';
import { ViewOff } from '@carbon/react/icons';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';
const OrganigrammaEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };

  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString = type == 'A4' ? Organigramma : Organigramma169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? OrganigrammaPopA4 : OrganigrammaPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString = type == 'A4' ? Organigramma : Organigramma169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);

  const force_update = templateData => {
    renderHTMLtoiFrame(templateData);
  };

  const [testoOrganigramma, setTestoOrganigramma] = useState('');
  const [immagineOrganigramma, setImmagineOrganigramma] = useState('');
  const [didascaliaOrganigramma, setDidascaliaOrganigramma] = useState('');
  const [organigrammaData, setOrganigrammaData] = useState(Organigramma);

  const [organigrammaDataA4, setorganigrammaDataA4] = useState(Organigramma);
  const [organigrammaData169, setorganigrammaaData169] = useState(
    Organigramma169
  );
  if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'pop'
  ) {
    setOrganigrammaData(OrganigrammaPopA4);
    setorganigrammaDataA4(OrganigrammaPopA4);
    setorganigrammaaData169(OrganigrammaPop169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'professional'
  ) {
    setOrganigrammaData(Organigramma);
    setorganigrammaDataA4(Organigramma);
    setorganigrammaaData169(Organigramma169);
  } else if (
    esgData != undefined &&
    esgData['template'] != undefined &&
    esgData['template']['name_sample'] == 'linear'
  ) {
    setOrganigrammaData(Organigramma);
    setorganigrammaDataA4(Organigramma);
    setorganigrammaaData169(Organigramma169);
  }
  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');
  const { esgData } = useESG();
  const imgRef = useRef();
  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();
  useEffect(() => {
    //all'aggiornamento
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setOrganigrammaData(OrganigrammaPopA4);
      setorganigrammaDataA4(OrganigrammaPopA4);
      setorganigrammaaData169(OrganigrammaPop169);
      force_update(type == 'A4' ? OrganigrammaPopA4 : OrganigrammaPop169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setOrganigrammaData(Organigramma);
      setorganigrammaDataA4(Organigramma);
      setorganigrammaaData169(Organigramma169);
      force_update(type == 'A4' ? Organigramma : Organigramma169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setOrganigrammaData(Organigramma);
      setorganigrammaDataA4(Organigramma);
      setorganigrammaaData169(Organigramma169);
      force_update(type == 'A4' ? Organigramma : Organigramma169);
    }
    if (
      esgData['102-7main'] != undefined &&
      esgData['102-7main']['presentazione_team'] != testoOrganigramma
    ) {
      setTestoOrganigramma(esgData['102-7main']['presentazione_team']);
      hasUpdate = true;
    }
    if (
      esgData['102-7main'] != undefined &&
      esgData['102-7main']['didascalia_organigramma'] != didascaliaOrganigramma
    ) {
      setDidascaliaOrganigramma(
        esgData['102-7main']['didascalia_organigramma']
      );
      hasUpdate = true;
    }
    if (
      esgData['102-7main'] != undefined &&
      esgData['102-7main']['foto_organigramma'] != immagineOrganigramma
    ) {
      setImmagineOrganigramma(esgData['102-7main']['foto_organigramma']);
      hasUpdate = true;
    }

    if (
      esgData['102-3'] != undefined &&
      esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
    ) {
      setPeriodoRendicontazione(esgData['102-3']['periodo_rendicontazione']);
      hasUpdate = true;
    }
    if (hasUpdate) {
      renderHTMLtoiFrame();
    }
  }, [esgData]);
  //prova img

  const updateDataUrl = (formed_url = '') => {
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = organigrammaData169;
        to_init = '169';
      } else {
        templateHTMLString = organigrammaDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['102-7main'] != undefined &&
        esgData['102-7main']['presentazione_team'] != undefined
      ) {
        const presentazione_team = parsedHTML.getElementById(
          'organigramma_text'
        );
        presentazione_team.innerHTML =
          esgData['102-7main']['presentazione_team'];
      }
      if (
        esgData['102-7main'] != undefined &&
        esgData['102-7main']['didascalia_organigramma'] != undefined
      ) {
        const didascalia_organigramma = parsedHTML.getElementById(
          'didascalia_organigramma'
        );
        didascalia_organigramma.innerHTML =
          esgData['102-7main']['didascalia_organigramma'];
      }
      if (
        esgData['102-7main'] != undefined &&
        esgData['102-7main']['foto_organigramma'] != undefined
      ) {
        const foto_organigramma = parsedHTML.getElementById(
          'immagine_organigramma'
        );
        foto_organigramma.src = esgData['102-7main']['foto_organigramma'];
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        periodo_rendicontazione.innerHTML =
          esgData['102-3']['periodo_rendicontazione'];
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('102-7main', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Struttura Aziendale',
          initA4: OrganigrammaPopA4,
          init169: OrganigrammaPop169,
          keys_to_check: [
            'organigramma_text',
            'immagine_organigramma',
            'didascalia_organigramma',
          ],
          gri: '102-7main',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('102-7main', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Struttura Aziendale',
          initA4: Organigramma,
          init169: Organigramma169,
          keys_to_check: [
            'organigramma_text',
            'immagine_organigramma',
            'didascalia_organigramma',
          ],
          gri: '102-7main',
        });
      }
    }
  };

  const renderHTMLtoiFrame = (templateData = '') => {
    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData != '' ? templateData : organigrammaData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['102-7main'] != undefined &&
          esgData['102-7main']['presentazione_team'] != testoOrganigramma
        ) {
          const presentazione_team = parsedHTML.getElementById(
            'organigramma_text'
          );
          presentazione_team.innerHTML =
            esgData['102-7main']['presentazione_team'];
        }
        if (
          esgData['102-7main'] != undefined &&
          esgData['102-7main']['didascalia_organigramma'] !=
            didascaliaOrganigramma
        ) {
          const didascalia_organigramma = parsedHTML.getElementById(
            'didascalia_organigramma'
          );
          didascalia_organigramma.innerHTML =
            esgData['102-7main']['didascalia_organigramma'];
        }
        if (
          esgData['102-7main'] != undefined &&
          esgData['102-7main']['foto_organigramma'] != immagineOrganigramma
        ) {
          const foto_organigramma = parsedHTML.getElementById(
            'immagine_organigramma'
          );
          foto_organigramma.src = esgData['102-7main']['foto_organigramma'];
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
        }
      } else {
        if (
          esgData['102-7main'] != undefined &&
          esgData['102-7main']['presentazione_team'] != undefined
        ) {
          const presentazione_team = parsedHTML.getElementById(
            'organigramma_text'
          );
          presentazione_team.innerHTML =
            esgData['102-7main']['presentazione_team'];
        }
        if (
          esgData['102-7main'] != undefined &&
          esgData['102-7main']['didascalia_organigramma'] != undefined
        ) {
          const didascalia_organigramma = parsedHTML.getElementById(
            'didascalia_organigramma'
          );
          didascalia_organigramma.innerHTML =
            esgData['102-7main']['didascalia_organigramma'];
        }
        if (
          esgData['102-7main'] != undefined &&
          esgData['102-7main']['foto_organigramma'] != undefined
        ) {
          const foto_organigramma = parsedHTML.getElementById(
            'immagine_organigramma'
          );
          foto_organigramma.src = esgData['102-7main']['foto_organigramma'];
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      setIframeURL(blobURL);

      setOrganigrammaData(parsedHTML.documentElement.outerHTML);
      updateDataUrl(blobURL);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };

  return (
    <div style={{ display: 'block' }}>
      <iframe title="Report" src={iframeURL} style={imageRes} />
    </div>
  );
};

export default OrganigrammaEngine;
