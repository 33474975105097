import GestioneCriticita from '../../../../assets/html_template/professional/A4/07_5-informativa-generale-gri102-gestionecriticita.html';
import GestioneCriticita169 from '../../../../assets/html_template/professional/169/07_5-informativa-generale-gri102-gestionecriticita.html';
//import GestioneCriticitaPopA4 from '../../../../assets/html_template/pop/A4/07_5-informativa-generale-gri102-gestionecriticita.html';
//import GestioneCriticitaPop169 from '../../../../assets/html_template/pop/169/07_5-informativa-generale-gri102-gestionecriticita.html';
import GestioneCriticitaPopA4 from '../../../../assets/html_template/pop/A4/07_5-informativa-generale-gri102-gestionecriticita.html';
import GestioneCriticitaPop169 from '../../../../assets/html_template/pop/169/07_5-informativa-generale-gri102-gestionecriticita.html';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useESG } from '../../../../action/ESGData/esgdata';
import { cloneDeep } from 'lodash';
import { useHTML } from '../../../../action/HTMLContext/HTMLContext';

const CriticitaEngine = ({ isOpen, type }) => {
  const resizeDataSmall = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.25)',
    transformOrigin: '0 0',
    marginTop: '-800px',
  };
  const resizeDataBig = {
    width: '800px',
    height: '1130px',
    transform: 'scale(0.56)',
    transformOrigin: '0 0',
    marginTop: '-400px',
  };
  const resizeDataSmall169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.15)',
    marginTop: '-800px',
  };
  const resizeDataBig169 = {
    width: '1800px',
    transformOrigin: '0 0',
    height: '1000px',
    transform: 'scale(0.35)',
    marginTop: '-600px',
  };
  const [imageRes, setImageRes] = useState(resizeDataSmall);
  const [template, setTemplate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isOpen && type == 'A4') {
      setImageRes(resizeDataBig);
    } else if (!isOpen && type == 'A4') {
      setImageRes(resizeDataSmall);
    } else if (isOpen && type == '169') {
      setImageRes(resizeDataBig169);
    } else if (!isOpen && type == '169') {
      setImageRes(resizeDataSmall169);
    }
  }, [isOpen, type]);
  const updateTemplate = useCallback(() => {
    var templateHTMLString = '';
    if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional'
    ) {
      templateHTMLString =
        type == 'A4' ? GestioneCriticita : GestioneCriticita169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop'
    ) {
      templateHTMLString =
        type == 'A4' ? GestioneCriticitaPopA4 : GestioneCriticitaPop169;
    } else if (
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear'
    ) {
      templateHTMLString =
        type == 'A4' ? GestioneCriticita : GestioneCriticita169;
    }
    force_update(templateHTMLString);
    //setCoverData(parsedHTML.documentElement.outerHTML)
    //const parser = new DOMParser();
  }, [type]);
  useEffect(() => {
    updateTemplate();
  }, [type]);
  const { esgData } = useESG();
  const [gestioneCriticita, setGestioneCriticita] = useState('');
  const [descrizioneCriticita, setDescrizioneCriticita] = useState('');
  const [criticitaData, setCriticitaData] = useState(GestioneCriticita);
  const [criticitaDataA4, setCriticitaDataA4] = useState(GestioneCriticita);
  const [criticitaData169, setCriticitaData169] = useState(
    GestioneCriticita169
  );

  const [periodoRendicontazione, setPeriodoRendicontazione] = useState('');
  const [tabellacriticita, setTabellaCriticita] = useState([]);
  const [previousTable, setPreviousTable] = useState([]);

  const [iframeURL, setIframeURL] = useState('');
  const { state, updatePageData, delPageData } = useHTML();
  useEffect(() => {
    var hasUpdate = false;
    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setGestioneCriticita(GestioneCriticitaPopA4);
      setCriticitaDataA4(GestioneCriticitaPopA4);
      setCriticitaData169(GestioneCriticitaPop169);
      force_update(
        type == 'A4' ? GestioneCriticitaPopA4 : GestioneCriticitaPop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setGestioneCriticita(GestioneCriticita);
      setCriticitaDataA4(GestioneCriticita);
      setCriticitaData169(GestioneCriticita169);
      force_update(type == 'A4' ? GestioneCriticita : GestioneCriticita169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setGestioneCriticita(GestioneCriticita);
      setCriticitaDataA4(GestioneCriticita);
      setCriticitaData169(GestioneCriticita169);
      force_update(type == 'A4' ? GestioneCriticita : GestioneCriticita169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('102-16')
    ) {
      setIsVisible(false);
      delPageData('102-16');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(criticitaData);
    } else {
      var changed = '';
      if (
        esgData['102-16'] != undefined &&
        esgData['102-16']['gestione_crit_maxorggov'] != undefined &&
        esgData['102-16']['gestione_crit_maxorggov'] != gestioneCriticita
      ) {
        changed = changed + ' gestione criticità,';
        hasUpdate = true;
      }
      if (
        esgData['102-16'] != undefined &&
        esgData['102-16']['descrizione_crit'] != undefined &&
        esgData['102-16']['descrizione_crit'] != descrizioneCriticita
      ) {
        changed = changed + ' descrizione_crit,';
        hasUpdate = true;
      }
      if (
        esgData['102-16'] != undefined &&
        esgData['102-16']['rendicontazione_criticita'] != undefined &&
        !compareLists(
          tabellacriticita,
          esgData['102-16']['rendicontazione_criticita']
        )
      ) {
        changed = changed + ' rend_crit,';
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        changed = changed + 'per rend,';

        hasUpdate = true;
      }

      if (hasUpdate) {
        if (esgData != undefined) {
          renderHTMLtoiFrame();
        }
      }
    }
  }, [esgData]);
  useEffect(() => {
    var hasUpdate = false;

    if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'pop' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setGestioneCriticita(GestioneCriticitaPopA4);
      setCriticitaDataA4(GestioneCriticitaPopA4);
      setCriticitaData169(GestioneCriticitaPop169);
      force_update(
        type == 'A4' ? GestioneCriticitaPopA4 : GestioneCriticitaPop169
      );
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'professional' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setGestioneCriticita(GestioneCriticita);
      setCriticitaDataA4(GestioneCriticita);
      setCriticitaData169(GestioneCriticita169);
      force_update(type == 'A4' ? GestioneCriticita : GestioneCriticita169);
    } else if (
      esgData != undefined &&
      esgData['template'] != undefined &&
      esgData['template']['name_sample'] == 'linear' &&
      esgData['template']['name_sample'] != template
    ) {
      setTemplate(esgData['template']['name_sample']);
      setGestioneCriticita(GestioneCriticita);
      setCriticitaDataA4(GestioneCriticita);
      setCriticitaData169(GestioneCriticita169);
      force_update(type == 'A4' ? GestioneCriticita : GestioneCriticita169);
    }

    if (
      esgData != undefined &&
      esgData['visibility'] != undefined &&
      esgData['visibility'].includes('102-16')
    ) {
      setIsVisible(false);
      delPageData('102-16');
    } else if (!isVisible) {
      setIsVisible(true);
      // Aggiorna la sorgente dell'oggetto img con l'URL dell'oggetto Blob
      setIframeURL('');
      renderHTMLtoiFrame(criticitaData);
    } else {
      var changed = '';
      if (
        esgData['102-16'] != undefined &&
        esgData['102-16']['gestione_crit_maxorggov'] != undefined &&
        esgData['102-16']['gestione_crit_maxorggov'] != gestioneCriticita
      ) {
        changed = changed + ' gestione criticità,';
        hasUpdate = true;
      }
      if (
        esgData['102-16'] != undefined &&
        esgData['102-16']['descrizione_crit'] != undefined &&
        esgData['102-16']['descrizione_crit'] != descrizioneCriticita
      ) {
        changed = changed + ' descrizione_crit,';
        hasUpdate = true;
      }
      if (
        esgData['102-16'] != undefined &&
        esgData['102-16']['rendicontazione_criticita'] != undefined &&
        !compareLists(
          tabellacriticita,
          esgData['102-16']['rendicontazione_criticita']
        )
      ) {
        changed = changed + ' rend_crit,';
        hasUpdate = true;
      }
      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
      ) {
        changed = changed + 'per rend,';

        hasUpdate = true;
      }

      if (hasUpdate) {
        if (esgData != undefined) {
          renderHTMLtoiFrame();
        }
      }
    }
  }, []);
  const force_update = templateData => {
    if (esgData != undefined) {
      renderHTMLtoiFrame(templateData);
    }
  };
  const compareLists = (list1, list2) => {
    try {
      // Verifica se le lunghezze delle liste sono diverse
      if (list1.length !== list2.length) {
        return false;
      }

      // Confronto degli oggetti all'interno delle liste
      for (let i = 0; i < list1.length; i++) {
        const obj1 = list1[i];
        const obj2 = list2[i];

        // Comparazione delle proprietà degli oggetti
        const objKeys1 = Object.keys(obj1);
        const objKeys2 = Object.keys(obj2);

        if (
          objKeys1.length !== objKeys2.length ||
          !objKeys1.every(key => objKeys2.includes(key))
        ) {
          return false;
        }

        // Confronta i valori di tutte le proprietà degli oggetti
        for (const key of objKeys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    } catch {
      return false;
    }
  };
  const popolaTabella = (table, data, ordered_keys = '') => {
    // Ottieni l'intestazione della tabella
    const tableHeader = table.querySelector('thead tr');
    tableHeader.innerHTML = '';

    // Popola l'intestazione
    const headerKeys = ordered_keys == '' ? Object.keys(data[0]) : ordered_keys;

    headerKeys.forEach(colonna => {
      const th = document.createElement('th');
      th.textContent = colonna;
      tableHeader.appendChild(th);
    });

    // Ottieni il corpo della tabella
    const tableBody = table.querySelector('tbody');
    tableBody.innerHTML = '';

    // Popola il corpo
    data.forEach(riga => {
      const tr = document.createElement('tr');

      // Popola ogni cella della riga
      headerKeys.forEach(colonna => {
        const td = document.createElement('td');
        td.textContent = riga[colonna];
        tr.appendChild(td);
      });

      // Aggiungi la riga al corpo della tabella
      tableBody.appendChild(tr);
    });
  };

  const updateDataUrl = (formed_url = '') => {
    const ordered_keys = ['natura', 'nCriticità', 'impatti'];
    if (formed_url != '') {
      var to_init = '';
      var templateHTMLString = '';
      if (type == 'A4') {
        templateHTMLString = criticitaData169;
        to_init = '169';
      } else {
        templateHTMLString = criticitaDataA4;
        to_init = 'A4';
      }

      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (
        esgData['102-16'] != undefined &&
        esgData['102-16']['gestione_crit_maxorggov'] != undefined
      ) {
        const fotoLeaderElement = parsedHTML.getElementById(
          'gestione_crit_maxorggov'
        );
        fotoLeaderElement.innerHTML =
          esgData['102-16']['gestione_crit_maxorggov'];
        setGestioneCriticita(esgData['102-16']['gestione_crit_maxorggov']);
      }

      if (
        esgData['102-16'] != undefined &&
        esgData['102-16']['descrizione_crit'] != undefined
      ) {
        const citazioneP = parsedHTML.getElementById('descrizione_crit');
        citazioneP.innerHTML = esgData['102-16']['descrizione_crit'];
        setDescrizioneCriticita(esgData['102-16']['descrizione_crit']);
      }
      //

      if (
        esgData['102-16'] != undefined &&
        esgData['102-16']['rendicontazione_criticita'] != undefined
      ) {
        const table = parsedHTML.getElementById('rendicontazione_criticita');
        if (esgData['102-16']['rendicontazione_criticita'].length > 0) {
          let orderedKeys = ['nCriticità', 'natura', 'impatti'];
          let ordered_data = esgData['102-16']['rendicontazione_criticita'];
          ordered_data.forEach(item => {
            let orderedItem = {};
            orderedKeys.forEach(key => {
              orderedItem[key] = item[key];
            });
            return orderedItem;
          });
          popolaTabella(table, ordered_data, ordered_keys);
          setTabellaCriticita(
            cloneDeep(esgData['102-16']['rendicontazione_criticita'])
          );
        }
      }

      if (
        esgData['102-3'] != undefined &&
        esgData['102-3']['periodo_rendicontazione'] != undefined
      ) {
        const periodo_rendicontazione = parsedHTML.getElementById(
          'periodo_rendicontazione'
        );
        if (periodo_rendicontazione) {
          periodo_rendicontazione.innerHTML =
            esgData['102-3']['periodo_rendicontazione'];
          setPeriodoRendicontazione(
            esgData['102-3']['periodo_rendicontazione']
          );
        }
      }
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'pop'
      ) {
        updatePageData('102-16', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Gestione Segnalazioni',
          initA4: GestioneCriticitaPopA4,
          init169: GestioneCriticitaPop169,
          keys_to_check: [
            'gestione_crit_maxorggov',
            'rendicontazione_criticita',
            'descrizione_crit',
          ],
          gri: '102-16',
        });
      } else if (
        esgData['template'] != undefined &&
        esgData['template']['name_sample'] == 'professional'
      ) {
        updatePageData('102-16', {
          [type]: formed_url,
          [to_init]: blobURL,
          readable_name: 'Gestione Segnalazioni',
          initA4: GestioneCriticita,
          init169: GestioneCriticita169,
          keys_to_check: [
            'gestione_crit_maxorggov',
            'rendicontazione_criticita',
            'descrizione_crit',
          ],
          gri: '102-16',
        });
      }
    }
  };
  //prova img
  const renderHTMLtoiFrame = (templateData = '') => {
    const ordered_keys = ['natura', 'nCriticità', 'impatti'];

    try {
      // Ottieni il tuo template HTML dall'import
      const templateHTMLString =
        templateData == '' ? criticitaData : templateData;
      const parser = new DOMParser();
      // Analizza il testo HTML modificato
      const parsedHTML = parser.parseFromString(
        templateHTMLString,
        'text/html'
      );
      if (templateData == '') {
        if (
          esgData['102-16'] != undefined &&
          esgData['102-16']['gestione_crit_maxorggov'] != undefined &&
          esgData['102-16']['gestione_crit_maxorggov'] != gestioneCriticita
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'gestione_crit_maxorggov'
          );
          fotoLeaderElement.innerHTML =
            esgData['102-16']['gestione_crit_maxorggov'];
          setGestioneCriticita(esgData['102-16']['gestione_crit_maxorggov']);
        }

        if (
          esgData['102-16'] != undefined &&
          esgData['102-16']['descrizione_crit'] != undefined &&
          esgData['102-16']['descrizione_crit'] != descrizioneCriticita
        ) {
          const citazioneP = parsedHTML.getElementById('descrizione_crit');
          citazioneP.innerHTML = esgData['102-16']['descrizione_crit'];
          setDescrizioneCriticita(esgData['102-16']['descrizione_crit']);
        }
        //

        if (
          esgData['102-16'] != undefined &&
          esgData['102-16']['rendicontazione_criticita'] != undefined &&
          !compareLists(
            tabellacriticita,
            esgData['102-16']['rendicontazione_criticita']
          )
        ) {
          const table = parsedHTML.getElementById('rendicontazione_criticita');
          if (esgData['102-16']['rendicontazione_criticita'].length > 0) {
            let orderedKeys = ['nCriticità', 'natura', 'impatti'];
            let ordered_data = esgData['102-16']['rendicontazione_criticita'];
            ordered_data.forEach(item => {
              let orderedItem = {};
              orderedKeys.forEach(key => {
                orderedItem[key] = item[key];
              });
              return orderedItem;
            });
            popolaTabella(table, ordered_data, orderedKeys);

            setTabellaCriticita(
              cloneDeep(esgData['102-16']['rendicontazione_criticita'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != periodoRendicontazione
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          if (periodo_rendicontazione) {
            periodo_rendicontazione.innerHTML =
              esgData['102-3']['periodo_rendicontazione'];
            setPeriodoRendicontazione(
              esgData['102-3']['periodo_rendicontazione']
            );
          }
        }
      } else {
        if (
          esgData['102-16'] != undefined &&
          esgData['102-16']['gestione_crit_maxorggov'] != undefined
        ) {
          const fotoLeaderElement = parsedHTML.getElementById(
            'gestione_crit_maxorggov'
          );
          fotoLeaderElement.innerHTML =
            esgData['102-16']['gestione_crit_maxorggov'];
          setGestioneCriticita(esgData['102-16']['gestione_crit_maxorggov']);
        }

        if (
          esgData['102-16'] != undefined &&
          esgData['102-16']['descrizione_crit'] != undefined
        ) {
          const citazioneP = parsedHTML.getElementById('descrizione_crit');
          citazioneP.innerHTML = esgData['102-16']['descrizione_crit'];
          setDescrizioneCriticita(esgData['102-16']['descrizione_crit']);
        }
        //

        if (
          esgData['102-16'] != undefined &&
          esgData['102-16']['rendicontazione_criticita'] != undefined
        ) {
          const table = parsedHTML.getElementById('rendicontazione_criticita');
          if (esgData['102-16']['rendicontazione_criticita'].length > 0) {
            let orderedKeys = ['nCriticità', 'natura', 'impatti'];
            let ordered_data = esgData['102-16']['rendicontazione_criticita'];
            ordered_data.forEach(item => {
              let orderedItem = {};
              orderedKeys.forEach(key => {
                orderedItem[key] = item[key];
              });
              return orderedItem;
            });
            popolaTabella(table, ordered_data, orderedKeys);
            setTabellaCriticita(
              cloneDeep(esgData['102-16']['rendicontazione_criticita'])
            );
          }
        }

        if (
          esgData['102-3'] != undefined &&
          esgData['102-3']['periodo_rendicontazione'] != undefined
        ) {
          const periodo_rendicontazione = parsedHTML.getElementById(
            'periodo_rendicontazione'
          );
          if (periodo_rendicontazione) {
            periodo_rendicontazione.innerHTML =
              esgData['102-3']['periodo_rendicontazione'];
            setPeriodoRendicontazione(
              esgData['102-3']['periodo_rendicontazione']
            );
          }
        }
      }
      // Crea un oggetto Blob dai risultati dell'analisi
      const blob = new Blob([parsedHTML.documentElement.outerHTML], {
        type: 'text/html',
      });

      // Ottieni l'URL dell'oggetto Blob
      const blobURL = URL.createObjectURL(blob);

      setCriticitaData(parsedHTML.documentElement.outerHTML);
      updateDataUrl(blobURL);

      setIframeURL(blobURL);
    } catch (error) {
      console.error(
        'Errore durante la conversione del contenuto HTML in un oggetto renderizzabile:',
        error
      );
    }
  };
  return (
    <div style={{ display: 'block' }}>
      {isVisible ? (
        <iframe title="Report" src={iframeURL} style={imageRes} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CriticitaEngine;
