import React, { useState, useEffect, useRef } from 'react';
import { Stack, Button, Tooltip } from '@carbon/react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Information } from '@carbon/icons-react';
import { useESG } from '../../../action/ESGData/esgdata';
const ESG301_3 = () => {
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([
    {
      CategoriaProdotto: '',
      PercentualeProdottiRecuperati: 0,
      MaterialiConfezionamento: '',
    },
  ]);

  const onAddRowClick = () => {
    if (rowData.length < 5) {
      setRowData([
        ...rowData,
        {
          CategoriaProdotto: '',
          PercentualeProdottiRecuperati: 0,
          MaterialiConfezionamento: '',
        },
      ]);
    }
  };
  const { esgData, pushESGData, refreshESGData } = useESG();
  const columnDefs = [
    {
      headerName: 'Categoria Prodotto',
      field: 'CategoriaProdotto',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: '% Prodotti Recuperati',
      field: 'PercentualeProdottiRecuperati',
      cellEditor: 'agTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
    {
      headerName: 'Materiali Confezionamento',
      field: 'MaterialiConfezionamento',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      autoHeight: true,
      wrapText: true,
      resizable: true,
    },
  ];
  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
  };
  useEffect(() => {
    if (esgData['301-3'] != undefined) {
      setRowData(esgData['301-3']);
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    pushESGData('301-3', rowData);
  }, [rowData]);
  const onDeleteRow = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (selectedRows.length > 0) {
      // Filter out the selected rows and update rowData
      const updatedRowData = rowData.filter(row => !selectedRows.includes(row));
      setRowData(updatedRowData);
    }
  };

  const onGridReady = params => {
    gridApiRef.current = params.api;
  };

  return (
    <Stack gap={8}>
      <h1>GRI 301-3</h1>
      <p>
        Percentuale di prodotti recuperati e i relativi materiali di
        confezionamento per ciascuna categoria di prodotto
      </p>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          rowSelection={'multiple'}
          onCellValueChanged={onChangeRowValue}
        />
        <Button kind="ghost" onClick={onAddRowClick}>
          Aggiungi Riga
        </Button>

        <Button kind="ghost" onClick={onDeleteRow} className="delete-row">
          Elimina Riga
        </Button>
        <Tooltip
          align="bottom"
          label="Per cancellare le righe, selezionarle e premere il pulsante Elimina Riga">
          <Button kind="ghost" className="sb-tooltip-trigger" type="button">
            <Information />
          </Button>
        </Tooltip>
      </div>
    </Stack>
  );
};

export default ESG301_3;
