import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Stack } from '@carbon/react';
import { useESG } from '../../../action/ESGData/esgdata';

const ESGTableMaxGoverno = (setStateFun, esg102_9) => {
  const { esgData, pushESGData, refreshESGData } = useESG();
  const [rowData, setRowData] = useState([
    {
      type: 'Middle managers/dirigenti',
      monthsInOffice: 0,
      male: 0,
      female: 0,
      socialEnvironmentalImpact: '',
      stakeholdersRepresented: '',
    },
    {
      type: 'White collars/impiegati',
      monthsInOffice: 0,
      male: 0,
      female: 0,
      socialEnvironmentalImpact: '',
      stakeholdersRepresented: '',
    },
    {
      type: 'Blue collars/operai',
      monthsInOffice: 0,
      male: 0,
      female: 0,
      socialEnvironmentalImpact: '',
      stakeholdersRepresented: '',
    },
  ]);
  useEffect(() => {
    if (esgData['102-9'] != undefined) {
      if (esgData['102-9']['struttura_maxorg'] != undefined) {
        setRowData(esgData['102-9']['struttura_maxorg']);
      }
    } else {
    }
    return () => {
      // Puoi eseguire pulizie qui, se necessario
    };
  }, []);
  useEffect(() => {
    setStateFun['setStateFun']('struttura_maxorg', rowData);
  }, [rowData]);
  const columnDefs = [
    {
      headerName: 'Tipologia Membri',
      field: 'type',
      sortable: true,
      cellEditor: 'agTextEditor',
      filter: true,
      editable: true,
      resizable: true,
    },
    {
      headerName: 'Permanenza in carica',
      field: 'monthsInOffice',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '120' },
      editable: true,
      resizable: true,
    },
    {
      headerName: 'Uomini',
      field: 'male',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
      resizable: true,
    },
    {
      headerName: 'Donne',
      field: 'female',
      cellEditor: 'agTextEditor',
      cellEditorParams: { mask: '999' },
      editable: true,
      resizable: true,
    },

    {
      headerName: 'Influenze impatto socio ambientale',
      field: 'socialEnvironmentalImpact',
      cellEditor: 'agTextEditor',
      editable: true,
      resizable: true,
    },
    {
      headerName: 'Stakeholder Rappresentati',
      field: 'stakeholdersRepresented',
      cellEditor: 'agLargeTextEditor',
      editable: true,
      resizable: true,
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 250,
        rows: 10,
        cols: 50,
      },
    },
  ];

  const onChangeRowValue = e => {
    const ex_rowData = rowData;
    ex_rowData[e.rowIndex] = e.data;
    setRowData(ex_rowData);
  };

  return (
    <Stack gap={8}>
      <p>
        Rappresentare la struttura del massimo organo di governo e dei comitati
      </p>
      <div className="ag-theme-alpine" style={{ height: 300 }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onCellValueChanged={onChangeRowValue}
        />
      </div>
    </Stack>
  );
};

export default ESGTableMaxGoverno;
